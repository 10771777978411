import React from "react";
import "./ContentViewer.scss";
import { ContentViewerItem } from "./ContentViewerItem";
const ContentViewer = (props: any) => {
  const { data } = props;
  return (
    <div className="content-viewer-container">
      {data.map((item: any, index: number) => (
        <ContentViewerItem key={index} data={item} />
      ))}
    </div>
  );
};

export { ContentViewer };
