import { transformData } from "cards/indicator/utils/transform-data";
import { BarChart } from "components/charts/BarChart/BarChart";
import { BreakdownChart } from "components/charts/BreakdownChart/BreakdownChart";
import { ColumnChart } from "components/charts/ColumnChart/ColumnChart";
import { ColumnLineChart } from "components/charts/ColumnLineChart/ColumnLineChart";
import { FilledShapeChart } from "components/charts/FilledShapeChart/FilledShapeChart";
import { GroupedColumnChart } from "components/charts/GroupedColumnChart/GroupedColumnChart";
import { LineChart } from "components/charts/LineChart/LineChart";
import { MultiLineChart } from "components/charts/MultiLineChart/MultiLineChart";
import { PieChart } from "components/charts/PieChart/PieChart";
import { StackedColumnChart } from "components/charts/StackedColumnChart/StackedColumnChart";
import React from "react";

const ChartWrapper = ({ data, config }: any) => {
  let transformedData: any = {};
  if (config.chartType == "multiLineChart") {
    transformedData = data.length > 10 ? transformData(data?.slice(-10)) : data;
  }

  return (
    <div className="chart-wrapper-container">
      {(() => {
        switch (config.chartType) {
          case "lineChart":
            return <LineChart
              data={data}
              color={config?.color}
              decimalPrecision={config?.decimalPrecision}
            />;
          case "multiLineChart":
            return (
              <MultiLineChart
                data={data}
                decimalPrecision={config?.decimalPrecision}
              />
            );
          case "columnLineChart":
            return (
              <ColumnLineChart
                data={data}
                decimalPrecision={config?.decimalPrecision}
              />
            );
          case "pieChart":
            return (
              <PieChart
                data={data}
                decimalPrecision={config?.decimalPrecision}
              />
            );
          case "columnChart":
            return <ColumnChart
              data={data}
              color={config?.color}
              decimalPrecision={config?.decimalPrecision}
            />;
          case "groupedColumnChart":
            return <GroupedColumnChart
              data={data}
              decimalPrecision={config?.decimalPrecision}
              measurementUnit={config?.measurementUnit}
            />;
          case "stackedColumnChart":
            return <StackedColumnChart
              data={data}
              decimalPrecision={config?.decimalPrecision}
              measurementUnit={config?.measurementUnit}
            />;
          case "breakdownChart":
            return <BreakdownChart
              data={data}
              decimalPrecision={config?.decimalPrecision}
              measurementUnit={config?.measurementUnit}
            />;
          case "filledShapeChart":
            return <FilledShapeChart
              data={data}
              decimalPrecision={config?.decimalPrecision}
              measurementUnit={config?.measurementUnit}
            />;
        }
      })()}
    </div>
  );
};

export { ChartWrapper };
