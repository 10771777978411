import { useState, useEffect } from "react";
import "./AnimatedNumber.scss";

const AnimatedNumber = (props: any) => {
  const {
    color,
    prepend,
    append,
    type = "integer",
    duration = 500,
    value,
    fontWeight,
    fontSize,
    decimalPrecision = 0,
  } = props;

  const [animatedNumber, setAnimatedNumber] = useState(0 as any);

  useEffect(() => {
    setAnimatedNumber(0);
    let i: any = 0;
    let e: any = (value / duration) * 10;

    const interval = setInterval(() => {
      i = i + e;

      let val: any;

      if (type === "integer") {
        val = parseInt(i).toLocaleString("es-AR");
      } else if (type === "decimal") {
        val = parseFloat(i).toLocaleString("es-AR", {
          minimumFractionDigits: decimalPrecision,
          maximumFractionDigits: decimalPrecision,
        });
      }

      setAnimatedNumber(val);

      if (i > value) {
        clearInterval(interval);

        let finalValue: any;

        if (type === "integer") {
          finalValue = parseInt(value).toLocaleString("es-AR");
        } else if (type === "decimal") {
          finalValue = parseFloat(value).toLocaleString("es-AR", {
            minimumFractionDigits: decimalPrecision,
            maximumFractionDigits: decimalPrecision,
          });
        }

        setAnimatedNumber(finalValue);
      }
    }, 10);

    return () => clearInterval(interval);
  }, [value, type]);

  return (
    <div
      className="animated-number-container"
      style={{
        color: color,
        fontWeight: fontWeight,
        fontSize: fontSize,
      }}
    >
      {prepend && <span className="prepend">{prepend}</span>}
      <span>{animatedNumber}</span>
      {append && <span className="append">{append}</span>}
    </div>
  );
};

export { AnimatedNumber };
