import { useState, useEffect } from "react";
import { FieldEditControls } from "components/FieldEditControls/FieldEditControls";
import "./LongTextField.scss";

const LongTextField = (props: any) => {
  let {
    defaultValue,
    editable,
    label,
    editPath,
    id,
    onChange,
    recordId,
    placeholder,
    //readonly,
    name,
    height = 100,
    mode
  }: any = props;
  const [value, setValue] = useState(defaultValue as any);
  const [isEditEnabled, setIsEditEnabled] = useState(false);

  useEffect(() => {
    if (onChange) onChange(value);
  }, [value]);

  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);

  return (
    <div className="long-text-field-wrapper">
      {label && <label>{label}</label>}
      <div>
        <div>
          {mode == 'readonly' ||
            (mode == 'edit' && editable && !isEditEnabled) ||
            (mode == 'edit' && !editable) ? (
            <div className="readonly-content">
              <span>{value}</span>
            </div>
          ) : (
            <textarea
              placeholder={placeholder}
              id={id}
              value={value || ""}
              onChange={(e) => setValue(e.target.value)}
              style={{ height: height }}
            />
          )}
        </div>

        {(mode == 'edit' && editable) && (
          <FieldEditControls
            value={value}
            fieldName={name}
            editPath={editPath}
            onEditStart={() => {
              setIsEditEnabled(true);
            }}
            onEditCancel={(originalValue) => {
              setIsEditEnabled(false);
              setValue(originalValue);
            }}
            onEditEnd={(success: boolean, originalValue) => {
              setIsEditEnabled(false);
              if (!success) {
                setValue(originalValue);
              }
            }}
          />
        )}
      </div>
    </div>
  );
};

export { LongTextField };
