import React, { useEffect, useState } from 'react';
import Modal from 'components/Modal/Modal';
import './BoardModal.scss';
import BoardCard from 'components/BoardCard/BoardCard';
import SortableList, { SortableItem } from "react-easy-sort";
import { arrayMoveImmutable } from "array-move";
import recordService from 'services/recordService';
import AxiosInterceptor from "../../../AxiosInterceptor";
import urls from 'config/urls';
import { useDebounce } from 'hooks/useDebounce';
import { Spinner } from 'components/Spinner/Spinner'; // Importar Spinner si no está importado
import LoadingButton from '@mui/lab/LoadingButton'; // Importar LoadingButton
import Icon from 'components/Icon/Icon';

interface BoardModalProps {
  title: string;
  isOpen: boolean;
  data: any;
  parentCardId: number;
  onClose: () => void;
}

const BoardModal: React.FC<BoardModalProps> = ({ isOpen, title, data, parentCardId, onClose }) => {
  const [cards, setCards] = useState(data);
  const [loading, setLoading] = useState(false); // Estado para manejar la carga
  const [loadingMore, setLoadingMore] = useState(false); // Estado para manejar la carga de más tarjetas
  const [processing, setProcessing] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [offset, setOffset] = useState(0); // Estado para manejar el offset
  const [total, setTotal] = useState(0); // Estado para manejar el total de tarjetas
  const debouncedValue = useDebounce<string>(searchTerm, 500);
  const limit = 20;
  useEffect(() => {
    if (isOpen) {
      loadChildCards();
    }
  }, [isOpen, debouncedValue]);

  const loadChildCards = async (newOffset = 0, append = false) => {
    if (append) {
      setLoadingMore(true);
    } else {
      setLoading(true);
    }
    try {
      const res = await AxiosInterceptor.get(`${urls.server}/api/my/child-cards/${parentCardId}?search_term=${debouncedValue}&offset=${newOffset}&limit=${limit}`);
      const resData = res.data;
      if (append) {
        setCards((prev: any) => [...prev, ...resData.cards]);
      } else {
        setCards(resData.cards);
      }
      setTotal(resData.total);
    } catch (error) {
      console.error(error);
    } finally {
      if (append) {
        setLoadingMore(false);
      } else {
        setLoading(false);
      }
    }
  };

  const handleLoadMore = () => {
    const newOffset = offset + 10;
    setOffset(newOffset);
    loadChildCards(newOffset, true);
  };

  const sortCards = async (cards: any) => {
    setProcessing(true);
    try {
      const res = await recordService.sort({
        sortPath: `my/cards/${parentCardId}/sort-child-cards`,
        data: { data: cards }
      });
    } catch (error) {
      console.error(error);
    } finally {
      setProcessing(false);
    }
  };

  const handleSortEnd = (oldIndex: number, newIndex: number) => {
    let sortedCards = arrayMoveImmutable(cards, oldIndex, newIndex);
    setCards(sortedCards)
    sortCards(sortedCards);
  };

  const handleSearchChange = (res: string) => {
    setSearchTerm(res);
    setOffset(0);
  };

  return (
    <Modal title={title} className={'board-modal-container'} isOpen={isOpen} onClose={onClose} hasSearchBar onSearchChange={handleSearchChange}>
      {loading ? (
        <div className="spinner-wrapper">
          <Spinner visible={loading} />
        </div>
      ) : (
        <>
          <SortableList
            onSortEnd={handleSortEnd}
            className="cards-wrapper"
            draggedItemClassName="dragged"
          >
            {cards?.map((item: any, index: number) => {
              return (
                <SortableItem key={item.id}>
                  <div style={{ zIndex: 9999 }}>
                    <BoardCard
                      title={item.visible_name}
                      data={item.data}
                      config={item.config}
                      type={item.type}
                      isSortable={true}
                      isExpandable={item.is_expandable}
                      childCards={item.child_cards}
                      childCardsCount={item.child_cards_count}
                      id={item.id}
                    />
                  </div>
                </SortableItem>
              );
            })}
          </SortableList>
          {cards?.length < total && (
            <div className="loading-button-wrapper">
              <LoadingButton
                onClick={handleLoadMore}
                loading={loadingMore}
                startIcon={<Icon name="plus" />}
              >
                Más tarjetas
              </LoadingButton>
            </div>
          )}
        </>
      )}
    </Modal>
  );
}

export default BoardModal;
