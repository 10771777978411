import React, { useState, useEffect } from "react";
import { Dialog, IconButton, Slide, Alert, Fab, Snackbar } from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";

import "./StepBuilderModal.scss";
import AxiosInterceptor from "AxiosInterceptor";

import { makeStyles } from "@mui/styles";
import { Spinner } from "components/Spinner/Spinner";
import { StepList } from "../StepList/StepList";
import { FormModal } from "components/modals/FormModal/FormModal";
import { arrayMoveImmutable } from "array-move";

import { useSortItems } from "hooks/useSortItems";

import urls from "config/urls";
import Icon from "components/Icon/Icon";
const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const useStyles = makeStyles((theme) => ({
  dialog: {
    maxWidth: 500,
    width: "80%",
  },
}));
const StepBuilderModal = ({
  open,
  setOpen,
  onClose,
  workflowId,
  workflowVisibleName,
  workflowStepFields,
  workflowStepUserFields
}: any) => {
  const [refreshOnClose, setRefreshOnClose] = useState(false);
  const [alertIsOpen, setAlertIsOpen] = useState(false);
  const [formIsOpen, setFormIsOpen] = useState(false);
  const [formModalIsOpen, setFormModalIsOpen] = useState(false);
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [records, setRecords] = useState([]);
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  //const fields = getFields().step;

  const { sortItems, openAlert, processing, setOpenAlert, alertMsg, severity } =
    useSortItems({ sortedData: [], sortPath: `workflow-steps/sort` });
  const handleCloseAlert = (
    event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenAlert(false);
  };
  // const saveData = async () => {
  //   const res = await AxiosInterceptor.post(`${urls.server}/api/workflow-steps`, {
  //     data: {},
  //   });
  //   const data = await res?.data;
  //   setAlertIsOpen(true);

  // };
  const loadRecords = async () => {
    setLoading(true);
    try {
      const res = await AxiosInterceptor.get(
        `${urls.server}/api/workflow-steps/for-workflow/${workflowId}`
      );
      const data = await res?.data;
      setRecords(data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };
  useEffect(() => {
    if (open) loadRecords();
    else setOpenAlert(false);
  }, [open]);

  const handleSortEnd = ({
    oldIndex,
    newIndex,
  }: {
    oldIndex: number;
    newIndex: number;
  }): void => {
    let data = arrayMoveImmutable(records, oldIndex, newIndex);
    data.forEach((item: any, index: number) => {
      item.order = ++index;
    });
    console.log(data);
    setRecords(data);
    sortItems(data);
  };
  return (
    <Dialog
      fullScreen
      open={open}
      onClose={() => {
        setOpen(false);
        onClose({
          refresh: refreshOnClose,
        });
      }}
      TransitionComponent={Transition}
      PaperProps={{
        style: {
          backgroundColor: "#f2f2f4",
        },
      }}
      className="step-builder-modal-container"
    >
      <div className="modal-header">
        <div className="modal-title">
          <h2>Armador de pasos</h2>
          <div>
            <b>Workflow:</b> {workflowVisibleName}
          </div>
        </div>

        <div className="modal-end-btns">
          <IconButton
            color="inherit"
            onClick={() => {
              setOpen(false);
              onClose({
                refresh: refreshOnClose,
              });
            }}
            aria-label="close"
          >
            <Icon name="close" />
          </IconButton>
        </div>
      </div>
      <div className="modal-body">
        {(loading || processing) && (
          <div className="spinner-wrapper">
            <Spinner visible={loading || processing} />
          </div>
        )}
        {!loading && records.length == 0 && (
          <div className="no-content">
            <Icon name="workflow" />
            <p>No hay pasos cargados</p>
          </div>
        )}
        {!loading && !processing && records.length > 0 && (
          <>
            <StepList
              items={records}
              onSortEnd={handleSortEnd}
              resetState={loadRecords}
              workflowStepFields={workflowStepFields}
              workflowStepUserFields={workflowStepUserFields}
              onItemChange={(res: any) => {
                if (res) {
                  loadRecords();
                }
              }}
            />
          </>
        )}
      </div>
      <Fab
        className="fab-btn"
        color="primary"
        aria-label="add"
        onClick={() => {
          setFormModalIsOpen(true);
        }}
      >
        <Icon name="add" />
      </Fab>
      <FormModal
        isOpen={formModalIsOpen}
        onClose={(formData: any) => {
          setFormModalIsOpen(false)
          if (formData) {
            loadRecords();
            setRefreshOnClose(true);
          }
        }}
        title={"Paso del workflow"}
        fields={workflowStepFields}
        //tableName={"workflow-steps"}
        extraData={{ workflow_id: workflowId }}
      />

      {/* <InformationMessage
        open={openAlert}
        message={alertMsg}
        severity={severity}
        onClose={setOpenAlert}
      /> */}
      <Snackbar
        open={alertIsOpen}
        autoHideDuration={4000}
        onClose={handleCloseAlert}
      //style={{ zIndex: 999999 }}
      >
        <Alert
          variant="filled"
          onClose={handleCloseAlert}
          severity="success"
          sx={{ width: "100%" }}
        >
          Tabla guardada con éxito.
        </Alert>
      </Snackbar>
    </Dialog>
  );
};

export { StepBuilderModal };
