import { useState, useEffect } from "react";
import { DateCell } from "../cells/DateCell/DateCell";
import { TagsCell } from "../cells/TagsCell/TagsCell";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import {
  IconButton,
  Popover,
} from "@mui/material";

import { FormModal } from "../modals/FormModal/FormModal";
import { ThumbnailCell } from "../cells/ThumbnailCell/ThumbnailCell";
import { MoneyCell } from "../cells/MoneyCell/MoneyCell";
import DeleteConfirmationDialog from "components/dialogs/DeleteConfirmationDialog/DeleteConfirmationDialog";
import { RadioCell } from "components/cells/RadioCell/RadioCell";
import { StatusCell } from "components/cells/StatusCell/StatusCell";
import { Parser } from 'expr-eval';
import { MoreActionsButton } from "components/buttons/MoreActionsButton/MoreActionsButton";

const DynamicTableRow = ({
  data,
  cols,
  actions,
  moreActions,
  onSelectMoreAction,
  // id,
  // tableName,
  // recordId,
  // onDeleteRecord,
  // onEditRecord,
  // onSelectRowAction,
  // formFields,
  // formTitle,
  // deletePath
  //resetState,
}: any) => {
  const [confirmDialogIsOpen, setConfirmDialogIsOpen] = useState(false);
  // const [editRecordModalIsOpen, setEditRecordLogModalIsOpen] = useState(false);
  // const [viewRecordModalIsOpen, setViewRecordLogModalIsOpen] = useState(false);
  //const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  //const [processing, setProcessing] = useState(false);
  // const popoverIsOpen = Boolean(anchorEl);
  // const presentPopover = (event: React.MouseEvent<HTMLButtonElement>) => {
  //   setAnchorEl(event.currentTarget);
  // };
  // const closePopover = () => {
  //   setAnchorEl(null);
  //};
  // const handlePopoverClick = (action: any) => {
  //   console.log(action);
  //   setAnchorEl(null);
  //   switch (action.name) {
  //     // case "viewAuditLog":
  //     //   setAuditLogModalIsOpen(true);
  //     //   break;
  //     case "edit":
  //       setEditRecordLogModalIsOpen(true);
  //       break;
  //     case "view":
  //       setViewRecordLogModalIsOpen(true);
  //       break;
  //     case "delete":
  //       setConfirmDialogIsOpen(true);
  //       break;
  //     default:
  //       onSelectRowAction({ actionName: action?.name, recordId: data?.id });
  //       break;
  //   }
  // };
  // const closeConfirmDialog = () => {
  //   setConfirmDialogIsOpen(false);
  // };

  return (
    <tr>
      {cols.map((col: any, subIndex: number) => (
        <td
          key={subIndex}
          data-column-header={col["visibleName"]}
          style={{ width: col.type == "thumbnail" ? "70px" : "auto" }}
        >
          {(() => {
            switch (col.type) {
              case "checkbox":
                return <span>{data[col.name] ? "Si" : "No"}</span>;
              case "visibleId":
                return <span>{data.visible_id}</span>;
              case "date":
                return (
                  <span>
                    <DateCell
                      data={data[col.name]}
                      extraData={data}
                      config={col}
                      name={col.name}
                      showDiff={col?.showDiff}
                    />
                  </span>
                );
              case "tags":
                return (
                  <span>
                    <TagsCell data={data[col.name]} />
                  </span>
                );
              case "thumbnail":
                return (
                  <span>
                    <ThumbnailCell data={data[col.name]} />
                  </span>
                );
              case "status":
                return (
                  <span>
                    <StatusCell
                      data={data[col.name]}
                      options={col?.options}
                      readonly={col.readonly}
                      icon={col.icon}
                    />
                  </span>
                );
              case "money":
                return (
                  <span>
                    <MoneyCell data={data[col.name]} />
                  </span>
                );
              case "text":
              case "integer":
              case "decimal":
                return (
                  <span
                    style={{
                      textAlign: col.type === "text" ? "left" : "right",
                    }}
                  >
                    {col.prepend && <strong> {col.prepend} </strong>}
                    {
                      data[col.name] || "-"
                      //parser.evaluate(col?.name, data) || data[col.name]
                    }
                    {col.append && <strong> {col.append} </strong>}
                  </span>
                );
              case "radio":
                return (
                  <span>
                    <RadioCell data={data[col.name]} options={col.options} />
                  </span>
                );
              default:
                return <span>{data[col.name] || "-"}</span>;
            }
          })()}
        </td>
      ))}
      <td>
        {
          moreActions.length > 0 &&
          <MoreActionsButton
            id="row-more-actions-button"
            options={moreActions}
            context={data}
            onSelectItem={(res: any) => {
              if (onSelectMoreAction) {
                onSelectMoreAction({
                  actionName: res?.actionName,
                  actionVisibleName: res?.actionVisibleName,
                  currentRow: data,
                  config: res?.config,
                });
              }
            }}
          />
        }
        {/* <IconButton aria-label="actions" onClick={presentPopover}>
          <MoreVertIcon />
        </IconButton>

        <Popover
          id={"record_popover_" + id}
          open={popoverIsOpen}
          anchorEl={anchorEl}
          onClose={closePopover}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
        >
          <div className="popover-items">
            {actions.map((item: any, index: number) => {
              return (
                <a
                  key={index}
                  onClick={() => {
                    handlePopoverClick(item);
                  }}
                >
                  {item.visibleName}
                </a>
              );
            })}
          </div>
        </Popover> */}


        {/* <FormModal
          isOpen={viewRecordModalIsOpen}
          title={formTitle}
          fields={formFields}
          tableName={tableName}
          recordId={data.id}
          onClose={(formData: any) => {
            setViewRecordLogModalIsOpen(false)
            if (formData) {
              onEditRecord(true);
            }
          }}
          mode={'edit'}
        //readonly={true}
        /> */}
        {/* <DeleteConfirmationDialog
          //resetState={resetState}
          isOpen={confirmDialogIsOpen}
          onClose={() => setConfirmDialogIsOpen(false)}
          //onConfirm={closeConfirmDialog}
          tableName={tableName}
          data={data}
          recordId={recordId}
          confirmationText={
            <>
              Desea eliminar el registro <b>{data?.visible_id}</b>
            </>
          }
          onDeleteSuccess={() => {
            onDeleteRecord();
          }}
          deletePath={deletePath}
        /> */}
      </td>
    </tr>
  );
};

export { DynamicTableRow };
