import React, { useEffect, useState } from "react";

import "./IndicatorCard.scss";
import { SimpleIndicatorCardBody } from "../SimpleIndicatorCardBody/SimpleIndicatorCardBody";
import { SingleBreakdownIndicatorCardBody } from "../SingleBreakdownIndicatorCardBody/SingleBreakdownIndicatorCardBody";
import { Tooltip } from "@mui/material";
const IndicatorCard = (props: any) => {
  const { title, data, config } = props;
  useEffect(() => { }, []);
  return (
    <div className="indicator-card-container">
      <Tooltip
        placement="top"
        title={
          title
        }
        style={{
          top: "-10px"
        }}
      >
        <h2 className="card-title">{title}</h2>
      </Tooltip>

      <div className="card-body">
        {data.length > 0 ? (
          <>
            {config.indicatorType == "simple" ? (
              <SimpleIndicatorCardBody data={data} config={config} />
            ) : (
              <SingleBreakdownIndicatorCardBody data={data} config={config} />
            )}
          </>
        ) : (
          <div className="no-content">-</div>
        )}
      </div>
    </div>
  );
};

export { IndicatorCard };
