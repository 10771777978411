import React from "react";
import "./WebsiteBuilderCard.scss";
import CardPreview from "components/CardPreview/CardPreview";
const WebsiteBuilderCard = ({ title, config, data }: any) => {
  return (
    <div className="website-builder-card-container">
      <h2 className="card-title">{title}</h2>
      <div>
        <CardPreview
          type={config?.previewType}
          icon={config?.previewIcon}
          valueDefinitions={config?.previewValueDefinitions}
          data={data}
        />
      </div>
    </div>
  );
};

export { WebsiteBuilderCard };
