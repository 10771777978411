import { useState, useEffect } from "react";
import "./MiniColumnChart.scss";
const MiniColumnChart = (props: any) => {
  const { data, height = 60, color = "#666", onColumnSelect } = props;
  const [bars, setBars] = useState([] as any);
  const [selectedindex, setSelectedindex] = useState(data.length - 1);
  const chartHeight = height;
  const buildBars = () => {
    let array: any = [...data];
    const maxValue = array.reduce(
      (max: number, item: any) => Math.max(max, item.value),
      0
    );

    // Luego, iteramos a través de cada elemento del array y agregamos la propiedad 'height'
    array.forEach((item: any) => {
      item.height = (item.value / maxValue) * chartHeight;
    });
    setBars(array);
  };
  useEffect(() => {
    buildBars();
    console.log(data)
  }, []);
  const handleClick = (index: number) => {
    setSelectedindex(index);
  };
  useEffect(() => {
    onColumnSelect(data[selectedindex]);
  }, [selectedindex]);
  return (
    <div
      className="mini-column-chart-container"
      style={{ height: chartHeight + "px" }}
    >
      {bars.map((item: any, index: number) => {
        return (
          <a
            key={index}
            style={{
              height: item.height + "px",
              backgroundColor: index == selectedindex ? color : null,
            }}
            onClick={() => handleClick(index)}
          ></a>
        );
      })}
    </div>
  );
};

export { MiniColumnChart };
