import React, { useState, useEffect } from "react";
import "./DeletedRecordsModal.scss";
import Modal from "components/Modal/Modal";

interface ModalProps {
  isOpen: boolean;
  subtitle: string;
  onClose: (refreshOnClose?: boolean) => void;
}
const DeletedRecordsModal: React.FC<ModalProps> = ({
  isOpen,
  subtitle,
  onClose,

}) => {

  return (

    <>
      <Modal
        title={"Registros eliminados"}
        subtitle={subtitle}
        className={'deleted-records-modal-container'}
        isOpen={isOpen}
        onClose={onClose}
        hasSearchBar={true}
        onSearchChange={(searchTerm: string) => {

        }}
      >


        <></>
      </Modal>

    </>
  );
};

export { DeletedRecordsModal };
