import React, { useEffect, useState } from "react";
import { Button, IconButton } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import Icon from "components/Icon/Icon";
const ObjectCol = ({ defaultValue, onChange }: any) => {
  const [items, setItems] = useState([] as any);
  //const [items, setItems] = useState((defaultValue as any) || ([] as any));
  const addItem = () => {
    const itemsCopy = [...items];
    itemsCopy.push({ key: "", value: "" });
    setItems(itemsCopy);
  };
  const handleChangeItemInput = (
    index: number,
    value: string,
    type: string
  ) => {
    const itemsCopy = [...items];
    itemsCopy[index][type] = value;
    setItems(itemsCopy);
  };
  const handleDeleteItem = (index: number) => {
    const itemsCopy = [...items];
    itemsCopy.splice(index, 1);
    setItems(itemsCopy);
  };
  useEffect(() => {
    const object = items?.reduce((acc: any, item: any) => {
      acc[item.key] = item.value;
      return acc;
    }, {});
    onChange(object);
  }, [items]);
  useEffect(() => {
    //if (defaultValue) {
    // const object = defaultValue?.reduce((acc: any, item: any) => {
    //   acc[item.key] = item.value;
    //   return acc;
    // }, {});
    // setItems(object);
    console.log(defaultValue);
    //}
    if (defaultValue) {
      const items: any = [];
      const keys = Object.keys(defaultValue) as (keyof typeof defaultValue)[];
      console.log(keys); // 👉️ ['name', 'country']
      keys.forEach((key) => {
        // 👇️ name Bobby, country Chile
        //console.log(key, defaultValue[key]);
        items.push({ key: key, value: defaultValue[key] });
      });
      setItems(items);
    }
  }, []);
  return (
    <div className="object-wrapper">
      <div className="items-wrapper">
        {items?.map((item: any, index: number) => {
          return (
            <div key={index}>
              <input
                type="text"
                placeholder="key"
                value={item?.key || ""}
                onChange={(e) => {
                  handleChangeItemInput(index, e.target.value, "key");
                }}
              />
              <span>:</span>
              <input
                type="text"
                placeholder="value"
                value={item?.value || ""}
                onChange={(e) => {
                  handleChangeItemInput(index, e.target.value, "value");
                }}
              />
              <IconButton
                aria-label="delete"
                onClick={() => handleDeleteItem(index)}
                color="error"
                size="small"
              >
                <Icon name="delete" />
              </IconButton>
            </div>
          );
        })}
      </div>
      <div className="add-item-btn-wrapper">
        <Button onClick={addItem} startIcon={<Icon name="add" />}>
          Agregar
        </Button>
      </div>
    </div>
  );
};

export { ObjectCol };
