import React, { useState, useEffect } from "react";
import { ValueAddedTaxPicker } from "../ValueAddedTaxPicker/ValueAddedTaxPicker";
import { IconButton } from "@mui/material";
import "./EditableBillingItem.scss";
import { formatNumber } from "../../../../utils/formatNumber";
import Icon from "components/Icon/Icon";
const EditableBillingItem = ({
  value,
  onChange,
  onDelete,
  transactionType,
  adjustmentType,
  adjustmentValue,
  baseOn,
}: //documentType,
  any) => {
  const [data, setData] = useState(value);
  const [quantity, setQuantity] = useState(value.quantity);
  const [discountPercentage, setDiscountPercentage] = useState(0 as any);
  const [unitPrice, setUnitPrice] = useState(0 as any);

  useEffect(() => {
    setData((prev: any) => {
      const prevCopy = { ...prev };
      let price;
      if (transactionType == "sale") {
        // Calcula el precio en base al tipo de ajuste

        // Determina el precio base
        const basePrice = baseOn === "cost" ? prevCopy.cost : prevCopy.price;
        if (adjustmentType === "profit_margin") {
          // Si es un margen de ganancia
          price = basePrice * (1 + Number(adjustmentValue) / 100);
        } else if (adjustmentType === "discount") {
          // Si es un descuento
          price = basePrice * (1 - Number(adjustmentValue) / 100);
        } else {
          // Si no hay ajuste definido, usa el precio base
          price = basePrice;
        }
      } else {
        price = unitPrice;
      }
      prevCopy.unit_price = price;
      // Actualiza la cantidad
      prevCopy.quantity = quantity;
      // Actualiza el descuento
      prevCopy.discount_percentage = discountPercentage;

      // Calcula el monto de descuento
      prevCopy.discount_amount =
        (prevCopy.discount_percentage * prevCopy.quantity * price) / 100;

      // Calcula el subtotal
      prevCopy.subtotal = prevCopy.quantity * price - prevCopy.discount_amount;
      if (transactionType == "sale") {
        prevCopy.subtotal_with_tax = prevCopy.sale_value_added_tax
          ? prevCopy.subtotal * (1 + prevCopy.sale_value_added_tax.rate / 100)
          : prevCopy.subtotal;

        prevCopy.tax_amount = prevCopy.sale_value_added_tax?.rate
          ? prevCopy.subtotal * (prevCopy.sale_value_added_tax.rate / 100)
          : 0;
      } else {
        prevCopy.subtotal_with_tax = prevCopy.purchase_value_added_tax
          ? prevCopy.subtotal *
          (1 + prevCopy.purchase_value_added_tax.rate / 100)
          : prevCopy.subtotal;

        prevCopy.tax_amount = prevCopy.purchase_value_added_tax?.rate
          ? prevCopy.subtotal * (prevCopy.purchase_value_added_tax.rate / 100)
          : 0;
      }

      return prevCopy;
    });
  }, [
    discountPercentage,
    quantity,
    unitPrice,
    adjustmentType,
    adjustmentValue,
    baseOn,
  ]);
  useEffect(() => {
    console.log("data", data);
    onChange(data);
  }, [data]);
  useEffect(() => {
    console.log("value", value);
    //onChange(value);
    setQuantity(value.quantity);
  }, [value]);

  return (
    <tr className="editable-billing-item-container">
      <td data-column-header={"Cantidad"}>
        <span>
          <input
            type="number"
            value={quantity}
            onChange={(e) => {
              setQuantity(e.target.value);
            }}
          />
        </span>
      </td>
      <td data-column-header={"Detalle"}>
        <span>{data?.name}</span>
      </td>
      <td data-column-header={"Precio unitario"}>
        <span>
          {transactionType == "sale" ? (
            <div className="amount-wrapper">
              <span>$</span> {formatNumber(data?.unit_price)}
            </div>
          ) : (
            <div className="input-group">
              <span>$</span>
              <input
                value={unitPrice || 0}
                onChange={(e) => {
                  setUnitPrice(e.target.value);
                }}
              />
            </div>
          )}
        </span>
      </td>
      <td data-column-header={"Desc. (%)"}>
        <span>
          <div className="input-group">
            <input
              value={discountPercentage || 0}
              onChange={(e) => {
                setDiscountPercentage(e.target.value);
              }}
            />
            <span>%</span>
          </div>
        </span>
      </td>
      <td data-column-header={"Desc. ($)"}>
        <span>
          <div className="amount-wrapper">
            <span>$</span> {formatNumber(data?.discount_amount)}
          </div>
        </span>
      </td>
      <td data-column-header={"Subtotal"}>
        <span>
          <div className="amount-wrapper">
            <span>$</span> {formatNumber(data?.subtotal)}
          </div>
        </span>
      </td>
      <td data-column-header={"Impuesto"}>
        <span>
          <ValueAddedTaxPicker
            value={
              data[
              transactionType == "sale"
                ? "sale" + "_value_added_tax"
                : "purchase" + "_value_added_tax"
              ]
            }
            onChange={(data: any) => {
              console.log("data 1", data);
              //console.log(data?.rate);
              setData((prev: any) => {
                const prevCopy = { ...prev };
                if (transactionType == "sale") {
                  prevCopy.sale_value_added_tax = data;
                } else {
                  prevCopy.purchase_value_added_tax = data;
                }
                prevCopy.subtotal_with_tax = data?.rate
                  ? prevCopy.subtotal * (1 + data?.rate / 100)
                  : prevCopy.subtotal;
                if (transactionType == "sale") {
                  prevCopy.tax_amount = data?.rate
                    ? prevCopy.subtotal *
                    (prevCopy.sale_value_added_tax.rate / 100)
                    : 0;
                } else {
                  prevCopy.tax_amount = data?.rate
                    ? prevCopy.subtotal *
                    (prevCopy.purchase_value_added_tax.rate / 100)
                    : 0;
                }
                return prevCopy;
              });
            }}
          />
        </span>
      </td>
      <td data-column-header={"Subtotal con imp."}>
        <span>
          <div
            className="amount-wrapper"
            style={{ fontWeight: 700, fontSize: "1.1em" }}
          >
            <span>$</span> {formatNumber(data?.subtotal_with_tax)}
          </div>
        </span>
      </td>
      <td>
        <IconButton
          color="inherit"
          onClick={() => {
            onDelete(data);
          }}
          aria-label="remove item"
        >
          <Icon name="close" />
        </IconButton>
      </td>
    </tr>
  );
};

export { EditableBillingItem };
