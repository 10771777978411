import { useState, useEffect } from "react";
import { FieldEditControls } from "components/FieldEditControls/FieldEditControls";

import "./MoneyField.scss";
import { formatNumber } from "utils";

const MoneyField = (props: any) => {
  let {
    defaultValue,
    editable,
    label,
    editPath,
    id,
    onChange,
    placeholder,

    name,
    //readonly,
    mode
  }: any = props;
  const [value, setValue] = useState(defaultValue as any);
  const [isEditEnabled, setIsEditEnabled] = useState(false);

  useEffect(() => {
    if (onChange) {
      onChange(value);
    }
  }, [value]);
  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);

  return (
    <div className="money-field-container">
      {label && <label>{label}</label>}
      <div>
        <div>
          {mode == 'readonly' ||
            (mode == 'edit' && editable && !isEditEnabled) ||
            (mode == 'edit' && !editable) ? (
            <div className="readonly-content">
              <div className="value-wrapper">
                <span>$</span> <div>{value ? formatNumber(value) : "-"}</div>
              </div>
            </div>
          ) : (
            <div className="editable-content">
              <span>$</span>
              <input
                id={id}
                type="number"
                placeholder={placeholder}
                value={value || ""}
                onChange={(e) => setValue(e.target.value)}
              />
            </div>
          )}
        </div>

        {(mode == 'edit' && editable) && (
          <FieldEditControls
            value={value}
            fieldName={name}
            editPath={editPath}
            onEditStart={() => {
              setIsEditEnabled(true);
            }}
            onEditCancel={(originalValue) => {
              setIsEditEnabled(false);
              setValue(originalValue);
            }}
            onEditEnd={(success: boolean, originalValue) => {
              setIsEditEnabled(false);
              if (!success) {
                setValue(originalValue);
              }
            }}
          />
        )}
      </div>
    </div>
  );
};

export { MoneyField };
