import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { InputField } from "../../components/fields/InputField/InputField";
import { Button, Snackbar, Alert } from "@mui/material";
import axios from "axios";
import "./SignInPage.scss";
import urls from "config/urls";
import { PasswordField } from "../../components/fields/PasswordField/PasswordField";
import { useAuth } from "../../contexts/AuthContext";
import LogoAnimation from "../../components/animations/LogoAnimation/LogoAnimation";
import { Spinner } from "../../components/Spinner/Spinner";
import FooterAnimation from "components/animations/FooterAnimation/FooterAnimation";

const SignInPage = () => {
  const { setToken, setUser, getToken } = useAuth();
  const isAuthenticated = !!getToken();
  const navigate = useNavigate();
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [processing, setProcessing] = useState(false);
  const [alertIsOpen, setAlertIsOpen] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [loading, setLoading] = useState(false);
  const [logo, setLogo] = useState();
  const [logoWidth, setLogoWidth] = useState(200);
  const [footerAnimationName, setFooterAnimationName] = useState();
  //const [allowUserSignup, setAllowUserSignup] = useState(false);
  const [enableSignUpPage, setEnableSignUpPage] = useState(false);
  const handleChangeEmail = (value: any) => {
    setEmail(value);
  };
  const handleChangePassword = (value: any) => {
    setPassword(value);
  };
  const send = async () => {
    setProcessing(true);
    try {
      const res = await axios.post(`${urls.server}/api/login`, {
        email,
        password,
      });
      setProcessing(true);
      const data = await res?.data;
      setToken(data.token);
      setUser(data.user);

      navigate("/board");
    } catch (error: any) {
      error = JSON.parse(JSON.stringify(error));
      setProcessing(false);
      if (error.status == 401) {
        setErrorMsg("Usuario o Contraseña incorrectos");
      } else {
        setErrorMsg("Error del servidor");
      }
      setAlertIsOpen(true);
    }
  };

  const getSettings = async () => {
    setLoading(true);
    try {
      const res = await axios.get(`${urls.server}/api/public/settings`);
      setLoading(false);
      const data = await res?.data;
      setLogo(data.logo);
      setLogoWidth(data.logoWidth);
      setFooterAnimationName(data.signInAnimation);
      //setAllowUserSignup(data.allow_user_signup);
      setEnableSignUpPage(data.enableSignUpPage);
    } catch (error: any) {
      error = JSON.parse(JSON.stringify(error));

      setLoading(false);
    }
  };
  useEffect(() => {
    if (isAuthenticated) {
      navigate("/board");
    } else {
      getSettings();
    }
  }, []);
  return (
    <div className="sign-in-page-container">
      {loading && (
        <div className="spinner-wrapper">
          <Spinner visible={loading} />
        </div>
      )}
      {!loading && (
        <>
          <div className="logo-wrapper mt-30">
            {/* <img src={"assets/logos/logo.svg"} alt="" /> */}
            {logo ? <img src={urls.server + logo} alt="" style={{ maxWidth: logoWidth + 'px' }} /> : <LogoAnimation />}
          </div>
          <div className="form-wrapper">
            <h1>Ingresar</h1>
            <form>
              <div>
                <InputField
                  defaultValue=""
                  placeholder="Usuario"
                  //editable={true}
                  id="email"
                  onChange={(value: any) => handleChangeEmail(value)}
                />
              </div>
              <div>
                <PasswordField
                  borderRadius={99}
                  placeholder="Contraseña"
                  //placeholder="Contraseña"
                  onChange={(value: any) => handleChangePassword(value)}
                />
              </div>
              <div>
                <Button
                  onClick={send}
                  variant="contained"
                  disableElevation
                  disabled={processing}
                >
                  Ingresar
                </Button>
              </div>
              {
                enableSignUpPage &&
                <div>
                  <Button
                    variant="text"
                    onClick={() => {
                      navigate("/sign-up");
                    }}
                  >
                    Registrarse
                  </Button>
                </div>
              }


              <div>
                <Button
                  variant="text"
                  onClick={() => {
                    navigate("/forgot-password");
                  }}
                >
                  Olvide mi contraseña
                </Button>
              </div>
            </form>
          </div>


          {/* <FooterAnimation name="salta" /> */}
          {
            footerAnimationName && <FooterAnimation name={footerAnimationName} />
          }

        </>
      )}
      <Snackbar
        open={alertIsOpen}
        autoHideDuration={6000}
        onClose={() => setAlertIsOpen(false)}
      >
        <Alert
          variant="filled"
          onClose={() => setAlertIsOpen(false)}
          severity="error"
          sx={{ width: "100%" }}
        >
          {errorMsg}
        </Alert>
      </Snackbar>
    </div>
  );
};
export { SignInPage };
