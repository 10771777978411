// components/configurations/DynamicListConfiguration.tsx
import React from 'react';
import { ColorField } from 'components/fields/ColorField/ColorField';
import { RadioField } from 'components/fields/RadioField/RadioField';
import ExpandableSection from 'components/ExpandableSection/ExpandableSection';
import SizeField from '../../SizeField/SizeField';

interface DynamicListConfigurationProps {
    localConfig: any;
    selectedTab: string;
    handleChange: (field: string, value: any) => void;
    getFieldValues: (field: string) => any;
}

const DynamicListConfiguration: React.FC<DynamicListConfigurationProps> = ({
    localConfig,
    selectedTab,
    handleChange,
    getFieldValues
}) => {
    return (
        <>

            <ExpandableSection title="Envoltura items">
                <div className="action-wrapper">
                    <SizeField
                        label="Ancho (Envoltura items)"
                        field="itemsWrapper.width"
                        isEnabled={getFieldValues('itemsWrapper.width').value !== null}
                        value={getFieldValues('itemsWrapper.width').value}
                        unit={getFieldValues('itemsWrapper.width').unit}
                        handleChange={handleChange}
                        minValue={0}
                    />
                </div>
                <div className="action-wrapper">
                    <ColorField
                        defaultValue={selectedTab === 'default' ? localConfig?.itemsWrapper?.backgroundColor || null : localConfig?.itemsWrapper?.responsive?.[selectedTab]?.backgroundColor || localConfig?.itemsWrapper?.backgroundColor || null}
                        label={"Color de fondo (Envoltura items)"}
                        onChange={(color: any) => handleChange('itemsWrapper.backgroundColor', color)}
                    />
                </div>
                <SizeField
                    label="Margen interno arriba (Envoltura items)"
                    field="itemsWrapper.paddingTop"
                    isEnabled={getFieldValues('itemsWrapper.paddingTop').value !== null}
                    value={getFieldValues('itemsWrapper.paddingTop').value}
                    unit={getFieldValues('itemsWrapper.paddingTop').unit}
                    handleChange={handleChange}
                    minValue={0}
                />
                <SizeField
                    label="Margen interno abajo (Envoltura items)"
                    field="itemsWrapper.paddingBottom"
                    isEnabled={getFieldValues('itemsWrapper.paddingBottom').value !== null}
                    value={getFieldValues('itemsWrapper.paddingBottom').value}
                    unit={getFieldValues('itemsWrapper.paddingBottom').unit}
                    handleChange={handleChange}
                    minValue={0}
                />
                <SizeField
                    label="Margen interno derecha (Envoltura items)"
                    field="itemsWrapper.paddingRight"
                    isEnabled={getFieldValues('itemsWrapper.paddingRight').value !== null}
                    value={getFieldValues('itemsWrapper.paddingRight').value}
                    unit={getFieldValues('itemsWrapper.paddingRight').unit}
                    handleChange={handleChange}
                    minValue={0}
                />
                <SizeField
                    label="Margen interno izquierda (Envoltura items)"
                    field="itemsWrapper.paddingLeft"
                    isEnabled={getFieldValues('itemsWrapper.paddingLeft').value !== null}
                    value={getFieldValues('itemsWrapper.paddingLeft').value}
                    unit={getFieldValues('itemsWrapper.paddingLeft').unit}
                    handleChange={handleChange}
                    minValue={0}
                />

            </ExpandableSection>
        </>
    );
};

export default DynamicListConfiguration;
