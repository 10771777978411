import { Button } from "@mui/material";
import { useState } from "react";
import { TasksBoardModal } from "../TasksBoardModal/TasksBoardModal";
import { TasksCalendarModal } from "../TasksCalendarModal/TasksCalendarModal";
import "./TasksExpandedCard.scss";
import { CompletedTasksModal } from "../CompletedTasksModal/CompletedTasksModal";
import { TagsModal } from "../../../../components/modals/TagsModal/TagsModal";
import { TasksMapModal } from "../TasksMapModal/TasksMapModal";
import Icon from "components/Icon/Icon";
const TasksExpandedCard = (props: any) => {
  const { title, data, setRefreshOnClose } = props;
  const [calendarModalIsOpen, setCalendarModalIsOpen] = useState(false);
  const [mapModalIsOpen, setMapModalIsOpen] = useState(false);
  const [boardModalIsOpen, setBoardModalIsOpen] = useState(false);
  const [completedTasksModalIsOpen, setCompletedTasksModalIsOpen] =
    useState(false);
  const [tagsModalIsOpen, setTagsModalIsOpen] = useState(false);
  return (
    <>
      <div className="tasks-expanded-card-container">
        <div className="expanded-card-header">
          <h2>{title}</h2>
        </div>
        <div className="expanded-card-body">
          <a
            className="card-btn lg"
            onClick={() => {
              setCalendarModalIsOpen(true);
            }}
          >
            {/* <span>Crear</span> */}
            <strong>Calendario de tareas</strong>
            <div>
              <Icon name="calendarWrapper" />
              <Icon name="calendar" />
            </div>
          </a>

          <a
            className="card-btn lg"
            onClick={() => {
              setCompletedTasksModalIsOpen(true);
            }}
          >
            {/* <span>Crear</span> */}
            <strong>Tareas finalizadas</strong>
            <div>
              <Icon name="checkSquareWrapper" />
              <Icon name="checkSquare" />
            </div>
          </a>
          <a
            className="card-btn lg"
            onClick={() => {
              setMapModalIsOpen(true);
            }}
          >
            <strong>Mapa de tareas</strong>
            <div>
              <Icon name="mapWrapper" />
              <Icon name="map" />
            </div>
          </a>
          <a
            className="card-btn lg"
            onClick={() => {
              setTagsModalIsOpen(true);
            }}
          >
            <strong>Etiquetas de tareas</strong>
            <div>
              <Icon name="tagsWrapper" />
              <Icon name="tags" />
            </div>
          </a>
        </div>
      </div>
      <TasksCalendarModal
        isOpen={calendarModalIsOpen}
        onClose={(res: any) => {
          setCalendarModalIsOpen(false)
          if (res) {
            setRefreshOnClose(true);
          }
        }}
        mode="all"
      />
      <TasksMapModal
        isOpen={mapModalIsOpen}
        onClose={(res: any) => {
          setMapModalIsOpen(false)
          if (res) {
            setRefreshOnClose(true);
          }
        }}
        mode="all"
      />
      <TasksBoardModal
        open={boardModalIsOpen}
        setOpen={setBoardModalIsOpen}
        onClose={() => { }}
        onDismiss={() => { }}
      />
      <CompletedTasksModal
        isOpen={completedTasksModalIsOpen}
        onClose={(res: any) => {
          setCompletedTasksModalIsOpen(false)
          if (res) {
            setRefreshOnClose(true);
          }
        }}
        mode="all"
      />
      <TagsModal
        isOpen={tagsModalIsOpen}
        visibleGroupName={'Tareas'}
        groupName={'tasks'}
        onClose={() => {
          setTagsModalIsOpen(false);
        }}

      />
    </>
  );
};

export { TasksExpandedCard };
