import urls from "../config/urls";
import axiosInterceptor from "../AxiosInterceptor";

const configurationService = {
  findByName: async (name: string) => {
    try {
      const res = await axiosInterceptor.get(
        `${urls.api}/configurations/name/${name}`
      );
      return res?.data; // Manejar la respuesta
    } catch (error) {
      throw error; // Manejar errores
    }
  },

  findByNames: async (names: any) => {
    try {
      const res = await axiosInterceptor.post(
        `${urls.api}/configurations/names`,
        { names }
      );
      return res?.data; // Manejar la respuesta
    } catch (error) {
      throw error; // Manejar errores
    }
  },
};

export default configurationService;
