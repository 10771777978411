import React, { useState, useCallback, useEffect } from 'react';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ContentTreeNodeEditor from './ContentTreeNodeEditor';

interface ContentTreeNodeProps {
    node: any;
    addNode: (parentId: number) => void;
    removeNode: (nodeId: number) => void;
    moveNodeUp: (nodeId: number, parentId: number | null) => void;
    moveNodeDown: (nodeId: number, parentId: number | null) => void;
    updateNode: (id: number, updatedNode: any) => void;
    isFirstChild: boolean;
    isLastChild: boolean;
}

const ContentTreeNode: React.FC<ContentTreeNodeProps> = ({ node, addNode, removeNode, moveNodeUp, moveNodeDown, updateNode, isFirstChild, isLastChild }) => {
    const [isExpanded, setIsExpanded] = useState(true);
    const [isEditing, setIsEditing] = useState(false);
    const [formData, setFormData] = useState(node);

    useEffect(() => {
        setFormData(node); // Sincronizar formData con node cuando node cambia
    }, [node]);

    const handleToggle = () => {
        setIsExpanded(!isExpanded);
    };

    const handleEditToggle = () => {
        setIsEditing(!isEditing);
    };

    const handleFieldChange = useCallback((data: any) => {
        //setFormData(data);
        updateNode(node.id, { ...data, children: node.children }); // Mantener los hijos al actualizar
    }, [node.id, updateNode, node.children]);

    return (
        <li style={{ zIndex: 99999999, marginLeft: 0 }}>
            <div>
                <span>{node.id}</span>
                <button onClick={() => addNode(node.id)}>Agregar Item</button>
                <button onClick={() => removeNode(node.id)}>Eliminar</button>
                <button onClick={handleToggle}>
                    {isExpanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                </button>
                <button onClick={handleEditToggle}>
                    {isEditing ? 'Cerrar Editor' : 'Editar'}
                </button>
                {!isFirstChild && (
                    <button onClick={() => moveNodeUp(node.id, node.parentId)}>
                        <ArrowUpwardIcon />
                    </button>
                )}
                {!isLastChild && (
                    <button onClick={() => moveNodeDown(node.id, node.parentId)}>
                        <ArrowDownwardIcon />
                    </button>
                )}
            </div>
            {isEditing && (
                <ContentTreeNodeEditor
                    data={formData}
                    onChange={handleFieldChange}
                />
            )}
            {isExpanded && node.children.length > 0 && (
                <ul>
                    {node.children.map((childNode: any, index: number) => (
                        <ContentTreeNode
                            key={childNode.id}
                            node={{ ...childNode, parentId: node.id }}
                            addNode={addNode}
                            removeNode={removeNode}
                            moveNodeUp={moveNodeUp}
                            moveNodeDown={moveNodeDown}
                            updateNode={updateNode}
                            isFirstChild={index === 0}
                            isLastChild={index === node.children.length - 1}
                        />
                    ))}
                </ul>
            )}
        </li>
    );
};

export default ContentTreeNode;
