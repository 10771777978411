import React, { useState, useEffect, useRef } from "react";
import "./PDFViewerModal.scss";
import Modal from "components/Modal/Modal";


interface ModalProps {
    isOpen: boolean;
    fileUrl: string | null;
    title: string;
    onClose: (refreshOnClose?: boolean) => void;
}
const PDFViewerModal: React.FC<ModalProps> = ({
    isOpen,
    title,
    fileUrl,
    onClose
}) => {
    useEffect(() => {
        if (!isOpen) {

        }
    }, [isOpen]);

    const handleOnClose = () => {
        onClose();
    }

    return (
        <>
            <Modal
                title={title}
                className={'pdf-viewer-modal-container'}
                isOpen={isOpen}
                onClose={handleOnClose}
                style={{
                    overflowY: 'hidden',
                }}
            >
                <div className="pdf-wrapper">
                    {fileUrl &&
                        <embed src={fileUrl} type="application/pdf" width="100%" height="100%" />
                    }
                </div>
            </Modal>

        </>
    );
};

export { PDFViewerModal };
