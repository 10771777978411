import React, { useState, useEffect } from "react";
import axios from "axios";
import urls from "config/urls";
import { InputField } from "../../components/fields/InputField/InputField";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import LogoAnimation from "../../components/animations/LogoAnimation/LogoAnimation";
import { Spinner } from "../../components/Spinner/Spinner";
import FooterAnimation from "components/animations/FooterAnimation/FooterAnimation";
import "./ForgotPasswordPage.scss";
import { useAuth } from "contexts/AuthContext";
const ForgotPasswordPage = () => {
  const [email, setEmail] = useState();
  const [processing, setProcessing] = useState(false);
  const [loading, setLoading] = useState(false);
  const [logo, setLogo] = useState();
  const [logoWidth, setLogoWidth] = useState(200);
  const [footerAnimationName, setFooterAnimationName] = useState();
  const { getToken } = useAuth();
  const isAuthenticated = !!getToken();
  const navigate = useNavigate();

  const handleChangeEmail = (value: any) => {
    console.log("value", value);
    setEmail(value);
  };
  const send = async () => {
    setProcessing(true);
    try {
      const res = await axios.post(`${urls.server}/api/login`, {
        email,
      });
      setProcessing(true);
      const data = await res?.data;
    } catch (error: any) {
      error = JSON.parse(JSON.stringify(error));
      setProcessing(false);
    }
  };
  const getSettings = async () => {
    setLoading(true);
    try {
      const res = await axios.get(`${urls.server}/api/public/settings`);
      setLoading(false);
      const data = await res?.data;
      setLogo(data.logo);
      setLogoWidth(data.logoWidth);
      setFooterAnimationName(data.forgotPasswordAnimation);
    } catch (error: any) {
      error = JSON.parse(JSON.stringify(error));

      setLoading(false);
    }
  };
  useEffect(() => {
    if (isAuthenticated) {
      navigate("/");
    } else {
      getSettings();
    }
  }, []);

  return (
    <div className="forgot-password-page-container">
      {loading && (
        <div className="spinner-wrapper">
          <Spinner visible={loading} />
        </div>
      )}
      {!loading && (
        <>
          <div className="logo-wrapper mt-30">
            {logo ? <img src={urls.server + logo} alt="" style={{ maxWidth: logoWidth + 'px' }} /> : <LogoAnimation />}
          </div>
          <div className="form-wrapper">
            <h1>Recuperar contraseña</h1>
            <form>
              <div>
                <InputField
                  defaultValue=""
                  placeholder="mi@mail.com"
                  id="email"
                  onChange={(value: any) => handleChangeEmail(value)}
                />
              </div>

              <div>
                <Button
                  onClick={send}
                  variant="contained"
                  disableElevation
                  disabled={processing}
                >
                  Enviar mail
                </Button>
              </div>
              <div>
                <Button
                  variant="text"
                  onClick={() => {
                    navigate("/sign-in");
                  }}
                >
                  Ir al ingreso
                </Button>
              </div>
            </form>
          </div>
          {
            footerAnimationName && <FooterAnimation name={footerAnimationName} />
          }
        </>
      )}
    </div>
  );
};

export { ForgotPasswordPage };
