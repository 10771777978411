import urls from "../config/urls";
import axiosInterceptor from "../AxiosInterceptor";

const recordService = {
  fetch: async ({
    path,
    offset,
    limit,
    filters,
    sortBy,
    sortDirection,
    searchTerm
  }: any) => {
    try {
      const res = await axiosInterceptor.get(`${urls.api}/${path}?offset=${offset}&limit=${limit}&sort_by=${sortBy}&sort_direction=${sortDirection}&search_term=${searchTerm}&filters=${encodeURIComponent(
        JSON.stringify(filters)
      )}`);
      // Manejar la respuesta
      return res?.data;
    } catch (error) {
      // Manejar errores
      throw error;
    }
  },
  fetchAll: async ({ listPath }: any) => {
    try {
      const res = await axiosInterceptor.get(`${urls.api}/${listPath}`);
      // Manejar la respuesta
      return res?.data;
    } catch (error) {
      // Manejar errores
      throw error;
    }
  },
  fetchOne: async ({ viewPath }: any) => {
    try {
      const res = await axiosInterceptor.get(`${urls.api}/${viewPath}`);
      // Manejar la respuesta
      return res?.data;
    } catch (error) {
      // Manejar errores
      throw error;
    }
  },
  create: async ({ createPath, data }: any) => {
    try {
      const res = await axiosInterceptor.post(
        `${urls.api}/${createPath}`,
        data
      );
      // Manejar la respuesta
      return res?.data;
    } catch (error) {
      // Manejar errores
      throw error;
    }
  },
  update: async ({ editPath, data }: any) => {
    try {
      const res = await axiosInterceptor.put(`${urls.api}/${editPath}`, data);
      // Manejar la respuesta
      return res?.data;
    } catch (error) {
      // Manejar errores
      throw error;
    }
  },
  delete: async ({ deletePath }: any) => {
    try {
      const res = await axiosInterceptor.delete(`${urls.api}/${deletePath}`);
      // Manejar la respuesta
      return res?.data;
    } catch (error) {
      // Manejar errores
      throw error;
    }
  },
  sort: async ({ sortPath, data }: any) => {
    try {
      const res = await axiosInterceptor.post(
        `${urls.api}/${sortPath}`,
        data
      );
      // Manejar la respuesta
      return res?.data;
    } catch (error) {
      // Manejar errores
      throw error;
    }
  },
};

export default recordService;
