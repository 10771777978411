import React, { useState, useEffect } from "react";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import { formatNumber } from "../../../utils/formatNumber";
import "./ColumnChart.scss";

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} placement="top" />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#f2f2f4",
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
  },
}));

interface Item {
  label: string;
  value: number;
  height?: number;
}
const ColumnChart = (props: any) => {
  const { data, height = 250, color, decimalPrecision = 2 } = props;
  const [items, setItems] = useState(data as any);
  const [xAxisItems, setXAxisItems] = useState([] as any);
  const [yAxisItems, setYAxisItems] = useState([] as any);

  const addHeightToItems = (items: Item[], height: number): Item[] => {
    const maxValue = Math.max(...items.map((item) => item.value));
    const maxHeight = height;

    return items.map((item) => ({
      ...item,
      height: Math.round((item.value / maxValue) * maxHeight),
    }));
  };
  useEffect(() => {
    //console.log(JSON.stringify(data));
    if (data) {
      const columns = addHeightToItems(data, height);
      setItems(columns);
    }
  }, [data]);
  useEffect(() => {
    if (items) {
      setXAxisItems(() => getXAxisItems(data.length > 6 ? 6 : data.length));
      setYAxisItems(() => getYAxisItems(5));
    }
  }, [items]);
  function getXAxisItems(n: number) {
    if (n < 2 || n > items.length) {
      throw new Error("Invalid value for n");
    }

    const result = [];
    const step = (items.length - 1) / (n - 1);

    for (let i = 0; i < n; i++) {
      const index = Math.round(i * step);
      result.push(items[index].visible_period);
    }

    return result;
  }

  function getYAxisItems(n: number) {
    if (n < 2) {
      throw new Error("Invalid value for n");
    }

    const maxValue = Math.max(...items.map((item: any) => item.value));
    const interval = maxValue / (n - 1);
    const result = [];

    for (let i = 0; i < n; i++) {
      result.push(Math.round(i * interval * 100) / 100); // Redondear a 2 decimales
    }

    // Ordenar los valores en orden descendente
    result.sort((a, b) => b - a);

    return result;
  }

  useEffect(() => { }, []);
  return (
    <div className="column-chart-container">
      <div className="chart">
        <div>
          <div className="y-axis" style={{ height: height + "px" }}>
            {yAxisItems.map((item: any, index: number) => {
              return <span key={index}>{formatNumber(item)}</span>;
            })}
          </div>
          <div className="main-content" style={{ height: height + "px" }}>
            {items.map((item: any, index: number) => {
              return (
                <HtmlTooltip
                  key={index}
                  title={
                    <React.Fragment>
                      <div className="column-popover">
                        <b>{item.visible_period}</b>
                        <span>{formatNumber(item.value, decimalPrecision)}</span>
                      </div>
                    </React.Fragment>
                  }
                >
                  <a
                    style={{
                      height: item.height + "px",
                      backgroundColor: color,
                    }}
                  ></a>
                </HtmlTooltip>
              );
            })}
          </div>
        </div>
        <div className="x-axis">
          {xAxisItems.map((item: any, index: number) => {
            return <span key={index}>{item}</span>;
          })}
        </div>
      </div>
    </div>
  );
};

export { ColumnChart };
