import { useState, Fragment, useEffect } from "react";
import "./LinkedRecordsCell.scss";
import { RecordLinkingModal } from "components/modals/RecordLinkingModal/RecordLinkingModal";
const LinkedRecordsCell = (props: any) => {
  const {
    data,
    title,
    subtitle,
    searchPath,
    linkPath,
    onChange,
    readonly,
    label,
    primaryKey,
    secondaryKey,
    thumbnailKey,
    noContentIcon,
    noContentText,
  } = props;
  const [recordLinkingModalIsOpen, setRecordLinkingModalIsOpen] =
    useState(false);
  return (
    <>
      <div className="linked-records-cell-container">
        <a
          onClick={() => {
            setRecordLinkingModalIsOpen(true);
          }}
        >
          {label} ({data.length})
        </a>
      </div>
      <RecordLinkingModal
        isOpen={recordLinkingModalIsOpen}
        // onClose={(res) => {
        //   setRecordLinkingModalIsOpen(false);
        //   if (res) {
        //     onChange();
        //   }
        // }}
        onClose={(items, refresh) => {
          setRecordLinkingModalIsOpen(false);
          if (refresh) {
            onChange();
          }
        }}
        title={title}
        subtitle={subtitle}
        readonly={readonly}
        searchPath={searchPath}
        linkPath={linkPath}
        primaryKey={primaryKey}
        secondaryKey={secondaryKey}
        thumbnailKey={thumbnailKey}
        noContentIcon={noContentIcon}
        noContentText={noContentText}
        data={data}
      />


    </>
  );
};

export { LinkedRecordsCell };
