import React from 'react'
import Paper from 'components/Paper/Paper';

import colors from 'config/colors';
import './SelectedConfigurationItems.scss';
import DynamicList from 'components/DynamicList/DynamicList';

interface SelectedConfigurationItemsProps {
    data: any;
    onEditItem: (item: any, index: number) => void;
    onRemoveItem: (item: any, index: number) => void;
    onSortEnd: (items: Array<any>) => void;
}
const SelectedConfigurationItems: React.FC<SelectedConfigurationItemsProps> = ({ data, onEditItem, onRemoveItem, onSortEnd }) => {
    const itemCells = [
        {
            type: 'icon',
            color: 'primary',
            name: '{{icon}}',
            size: 40
        },
        {
            type: "interpolatedContent",
            htmlContent: `<div class='item-description'>
                <b>{{visible_type}}</b>
                <span>{{configurable_attributes.visibleName}}</span>
            </div>`,
        }
    ];
    return (
        <div className='selected-configuration-items-container'>
            <Paper title='Items seleccionados'>

                <DynamicList
                    className='selected-configuration-list'
                    data={data}
                    leftCells={itemCells}
                    itemActions={[
                        {
                            name: 'edit',
                            //visibleName: 'Eliminar'
                            icon: 'pencil',
                        },
                        {
                            name: 'delete',
                            //visibleName: 'Eliminar'
                            icon: 'delete',
                        }
                    ]}
                    onDeleteItem={onRemoveItem}
                    onEditItem={onEditItem}
                    onSortEnd={onSortEnd}
                    noContentText='No hay items agregados'
                    noContentIcon='records'
                    isSortable
                    style={{
                        overflowY: 'scroll',
                        height: '85%'
                    }}
                    itemStyle={{
                        filter: "none",
                        backgroundColor: colors.light
                    }}
                    onSelectItemAction={(res: any) => {
                        console.log(res);

                    }}
                //forceRefresh={true}
                // canDelete
                // canEdit
                />
            </Paper>
        </div>
    )
}

export default SelectedConfigurationItems