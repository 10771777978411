import React, { Fragment, useEffect } from "react";
import { useAuth } from "../../contexts/AuthContext";
import { useNavigate } from "react-router-dom";
import { ConfirmEmailStep } from "../../components/onboardingSteps/ConfirmEmailStep/ConfirmEmailStep";

import "./OnboardingPage.scss";
import { Button } from "@mui/material";
import { ConfirmWhatsappNumberStep } from "../../components/onboardingSteps/ConfirmWhatsappNumberStep/ConfirmWhatsappNumberStep";

const OnboardingPage = () => {
  const { saveOnboardingStatus } = useAuth();
  const navigate = useNavigate();
  const { deleteToken, deleteUser, getUser } = useAuth();

  const getInitialSteps = () => {
    const user = getUser();
    const steps = [
      {
        name: "ConfirmEmailStep",
        visibleName: "Confirmar email",
        completed: user.is_email_verified,
      },
      {
        name: "ConfirmWhatsappNumberStep",
        visibleName: "Confirmar num. de tel.",
        completed: user.is_whatsapp_number_verified,
        optional: true,
      },
    ];

    return steps;
  };
  const [onboardingSteps, setOnboardingSteps] = React.useState(
    getInitialSteps()
  );

  useEffect(() => {
    setOnboardingSteps(getInitialSteps());
  }, [getUser()]);

  const handleStepCompletion = (stepName: string) => {
    setOnboardingSteps(
      onboardingSteps.map((step) =>
        step.name === stepName ? { ...step, completed: true } : step
      )
    );
  };
  const allRequiredStepsCompleted = onboardingSteps.every(
    (step) => step.optional || step.completed
  );

  return (
    <div className="onboarding-page-container">
      <div className="steps-wrapper">
        {onboardingSteps.map((step: any) => {
          return (
            <Fragment key={step.name}>
              {(() => {
                switch (step.name) {
                  case "ConfirmEmailStep":
                    return (
                      <ConfirmEmailStep
                        title={step.visibleName}
                        completed={step.completed}
                        onComplete={() => handleStepCompletion(step.name)}
                      />
                    );
                  case "ConfirmWhatsappNumberStep":
                    return (
                      <ConfirmWhatsappNumberStep
                        title={step.visibleName}
                        completed={step.completed}
                        onComplete={() => handleStepCompletion(step.name)}
                      />
                    );
                }
              })()}
            </Fragment>
          );
        })}
      </div>
      <div className="btns-wrapper">
        <Button
          variant="text"
          onClick={() => {
            deleteToken();
            deleteUser();
          }}
        >
          Cerrar sesión
        </Button>
        <Button
          variant="contained"
          disabled={!allRequiredStepsCompleted}
          onClick={() => {
            saveOnboardingStatus(true);
            navigate("/");
          }}
        >
          Finalizar
        </Button>
      </div>
    </div>
  );
};

export { OnboardingPage };
