import { useState } from "react";

import IconButton from "@mui/material/IconButton";

import Popover from "@mui/material/Popover";

import "./ColorPickerButton.scss";
import Icon from "components/Icon/Icon";
const ColorPickerButton = ({ handleChangeColor, id, defaultColor }: any) => {
  /**
   * Popover
   */
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handlePresentPopover = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    setAnchorEl(event.currentTarget);
  };

  const handleClosePopover = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const colors = [
    "#0046BF",
    "#00A7A7",
    "#00A700",
    "#A7A700",
    "#fa7121",
    "#A70000",
    "#A700A7",
    "#650086",
    "#222222",
    "#7f7f7f",
  ];
  const amts: any = [-0.4, -0.25, 0, 0.25, 0.4];
  const lightenDarkenColor = (hex: string, amount?: number) => {
    // validate hex string
    hex = String(hex).replace(/[^0-9a-f]/gi, "");
    if (hex.length === 3) {
      hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
    }
    if (hex.length === 6) {
      hex = hex[0] + hex[1] + hex[2] + hex[3] + hex[4] + hex[5];
    }
    amount = amount || 0;

    // convert to decimal and change luminosity
    let rgb = "#";
    let c: any;
    for (let i = 0; i < 3; i++) {
      c = parseInt(hex.substr(i * 2, 2), 16);
      c = Math.round(Math.min(Math.max(0, c + c * amount), 255)).toString(16);
      rgb += ("00" + c).substr(c.length);
    }

    return rgb;
  };
  const handleSelectColor = (
    event: React.MouseEvent<HTMLButtonElement>,
    color: string,
    amount: number
  ) => {
    event.preventDefault();
    const slectedColor = lightenDarkenColor(color, amount);
    handleChangeColor(slectedColor, id);
    setColor(slectedColor);
    setAnchorEl(null);
    setCustomColor(null);
  };

  const [customColor, setCustomColor] = useState(null as any);
  const [color, setColor] = useState(defaultColor);
  const handleChangeCustomColor = (e: any) => {
    setCustomColor(e.target.value);
  };
  const handleSelectCustomColor = () => {
    console.log(customColor);
    handleChangeColor(`#${customColor}`, id);
    setColor(`#${customColor}`);
    setAnchorEl(null);
    //setCustomColor(null);
  };
  return (
    <div className="color-picker-button-container">
      <a
        style={{ backgroundColor: color }}
        onClick={(e: any) => handlePresentPopover(e)}
      ></a>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClosePopover}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <div className="popover-colors-table-wrapper">
          <table>
            <tbody>
              {colors.map((color: string, r: number) => {
                return (
                  <tr key={r}>
                    {amts.map((amount: number, c: number) => {
                      return (
                        <td key={c}>
                          <a
                            onClick={(e: any) =>
                              handleSelectColor(e, color, amount)
                            }
                            style={{
                              backgroundColor: lightenDarkenColor(
                                color,
                                amount
                              ),
                            }}
                          ></a>
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
          <div>
            <strong>#</strong>
            <span style={{ backgroundColor: "#" + customColor }}></span>
            <div>
              <input
                type="text"
                value={customColor || ""}
                onChange={(e) => handleChangeCustomColor(e)}
              />
            </div>
            <IconButton
              aria-label="send color"
              onClick={handleSelectCustomColor}
              disabled={customColor?.length !== 3 && customColor?.length !== 6}
            >
              <Icon name="check" />
            </IconButton>
          </div>
        </div>
      </Popover>
    </div>
  );
};

export { ColorPickerButton };
