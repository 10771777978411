import { useState, useEffect } from "react";
import "./BillingField.scss";
import { ReadonlyBillingField } from "./ReadonlyBillingField/ReadonlyBillingField";
import { EditableBillingField } from "./EditableBillingField/EditableBillingField";
import { FieldEditControls } from "components/FieldEditControls/FieldEditControls";
const BillingField = (props: any) => {
  let {
    defaultValue,
    editable,
    editPath,
    onChange,
    name,
    label,
    //readonly,
    transactionType,
    documentType,
    mode
  }: any = props;
  const [data, setData] = useState(defaultValue as any);
  const [isEditEnabled, setIsEditEnabled] = useState(false);

  const enableEdition = () => {
    setIsEditEnabled(true);
  };
  useEffect(() => {
    if (onChange) {
      onChange(data);
    }
  }, [data]);

  return (
    <div className="billing-field-container">
      <div className="field-header">
        {label && (
          <label>
            <span>{label}</span>
          </label>
        )}
        {editable && (
          <div>
            {(mode == 'edit' && editable) && (
              <FieldEditControls
                value={data}
                fieldName={name}
                editPath={editPath}
                onEditStart={() => {
                  setIsEditEnabled(true);
                }}
                onEditCancel={(originalValue) => {
                  setIsEditEnabled(false);
                  setData(originalValue);
                }}
                onEditEnd={(success: boolean, originalValue) => {
                  setIsEditEnabled(false);
                  if (!success) {
                    setData(originalValue);
                  }
                }}
              />
            )}</div>
        )}
      </div>
      <div className="field-body">
        <div>
          {mode == 'readonly' ||
            (mode == 'edit' && editable && !isEditEnabled) ||
            (mode == 'edit' && !editable) ? (
            <ReadonlyBillingField
              data={defaultValue}
              transactionType={transactionType}
              documentType={documentType}
            />
          ) : (
            <EditableBillingField
              defaultData={defaultValue}
              transactionType={transactionType}
              documentType={documentType}
              onChange={onChange}
            />
          )}
        </div>
      </div>

    </div>
  );
};

export { BillingField };
