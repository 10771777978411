import React from "react";
import "./SalesPointPage.scss";
const SalesPointPage = () => {

  return (
    <div className="sales-point-page-container page-container">
      <div className="page-header">
        <h1>Punto de venta</h1>

      </div>
      <div className="page-body">

      </div>
    </div>
  );
};

export { SalesPointPage };
