import ContentViewer from "components/ContentViewer/ContentViewer";
import React from "react";
import { useParams } from "react-router-dom";
import './DynamicPage.scss';

const DynamicPage = () => {
    const { slug } = useParams<{ slug: string }>();
    const [pageContent, setPageContent] = React.useState<any>(null);
    const [screenWidth, setScreenWidth] = React.useState<string>('lg');

    // Función para calcular el tamaño de la pantalla
    const calculateScreenWidth = (width: number) => {
        // if (width < 768) {
        //     return 'xs';
        // } else if (width >= 768 && width < 992) {
        //     return 'sm';
        // } else if (width >= 992 && width < 1200) {
        //     return 'md';
        // } else {
        //     return 'lg';
        // }
        if (width < 768) {
            return 'xs';
        } else if (width >= 768 && width < 1200) {
            return 'md';
        } else {
            return 'lg';
        }
    };

    React.useEffect(() => {
        const websiteConfig = localStorage.getItem('websiteConfig');
        if (websiteConfig) {
            const parsedConfig = JSON.parse(websiteConfig);
            const pageData = parsedConfig.pages.find((page: any) => page.slug === slug);
            if (pageData) {
                setPageContent(pageData.content);
            }
        }
    }, [slug]);

    React.useEffect(() => {
        // Configura el tamaño inicial de la pantalla
        setScreenWidth(calculateScreenWidth(window.innerWidth));

        // Función para manejar el cambio de tamaño de la ventana
        const handleResize = () => {
            setScreenWidth(calculateScreenWidth(window.innerWidth));
        };

        // Añade un listener para el evento resize
        window.addEventListener('resize', handleResize);

        // Limpia el listener cuando el componente se desmonta
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    if (!pageContent) {
        return <div>Página no encontrada</div>;
    }

    return (
        <div className="dynamic-page-container">
            <ContentViewer
                structure={pageContent}
                screenWidth={screenWidth}
            />
        </div>
    );
};

export default DynamicPage;
