import React from 'react';

interface TextProps {
    textContent: string;
    selected: boolean;
    onChange: (text: string) => void;
    style: React.CSSProperties;

}

const Text: React.FC<TextProps> = ({ textContent, selected, onChange, style }) => {
    const handleTextareaClick = (e: React.MouseEvent) => {
        e.stopPropagation();
    };

    return selected ? (
        <textarea
            autoFocus
            value={textContent}
            onChange={(e) => onChange(e.target.value)}
            onClick={handleTextareaClick}

            style={{ ...style, resize: 'none', border: 'none', outline: 'none', padding: 0, margin: 0, width: '100%' }}
        />
    ) : (
        <span style={style}>{textContent}</span>
    );
};

export default Text;
