import { useEffect, useState } from "react";
import { IconButton } from "@mui/material";
import { Spinner } from "components/Spinner/Spinner";
import recordService from "services/recordService";

// import { useSessionStorage } from "hooks/useSessionStorage";
import "./FieldEditControls.scss";
import Icon from "components/Icon/Icon";
import { Toast } from "components/Toast/Toast";

interface FieldEditControlsProps {
  value: any;
  fieldName: string;
  editPath?: string;
  fieldType?: string;
  onEditStart?: () => any;
  onEditEnd?: (success: boolean, originalValue?: any) => any;
  onEditCancel?: (originalValue: any) => void;
}
const FieldEditControls = ({
  value,
  fieldName,
  fieldType,
  onEditStart,
  editPath,
  onEditEnd,
  onEditCancel,
}: FieldEditControlsProps) => {
  const [isEditEnabled, setIsEditEnabled] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [toastIsOpen, setToastIsOpen] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [toastSeverity, setToastSeverity] = useState("success");

  const getStorageValue = (key: string) => {
    let strValue = sessionStorage.getItem(key);
    if (strValue === null) return null;
    return JSON.parse(strValue);
  };
  const setStorageValue = ({ fieldName, value }: { fieldName: string, value: any }): void => {
    if (value !== undefined) {
      const stringValue = JSON.stringify(value);
      sessionStorage.setItem(fieldName, stringValue);
    } else {
      console.log(`El valor para ${fieldName} es undefined.`);
    }
  };

  const isValueChanged = () => {
    const current = JSON.stringify(value)
    const storage = JSON.stringify(getStorageValue(`${fieldName}_field`))
    return current !== storage
  }
  const handleUpdateValue = async () => {
    setProcessing(true);
    const valueToSend = fieldType == 'addressPicker' ? value?.id : value
    try {
      const res = await recordService.update({
        editPath: editPath,
        data: {
          data: valueToSend,
        },
      });
      // setStorageValue(value);
      setStorageValue({ fieldName: `${fieldName}_field`, value })
      setProcessing(false);
      setIsEditEnabled(false);
      onEditEnd?.(true);
      setToastMessage(`updated successfully`);
      setToastIsOpen(true);
      setToastSeverity("success");



    } catch (error: any) {
      setProcessing(false);
      setIsEditEnabled(false);
      onEditEnd?.(false, getStorageValue(`${fieldName}_field`));
      setToastMessage(error.toString());
      setToastIsOpen(true);
      setToastSeverity("error");

    }
  };
  useEffect(() => {
    //setStorageValue(value);
    setStorageValue({ fieldName: `${fieldName}_field`, value })
  }, []);
  return (
    <>
      <div className="field-edit-controls-container">
        {isEditEnabled ? (
          <>
            {processing ? (
              <div className="spinner-wrapper">
                <Spinner size="sm" />
              </div>
            ) : (
              <IconButton
                disabled={!isValueChanged()}
                aria-label="update"
                onClick={handleUpdateValue}
                color="primary"
              >
                <Icon name="check" />
              </IconButton>
            )}

            <IconButton
              aria-label="close"
              onClick={() => {
                setIsEditEnabled(false);
                onEditCancel?.(getStorageValue(`${fieldName}_field`));
              }}
            >
              <Icon name="close" />
            </IconButton>
          </>
        ) : (
          <>
            <IconButton
              aria-label="edit"
              onClick={() => {
                setIsEditEnabled(true);
                onEditStart?.();
              }}
            >
              <Icon name="pencil" />
            </IconButton>
          </>
        )}
      </div>

      <Toast
        isOpen={toastIsOpen}
        onClose={() => setToastIsOpen(false)}
        message={toastMessage}
        severity={toastSeverity}
      />
    </>
  );
};

export { FieldEditControls };
