import { useState, useEffect } from "react";
import Fab from "@mui/material/Fab";
import "./WorkflowsExpandedCard.scss";

import urls from "config/urls";
import AxiosInterceptor from "../../../../AxiosInterceptor";
import { Spinner } from "../../../../components/Spinner/Spinner";
import { LoadingButton } from "@mui/lab";
import { WorkflowListItem } from "../WorkflowListItem/WorkflowListItem";
import { FormModal } from "../../../../components/modals/FormModal/FormModal";
import { IconButton, Popover } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { ConfigurationImporterModal } from "components/modals/ConfigurationImporterModal/ConfigurationImporterModal";
import configurationService from "services/configurationService";
import Icon from "components/Icon/Icon";
const WorkflowsExpandedCard = (props: any) => {
  const { title, config, cardId, setRefreshOnClose } = props;
  const [formIsOpen, setFormIsOpen] = useState(false);
  const [formData, setFormData] = useState({} as any);
  const [searchTerm, setSearchTerm] = useState("");
  const [offset, setOffset] = useState(0);
  const [limit, setLimit] = useState(10);
  const [loading, setLoading] = useState(false);
  const [loadingMore, setLoadingMore] = useState(false);
  const [loadingConfig, setLoadingConfig] = useState(false);
  const [records, setRecords] = useState([] as any);
  const [hasMore, setHasMore] = useState(true);

  const [filters, setFilters] = useState([]);
  const [sortBy, setSortBy] = useState("");
  const [sortDirection, setSortDirection] = useState("desc");
  const [workflowFields, setWorkflowFields] = useState([]);
  const [workflowUserFields, setWorkflowUserFields] = useState([]);
  const [workflowStepFields, setWorkflowStepFields] = useState([]);
  const [workflowStepUserFields, setWorkflowStepUserFields] = useState([]);

  const [configurationImporterModalIsOpen, setConfigurationImporterModalIsOpen] =
    useState(false);
  //const fields = getFields().workflow;
  const loadRecords = async ({ offset }: any) => {
    if (offset) {
      setLoadingMore(true);
    } else {
      setLoading(true);
    }

    const res = await AxiosInterceptor.get(
      `${urls.server
      }/api/workflows?offset=${offset}&limit=${limit}&sort_by=${sortBy}&sort_direction=${sortDirection}&search_term=${searchTerm}&filters=${encodeURIComponent(
        JSON.stringify(filters)
      )}`
    );
    const data = await res?.data;
    if (offset > 0) {
      setRecords((prev: any) => [...prev, ...data]);
    } else {
      setRecords(data);
    }

    setLoadingMore(false);
    setLoading(false);
    if (data.length < limit) setHasMore(false);
  };
  useEffect(() => {
    setOffset(0);
    setHasMore(true);
    loadRecords({ offset: 0 });
  }, [searchTerm, filters]);
  useEffect(() => {
    if (offset > 0) loadRecords({ offset });
  }, [offset]);

  useEffect(() => {
    if (!formIsOpen) {
      setFormData({});
    }
  }, [formIsOpen]);
  const getMore = () => {
    setOffset((prev: number) => prev + limit);
  };
  const getConfigurations = () => {
    setLoadingConfig(true)
    const configurationNames = [
      `workflowFields`,
      `workflowUserFields`,
      `workflowStepFields`,
      `workflowStepUserFields`,
    ];
    configurationService
      .findByNames(configurationNames)
      .then((configurations) => {
        setLoadingConfig(false);
        configurations.forEach((configuration: any) => {
          switch (configuration.name) {
            case `workflowFields`:
              setWorkflowFields(configuration.json_schema);
              break;
            case `workflowUserFields`:
              setWorkflowUserFields(configuration.json_schema);
              break;
            case `workflowStepFields`:
              setWorkflowStepFields(configuration.json_schema);
              break;
            case `workflowStepUserFields`:
              setWorkflowStepUserFields(configuration.json_schema);
              break;
            default:
            // Manejar cualquier otro caso
          }
        });
      })
      .catch((error) => {
        setLoadingConfig(false);
        console.log(error);
      });
  }
  /**
   * Popover
   */
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const openPopover = Boolean(anchorEl);
  const presentPopover = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const closePopover = () => {
    setAnchorEl(null);
  };
  const resetState = () => {
    setHasMore(true);
    setOffset(0);
    loadRecords({ offset: 0 });
    setAnchorEl(null);
  };
  useEffect(() => {
    getConfigurations();
  }, [])
  return (
    <>
      <div className="workflows-expanded-card-container">
        <div className="expanded-card-header">
          <h2>{title}</h2>
          <div>

            <IconButton aria-label="delete" onClick={presentPopover}>
              <MoreVertIcon />
            </IconButton>
            <Popover
              id={"workflows-popover"}
              open={openPopover}
              anchorEl={anchorEl}
              onClose={closePopover}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
            >
              <div className="popover-items">
                <a
                  onClick={() => {
                    setConfigurationImporterModalIsOpen(true);
                    closePopover();
                  }}
                >
                  Importar
                </a>
              </div>
            </Popover>
          </div>
        </div>
        <div className="expanded-card-body">
          {loading || loadingConfig && (
            <div className="spinner-wrapper">
              <Spinner visible={loading || loadingConfig} />
            </div>
          )}
          {!loading && !loadingConfig && records.length == 0 && (
            <div className="no-content">
              <Icon name="workflow" />
              <p>No hay workflows registrados</p>
            </div>
          )}
          {!loading && !loadingConfig && records.length > 0 && (
            <>
              <div className="workflow-list">
                {records.map((item: any, index: number) => {
                  return (
                    <WorkflowListItem
                      key={index}
                      id={index}
                      data={item}
                      resetState={resetState}
                      workflowFields={workflowFields}
                      workflowUserFields={workflowUserFields}
                      workflowStepFields={workflowStepFields}
                      workflowStepUserFields={workflowStepUserFields}
                      onDelete={() => {
                        resetState();
                      }}
                      onChange={() => {
                        resetState();
                      }}
                      onSelectPopoverItem={(res: any) => {
                        console.log(res);
                        if (res?.action == "edit") {
                          setFormData(res?.data);
                          setFormIsOpen(true);
                        }
                      }}
                    />
                  );
                })}
              </div>
              {hasMore && (
                <div className="loading-button-wrapper">
                  <LoadingButton
                    onClick={getMore}
                    loading={loadingMore}
                    loadingPosition="start"
                    startIcon={<Icon name="plus" />}
                  >
                    Más registros
                  </LoadingButton>
                </div>
              )}
            </>
          )}
        </div>
        {
          !loadingConfig &&
          <Fab
            className="fab"
            aria-label="add"
            onClick={() => {
              setFormIsOpen(true);
            }}
          >
            <Icon name="add" />
          </Fab>
        }

        <FormModal
          isOpen={formIsOpen}
          fields={workflowFields}
          title={"Workflow"}
          //tableName={"workflows"}
          mode={'create'}
          onClose={(res: any) => {
            setFormIsOpen(false)
            if (res) {
              setHasMore(true);
              setOffset(0);
              loadRecords({ offset: 0 });
              setRefreshOnClose(true);
            }
          }}
        />
        <ConfigurationImporterModal
          isOpen={configurationImporterModalIsOpen}
          subtitle={`Importar workflow`}
          importPath="workflows/import"
          onClose={(res: any) => {
            console.log(res);
            setConfigurationImporterModalIsOpen(false)
            if (res) {
              setOffset(0);
              setHasMore(true);
              loadRecords({ offset: 0 });
              setRefreshOnClose(true);
            }
          }}
        />
      </div>
    </>
  );
};

export { WorkflowsExpandedCard };