import "./MultiLineChart.scss";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { useEffect } from "react";
import { Line } from "react-chartjs-2";
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);
const MultiLineChart = (props: any) => {
  const { data, decimalPrecision = 2, } = props;
  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top" as const,
      },
      title: {
        display: false,
        //text: "Chart.js Line Chart",
      },
    },
  };
  function adaptSingleBreakdownData(indicatorData: any) {
    // Primero, creamos un mapa para rastrear los datos para cada etiqueta
    const datasetsMap = new Map();

    // Extraemos las etiquetas de los períodos
    const labels = indicatorData.map((item: any) => item.visible_period);

    // Llenamos el mapa con los datos para cada etiqueta
    indicatorData.forEach((period: any) => {
      period.data.forEach((item: any) => {
        if (!datasetsMap.has(item.label)) {
          datasetsMap.set(item.label, {
            label: item.label,
            data: new Array(indicatorData.length).fill(0),
            borderColor: item.color,
            backgroundColor: item.color,
            hidden: !item.is_visible
          });
        }
        const dataset = datasetsMap.get(item.label);
        const periodIndex = labels.indexOf(period.visible_period);
        dataset.data[periodIndex] = item.value.toFixed(decimalPrecision);
      });
    });

    // Convertimos el mapa en un array para los datasets
    const datasets = Array.from(datasetsMap.values());

    return {
      labels: labels,
      datasets: datasets,
    };
  }

  const chartData = adaptSingleBreakdownData(data);
  useEffect(() => {
    console.log(data)
  }, [data])
  return (
    <div
      className="multi-line-chart-container"
    //style={{ height: height + "px" }}
    >
      <div className="chart-wrapper">
        <Line options={options} data={chartData} />
      </div>
    </div>
  );
};

export { MultiLineChart };
