const createImage = (url: string) =>
  new Promise((resolve, reject) => {
    const image = new Image();
    image.addEventListener("load", () => resolve(image));
    image.addEventListener("error", (error) => reject(error));
    image.setAttribute("crossOrigin", "anonymous");
    image.src = url;
  });

// export const getCroppedImg = async (imageSrc: string, crop: any) => {
//   const image = await createImage(imageSrc);
//   const canvas = document.createElement("canvas");
//   const ctx: any = canvas.getContext("2d");

//   /* setting canvas width & height allows us to 
//     resize from the original image resolution */
//   canvas.width = 200;
//   canvas.height = 200;

//   ctx.drawImage(
//     image,
//     crop.x,
//     crop.y,
//     crop.width,
//     crop.height,
//     0,
//     0,
//     canvas.width,
//     canvas.height
//   );

//   return new Promise((resolve) => {
//     canvas.toBlob((blob) => {
//       resolve(blob);
//     }, "image/jpeg");
//   });
// };
export const getCroppedImg = async (imageSrc: string, crop: any, aspect: number) => {
  const image = await createImage(imageSrc);
  const canvas = document.createElement("canvas");
  const ctx: any = canvas.getContext("2d");

  // Asumir que queremos mantener un ancho o alto máximo de 200px
  const maxWidth = 200;
  const maxHeight = 200;

  // Calcular dimensiones del canvas basadas en el aspect
  if (aspect >= 1) {
    canvas.width = maxWidth;
    canvas.height = maxWidth / aspect;
  } else {
    canvas.width = maxHeight * aspect;
    canvas.height = maxHeight;
  }

  ctx.drawImage(
    image,
    crop.x,
    crop.y,
    crop.width,
    crop.height,
    0,
    0,
    canvas.width,
    canvas.height
  );

  return new Promise((resolve) => {
    canvas.toBlob((blob) => {
      resolve(blob);
    }, "image/jpeg");
  });
};

export const blobToBase64 = (blob: any) => {
  return new Promise((resolve, _) => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result);
    reader.readAsDataURL(blob);
  });
};
