import { useState, useEffect } from "react";
import "./RadioItemField.scss";

const RadioItemField = (props: any) => {
  const {
    defaultValue,

    label,

    onChange,
    options = [],


  }: any = props;
  const [value, setValue] = useState(defaultValue as any);


  useEffect(() => {
    if (onChange) onChange(value);
  }, [value]);
  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);
  const handleClick = (index: number) => {
    setValue(options[index].name);
  };
  useEffect(() => {
    if (!defaultValue && options.length > 0) {
      setValue(options[0].name);

    }
  }, []);

  return (
    <div className="radio-item-field-container">
      <div className="item-field-header">
        {label && (
          <span>{label}</span>
        )}
      </div>


      <div className="item-field-body">
        {options.map((option: any, index: any) => {
          return (
            <a
              key={index}
              onClick={() => handleClick(index)}
              className={value == option.name ? "actived" : ""}
            >
              <span>{option?.visibleName}</span>
            </a>
          );
        })}
      </div>
    </div>
  );
};

export { RadioItemField };
