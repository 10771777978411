import React from 'react'
import Lottie from "lottie-react";

interface LottieProps {
    style: React.CSSProperties;
    commonProps?: any;
    animationData?: string;
    loop?: boolean | null;
}
const LottieComponent: React.FC<LottieProps> = ({ style, commonProps, animationData, loop = false }) => {
    return (
        <>
            {
                animationData ?
                    <div
                        style={style}
                    >
                        {
                            <Lottie
                                loop={loop || false}
                                animationData={animationData}
                                style={{
                                    // position: "absolute",
                                    // width: "100%",
                                    // height: "100%"
                                }}
                            />
                        }
                    </div>
                    : <img src={"assets/placeholder-lottie.svg"} alt="loading" width="100%" />
            }
        </>

    )
}

export default LottieComponent