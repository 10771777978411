import React, { useEffect, useRef, useState } from "react";
import "./VideosField.scss";
import urls from "config/urls";
import AxiosInterceptor from "../../../AxiosInterceptor";
import { IconButton } from "@mui/material";
import { Spinner } from "components/Spinner/Spinner";
import { FieldEditControls } from "components/FieldEditControls/FieldEditControls";
import Icon from "components/Icon/Icon";

const VideosField = (props: any) => {
  let {
    defaultValue,
    editable,
    label,
    editPath,
    id,
    name,
    onChange,
    accept = "video/*",
    //readonly,
    folder = "videos",
    maxItems,
    mode,
  }: any = props;
  const [value, setValue] = useState(defaultValue as any);
  const [isEditEnabled, setIsEditEnabled] = useState(false);
  const [queuedFiles, setQueuedFiles] = useState([] as any);
  const fileInputRef = useRef(null);
  const removeVideo = (index: number) => {
    const valueCopy = [...value];
    valueCopy.splice(index, 1);
    setValue(valueCopy);
  };

  useEffect(() => {
    if (onChange) {
      console.log("value", value);
      onChange(value);
    }
  }, [value]);

  useEffect(() => {
    console.log(defaultValue);
    setValue(defaultValue);
  }, [defaultValue]);

  const handleChange = async (e: any, value: any) => {
    if (e.target.files) {
      let reader = new FileReader();
      const file: File = e.target.files[0];
      const extension = file?.name.split(".").pop();
      reader.readAsDataURL(file);
      reader.onload = await function () {
        console.log(reader.result);
        const data = {
          content: reader.result,
          extension,
          folder: folder,
        };
        saveVideo(value, data);
      };
      reader.onerror = function (error) {
        console.log("Error: ", error);
      };
      // Resetear el valor del input de archivo
      e.target.value = null;
    } else {
      console.log("No Video selected");
    }
  };

  const saveVideo = async (value: any, { content, extension, folder }: any) => {
    //setProcessing(true);
    const queuedFilesCopy = [...queuedFiles];
    queuedFilesCopy.push("");
    setQueuedFiles(queuedFilesCopy);
    try {
      const res = await AxiosInterceptor.post(`${urls.server}/api/videos`, {
        file_content: content,
        extension,
        folder,
      });
      const resData = await res?.data;
      const valueCopy = value !== null ? [...value] : [];
      valueCopy.push(resData?.path);
      setValue(valueCopy);
      const queuedFilesCopy = [...queuedFiles];
      queuedFilesCopy.pop();
      setQueuedFiles(queuedFilesCopy);

    } catch (error: any) {
      const queuedFilesCopy = [...queuedFiles];
      queuedFilesCopy.pop();
      setQueuedFiles(queuedFilesCopy);

    }
  };


  return (
    <div className="videos-field-container field-container">
      <div className="field-header">
        {label && (
          <label>
            <span>{label}</span>
          </label>
        )}
        {editable && (
          <div>
            {(mode == 'edit' && editable) && (
              <FieldEditControls
                value={value}
                fieldName={name}
                editPath={editPath}
                onEditStart={() => {
                  setIsEditEnabled(true);
                }}
                onEditCancel={(originalValue) => {
                  setIsEditEnabled(false);
                  setValue(originalValue);
                }}
                onEditEnd={(success: boolean, originalValue) => {
                  setIsEditEnabled(false);
                  if (!success) {
                    setValue(originalValue);
                  }
                }}
              />
            )}</div>
        )}
      </div>
      <div className="field-body">
        {mode == 'readonly' ||
          (mode == 'edit' && editable && !isEditEnabled) ||
          (mode == 'edit' && !editable) ? (
          <div className="readonly-content">
            {value && value.length > 0 ? (
              <div className="video-preview-wrapper">
                <video src={`${urls.server}${value}`} controls>
                  El navegador no admite el elemento <code>video</code>.
                </video>
              </div>
            ) : (
              <div className="no-value">
                <Icon name="videos" />
                <span>Sin Videos</span>
              </div>
            )}
          </div>
        ) : (
          <div className="editable-content">
            {((maxItems && value?.length < maxItems) || maxItems == null) && (
              <div className="add-image-btn">
                <input
                  id={id}
                  accept={accept}
                  type="file"
                  onChange={(e) => handleChange(e, value)}
                  ref={fileInputRef}
                />
                <div>
                  <div>
                    <Icon name="video" />
                    <span>Seleccionar Video</span>
                  </div>
                  <label htmlFor={id}></label>
                </div>
              </div>
            )}

            {queuedFiles.length > 0 && (
              <div className="spinner-wrapper">
                <Spinner size="sm" />
              </div>
            )}

            {value?.length > 0 && (
              <>
                {value.map((item: any, index: number) => {
                  return (
                    <div key={index} className="video-preview-wrapper">
                      <video src={`${urls.server}${item}`} controls>
                        El navegador no admite el elemento <code>video</code>.
                      </video>

                      <IconButton aria-label="delete" onClick={() => removeVideo(index)}>
                        <Icon name="delete" />
                      </IconButton>
                    </div>
                  );
                })}
              </>
            )}

          </div>
        )}
      </div>
    </div>
  );
};

export { VideosField };
