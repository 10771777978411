import { Button } from "@mui/material";
import { FieldEditControls } from "components/FieldEditControls/FieldEditControls";
import { useState, useEffect } from "react";
import './AddressField.scss';
import { AddressFormModal } from "components/modals/AddressFormModal/AddressFormModal";
import Icon from "components/Icon/Icon";
import { AddressData } from "./AddressData";
const AddressField = (props: any) => {
  let {
    defaultValue,
    editable,
    label,
    name,
    onChange,
    //readonly,
    editPath,
    mode
  } = props;

  // Asegurándonos de que defaultValue es un objeto con las propiedades correctas
  defaultValue = defaultValue || {
    name: "",
    full_address: "",
    city_name: "",
    province_name: "",
    country_name: "",
    observations: "",
    lat: null,
    lng: null
  };

  const [value, setValue] = useState(defaultValue);
  const [isEditEnabled, setIsEditEnabled] = useState(false);
  const [addressFormModalIsOpen, setAddressFormModalIsOpen] = useState(false);
  useEffect(() => {
    if (onChange) onChange(value);
  }, [value]);
  return (
    <>
      <div className="field-container address-field-container">
        <div className="field-header">
          {label && (
            <label>
              <span>{label}</span>
            </label>
          )}
          {editable && (
            <div>
              {(mode == 'edit' && editable) && (
                <FieldEditControls
                  value={value}
                  fieldName={name}
                  editPath={editPath}
                  onEditStart={() => {
                    setIsEditEnabled(true);
                  }}
                  onEditCancel={(originalValue) => {
                    setIsEditEnabled(false);
                    setValue(originalValue);
                  }}
                  onEditEnd={(success: boolean, originalValue) => {
                    setIsEditEnabled(false);
                    if (!success) {
                      setValue(originalValue);
                    }
                  }}
                />
              )}
            </div>
          )}
        </div>
        <div className="field-body">
          <div>
            {mode == 'readonly' ||
              (mode == 'edit' && editable && !isEditEnabled) ||
              (mode == 'edit' && !editable) ? (
              <div className="readonly-content">
                <AddressData data={value} />
              </div>
            ) : (
              <div className="editable-content">
                <AddressData data={value} />
                <Button variant="outlined" startIcon={<Icon name="marker" />} onClick={() => {
                  setAddressFormModalIsOpen(true);
                }}>
                  Abrir mapa
                </Button>
              </div>
            )}
          </div>
        </div>

      </div>
      <AddressFormModal
        isOpen={addressFormModalIsOpen}
        onClose={(refreshOnClose, res) => {
          console.log(res)
          if (res) {
            setValue(res);
          }
          setAddressFormModalIsOpen(false)
        }}
        mode={"preview"}
        data={value}
        title=""
      />
    </>

  );
};

export { AddressField };
