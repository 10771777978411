import { useState, useEffect } from "react";
import { EditableSimplePaymentField } from "./EditableSimplePaymentField/EditableSimplePaymentField";
import { ReadonlySimplePaymentField } from "./ReadonlySimplePaymentField/ReadonlySimplePaymentField";
import "./SimplePaymentField.scss";
import { FieldEditControls } from "components/FieldEditControls/FieldEditControls";

const SimplePaymentField = (props: any) => {
  let {
    defaultValue = {
      paymentMethods: [
        {
          name: "cash",
          visibleName: "Efectivo",
          amount: 0,
        },
        {
          name: "credit_card",
          visibleName: "Tarjeta de crédito",
          amount: 0,
        },
        {
          name: "debit_card",
          visibleName: "Tarjeta de débito",
          amount: 0,
        },
        {
          name: "mercado_pago",
          visibleName: "Mercado Pago",
          amount: 0,
        },
        {
          name: "bank_payment",
          visibleName: "Pago bancario",
          amount: 0,
        },
      ],
      totalPaid: 0,
    },
    editable,
    label,
    editPath,
    onChange,
    name,
    //readonly,
    totalDueKey,
    formData,
    mode
  }: any = props;

  const [data, setData] = useState(defaultValue as any);
  const [isEditEnabled, setIsEditEnabled] = useState(false);

  useEffect(() => {
    if (onChange) {
      onChange(data);
    }
  }, [data]);

  return (
    <div className="simple-payment-field-container">
      <div className="field-header">
        {label && (
          <label>
            <span>{label}</span>
          </label>
        )}
        {editable && (
          <div>
            {(mode == 'edit' && editable) && (
              <FieldEditControls
                value={data}
                fieldName={name}
                editPath={editPath}
                onEditStart={() => {
                  setIsEditEnabled(true);
                }}
                onEditCancel={(originalValue) => {
                  setIsEditEnabled(false);
                  setData(originalValue);
                }}
                onEditEnd={(success: boolean, originalValue) => {
                  setIsEditEnabled(false);
                  if (!success) {
                    setData(originalValue);
                  }
                }}
              />
            )}</div>
        )}
      </div>
      <div className="field-body">
        <div>
          {mode == 'readonly' ||
            (mode == 'edit' && editable && !isEditEnabled) ||
            (mode == 'edit' && !editable) ? (
            <ReadonlySimplePaymentField
              data={defaultValue}
              formData={formData}
              totalDueKey={totalDueKey}
            />
          ) : (
            <EditableSimplePaymentField
              defaultData={defaultValue}
              formData={formData}
              totalDueKey={totalDueKey}
              onChange={(value: any) => {
                setData(value);
              }}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default SimplePaymentField;
