import { IconButton } from '@mui/material';
import React, { useEffect, useState } from 'react';
import './SearchBar.scss';
import Icon from 'components/Icon/Icon';

interface SearchBarProps {
    style?: any;
    placeholder?: string,
    onChange: (text: string) => void,
    value?: string
}

const SearchBar: React.FC<SearchBarProps> = ({ style, placeholder, onChange, value }) => {
    const [inputValue, setInputValue] = useState(value || '');
    // Función para limpiar el input
    const clearInput = () => {
        setInputValue('');
    };
    useEffect(() => {
        onChange(inputValue)
    }, [inputValue])
    return (
        <div style={style} className='search-bar-container'>
            <Icon name="search" />
            <input
                type={"text"}
                placeholder={placeholder}
                value={inputValue || ""}
                onChange={(e) => setInputValue(e.target.value)}
                style={{

                }}
            />
            {inputValue && (
                <IconButton

                    color="inherit"
                    onClick={clearInput}
                    aria-label="close"
                >
                    <Icon name="close" />
                </IconButton>
            )}
        </div>
    );
}

export default SearchBar;
