import React from 'react'

interface ImageProps {
    style: React.CSSProperties;
    src?: string;
}
const Image: React.FC<ImageProps> = ({ style, src }) => {
    return (
        <>
            {
                src ? <img style={style} src={src} /> : <img style={style} src={"../assets/placeholder-image.svg"} alt="loading" width="100%" />
            }
        </>
    )
}
export default Image