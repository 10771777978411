import React, { useEffect, useState } from 'react';
import ContentTreeNode from './ContentTreeNode';

interface ContentTreeProps {
    onChange: (data: any) => void;
    data: any; // Agregado para recibir los datos iniciales
}

const ContentTree: React.FC<ContentTreeProps> = ({ onChange, data }) => {
    const [treeData, setTreeData] = useState(data || []);

    useEffect(() => {
        setTreeData(data || []);
    }, [data]);

    const addNode = (parentId: number | null = null) => {
        const newNode = { id: Date.now(), type: 'text', tagName: '', attributes: '', showIf: '', content: '', children: [] };

        if (parentId === null) {
            setTreeData([...treeData, newNode]);
        } else {
            const updateTree = (nodes: any) => nodes.map((node: any) => {
                if (node.id === parentId) {
                    return { ...node, children: [...node.children, newNode] };
                } else if (node.children.length > 0) {
                    return { ...node, children: updateTree(node.children) };
                } else {
                    return node;
                }
            });

            setTreeData(updateTree(treeData));
        }
    };

    const removeNode = (nodeId: number) => {
        const deleteNode = (nodes: any) => nodes.filter((node: any) => node.id !== nodeId).map((node: any) => ({
            ...node,
            children: deleteNode(node.children)
        }));

        setTreeData(deleteNode(treeData));
    };

    const updateNode = (id: number, updatedNode: any) => {
        const updateTree = (nodes: any): any => nodes.map((node: any) => {
            if (node.id === id) {
                return { ...node, ...updatedNode, children: node.children }; // Mantener los hijos al actualizar
            } else if (node.children.length > 0) {
                return { ...node, children: updateTree(node.children) };
            } else {
                return node;
            }
        });

        setTreeData(updateTree(treeData));
    };

    const moveNodeUp = (nodeId: number, parentId: number | null) => {
        const updateTree = (nodes: any): any => {
            let updatedNodes = [...nodes];
            nodes.forEach((node: any, index: number) => {
                if (node.id === nodeId && index > 0) {
                    [updatedNodes[index - 1], updatedNodes[index]] = [updatedNodes[index], updatedNodes[index - 1]];
                } else if (node.children.length > 0) {
                    node.children = updateTree(node.children);
                }
            });
            return updatedNodes;
        };

        if (parentId === null) {
            setTreeData(updateTree(treeData));
        } else {
            setTreeData(updateTree(treeData));
        }
    };

    const moveNodeDown = (nodeId: number, parentId: number | null) => {
        const updateTree = (nodes: any): any => {
            let updatedNodes = [...nodes];
            nodes.forEach((node: any, index: number) => {
                if (node.id === nodeId && index < nodes.length - 1) {
                    [updatedNodes[index + 1], updatedNodes[index]] = [updatedNodes[index], updatedNodes[index + 1]];
                } else if (node.children.length > 0) {
                    node.children = updateTree(node.children);
                }
            });
            return updatedNodes;
        };

        if (parentId === null) {
            setTreeData(updateTree(treeData));
        } else {
            setTreeData(updateTree(treeData));
        }
    };

    useEffect(() => {
        console.log(treeData);
        onChange(treeData); // Llamar a la prop onChange con el treeData actualizado
    }, [treeData]);

    return (
        <div>
            <button onClick={() => addNode(null)}>Agregar Item</button>
            <ul>
                {treeData.map((node: any, index: number) => (
                    <ContentTreeNode
                        key={node.id}
                        node={{ ...node, index }}
                        addNode={addNode}
                        removeNode={removeNode}
                        moveNodeUp={moveNodeUp}
                        moveNodeDown={moveNodeDown}
                        updateNode={updateNode}
                        isFirstChild={index === 0}
                        isLastChild={index === treeData.length - 1}
                    />
                ))}
            </ul>
        </div>
    );
};

export default ContentTree;
