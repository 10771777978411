import { Button } from "@mui/material";
import { useState, useEffect } from "react";
import AxiosInterceptor from "AxiosInterceptor";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import "./TagsField.scss";


import { Spinner } from "components/Spinner/Spinner";
import urls from "config/urls";
import { FieldEditControls } from "components/FieldEditControls/FieldEditControls";
import { FormDialog } from "components/dialogs/FormDialog/FormDialog";
import Icon from "components/Icon/Icon";



const TagsField = (props: any) => {
  const {
    editable,
    label,
    editPath,
    onChange,
    onEdit,
    groupName,
    //readonly,
    defaultValue,
    name,
    mode
  }: any = props;
  const [tags, setTags] = useState((defaultValue as any) || ([] as any));
  const [isEditEnabled, setIsEditEnabled] = useState(false);
  const [selectedTags, setSelectedTags] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);

  const [formIsOpen, setFormIsOpen] = useState(false);

  //const classes = useStyles();
  useEffect(() => {
    if (!defaultValue) loadData();
  }, []);
  // useEffect(() => {
  //   setTags(defaultValue)
  // }, [defaultValue]);
  useEffect(() => {
    if (onChange) onChange(tags);
  }, [tags]);

  const loadData = async () => {
    setLoading(true);
    try {
      let url = `${urls.server}/api/tags?group_name=${groupName}`;
      const res = await AxiosInterceptor.get(url);
      const resData = await res?.data;
      console.log(resData);
      setTags(resData);
      setLoading(false);
    } catch (error: any) { }
  };
  const handleToggleTag = (index: number) => {
    const items = [...tags];
    items[index].selected = !items[index].selected;

    const defaultSelected = defaultValue.filter(
      (tag: { selected: any }) => tag.selected
    );

    setTags(items);
    setSelectedTags([...defaultSelected]);
  };

  const formFields: any[] = [
    {
      type: "text",
      name: "name",
      visibleName: "Nombre",
      size: 12,
      showInModes: ['create']
    },
    {
      type: "pastelColor",
      name: "color",
      visibleName: "Color",
      size: 12,
      showInModes: ['create']
    },
  ];
  useEffect(() => {
    if (!formIsOpen) {

    }
  }, [formIsOpen]);

  return (
    <div className="tags-field-container">
      <div className="field-header">
        {label && (
          <label>
            <span>{label}</span>
          </label>
        )}
        {editable && (
          <div>
            {(mode == 'edit' && editable) && (
              <FieldEditControls
                value={selectedTags}
                fieldName={name}
                editPath={editPath}
                onEditStart={() => {
                  setIsEditEnabled(true);
                }}
                onEditCancel={(originalValue) => {
                  setIsEditEnabled(false);
                  setSelectedTags(originalValue);
                }}
                onEditEnd={(success: boolean, originalValue) => {
                  setIsEditEnabled(false);
                  if (success) onEdit();
                  if (!success) {
                    setSelectedTags(originalValue);
                  }
                }}
              />
            )}</div>
        )}
      </div>

      <div className="field-body">
        {mode == 'readonly' ||
          (mode == 'edit' && editable && !isEditEnabled) ||
          (mode == 'edit' && !editable) ? (
          <>
            {loading ? (
              <div className="spinner-wrapper">
                <Spinner visible={loading} size="sm" />
              </div>
            ) : (
              <div className="readonly-content">
                <div>
                  {tags.length > 0 ? (
                    <>
                      {tags
                        .filter((item: any) => item.selected === true)
                        .map((item: any, index: number) => {
                          return (
                            <span
                              key={index}
                              style={{
                                color: item.color,
                                borderColor: item.color,
                                borderRadius: 99,
                                borderWidth: 2,
                              }}
                            >
                              <Icon name="tag" />
                              <span>{item.name}</span>
                            </span>
                          );
                        })}
                    </>
                  ) : (
                    <>-</>
                  )}
                </div>
              </div>
            )}
          </>
        ) : (
          <>
            {loading ? (
              <div className="spinner-wrapper">
                <Spinner visible={loading} size="sm" />
              </div>
            ) : (
              <div className="editable-content">
                {tags.map((item: any, index: number) => {
                  return (
                    <Button
                      key={index}
                      size="small"
                      variant="outlined"
                      endIcon={
                        item.is_selectable ? (
                          <>
                            {
                              item.selected ? (
                                <CheckBoxIcon />
                              ) : (
                                <CheckBoxOutlineBlankIcon />
                              )
                            }
                          </>
                        ) : ""
                      }
                      disabled={!item.is_selectable}
                      startIcon={<Icon name="tag" />}
                      onClick={() => handleToggleTag(index)}
                      style={{
                        color: item.selected ? item.color : null,
                        borderColor: item.selected ? item.color : null,
                        borderRadius: 99,
                        borderWidth: 2,
                      }}
                    >
                      {item.name}
                    </Button>
                  );
                })}
                <div>
                  <Button
                    onClick={() => {
                      setFormIsOpen(true);
                    }}
                    startIcon={<Icon name="add" />}
                  >
                    Etiqueta
                  </Button>
                </div>
              </div>
            )}
          </>
        )}
      </div>
      <FormDialog
        isOpen={formIsOpen}
        onClose={(data: any) => {
          setFormIsOpen(false);
          if (data) {
            console.log(data);
            loadData();
          }
        }}
        title={"Crear etiqueta"}
        size="sm"
        fields={formFields}
        createPath="tags"
        extraData={{
          'group_name': groupName
        }}
        mode={'create'}
      />

    </div>
  );
};

export { TagsField };
