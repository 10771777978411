import React from 'react';
import Counter from 'components/Counter/Counter';
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import './SizeField.scss';

const units = ['px', 'em', '%', 'rem', 'vh', 'vw'];

interface SizeFieldProps {
    label: string;
    field: string;
    isEnabled: boolean;
    value: number | null;
    unit: string;
    handleChange: (field: string, value: any) => void;
    minValue?: number;
    maxValue?: number;
}

const SizeField: React.FC<SizeFieldProps> = ({
    label,
    field,
    isEnabled,
    value,
    unit,
    handleChange,
    minValue = 0,
    maxValue,
}) => {

    const handleCheckboxChange = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        e.preventDefault();
        if (isEnabled) {
            handleChange(field, undefined);
        } else {
            handleChange(field, { value: minValue, unit: 'px' });
        }
    };

    const handleCounterChange = (newValue: number) => {
        handleChange(field, { value: newValue, unit });
    };

    const handleUnitChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        handleChange(field, { value, unit: e.target.value });
    };

    return (
        <div className="action-wrapper">
            <label>{label}</label>
            <div className="size-field input-group">
                <a className="checkbox" onClick={handleCheckboxChange} href="#">
                    <div>
                        {isEnabled ? <CheckBoxIcon /> : <CheckBoxOutlineBlankIcon />}
                    </div>
                </a>
                {isEnabled && (
                    <>
                        <Counter
                            value={value || minValue}
                            onChange={handleCounterChange}
                            minValue={minValue}
                            maxValue={maxValue}
                        />
                        <select
                            value={unit}
                            onChange={handleUnitChange}
                        >
                            {units.map((unit) => (
                                <option key={unit} value={unit}>
                                    {unit}
                                </option>
                            ))}
                        </select>
                    </>
                )}
            </div>
        </div>
    );
};

export default SizeField;
