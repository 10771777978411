import React, { useState, useEffect } from "react";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import "./UnpaidDocumentsPicker.scss";
const UnpaidDocumentsPicker = ({ data, totalToPay, onChange }: any) => {
  const [items, setItems] = useState(data);
  const [totalAssigned, setTotalAssigned] = useState(0);

  useEffect(() => {
    const initialItems = data.map((item: any) => ({
      ...item,
      assignedAmount: 0,
      selected: false,
    }));
    setItems(initialItems);
    setTotalAssigned(0);
  }, [data, totalToPay]);

  const handleItemClick = (index: number) => {
    const newItems = [...items];
    const item: any = newItems[index];
    if (!canSelectItem(item)) return;
    //const available = totalToPay - totalAssigned;
    item.selected = item.selected ? !item.selected : true;
    if (item.selected) {
      const available = totalToPay - totalAssigned;
      item.assignedAmount = Math.min(item.balance, available);
      item.balance -= item.assignedAmount;
    } else {
      item.balance += item.assignedAmount;
      item.assignedAmount = 0;
    }

    setItems(newItems);
    updateTotalAssigned();
  };
  const canSelectItem = (item: any) => {
    if (item.selected) return true; // Always allow deselecting
    const available = totalToPay - totalAssigned;
    // return item.balance <= available;
    return available > 0;
  };
  const updateTotalAssigned = () => {
    const total = items.reduce(
      (sum: number, item: any) => sum + item.assignedAmount,
      0
    );
    setTotalAssigned(total);
  };
  useEffect(() => {
    onChange(items);
  }, [items]);
  return (
    <div className="unpaid-documents-picker-container">
      {items?.map((item: any, index: number) => (
        <a
          className={`item ${!canSelectItem(item) && !item.selected ? "blocked" : ""
            }`}
          key={index}
          onClick={() => handleItemClick(index)}
        >
          <div>
            <span>{item.visible_id}</span>
            <span></span>
          </div>
          <div>
            <div>
              <span>{item.total}</span>
              <span>{item.balance}</span>
              <span>Importe Asignado: {item.assignedAmount}</span>
            </div>
            <div>
              {item.selected ? <CheckBoxIcon /> : <CheckBoxOutlineBlankIcon />}
            </div>
          </div>
        </a>
      ))}
    </div>
  );
};

export { UnpaidDocumentsPicker };
