import React from 'react';
import Typewriter from 'typewriter-effect';

interface TypingTextProps {
    content: string;
    onComplete?: () => void;
    speed?: number;
    style?: React.CSSProperties;
    showCursor?: boolean;
}

const TypingText: React.FC<TypingTextProps> = ({
    content,
    onComplete,
    speed = 50,
    style = {},
    showCursor = false,
}) => {
    return (
        <div style={style}>
            <Typewriter
                options={{
                    delay: speed,
                    cursor: showCursor ? '|' : '',
                }}
                onInit={(typewriter) => {
                    typewriter
                        .typeString(content)
                        .callFunction(() => {
                            if (onComplete) onComplete();
                            // Ocultar el cursor al finalizar
                            if (showCursor) {
                                setTimeout(() => {
                                    const cursorElement = document.querySelector('.Typewriter__cursor');
                                    if (cursorElement) {
                                        cursorElement.remove(); // Elimina el cursor del DOM
                                    }
                                }, 100); // Se espera un poco para asegurarse de que el texto esté terminado
                            }
                        })
                        .start();
                }}
            />
        </div>
    );
};

export default TypingText;
