import React, { useState } from 'react';
import './ChildRecordsCell.scss';
import { Button } from '@mui/material';
import { ChildRecordsModal } from 'components/modals/ChildRecordsModal/ChildRecordsModal';
import Icon from 'components/Icon/Icon';

interface Props {
    childRecords: any[];
    title: string;
    subtitle?: string;
    listPath: string;
    areChildrenSortable: boolean;
    // canEditChild: boolean;
    // canDeleteChild: boolean;
    listActions: any[];
    headerActions: any[];
    itemActions: any[];
    //cells: any;
    rightCells: any;
    leftCells: any;
    fields: any;
    sortPath: string;
    deletePath: string;
    deleteConfirmationMessage: string;
    noContentText: string;
    noContentIcon: string;
    createFormTitle: string;
    editFormTitle: string;
    createPath: string;
    editPath: string;
    extraData?: any;
    onChange: () => void;
}

const ChildRecordsCell: React.FC<Props> = ({
    childRecords,
    title,
    subtitle,
    listPath,
    areChildrenSortable,
    // canEditChild,
    // canDeleteChild,
    listActions = [],
    headerActions = [],
    itemActions = [],
    //cells,
    rightCells,
    leftCells,
    fields,
    sortPath,
    deletePath,
    deleteConfirmationMessage,
    noContentText,
    noContentIcon,
    createFormTitle,
    editFormTitle,
    createPath,
    editPath,
    extraData,
    onChange
}) => {
    const [childRecordsModalIsOpen, setChildRecordsModalIsOpen] = useState(false);

    const handleClick = () => {
        setChildRecordsModalIsOpen(true);
    }

    return (
        <>
            <div className='child-records-cells-container'>
                <Button
                    aria-label={`Show ${childRecords?.length} child records`}
                    onClick={handleClick}
                >

                    <Icon name="children" />
                    <span>{childRecords?.length}</span>
                </Button>
            </div>
            <ChildRecordsModal
                isOpen={childRecordsModalIsOpen}
                onClose={(refresh) => {
                    if (refresh) onChange();
                    setChildRecordsModalIsOpen(false);
                }}
                title={title}
                subtitle={subtitle}
                listPath={listPath}
                deletePath={deletePath}
                deleteConfirmationMessage={deleteConfirmationMessage}
                sortPath={sortPath}
                listActions={listActions}
                headerActions={headerActions}
                itemActions={itemActions}
                //itemCells={cells}
                itemLeftCells={leftCells}
                itemRightCells={rightCells}
                noContentText={noContentText}
                noContentIcon={noContentIcon}
                isSortable={areChildrenSortable}
                createFormTitle={createFormTitle}
                editFormTitle={editFormTitle}
                formFields={fields}
                createPath={createPath}
                editPath={editPath}
                extraData={extraData}
            />
        </>
    );
}

export default ChildRecordsCell;
