import React from 'react'
import './NotificationItem.scss';
import { ChevronRight } from '@mui/icons-material';

const NotificationItem = ({ data, className, onClick }: any) => {
    return (
        <a className={`notification-item-container ${className}`} onClick={onClick}>
            <div>{data?.message}</div>
            <ChevronRight />
        </a>
    )
}

export default NotificationItem