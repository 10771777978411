import React from "react";
import "./TasksCard.scss";
import CardPreview from "components/CardPreview/CardPreview";
const TasksCard = (props: any) => {
  const { title, data, config } = props;
  return (
    <div className="tasks-card-container">
      <h2 className="card-title">{title}</h2>
      <div>
        {/* <div>
          <BasicIndicator
            color={"#4d6572"}
            value={data?.pendingTasksCount}
            label={"Tareas pendientes"}
            size="sm"
          />
        </div>

        <div>
          <BasicIndicator
            color={"#ed3b4b"}
            value={data?.overdueTasksCount}
            label={"Tareas vencidas"}
            size="sm"
          />
        </div> */}
        <CardPreview
          type={config?.previewType}
          icon={config?.previewIcon}
          valueDefinitions={config?.previewValueDefinitions}
          data={data}
        />
      </div>
    </div>
  );
};

export { TasksCard };
