import { formatNumber } from "../../../utils/formatNumber";
import "./MoneyCell.scss";
const MoneyCell = ({ visibleName, data, extraData }: any) => {
  return (
    <div className="money-cell-container">
      <span>{visibleName}</span>
      <div>
        {data ? (
          <>
            <span>$</span> {formatNumber(data)}
          </>
        ) : (
          <>-</>
        )}
      </div>
    </div>
  );
};

export { MoneyCell };
