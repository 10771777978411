import React from "react";
import "./StepperCell.scss";

import colors from "config/colors";
import Icon from "components/Icon/Icon";

const StepperCell = ({ steps, stepsPerGroup = 3 }: any) => {
  // Función auxiliar para dividir el array en grupos de tamaño "stepsPerGroup"
  const chunkArray = (array: any[], size: number) => {
    const chunkedArr = [];
    let index = 0;
    while (index < array.length) {
      chunkedArr.push(array.slice(index, size + index));
      index += size;
    }
    return chunkedArr;
  };

  // Dividimos los pasos en grupos de tamaño "stepsPerGroup"
  const stepGroups = chunkArray(steps, stepsPerGroup);

  return (
    <div className="stepper-cell-container">
      {stepGroups.map((group: any[], groupIndex: number) => (
        <div key={groupIndex} className="step-group">
          {group.map((item: any, index: number) => {
            let statusIcon;
            let statusText;

            switch (item.status) {
              case "pending":
                statusIcon = null;
                statusText = "Pendiente";
                break;
              case "incomplete":
                statusIcon = null;
                statusText = "Incompleto";
                break;
              case "draft":
                statusIcon = (
                  <Icon name="saveAsDraft" style={{ color: colors.medium }} />
                );
                statusText = "Borrador";
                break;
              case "completed":
                statusIcon = <Icon name="check" style={{ color: colors.medium }} />;
                statusText = "Completado";
                break;
              case "rejected":
              case "expired":
                statusIcon = <Icon name="alert" style={{ color: colors.danger }} />;
                statusText =
                  item.status === "rejected" ? "Rechazado" : "Vencido";
                break;
              case "partialApproval":
                statusIcon = (
                  <Icon name="saveAsDraft" style={{ color: colors.success }} />
                );
                statusText = "Aprobación Parcial";
                break;
              case "approved":
                statusIcon = <Icon name="check" style={{ color: colors.success }} />;
                statusText = "Aprobado";
                break;
              case "done":
                statusIcon = <Icon name="check" style={{ color: colors.medium }} />;
                statusText = "Realizado";
                break;
              case "actions_executed":
                statusIcon = <Icon name="check" style={{ color: colors.success }} />;
                statusText = "Acción/s ejecutadas";
                break;
              // case "observed":
              //   statusIcon = <AlertCircleIcon style={{ color: "red" }} />;
              //   statusText = "Observed";
              //   break;
              default:
                break;
            }

            return (
              <div key={index} className="step">
                <div className="step-name">
                  <h3>{item.workflow_step_visible_name}</h3>
                </div>
                <span>{statusIcon}</span>
                <div className="status-text">{statusText}</div>
              </div>
            );
          })}
        </div>
      ))}
    </div>
  );
};

export { StepperCell };
