import { Button } from "@mui/material";
import { useTextEditorField } from "./TextEditorFieldContext";
import IconButton from "@mui/material/IconButton";
import ClearIcon from "@mui/icons-material/Clear";
import urls from "config/urls";
import InsertPhotoIcon from "@mui/icons-material/InsertPhoto";
import CircleIcon from "@mui/icons-material/Circle";
import "./TextEditorField.scss";
import Icon from "components/Icon/Icon";

const TextEditorFieldItemContent = ({ i, item }: any) => {
  const {
    handleChangeContent,
    handleChangeImageDescription,
    handleChangeListItem,
    handleAddListItem,
    handleDeleteListItem,
    handleAddColumn,
    handleRemoveColumn,
    handleAddRow,
    handleRemoveRow,
    handleChangeTableCell,
    handleImageUpload,
    handleRemoveImage,
  } = useTextEditorField();
  return (
    <div className="text-editor-field-item-content">
      {/* <span>{item.label}</span> */}
      {(item?.type === "h1" || item?.type === "h2" || item?.type === "h3") && (
        <input
          type="text"
          value={item.content}
          onChange={(e) => handleChangeContent(i, e.target.value)}
          style={{
            fontSize: item.fontSize,
            textAlign: item.textAlign,
            fontWeight: item.fontWeight,
          }}
        />
      )}
      {(item.type === "text" || item.type === "paragraph") && (
        <textarea
          value={item.content}
          onChange={(e) => handleChangeContent(i, e.target.value)}
          style={{
            fontSize: item.fontSize,
            textAlign: item.textAlign,
            fontWeight: item?.fontStyle.indexOf("bold") + 1 ? 600 : 300,
            fontStyle: item?.fontStyle.indexOf("italic") + 1 ? "italic" : "",
            color: item.color,
          }}
        ></textarea>
      )}
      {item.type === "list" && (
        <div className="list-item-wrapper">
          <div>
            {item.content.map((listItem: string, index: number) => {
              return (
                <div key={index}>
                  <div className="marker-wrapper">
                    {item?.markerType === "square" && <Icon name="square" />}
                    {item?.markerType === "squareOutline" && (
                      <Icon name="squareOutline" />
                    )}
                    {item?.markerType === "circle" && <Icon name="circle" />}
                    {item?.markerType === "circleOutline" && (
                      <Icon name="circleOutline" />
                    )}
                    {item?.markerType === "checkMark" && <Icon name="checkmark" />}
                  </div>
                  <textarea
                    value={listItem}
                    onChange={(e) =>
                      handleChangeListItem(i, index, e.target.value)
                    }
                  ></textarea>

                  <IconButton
                    aria-label="delete"
                    onClick={() => handleDeleteListItem(i, index)}
                  >
                    <ClearIcon />
                  </IconButton>
                </div>
              );
            })}
          </div>
          <div>
            <Button
              onClick={() => handleAddListItem(i)}
              startIcon={<Icon name="add" />}
            >
              Agregar
            </Button>
          </div>
        </div>
      )}
      {item.type === "image" && (
        <div className="image-item-wrapper">
          {item.content?.path && (
            <>
              <div className="preview-img-wrapper">
                <img src={`${urls.server}${item?.content?.path}`} />
                <IconButton
                  aria-label="delete"
                  onClick={() => handleRemoveImage(i)}
                >
                  <Icon name="delete" />
                </IconButton>
              </div>
              {/* <div>{item?.content?.description}</div> */}
            </>
          )}
          {!item.content?.path && (
            <div className="add-image-btn">
              <input
                id={"image" + i}
                accept="image/*"
                type="file"
                onChange={(e) => handleImageUpload(e, i)}
              />
              <div>
                <div>
                  <InsertPhotoIcon />
                  <span>Seleccionar imagen</span>
                </div>
                <label htmlFor={"image" + i}></label>
              </div>
            </div>
          )}

          <div className="image-item-description">
            <span>Descripción de la imagen:</span>
            <textarea
              value={item.content?.description || ""}
              onChange={(e) => handleChangeImageDescription(i, e.target.value)}
              style={{
                fontSize: item.fontSize,
                textAlign: item.textAlign,
                fontWeight: item.fontWeight,
              }}
            />
          </div>
        </div>
      )}
      {item.type === "table" && (
        <div style={{ overflowX: "auto" }}>
          <table>
            <tbody>
              <tr>
                {item.content[0].map((col: any, c: number) => {
                  return (
                    <td key={c}>
                      <div className="col-btns">
                        {/* <a onClick={() => handleRemoveColumn(i, c)}>
                          <ClearIcon />
                        </a>
                        <a onClick={() => handleAddColumn(i, c + 1)}>
                          <Icon name="add" />
                        </a> */}

                        <IconButton
                          aria-label="clear"
                          onClick={() => handleRemoveColumn(i, c)}
                        >
                          <ClearIcon />
                        </IconButton>
                        <IconButton
                          aria-label="add"
                          onClick={() => handleAddColumn(i, c + 1)}
                        >
                          <Icon name="add" />
                        </IconButton>
                      </div>
                    </td>
                  );
                })}
              </tr>
              {item.content.map((row: any, r: number) => {
                return (
                  <tr key={r}>
                    {row.map((col: any, c: number) => {
                      return (
                        <td key={c}>
                          <input
                            value={col.text}
                            style={{
                              fontWeight:
                                r == 0 && item.withHeader ? "bold" : 300,
                            }}
                            onChange={(e) =>
                              handleChangeTableCell(i, r, c, e.target.value)
                            }
                          />
                        </td>
                      );
                    })}
                    <td>
                      <div className="row-btns">
                        <IconButton
                          aria-label="clear"
                          onClick={() => handleRemoveRow(i, r)}
                        >
                          <ClearIcon />
                        </IconButton>

                        <IconButton
                          aria-label="add"
                          onClick={() => handleAddRow(i, r + 1)}
                        >
                          <Icon name="add" />
                        </IconButton>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      )}
      {item?.type === "code" && (
        <textarea
          value={item.content || ""}
          onChange={(e) => handleChangeContent(i, e.target.value)}
        ></textarea>
      )}
    </div>
  );
};

export { TextEditorFieldItemContent };
