import React, { useState } from "react";
import "./MyTasksExpandedCard.scss";
import { TasksCalendarModal } from "../TasksCalendarModal/TasksCalendarModal";
import { TasksBoardModal } from "../TasksBoardModal/TasksBoardModal";
import { CompletedTasksModal } from "../CompletedTasksModal/CompletedTasksModal";
import Icon from "components/Icon/Icon";
const MyTasksExpandedCard = (props: any) => {
  const { title, data, setRefreshOnClose } = props;
  const [calendarModalIsOpen, setCalendarModalIsOpen] = useState(false);
  const [boardModalIsOpen, setBoardModalIsOpen] = useState(false);
  const [completedTasksModalIsOpen, setCompletedTasksModalIsOpen] =
    useState(false);
  return (
    <>
      <div className="my-tasks-expanded-card-container">
        <div className="expanded-card-header">
          <h2>{title}</h2>
        </div>
        <div className="expanded-card-body">
          <a
            className="card-btn lg"
            onClick={() => {
              setCalendarModalIsOpen(true);
            }}
          >
            {/* <span>Crear</span> */}
            <strong>Calendario de tareas</strong>
            <div>
              <Icon name="calendarWrapper" />
              <Icon name="calendar" />
            </div>
          </a>
          <a
            className="card-btn lg"
            onClick={() => {
              setBoardModalIsOpen(true);
            }}
          >
            {/* <span>Crear</span> */}
            <strong>Tablero de tareas</strong>
            <div>
              <Icon name="taskBoardSquareWrapper" />
              <Icon name="taskBoard" />
            </div>
          </a>
          <a
            className="card-btn lg"
            onClick={() => {
              setCompletedTasksModalIsOpen(true);
            }}
          >
            {/* <span>Crear</span> */}
            <strong>Tareas finalizadas</strong>
            <div>
              <Icon name="checkSquareWrapper" />
              <Icon name="checkSquare" />
            </div>
          </a>
        </div>
      </div>
      <TasksCalendarModal
        isOpen={calendarModalIsOpen}
        onClose={(res: any) => {
          setCalendarModalIsOpen(false)
          if (res?.refresh == true) {
            setRefreshOnClose(true);
          }
        }}
        mode="user"
      />
      <TasksBoardModal
        open={boardModalIsOpen}
        setOpen={setBoardModalIsOpen}
        onClose={() => { }}
      />
      <CompletedTasksModal
        isOpen={completedTasksModalIsOpen}
        onClose={(res: any) => {
          setCompletedTasksModalIsOpen(false)
          if (res) {
            setRefreshOnClose(true);
          }
        }}
        mode="user"
      />
    </>
  );
};

export { MyTasksExpandedCard };
