import Modal from 'components/Modal/Modal';
import React, { useEffect, useState } from 'react'
import ConfigurationItemSelector from './components/ConfigurationItemSelector/ConfigurationItemSelector';
import './ConfigurationBuilderModal.scss';
import SelectedConfigurationItems from './components/SelectedConfigurationItems/SelectedConfigurationItems';
import ConfigurationItemEditor from './components/ConfigurationItemEditor/ConfigurationItemEditor';
import { Fab } from '@mui/material';
import recordService from 'services/recordService';
import { toKebabCase } from 'utils/toKebabCase';
import colors from 'config/colors';
import { PreviewFormModal } from '../PreviewFormModal/PreviewFormModal';
import { Toast } from 'components/Toast/Toast';
import Icon from 'components/Icon/Icon';
interface ConfigurationBuilderModalProps {
    isOpen: boolean;
    onClose: (value?: any) => void;
    type: 'fields' | 'cols' | 'cells' | 'filters' | 'rowActions' | 'moreRowActions' | 'headerActions' | 'listActions' | 'moreHeaderActions' | 'dividerGroups' | 'itemActions' | 'moreItemActions' | 'importCols' | 'exportCols';
    data?: any;
    extraData?: any;
}
const ConfigurationBuilderModal: React.FC<ConfigurationBuilderModalProps> = ({ isOpen, type, data, extraData, onClose }) => {
    const [selectedItems, setSelectedItems] = React.useState([] as any);
    const [selectedItemIndex, setSelectedItemIndex] = React.useState(null as any);
    const [itemToEdit, setItemToEdit] = useState(null);
    const [configurationItemSelectorWrapperIsVisible, setConfigurationItemSelectorWrapperIsVisible] = useState(true);
    const [configurationItemEditorWrapperIsVisible, setConfigurationItemEditorWrapperIsVisible] = useState(false);
    const [previewFormModalIsOpen, setPreviewFormModalIsOpen] = useState(false);
    const [previewTableModalIsOpen, setPreviewTableModalIsOpen] = useState(false);
    const [previewListModalIsOpen, setPreviewListModalIsOpen] = useState(false);
    const [items, setItems] = useState([]);
    const [loading, setLoading] = useState(false);
    const [attributesToSave, setAttributesToSave] = useState([]);
    const [toastIsOpen, setToastIsOpen] = useState(false);
    const [toastMessage, setToastMessage] = useState("");
    const [toastSeverity, setToastSeverity] = useState("error");
    let title = '';
    switch (type) {
        case 'fields':
            title = "Armador de campos del formulario";
            break;
        case 'cols':
            title = "Armador de columnas";
            break;
        case 'cells':
            title = "Armador de celdas";
            break;
        case 'filters':
            title = "Armador de filtros";
            break;
        case 'listActions':
            title = "Armador de acciones (lista)";
            break;
        case 'rowActions':
            title = "Armador de acciones (fila)";
            break;
        case 'itemActions':
            title = "Armador de acciones (item)";
            break;
        case 'moreRowActions':
            title = "Armador de otras acciones (fila)";
            break;
        case 'moreItemActions':
            title = "Armador de otras acciones (item)";
            break;
        case 'headerActions':
            title = "Armador de acciones (cabecera)";
            break;
        case 'moreHeaderActions':
            title = "Armador de otras acciones (cabecera)";
            break;
        case 'dividerGroups':
            title = "Armador de grupo de divisores";
            break;
        default:
            break;
    }
    const fetchItems = async () => {
        setLoading(true);
        try {
            const res = await recordService.fetchAll({
                listPath: `configuration-items/${toKebabCase(type)}`,
            });
            console.log(res)

            setItems(res);
            setTimeout(() => {
                setLoading(false);
            }, 500)

        } catch (error: any) {
            setLoading(false)
        }
    };
    useEffect(() => {
        if (isOpen) fetchItems()

    }, [isOpen])
    const toggleConfigurationItemSelectorWrapperVisibility = () => {
        setConfigurationItemSelectorWrapperIsVisible(!configurationItemSelectorWrapperIsVisible);
    };

    const handleSelectItem = (newItem: any) => {
        setSelectedItems((prevItems: any) => [...prevItems, newItem]);
    };
    const handleEditItem = (item: any, index: number) => {
        console.log('Editar ítem:', index, item);
        setSelectedItemIndex(index)
        setItemToEdit(item);
        // Lógica para editar el ítem
    };

    const handleRemoveItem = (item: any, index: number) => {
        setSelectedItems((prevItems: any) => prevItems.filter((item: any, idx: number) => idx !== index));
        console.log('Eliminar ítem:', index, item);
        // Lógica para eliminar el ítem
    };
    const handleSortEnd = (items: any) => {
        console.log(items)
        setSelectedItems(items);
    };
    useEffect(() => {
        if (itemToEdit) {
            setConfigurationItemEditorWrapperIsVisible(true)
        }

    }, [itemToEdit])

    useEffect(() => {
        if (isOpen && data) {
            const transformedData = data.map((dataItem: any) => {
                // Buscar el ítem correspondiente de `items` basado en `type`
                const itemInfo: any = items.find((item: any) => item.type === dataItem.type);
                return {
                    ...itemInfo, // Esto establecerá `type`, `visible_type`, `icon` del ítem encontrado
                    configurable_attributes: { ...dataItem }, // Coloca todo el objeto de dataItem dentro de `configurable_attributes`
                };
            });
            console.log("transformedData", transformedData);
            setSelectedItems(transformedData);
        }
    }, [isOpen, data, items]);
    const validateAttributes = (attributesToSave: any) => {

        // Validación de nombres no nulos y no vacíos
        const allNamesValid = attributesToSave.every((item: any) => item.name && item.name.trim() !== '');

        // Validación de nombres únicos
        //const names = attributesToSave.map((item: any) => item?.name.trim());
        const names = attributesToSave.map((item: any) => item.name ? item.name.trim() : "");

        const uniqueNames = new Set(names);
        const allNamesUnique = names.length === uniqueNames.size;

        if (!allNamesValid) {
            setToastMessage("Todos los campos deben tener un nombre válido y no vacío.");
            setToastSeverity("error");
            setToastIsOpen(true);
            return false; // Retornar falso si la validación falla
        }

        if (!allNamesUnique) {
            // setToastMessage("Los nombres de los campos deben ser únicos.");
            // setToastSeverity("error");
            // setToastIsOpen(true);
            // return false; // Retornar falso si la validación falla
        }

        return true; // Retornar verdadero si todas las validaciones son correctas
    }

    return (
        <Modal title={title} className={'configuration-builder-modal-container'} isOpen={isOpen} onClose={() => {
            onClose();
            setSelectedItems([]);
        }}>
            <div className={`configuration-item-selector-wrapper ${configurationItemSelectorWrapperIsVisible ? "visible" : "hidden"
                }`}
            >
                <a className="toggle-button" onClick={toggleConfigurationItemSelectorWrapperVisibility}>
                    {configurationItemSelectorWrapperIsVisible ? <Icon name="close" /> : <Icon name="add" />}
                </a>
                <ConfigurationItemSelector items={items} onSelectItem={handleSelectItem} />
            </div>
            <div className='selected-configuration-items-wrapper'>
                {!loading ? <SelectedConfigurationItems
                    data={selectedItems}
                    onEditItem={handleEditItem}
                    onRemoveItem={handleRemoveItem}
                    onSortEnd={handleSortEnd}
                /> : null}

            </div>

            <ConfigurationItemEditor
                configurationType={type}
                data={itemToEdit}
                onHide={(modifiedItem) => {
                    if (modifiedItem) {
                        setSelectedItems((prevItems: any) =>
                            prevItems.map((item: any, index: number) =>
                                //item.id === modifiedItem.id ? modifiedItem : item
                                selectedItemIndex === index ? modifiedItem : item
                            )
                        );
                    }
                    setConfigurationItemEditorWrapperIsVisible(false);
                    setItemToEdit(null);
                }}
                isVisible={configurationItemEditorWrapperIsVisible} />

            {
                type == 'fields' &&
                <Fab
                    className="preview-fab"
                    size="small"
                    aria-label="preview"
                    onClick={() => {

                        const attributesToSave = selectedItems.map((item: any) => item.configurable_attributes);
                        if (validateAttributes(attributesToSave)) {
                            setAttributesToSave(attributesToSave);

                            console.log(attributesToSave);
                            setPreviewFormModalIsOpen(true);

                        }

                    }}
                    sx={{
                        position: "fixed",
                        bottom: 24,
                        right: 85,
                        zIndex: 0,
                        backgroundColor: colors.medium,
                        color: "#fff"
                    }}
                >
                    <Icon name="eye" />
                </Fab>
            }

            <Fab
                className="fab"
                color="primary"
                aria-label="save"
                onClick={() => {
                    const attributesToSave = selectedItems.map((item: any) => item.configurable_attributes);
                    if (validateAttributes(attributesToSave)) {
                        onClose(attributesToSave);
                        setSelectedItems([]);
                    }
                }}
                //disabled={selectedItems.length === 0}
                //style={{ opacity: selectedItems.length === 0 ? .6 : 1 }}
                sx={{
                    position: "fixed",
                    bottom: 16,
                    right: 16,
                    zIndex: 0
                }}
            >
                <Icon name="check" />
            </Fab>
            <PreviewFormModal
                isOpen={previewFormModalIsOpen}
                onClose={() => {
                    setPreviewFormModalIsOpen(false);
                }}
                fields={attributesToSave}
            />
            <Toast
                isOpen={toastIsOpen}
                onClose={() => setToastIsOpen(false)}
                message={toastMessage}
                severity={toastSeverity}
            />
        </Modal>
    )

}

export default ConfigurationBuilderModal