import React, { useEffect } from 'react';
import urls from "config/urls";
import CollapsedHeader from './uiComponents/CollapsedHeader/CollapsedHeader';
import Nav from './uiComponents/Nav/Nav';
import Image from './uiComponents/Image/Image';
import Video from './uiComponents/Video/Video';
import LottieComponent from './uiComponents/Lottie/Lottie';
import ProfileButton from './uiComponents/ProfileButton/ProfileButton';
const ContentViewer = ({ structure, screenWidth, screenHeight = 1000 }: { structure: any, screenWidth: string, screenHeight?: number }) => {
    useEffect(() => {
        console.log(structure);
    }, [structure]);
    const renderComponent = (component: any) => {
        const { type, children, responsive, ...defaultProps } = component;

        // Si el componente tiene propiedades responsive, las aplicamos según el screenWidth
        let responsiveProps = {};
        if (responsive && responsive[screenWidth]) {
            responsiveProps = responsive[screenWidth];
        }

        // Mezclamos las propiedades por defecto con las responsivas
        const componentProps = {
            ...defaultProps,
            ...responsiveProps,
        };
        const src = componentProps.src ? `${urls?.server}${componentProps.src}` : "../assets/placeholder-image.svg";
        switch (type) {
            case 'block':
                return (
                    <div
                        key={component.id}
                        style={{
                            width: `${componentProps.width?.value}${componentProps.width?.unit}`,
                            height: componentProps.height ? `${componentProps.height?.value}${componentProps.height?.unit}` : 'auto',
                            marginTop: `${componentProps.marginTop?.value}${componentProps.marginTop?.unit}`,
                            marginBottom: `${componentProps.marginBottom?.value}${componentProps.marginBottom?.unit}`,
                            marginLeft: `${componentProps.marginLeft?.value}${componentProps.marginLeft?.unit}`,
                            marginRight: `${componentProps.marginRight?.value}${componentProps.marginRight?.unit}`,
                            paddingTop: `${componentProps.paddingTop?.value}${componentProps.paddingTop?.unit}`,
                            paddingBottom: `${componentProps.paddingBottom?.value}${componentProps.paddingBottom?.unit}`,
                            paddingLeft: `${componentProps.paddingLeft?.value}${componentProps.paddingLeft?.unit}`,
                            paddingRight: `${componentProps.paddingRight?.value}${componentProps.paddingRight?.unit}`,
                            backgroundColor: componentProps.backgroundColor,
                            display: 'flex',
                            flexDirection: componentProps.flexDirection || 'row',
                            justifyContent: componentProps.justifyContent || 'flex-start',
                            alignItems: componentProps.alignItems || 'stretch',
                            flexWrap: componentProps.flexWrap || 'nowrap',
                            color: componentProps.color,
                        }}
                        className={componentProps.className}
                    >
                        {children && children.map((child: any) => renderComponent(child))}
                    </div>
                );
            case 't1':
                return (
                    <h1
                        key={component.id}
                        style={{
                            fontSize: `${componentProps.fontSize?.value}${componentProps.fontSize?.unit}`,
                            fontFamily: componentProps.fontFamily,
                            fontWeight: componentProps.fontWeight,
                            fontStyle: componentProps.fontStyle,
                            color: componentProps.color,
                            textAlign: componentProps.textAlign?.replace('align', '').toLowerCase(),
                            marginTop: `${componentProps.marginTop?.value}${componentProps.marginTop?.unit}`,
                            marginBottom: `${componentProps.marginBottom?.value}${componentProps.marginBottom?.unit}`,
                            marginLeft: `${componentProps.marginLeft?.value}${componentProps.marginLeft?.unit}`,
                            marginRight: `${componentProps.marginRight?.value}${componentProps.marginRight?.unit}`,
                            backgroundColor: componentProps.backgroundColor,
                        }}
                        className={componentProps.className}
                    >
                        {componentProps.textContent}
                    </h1>
                );
            case 't2':
                return (
                    <h2
                        key={component.id}
                        style={{
                            fontSize: `${componentProps.fontSize?.value}${componentProps.fontSize?.unit}`,
                            fontFamily: componentProps.fontFamily,
                            fontWeight: componentProps.fontWeight,
                            fontStyle: componentProps.fontStyle,
                            color: componentProps.color,
                            textAlign: componentProps.textAlign?.replace('align', '').toLowerCase(),
                            marginTop: `${componentProps.marginTop?.value}${componentProps.marginTop?.unit}`,
                            marginBottom: `${componentProps.marginBottom?.value}${componentProps.marginBottom?.unit}`,
                            backgroundColor: componentProps.backgroundColor,
                        }}
                        className={componentProps.className}
                    >
                        {componentProps.textContent}
                    </h2>
                );
            // case 'image':
            //     return (
            //         <img
            //             key={component.id}
            //             src={src}
            //             style={{
            //                 width: `${componentProps.width?.value}${componentProps.width?.unit}`,
            //                 height: `${componentProps.height?.value}${componentProps.height?.unit}`,
            //                 marginTop: `${componentProps.marginTop?.value}${componentProps.marginTop?.unit}`,
            //                 marginBottom: `${componentProps.marginBottom?.value}${componentProps.marginBottom?.unit}`,
            //                 marginLeft: `${componentProps.marginLeft?.value}${componentProps.marginLeft?.unit}`,
            //                 marginRight: `${componentProps.marginRight?.value}${componentProps.marginRight?.unit}`,
            //             }}
            //             className={componentProps.className}
            //             alt=""
            //         />
            //     );
            case 'text':
                return (
                    <p
                        key={component.id}
                        style={{
                            fontSize: `${componentProps.fontSize?.value}${componentProps.fontSize?.unit}`,
                            fontFamily: componentProps.fontFamily,
                            fontWeight: componentProps.fontWeight,
                            fontStyle: componentProps.fontStyle,
                            color: componentProps.color,
                            textAlign: componentProps.textAlign?.replace('align', '').toLowerCase(),
                        }}
                        className={componentProps.className}
                    >
                        {componentProps.textContent}
                    </p>
                );
            case 'nav':
                return (
                    <Nav
                        key={component.id}
                        style={{}}
                        items={componentProps?.items}
                        itemStyle={{}}
                    />
                );
            case 'profileButton':
                return (
                    <ProfileButton
                        key={component.id}
                        style={{
                            width: `${componentProps.width?.value}${componentProps.width?.unit}`,
                            marginTop: `${componentProps.marginTop?.value}${componentProps.marginTop?.unit}`,
                            marginBottom: `${componentProps.marginBottom?.value}${componentProps.marginBottom?.unit}`,
                            marginLeft: `${componentProps.marginLeft?.value}${componentProps.marginLeft?.unit}`,
                            marginRight: `${componentProps.marginRight?.value}${componentProps.marginRight?.unit}`,
                            paddingTop: `${componentProps.paddingTop?.value}${componentProps.paddingTop?.unit}`,
                            paddingBottom: `${componentProps.paddingBottom?.value}${componentProps.paddingBottom?.unit}`,
                            paddingLeft: `${componentProps.paddingLeft?.value}${componentProps.paddingLeft?.unit}`,
                            paddingRight: `${componentProps.paddingRight?.value}${componentProps.paddingRight?.unit}`,
                            backgroundColor: componentProps.backgroundColor,
                        }}

                        profileThumbnailStyle={{
                            width: componentProps.profileThumbnail?.width ? `${componentProps.profileThumbnail?.width.value}${componentProps.profileThumbnail?.width.unit}` : undefined,
                            borderRadius: componentProps.profileThumbnail?.borderRadius ? `${componentProps.profileThumbnail?.borderRadius.value}${componentProps.profileThumbnail?.borderRadius.unit}` : undefined,
                        }}
                        popoverStyle={{}}
                        profileImageStyle={{}}
                        profileNameStyle={{}}
                        profileEmailStyle={{}}
                        authButtonStyle={{}}
                        displayMode={componentProps?.displayMode}
                    />
                );
            case 'header':
                return (
                    <React.Fragment key={component.id}>{
                        componentProps.isCollapsed ? (
                            <CollapsedHeader
                                style={{}}
                                hamburgerButtonStyle={{}}
                                drawerStyle={{}}
                                openIcon={componentProps?.hamburgerButton?.openIcon?.name}
                                closeIcon={componentProps?.hamburgerButton?.closeIcon?.name}
                                screenHeight={screenHeight}
                            >

                                {children && children.map((child: any, index: number) => (
                                    <React.Fragment key={child?.id}>
                                        {renderComponent(child)}
                                    </React.Fragment>
                                ))}
                            </CollapsedHeader>

                        ) : (
                            <div
                                style={{
                                    width: `${componentProps.width?.value}${componentProps.width?.unit}`,
                                    height: componentProps.height ? `${componentProps.height?.value}${componentProps.height?.unit}` : 'auto',
                                    marginTop: `${componentProps.marginTop?.value}${componentProps.marginTop?.unit}`,
                                    marginBottom: `${componentProps.marginBottom?.value}${componentProps.marginBottom?.unit}`,
                                    marginLeft: `${componentProps.marginLeft?.value}${componentProps.marginLeft?.unit}`,
                                    marginRight: `${componentProps.marginRight?.value}${componentProps.marginRight?.unit}`,
                                    paddingTop: `${componentProps.paddingTop?.value}${componentProps.paddingTop?.unit}`,
                                    paddingBottom: `${componentProps.paddingBottom?.value}${componentProps.paddingBottom?.unit}`,
                                    paddingLeft: `${componentProps.paddingLeft?.value}${componentProps.paddingLeft?.unit}`,
                                    paddingRight: `${componentProps.paddingRight?.value}${componentProps.paddingRight?.unit}`,
                                    backgroundColor: componentProps.backgroundColor,
                                    display: 'flex',
                                    flexDirection: componentProps.direction || 'row',
                                    justifyContent: componentProps.justifyContent || 'flex-start',
                                    alignItems: componentProps.alignItems || 'stretch',
                                    flexWrap: componentProps.wrap || 'nowrap',
                                    color: componentProps.color,
                                }}
                                className={componentProps.className}
                            >
                                {children && children.map((child: any) => renderComponent(child))}
                            </div>
                        )
                    }
                    </React.Fragment>

                );
            case 'image':
                return (
                    <Image
                        key={component.id}
                        style={{
                            width: `${componentProps.width?.value}${componentProps.width?.unit}`,
                            height: `${componentProps.height?.value}${componentProps.height?.unit}`,
                            marginTop: `${componentProps.marginTop?.value}${componentProps.marginTop?.unit}`,
                            marginBottom: `${componentProps.marginBottom?.value}${componentProps.marginBottom?.unit}`,
                            marginLeft: `${componentProps.marginLeft?.value}${componentProps.marginLeft?.unit}`,
                            marginRight: `${componentProps.marginRight?.value}${componentProps.marginRight?.unit}`,
                        }}
                        src={componentProps?.src}
                    />
                );


            case 'video':

                return (
                    <Video
                        key={component.id}
                        style={{
                            width: `${componentProps.width?.value}${componentProps.width?.unit}`,
                            height: `${componentProps.height?.value}${componentProps.height?.unit}`,
                            marginTop: `${componentProps.marginTop?.value}${componentProps.marginTop?.unit}`,
                            marginBottom: `${componentProps.marginBottom?.value}${componentProps.marginBottom?.unit}`,
                            marginLeft: `${componentProps.marginLeft?.value}${componentProps.marginLeft?.unit}`,
                            marginRight: `${componentProps.marginRight?.value}${componentProps.marginRight?.unit}`,
                        }}
                        src={src}
                    />
                );
            case 'lottie':
                return (
                    <LottieComponent
                        key={component.id}
                        style={{
                            width: `${componentProps.width?.value}${componentProps.width?.unit}`,
                            height: componentProps.height ? `${componentProps.height?.value}${componentProps.height?.unit}` : 'auto',
                            marginTop: `${componentProps.marginTop?.value}${componentProps.marginTop?.unit}`,
                            marginBottom: `${componentProps.marginBottom?.value}${componentProps.marginBottom?.unit}`,
                            marginLeft: `${componentProps.marginLeft?.value}${componentProps.marginLeft?.unit}`,
                            marginRight: `${componentProps.marginRight?.value}${componentProps.marginRight?.unit}`,
                        }}
                        loop={componentProps.loop}
                        animationData={componentProps.animationData}
                    />
                );
            default:
                return null;
        }
    };

    return (
        <div>
            {renderComponent(structure)}
        </div>
    );
};

export default ContentViewer;
