import React, { useEffect } from 'react';
import './DevicePreview.scss';
import SelectableContentViewer from 'components/SelectableContentViewer/SelectableContentViewer';

const DevicePreview = ({ mode, structure, selectable, onSelectItem, onChange }: any) => {
    let screenWidth = 1200; // Default to desktop
    useEffect(() => {
        console.log('structure', structure);
    }, [structure])
    switch (mode) {
        case 'tablet':
            screenWidth = 992;
            break;
        case 'mobile':
            screenWidth = 375;
            break;
        default:
            screenWidth = 1200;
            break;
    }

    return (
        <div className={`device-preview-container ${mode}`}>
            <div>
                <div className="screen">
                    <div className="power-button"></div>
                    {mode === 'mobile' && <div className="speaker-line"></div>}

                    <SelectableContentViewer
                        structure={structure}
                        //selectable={selectable}
                        onSelectItem={onSelectItem}
                        screenWidth={screenWidth}
                        onChange={onChange}
                    />
                </div>
                {mode === 'desktop' && (
                    <div className="pc-base">
                        <span></span>
                        <span></span>
                    </div>
                )}
            </div>
        </div>
    );
};

export default DevicePreview;
