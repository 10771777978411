import React from "react";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import colors from "config/colors";
import './ReadonlyChecklistField.scss';
const ReadonlyChecklistField = ({ data }: any) => {
  return <div className="readonly-checklist-field-container">
    {data?.map((item: any, index: number) => {
      return (
        <div key={index} className={item.checked ? "done" : ""}>
          {item.checked ? (
            <CheckBoxIcon style={{ color: colors.primary }} />
          ) : (
            <CheckBoxOutlineBlankIcon
              style={{ color: colors.textTint }}
            />
          )}
          <span>{item.text}</span>
        </div>
      )
    })}

  </div>;
};

export { ReadonlyChecklistField };
