import React, { useEffect, useRef, useState } from 'react';
import DynamicListItem from './components/DynamicListItem/DynamicListItem';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import { arrayMoveImmutable } from "array-move";
import AxiosInterceptor from "../../AxiosInterceptor";
import urls from 'config/urls';
import { LoadingButton } from '@mui/lab';
import { Spinner } from 'components/Spinner/Spinner';
import Icon from 'components/Icon/Icon';
import './DynamicList.scss';
import DeleteConfirmationDialog from 'components/dialogs/DeleteConfirmationDialog/DeleteConfirmationDialog';
import { useDebounce } from 'hooks/useDebounce';
import { formatFilters } from 'utils/formatFilters';
import interpolateString from 'utils/interpolateString';
import { ImportModal } from 'components/modals/ImportModal/ImportModal';
import { Toast } from 'components/Toast/Toast';
import ActionButtons from 'components/ActionButtons/ActionButtons';
// Definir las props de DynamicListItem
interface DynamicListItemProps {
    data: any;
    leftCells?: Array<any>;
    rightCells?: Array<any>;
    dragHandleEnabled: boolean;
    moreActions?: Array<any>;

}

// Definir las props para SortableItem (incluyendo todas las props de DynamicListItem)
interface SortableItemProps extends DynamicListItemProps {
    index: number; // index es requerido por SortableElement
}

// Crear el componente SortableItem
const SortableItem: any = SortableElement((props: SortableItemProps) => <DynamicListItem {...props} />);

// Crear el componente SortableDynamicList
const SortableDynamicList: any = SortableContainer(({ children }: { children: React.ReactNode }) => {
    return <div className='sortable-dynamic-list-container'>{children}</div>;
});

// Definir las props de DynamicList
interface DynamicListProps {
    className?: string;
    data?: any;
    listPath?: string;
    sortPath?: string;
    limit?: number;
    activeFilters?: any[];
    hiddenActiveFilters?: any[];
    searchTerm?: string;
    isSortable?: boolean;
    leftCells?: Array<any>;
    rightCells?: Array<any>;
    style?: any;
    itemStyle?: any;
    noContentText?: string;
    noContentIcon?: string;
    itemActions?: Array<any>;
    moreItemActions?: Array<any>;
    forceRefresh?: boolean;
    extraData?: any;
    listActions?: Array<any>;
    selectableItems?: boolean;
    onSelectedItemsChange?: (selectedItems: any[]) => void;
    onRefreshComplete?: () => void;
    onEditItem?: (item: any, index: number) => void;
    onChangeItem?: (val: any, item: any, index: number) => void;
    onDeleteItem?: (item: any, index: number) => void;
    onDeleteItemSuccess?: (response: any) => void;
    onSortEnd?: (items: Array<any>) => void;
    onSelectItemAction?: (response: any) => void;
}


const DynamicList: React.FC<DynamicListProps> = ({
    className = '',
    data,
    listPath,
    sortPath,
    limit = 10,
    searchTerm = '',
    isSortable = false,
    activeFilters = [],
    hiddenActiveFilters = [],
    leftCells,
    rightCells,
    moreItemActions = [],
    itemActions = [],
    itemStyle,
    style = {},
    noContentText = 'No hay registros cargados',
    noContentIcon = 'records',
    forceRefresh = false,
    listActions = [],
    extraData = {},
    selectableItems = false,
    onSelectedItemsChange,
    onRefreshComplete,
    onEditItem,
    onChangeItem,
    onDeleteItem,
    onSortEnd,
    onSelectItemAction,
    onDeleteItemSuccess
}) => {
    const [orderBy, setOrderBy] = useState(isSortable ? 'order' : "");
    const [orderDirection, setOrderDirection] = useState("asc");
    const [offset, setOffset] = useState(0);
    const [processing, setProcessing] = useState(false);
    const [loading, setLoading] = useState(true);
    const [loadingMore, setLoadingMore] = useState(false);
    const [records, setRecords] = useState(data || [] as any);
    const [hasMore, setHasMore] = useState(true);
    const [currentItem, setCurrentItem] = useState(null as any);
    const [currentAction, setCurrentAction] = useState(null as any);
    const [deleteConfirmationDialogIsOpen, setDeleteConfirmationDialogIsOpen] = useState(false);
    const [importModalIsOpen, setImportModalIsOpen] = useState(false);
    const [interpolatedListPath, setInterpolatedListPath] = useState(null as any);
    const [interpolatedDeletePath, setInterpolatedDeletePath] = useState(null as any);
    const [interpolatedDeleteConfirmationMessage, setInterpolatedDeleteConfirmationMessage] = useState(null as any);
    const [toastIsOpen, setToastIsOpen] = useState(false);
    const [toastMessage, setToastMessage] = useState("");
    const [toastSeverity, setToastSeverity] = useState("error");
    const [selectedItems, setSelectedItems] = useState<any[]>([]);
    const debouncedValue = useDebounce<string>(searchTerm, 500);
    const prevActiveFiltersRef = useRef(activeFilters);
    const prevHiddenActiveFiltersRef = useRef(hiddenActiveFilters);
    const prevOrderByRef = useRef(orderBy);
    const prevOrderDirectionRef = useRef(orderDirection);
    const prevDebouncedValueRef = useRef(debouncedValue);

    const handleItemSelect = (item: any, isSelected: boolean) => {
        setSelectedItems((prevSelectedItems) => {
            const newSelectedItems = isSelected
                ? [...prevSelectedItems, item]
                : prevSelectedItems.filter(selectedItem => selectedItem.id !== item.id);

            if (onSelectedItemsChange) {
                onSelectedItemsChange(newSelectedItems);
            }
            return newSelectedItems;
        });
    };

    const loadRecords = async (reset: boolean = false) => {
        if (reset) {
            setLoading(true);
            setOffset(0);
        } else {
            setLoadingMore(true);
        }

        const combinedFilters = [...activeFilters, ...hiddenActiveFilters];

        try {
            const hasQueryParams = listPath?.includes('?');
            const prefix = hasQueryParams ? '&' : '?';
            let url = `${urls.server
                }/api/${listPath}${prefix}offset=${reset ? 0 : offset}&limit=${limit}&order_by=${orderBy}&order_direction=${orderDirection}&search_term=${searchTerm}&filters=${encodeURIComponent(
                    JSON.stringify(combinedFilters)
                )}`
            const response = await AxiosInterceptor.get(
                url
            );
            const data = response.data;
            if (reset) {
                setRecords(data);
                setOffset(data.length);
                //setOffset(limit);
                //if (onRefreshComplete) onRefreshComplete()
            } else {
                setRecords((prevRecords: any) => [...prevRecords, ...data]);

                setOffset((prevOffset: number) => {
                    if (data.length == 0) return prevOffset;
                    return prevOffset + limit
                });
            }

        } catch (error) {
            console.error("Error fetching files", error);
        } finally {
            setLoading(false);
            setLoadingMore(false);
            if (onRefreshComplete) onRefreshComplete()
        }
    };


    useEffect(() => {
        const prevActiveFilters = prevActiveFiltersRef.current;
        const prevHiddenActiveFilters = prevHiddenActiveFiltersRef.current;
        const prevOrderBy = prevOrderByRef.current;
        const prevOrderDirection = prevOrderDirectionRef.current;
        const prevDebouncedValue = prevDebouncedValueRef.current;

        if (
            JSON.stringify(prevActiveFilters) !== JSON.stringify(activeFilters) ||
            JSON.stringify(prevHiddenActiveFilters) !== JSON.stringify(hiddenActiveFilters) ||
            prevOrderBy !== orderBy ||
            prevOrderDirection !== orderDirection ||
            prevDebouncedValue !== debouncedValue
        ) {
            prevActiveFiltersRef.current = activeFilters;
            prevHiddenActiveFiltersRef.current = hiddenActiveFilters;
            prevOrderByRef.current = orderBy;
            prevOrderDirectionRef.current = orderDirection;
            prevDebouncedValueRef.current = debouncedValue;

            const delayDebounceFn = setTimeout(() => {
                loadRecords(true);
            }, 500);

            return () => clearTimeout(delayDebounceFn);
        }
    }, [
        debouncedValue,
        activeFilters,
        hiddenActiveFilters,
        orderBy,
        orderDirection,
    ]);
    useEffect(() => {
        console.log("forceRefresh", forceRefresh)
        if (forceRefresh) {
            loadRecords(true);
        }
    }, [forceRefresh]);

    const sortRecords = async (sortedData: any) => {
        setLoading(true);
        try {
            const res = await AxiosInterceptor.post(
                `${urls.api}/${sortPath}`,
                {
                    items: sortedData
                }
            );
            const resData = await res?.data;
            setRecords(resData?.items);
            setLoading(false);
        } catch (error) {
            setLoading(false);
        }

    };
    const handleSortEnd = ({ oldIndex, newIndex }: { oldIndex: number; newIndex: number }) => {

        let sortedData = arrayMoveImmutable(records, oldIndex, newIndex);
        if (onSortEnd) onSortEnd(sortedData)
        if (sortPath) {
            sortRecords(sortedData);
        }
    };
    const exportRecord = async (exportPath: string) => {
        setProcessing(true);
        try {
            const res = await AxiosInterceptor.get(
                `${urls.server}/api/${exportPath}`
            );

            // Crear un Blob con el contenido JSON
            const blob = new Blob([JSON.stringify(res?.data)], {
                type: "application/json",
            });

            // Crear una URL Blob para el Blob
            const url = URL.createObjectURL(blob);

            // Crear un enlace para descargar
            const link = document.createElement("a");
            link.href = url;
            link.download = `${res?.data?.file_name || 'recod'}.json`;
            link.click();

            // Liberar la URL Blob
            URL.revokeObjectURL(url);
        } catch (error) {

        }
    };
    const executeCustomAction = async (sendPath: string) => {
        if (sendPath) {
            setProcessing(true);
            try {
                const res = await AxiosInterceptor.put(
                    `${urls.server}/api/${sendPath}`
                );
                setToastMessage("Acción ejecutada con éxito");
                setToastIsOpen(true);
                setToastSeverity('success');
                console.log('algo')
            } catch (error) {
                setToastMessage("Error al ejecutar la acción");
                setToastIsOpen(true);
                setToastSeverity('error');
            }
        }

    }
    const executeListAction = async (sendPath: string) => {
        setProcessing(true);
        try {
            const res = await AxiosInterceptor.put(
                `${urls.server}/api/${sendPath}`
            );
            setToastMessage("Acción ejecutada con éxito");
            setToastIsOpen(true);
            setToastSeverity('success');
            console.log('algo')
        } catch (error) {
            setToastMessage("Error al ejecutar la acción");
            setToastIsOpen(true);
            setToastSeverity('error');
        }
    }
    const handleSelectItemAction = (actionData: any, itemData: any, itemIndex: number) => {
        setCurrentAction(actionData);
        setCurrentItem(actionData?.currentItem);
        if (onSelectItemAction) {
            onSelectItemAction({
                actionName: actionData?.actionName,
                actionVisibleName: actionData?.actionVisibleName,
                currentItem: actionData?.currentItem,
                config: actionData?.config
            });
        }
        if (actionData?.actionName == 'delete') {
            if (onDeleteItem) onDeleteItem(itemData, itemIndex);
            if (actionData?.config?.deleteConfirmationMessage) {
                setInterpolatedDeleteConfirmationMessage(interpolateString(actionData?.currentItem, actionData?.config?.deleteConfirmationMessage));
            }
            if (actionData?.config?.deletePath) {

                setInterpolatedDeletePath(interpolateString(actionData?.currentItem, actionData?.config?.deletePath))
                setDeleteConfirmationDialogIsOpen(true);
            }
        } else if (actionData?.actionName == 'edit') {
            if (onEditItem) onEditItem(itemData, itemIndex);
        } else if (actionData?.actionName == 'importRecords') {
            setInterpolatedListPath(interpolateString(actionData?.currentItem, actionData?.config?.importPath))
            setImportModalIsOpen(true);
        } else if (actionData?.actionName == 'exportSingle') {
            exportRecord(interpolateString(actionData?.currentItem, actionData?.config?.exportPath));
        } else if (actionData?.config?.type == 'customAction') {
            executeCustomAction(interpolateString(actionData?.currentItem, actionData?.config?.sendPath));
        }
    }
    useEffect(() => {
        if (listPath == null) setLoading(false);
    }, [])
    useEffect(() => {
        if (data) {
            setRecords(data);
            setHasMore(false);
        }

    }, [data]);
    return (
        <div className={`dynamic-list-container ${className}`} style={style}>
            {loading && (
                <div className="spinner-wrapper">
                    <Spinner visible={loading} />
                </div>
            )}
            {!loading && records?.length == 0 && (
                <div className="no-content">
                    <Icon name={noContentIcon} />
                    <p>{noContentText}</p>
                </div>
            )}
            {!loading &&
                <div className='list-actions-wrapper'>
                    <ActionButtons actions={listActions} onSelectAction={(action: any) => {

                        executeListAction(interpolateString(extraData, action?.sendPath));
                    }} />
                </div>
            }
            {!loading && records?.length > 0 && (
                <>

                    <div className="list-wrapper">
                        <div>
                            <SortableDynamicList onSortEnd={handleSortEnd} useDragHandle>
                                {records.map((item: any, index: number) => {
                                    return (
                                        <SortableItem
                                            key={`item-${index}`}
                                            index={index}
                                            disabled={!isSortable}
                                            data={item}
                                            leftCells={leftCells}
                                            rightCells={rightCells}
                                            moreActions={moreItemActions}
                                            actions={itemActions}
                                            dragHandleEnabled={isSortable && !searchTerm}
                                            style={itemStyle}
                                            selectable={selectableItems}
                                            isSelected={selectedItems.some(selectedItem => selectedItem.id === item.id)}
                                            onSelectItem={(isSelected: boolean) => handleItemSelect(item, isSelected)}
                                            onSelectMoreAction={(res: any) => {
                                                handleSelectItemAction(res, item, index);
                                            }}
                                            onSelectAction={(res: any) => {
                                                handleSelectItemAction(res, item, index);
                                            }}
                                            onChange={(val: any) => {
                                                if (onChangeItem) onChangeItem(val, item, index);

                                            }}
                                        />
                                    );
                                })}
                            </SortableDynamicList>

                            {limit && hasMore && (
                                <div className="loading-button-wrapper">
                                    <LoadingButton
                                        onClick={() => loadRecords(false)}
                                        loading={loadingMore}
                                        loadingPosition="start"
                                        startIcon={<Icon name="plus" />}
                                    >
                                        Más registros
                                    </LoadingButton>
                                </div>
                            )}
                        </div>

                    </div>
                </>

            )
            }
            <DeleteConfirmationDialog
                isOpen={deleteConfirmationDialogIsOpen}
                onClose={() => setDeleteConfirmationDialogIsOpen(false)}
                onDeleteSuccess={() => {
                    if (onDeleteItemSuccess) onDeleteItemSuccess(currentItem);
                    //setOffset(0);
                    loadRecords(true);
                    //setHasMore(true);
                }}
                data={currentItem}
                deletePath={interpolatedDeletePath}
                confirmationMessage={interpolatedDeleteConfirmationMessage}
            />
            <ImportModal
                isOpen={importModalIsOpen}
                //subtitle={importerSubtitle || ''}
                subtitle={'Subir registros'}
                importPath={interpolatedListPath}
                importType={'multipleRecords'}
                onClose={(res: any) => {
                    setImportModalIsOpen(false)
                    if (res) {

                    }
                }}
            />
            <Toast
                isOpen={toastIsOpen}
                onClose={() => setToastIsOpen(false)}
                message={toastMessage}
                severity={toastSeverity}
            />
        </div>
    );
};

export default DynamicList;
