import React, { useEffect, useState } from "react";
import "./WorkflowEntryStepper.scss";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import colors from "config/colors";
import Icon from "components/Icon/Icon";

const WorkflowEntryStepper = ({ steps, onStepChange, goToStep }: any) => {
  const [value, setValue] = React.useState(goToStep || 0);
  //const [selectedTabIndex, setSelectedTabIndex] = React.useState(0);
  const handleTabChange = (event: any, newValue: any) => {
    //setSelectedTabIndex(newValue);
    setValue(newValue);
  };
  useEffect(() => {
    if (onStepChange) {
      onStepChange(value);
    }
  }, [value]);
  return (
    <div className="workflow-entry-stepper-container">
      <Box
        className="step-tabs"
        sx={{
          width: "100%",
          maxWidth: { xs: 300, sm: 900 },
          margin: "0 auto",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Tabs
          component="a"
          value={value}
          onChange={handleTabChange}
          variant="scrollable"
          scrollButtons="auto"
          aria-label="scrollable auto tabs"
        //centered
        >
          {steps.map((item: any, index: number) => {
            let statusIcon;
            let statusText;
            switch (item.status) {
              case "pending":
                statusIcon = null;
                statusText = "Pendiente";
                break;
              case "incomplete":
                statusIcon = null;
                statusText = "Incompleto";
                break;
              case "draft":
                statusIcon = (
                  <Icon name="saveAsDraft" style={{ color: colors.medium }} />
                );
                statusText = "Borrador";
                break;
              case "completed":
                statusIcon = <Icon name="check" style={{ color: colors.medium }} />;
                statusText = "Completado";
                break;
              case "done":
                statusIcon = <Icon name="check" style={{ color: colors.medium }} />;
                statusText = "Realizado";
                break;
              case "rejected":
              case "expired":
                statusIcon = <Icon name="alert" style={{ color: colors.danger }} />;
                statusText =
                  item.status === "rejected" ? "Rechazado" : "Vencido";
                break;
              case "partialApproval":
                statusIcon = (
                  <Icon name="saveAsDraft" style={{ color: colors.success }} />
                );
                statusText = "Aprobación Parcial";
                break;
              case "approved":
                statusIcon = <Icon name="check" style={{ color: colors.success }} />;
                statusText = "Aprobado";
                break;
              case "actions_executed":
                statusIcon = <Icon name="check" style={{ color: colors.success }} />;
                statusText = "Acción/s ejecutadas";
                break;
              // case "observed":
              //   statusIcon = <AlertCircleIcon style={{ color: "red" }} />;
              //   statusText = "Observed";
              //   break;
              default:
                break;
            }
            return (
              <Tab
                key={index}
                label={
                  <div className="step">
                    <div>
                      <span
                      // style={{
                      //   backgroundColor:
                      //     item.status == "pending" || !item.status
                      //       ? "transparent"
                      //       : statusColor,
                      // }}
                      >
                        {statusIcon}
                      </span>
                    </div>
                    <span>
                      {item.visible_name}
                      <span>{statusText}</span>
                    </span>
                  </div>
                }
              />
            );
          })}
        </Tabs>
      </Box>
    </div>
  );
};

export { WorkflowEntryStepper };
