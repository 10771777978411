import React, { Fragment, useEffect, useState } from "react";
import { Drawer, Fab, IconButton } from "@mui/material";
import "./FiltersDrawer.scss";
import { TextFilter } from "components/filters/TextFilter/TextFilter";
import { DateFilter } from "components/filters/DateFilter/DateFilter";
import { TagsFilter } from "components/filters/TagsFilter/TagsFilter";
import { NumberFilter } from "components/filters/NumberFilter/NumberFilter";
import { BooleanFilter } from "components/filters/BooleanFilter/BooleanFilter";
import { MultiCheckboxFilter } from "components/filters/MultiCheckboxFilter/MultiCheckboxFilter";
import { MultiSelectFilter } from "components/filters/MultiSelectFilter/MultiSelectFilter";
import Icon from "components/Icon/Icon";

const FiltersDrawer = ({ isOpen, onClose, filters = [] }: any) => {
  const [localFilters, setLocalFilters] = useState([] as any);

  const handleFilterChange = (index: number, updatedFilter: any) => {
    const newFilters = [...localFilters];
    newFilters[index] = { ...newFilters[index], ...updatedFilter };
    setLocalFilters(newFilters);
    console.log(newFilters)
  };

  const handleFabClick = () => {
    onClose(localFilters);
  };
  useEffect(() => {
    setLocalFilters(filters);
  }, [filters])
  return (
    <Drawer
      anchor={"right"}
      open={isOpen}
      onClose={() => {
        onClose();
      }}
      style={{ zIndex: 99999999 }}
      className="filters-drawer-container"
      PaperProps={{
        sx: { width: "100%", maxWidth: "300px" },
      }}
    >
      <div className="drawer-header">
        <div>
          <h3>Filtros</h3>
        </div>

        <IconButton
          onClick={() => {
            onClose();
          }}
        >
          <Icon name="close" />
        </IconButton>
      </div>
      <div className="drawer-body">
        {localFilters.map((item: any, index: number) => {
          return (
            <Fragment key={index}>
              {(() => {
                switch (item.type) {
                  case "date":
                    return (
                      <DateFilter
                        initialValue={item.value}
                        initialOperator={item.operator}
                        label={item.visibleName}
                        availableOperators={item.availableOperators}
                        onChange={(updatedFilter: any) => handleFilterChange(index, updatedFilter)}
                      />
                    );
                  case "text":
                    return (
                      <TextFilter
                        initialValue={item.value}
                        initialOperator={item.operator}
                        label={item.visibleName}
                        availableOperators={item.availableOperators}
                        onChange={(updatedFilter: any) => handleFilterChange(index, updatedFilter)}
                      />
                    );
                  case "tags":
                    return (
                      <TagsFilter
                        initialValue={item.value}
                        label={item.visibleName}
                        groupName={item.groupName}
                        onChange={(updatedFilter: any) => handleFilterChange(index, updatedFilter)}
                      />
                    );
                  case "number":
                    return (
                      <NumberFilter
                        initialValue={item.value}
                        initialOperator={item.operator}
                        label={item.visibleName}
                        availableOperators={item.availableOperators}
                        onChange={(updatedFilter: any) => handleFilterChange(index, updatedFilter)}
                      />
                    );
                  case "multiCheckbox":
                    return (
                      <MultiCheckboxFilter
                        initialValue={item.value}
                        label={item.visibleName}
                        options={item.options}
                        onChange={(updatedFilter: any) => handleFilterChange(index, updatedFilter)}
                      />
                    );
                  case "multiSelect":
                    return (
                      <MultiSelectFilter
                        initialValue={item.value}
                        label={item.visibleName}
                        searchPath={item.searchPath}
                        primaryKey={item.primaryKey}
                        onChange={(updatedFilter: any) => handleFilterChange(index, updatedFilter)}
                      />
                    );
                  case "boolean":
                    return (
                      <BooleanFilter
                        initialValue={item.value}
                        initialOperator={item.operator}
                        label={item.visibleName}
                        availableOperators={item.availableOperators}
                        onChange={(updatedFilter: any) => handleFilterChange(index, updatedFilter)}
                      />
                    );
                  default:
                    return null;
                }
              })()}
            </Fragment>
          );
        })}

        <Fab
          className="fab-btn"
          color="primary"
          aria-label="send filters"
          onClick={handleFabClick}
        >
          <Icon name="check" />
        </Fab>
      </div>
    </Drawer>
  );
};

export { FiltersDrawer };
