import Icon from 'components/Icon/Icon';
import React, { useState } from 'react';
import './CollapsedHeader.scss';

interface CollapsedHeaderProps {
    style: React.CSSProperties;
    hamburgerButtonStyle: React.CSSProperties;
    drawerStyle: React.CSSProperties;
    openIcon: string;
    closeIcon: string;
    screenHeight: number;
    children?: React.ReactNode;

}
const CollapsedHeader: React.FC<CollapsedHeaderProps> = ({
    style,
    hamburgerButtonStyle,
    drawerStyle,
    openIcon,
    closeIcon,
    screenHeight,
    children

}) => {
    const [drawerIsVisible, setDrawerIsVisible] = useState(false);
    return (
        <div style={style} className='collapsed-header-container'>
            <a style={hamburgerButtonStyle} onClick={() => {
                setDrawerIsVisible(!drawerIsVisible);
            }}>
                {
                    drawerIsVisible ? <Icon name={closeIcon} /> : <Icon name={openIcon} />
                }


            </a>
            <div style={{ ...drawerStyle, height: screenHeight }} className={`drawer ${drawerIsVisible ? "visible" : "hidden"}`}>
                {
                    children
                }
            </div>
        </div>
    )
}

export default CollapsedHeader