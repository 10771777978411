import React, { useState, useEffect } from "react";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { IconButton } from "@mui/material";
import { styled } from "@mui/material/styles";
import "./BreakdownChart.scss";
import { formatNumber } from "utils/formatNumber";
const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} placement="top" />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#f2f2f4",
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    //maxHeight: "100px",
  },
}));
const BreakdownChart = ({ data = [], height = 200, decimalPrecision = 2, measurementUnit = 'unid' }: any) => {
  const [chartData, setChartData] = useState(null as any);
  const [currentIndex, setCurrentIndex] = useState(data.length - 1);
  const [legendItems, setLegendItems] = useState([] as any);
  const [totalValue, setTotalValue] = useState(0 as any);
  const [filteredItems, setFilteredItems] = useState([]);


  const goToPrevious = () => {
    setCurrentIndex((prevIndex: number) =>
      prevIndex > 0 ? prevIndex - 1 : prevIndex
    );
  };

  // Función para ir al próximo período
  const goToNext = () => {
    setCurrentIndex((prevIndex: number) =>
      prevIndex < data.length - 1 ? prevIndex + 1 : prevIndex
    );
  };

  useEffect(() => {
    let sortedData = [...data[currentIndex].data].sort(
      (a: any, b: any) => b.value - a.value
    );
    setChartData(sortedData);
  }, [currentIndex]);

  function handleLegendItemClick(index: number) {
    setLegendItems((prev: any) => {
      const prevCopy: any = [...prev];
      prevCopy[index].selected = !prevCopy[index].selected;
      return prevCopy;
    });
  }
  useEffect(() => {
    if (chartData && chartData.length > 0) {
      setLegendItems(() => getLegendItems());

    }
  }, [chartData]);

  function getLegendItems() {
    const legendItems: any = [];
    chartData.forEach((item: any) => {
      legendItems.push({
        label: item.label,
        color: item.color || "#ccc",
        selected: true,

      });
    })
    return legendItems;
  }
  useEffect(() => {
    if (chartData && legendItems && legendItems.length > 0) {
      const newFilteredItems = chartData.filter((item: any) => isSelected(item.label, legendItems))
        .map((item: any) => {
          return {
            ...item,
          };
        })
      setTotalValue(0);
      setFilteredItems([]);

      setTimeout(() => {
        setFilteredItems(newFilteredItems);
        const totalValue = newFilteredItems.reduce(
          (total: number, item: any) => total + item.value,
          0
        );
        setTotalValue(totalValue);
      }, 100)

    }
  }, [legendItems]);
  function isSelected(label: string, legendItems: any) {
    const item = legendItems.find((item: any) => item.label === label);
    return item ? item.selected : false;
  }
  return (
    <div className="breakdown-chart-container">
      <div className="current-period">
        <IconButton
          aria-label="Previous Period"
          onClick={goToPrevious}
          disabled={currentIndex === 0}
        >
          <KeyboardArrowLeftIcon />
        </IconButton>
        <strong>{data[currentIndex]?.visible_period}</strong>
        <IconButton
          aria-label="Next Period"
          onClick={goToNext}
          disabled={currentIndex === data.length - 1}
        >
          <KeyboardArrowRightIcon />
        </IconButton>
      </div>
      <div className="graph-wrapper">
        <div className="graph" style={{ height: height + "px" }}>
          {filteredItems && <>
            {filteredItems.map((item: any, index: number) => {
              // Calcula el ancho en porcentaje para cada ítem
              const widthPercentage = (item.value / totalValue) * 100;

              return (
                <HtmlTooltip
                  key={index}
                  title={
                    <React.Fragment>
                      <div className="breakdown-item-tooltip">
                        <strong>{data[currentIndex]?.visible_period}</strong>
                        <span style={{
                          color: item.color
                        }}>{item.label}</span>
                        <div>
                          <strong style={{
                            color: item.color
                          }}>{formatNumber(item.value, decimalPrecision)}</strong>
                          <span>({measurementUnit})</span>
                        </div>
                      </div>
                    </React.Fragment>
                  }
                >
                  <span

                    style={{
                      width: `${widthPercentage}%`,
                      backgroundColor: item.color,

                    }}

                  ></span>
                </HtmlTooltip>

              );
            })}
          </>}
        </div>
      </div>

      <div className="legend">
        {legendItems.map((item: any, index: number) => {
          return (
            <a
              className="legend-item"
              key={index}
              onClick={() => {

                handleLegendItemClick(index);
              }}
            >
              <span
                className="legend-color"
                style={{
                  backgroundColor: item.selected ? item.color : "#ccc",
                }}
              ></span>
              <span className="legend-label">{item.label}</span>
            </a>
          );
        })}
      </div>
    </div>
  );
};

export { BreakdownChart };
