import { useState, useEffect, Fragment } from "react";
import MUIAutocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import AxiosInterceptor from "../../AxiosInterceptor";
import urls from "config/urls";
import CircularProgress from "@mui/material/CircularProgress";
import "./Autocomplete.scss";
const Autocomplete = ({
  onSelect,
  tableName,
  placeholder = "Buscar...",
  primaryKey,
  secondaryKey,
  thumbnailKey,
  size,
  filters = [],
  searchPath,
}: any) => {
  const [value, setValue] = useState(null);
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState<readonly []>([]);
  const [loading, setLoading] = useState(false);
  const [noOptionsText, setNoOptionsText] = useState("Buscar...");

  const handleChange = async (event: any) => {
    if (event.target.value != "") {
      setNoOptionsText("Sin resultado");
    } else {
      setNoOptionsText("Buscar...");
    }
    setLoading(true);
    let url;
    if (searchPath) {
      url = `${urls.server}/api/${searchPath}?search_term=${event.target.value
        }&filters=${encodeURIComponent(JSON.stringify(filters))}`;
    } else {
      tableName = tableName.replace(/_/g, "-");
      url = `${urls.server}/api/${tableName}?search_term=${event.target.value
        }&filters=${encodeURIComponent(JSON.stringify(filters))}`;
    }

    const res = await AxiosInterceptor.get(`${url}`);
    const data = await res?.data;
    setOptions(data);
    setLoading(false);
  };
  useEffect(() => {
    onSelect(value);
    if (value) {
      setTimeout(() => {
        setValue(null);
      }, 1000);
    }
  }, [value]);

  return (
    <div className="autocomplete-container">
      <MUIAutocomplete
        noOptionsText={noOptionsText}
        loadingText="Buscando..."
        //sx={{ width: 300 }}
        open={open}
        onOpen={() => {
          setOpen(true);
        }}
        onClose={() => {
          setOpen(false);
        }}
        isOptionEqualToValue={(option: any, value) =>
          option[primaryKey] === value[primaryKey]
        }
        getOptionLabel={(option) => option[primaryKey]}
        options={options || []}
        renderOption={(props, option) => (
          <Box component="li" {...props}>
            <div className="autocomplete-item">
              {thumbnailKey && (
                <img src={urls.server + option[thumbnailKey]} alt="" />
              )}

              <div>
                <strong>{option[primaryKey]}</strong>
                {secondaryKey && <span>{option[secondaryKey] || "-"}</span>}
              </div>
            </div>
          </Box>
        )}
        loading={loading}
        value={value}
        onChange={(event: any, newValue: any | null) => {
          setValue(newValue);
        }}
        onSelect={() => { }}
        renderInput={(params) => (
          <TextField
            hiddenLabel
            onChange={handleChange}
            onFocus={handleChange}
            placeholder={placeholder}
            {...params}
            size={size ? size : "small"}
            //label={label}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <Fragment>
                  {loading ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </Fragment>
              ),
            }}
          />
        )}
      />
    </div>
  );
};

export { Autocomplete };
