import React, { useState, useEffect } from "react";
import "./BooleanFilter.scss";
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import { Button } from "@mui/material";

const BooleanFilter = ({ label, initialValue, onChange }: any) => {
  const [value, setValue] = useState(initialValue !== undefined ? initialValue : null);

  useEffect(() => {
    const isActive = value !== null;

    if (onChange) {
      onChange({
        operator: "equal", // Usamos "equal" como operador por defecto para booleanos
        value: value,
        active: isActive
      });
    }
  }, [value]);

  const handleValueChange = (newValue: boolean) => {
    setValue((prevValue: any) => (prevValue === newValue ? null : newValue));
  };

  return (
    <div className="boolean-filter-container">
      <label className="filter-label">{label}</label>
      <div className="button-wrapper">
        <Button
          variant="text"
          color={value === true ? "primary" : "inherit"}
          onClick={() => handleValueChange(true)}
          startIcon={value === true ? <RadioButtonCheckedIcon /> : <RadioButtonUncheckedIcon />}
        >
          Si
        </Button>
        <Button
          variant="text"
          color={value === false ? "primary" : "inherit"}
          onClick={() => handleValueChange(false)}
          startIcon={value === false ? <RadioButtonCheckedIcon /> : <RadioButtonUncheckedIcon />}
        >
          No
        </Button>
      </div>
    </div>
  );
};

export { BooleanFilter };
