import { DateCell } from "components/cells/DateCell/DateCell";
import IconCell from "components/cells/IconCell/IconCell";
import InterpolatedContentCell from "components/cells/InterpolatedContentCell/InterpolatedContentCell";
import { MoneyCell } from "components/cells/MoneyCell/MoneyCell";
import { RadioCell } from "components/cells/RadioCell/RadioCell";
import { StatusCell } from "components/cells/StatusCell/StatusCell";
import { StepperCell } from "components/cells/StepperCell/StepperCell";
import { TagsCell } from "components/cells/TagsCell/TagsCell";
import { ThumbnailCell } from "components/cells/ThumbnailCell/ThumbnailCell";
import { UsersCell } from "components/cells/UsersCell/UsersCell";
import * as ev from "expr-eval";
import './DynamicCell.scss';
import { LinkedRecordsCell } from "components/cells/LinkedRecordsCell/LinkedRecordsCell";
import { PlayPauseCell } from "components/cells/PlayPauseCell/PlayPauseCell";
import interpolateString from "utils/interpolateString";
import Icon from "components/Icon/Icon";
import ChildRecordsCell from "components/cells/ChildRecordsCell/ChildRecordsCell";
import { LockToggleCell } from "components/cells/LockToggleCell/LockToggleCell";
import { ColorCell } from "components/cells/ColorCell/ColorCell";

const DynamicCell = (props: any) => {
    const {
        config,
        data,
        extraData,
        onChange,
    } = props;
    const evaluateReadonly = (config: {
        readonly?: boolean,
        readonlyIf?: string
    }) => {
        // Si .readonly está definido, se retorna su valor directamente.
        if (typeof config.readonly !== 'undefined') {
            return config.readonly;
        }

        try {
            return config.readonlyIf ? ev.Parser.evaluate(config.readonlyIf, extraData) : false;
        } catch (error) {
            console.error("Error evaluating expression: ", error);
            return false; // Retorna false en caso de error
        }
    };

    return (
        <div className="dynamic-cell-container">
            {(() => {

                switch (config?.type) {
                    case "checkbox":
                        return <span>{data ? "Si" : "No"}</span>;
                    case "visibleId":
                        return <span>{data || extraData.visible_id}</span>;
                    // case "text":
                    //     return (
                    //         <span>
                    //             <TextCell
                    //                 data={data}
                    //                 startIcon={config?.startIcon}
                    //                 prepend={config?.prepend}
                    //                 append={config?.append}
                    //                 endIcon={config?.endIcon}
                    //             />
                    //         </span>
                    //     );
                    case "date":
                        return (
                            <span>
                                {config?.name} aa
                                <DateCell
                                    data={data}
                                    extraData={extraData}
                                    //config={config}
                                    showDiff={config?.showDiff}
                                    name={config?.name}
                                />
                            </span>
                        );
                    case "interpolatedContent":
                        return (
                            <span>
                                <InterpolatedContentCell
                                    data={extraData}
                                    htmlContent={config?.htmlContent}
                                    jsonStructure={config?.jsonStructure}
                                //template={config?.template}
                                />
                            </span>
                        );
                    case "childRecords":
                        return (
                            <span>
                                <ChildRecordsCell
                                    childRecords={data}
                                    title={interpolateString(extraData, config?.title)}
                                    subtitle={interpolateString(extraData, config?.subtitle)}
                                    listPath={interpolateString(extraData, config?.listPath)}
                                    areChildrenSortable={config?.areChildrenSortable}
                                    //cells={config?.cells}
                                    rightCells={config?.rightCells}
                                    leftCells={config?.leftCells}
                                    listActions={config?.listActions}
                                    headerActions={config?.headerActions}
                                    itemActions={config?.itemActions}
                                    fields={config?.fields}
                                    sortPath={interpolateString(extraData, config?.sortPath)}
                                    deletePath={config?.deletePath}
                                    deleteConfirmationMessage={config?.deleteConfirmationMessage}
                                    noContentText={config?.noContentText}
                                    noContentIcon={config?.noContentIcon}
                                    createFormTitle={config?.createFormTitle}
                                    editFormTitle={config?.editFormTitle}
                                    createPath={interpolateString(extraData, config?.createPath)}
                                    editPath={config?.editPath}
                                    extraData={extraData}
                                    onChange={() => {
                                        onChange();
                                    }}
                                />
                            </span>
                        );
                    case "tags":
                        return (
                            <span>
                                <TagsCell data={data} />
                            </span>
                        );
                    case "thumbnail":
                        return (
                            <span>
                                <ThumbnailCell data={data} />
                            </span>
                        );
                    case "money":
                        return (
                            <span>
                                <MoneyCell data={data} visibleName={config?.visibleName} />
                            </span>
                        );
                    case "text":
                    case "integer":
                    case "decimal":
                        return (
                            <div style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}>
                                <span
                                    style={{
                                        textAlign: config?.type === "text" ? "left" : "right",
                                        lineHeight: 1
                                    }}
                                >
                                    {config?.startIcon && <Icon name={config?.startIcon} />}
                                    {config?.prepend && <strong> {config?.prepend} </strong>}
                                    {extraData[config?.name] || "-"}
                                    {config?.append && <strong> {config?.append} </strong>}
                                    {config?.endIcon && <Icon name={config?.endIcon} />}
                                </span>
                            </div>
                        );
                    case "radio":
                        return (
                            <span>
                                <RadioCell data={data} options={config?.options} />
                            </span>
                        );
                    case "color":
                        return (
                            <span>
                                <ColorCell data={data} />
                            </span>
                        );
                    case "stepper":
                        return (
                            <span>
                                <StepperCell steps={data} />
                            </span>
                        );
                    case "icon":
                        return (
                            <span>
                                <IconCell
                                    name={config?.name}
                                    color={config?.color}
                                    colorKey={config?.colorKey}
                                    paths={config?.paths}
                                    pathsKey={config?.pathsKey}
                                    size={config?.size}
                                    extraData={extraData}
                                />
                            </span>
                        );
                    case "linkedRecords":
                        return (
                            <span>
                                <LinkedRecordsCell
                                    label={config?.visibleName}
                                    data={data}
                                    title={interpolateString(extraData, config?.title)}
                                    subtitle={interpolateString(extraData, config?.subtitle)}
                                    searchPath={interpolateString(extraData, config?.searchPath)}
                                    //linkPath={config?.linkPath}
                                    linkPath={interpolateString(extraData, config?.linkPath)}
                                    onChange={(val: any) => {
                                        onChange(val);
                                    }}
                                    readonly={evaluateReadonly(config)}
                                    primaryKey={config.primaryKey}
                                    secondaryKey={config.secondaryKey}
                                    thumbnailKey={config.thumbnailKey}
                                    noContentIcon={config.noContentIcon}
                                    noContentText={config.noContentText}
                                />
                            </span>
                        );
                    case "users":
                        return (
                            <span>
                                <UsersCell
                                    data={data}
                                    extraData={extraData}
                                    searchPath={config?.searchPath}
                                    sendPath={interpolateString(extraData, config?.sendPath)}
                                    subtitle={interpolateString(extraData, config?.subtitle)}
                                    itemFields={config?.itemFields}
                                    onChange={(val: any) => {
                                        onChange(val);
                                    }}
                                    readonly={evaluateReadonly(config)}
                                />
                            </span>
                        );
                    case "playPause":
                        return (
                            <span>
                                <PlayPauseCell
                                    data={data}
                                    sendPath={interpolateString(extraData, config?.sendPath)}
                                    onChange={(val: any) => {
                                        console.log(val);
                                        onChange(val);
                                    }}
                                    readonly={evaluateReadonly(config)}
                                />
                            </span>
                        );
                    case "lockToggle":
                        return (
                            <span>
                                <LockToggleCell
                                    data={data}
                                    sendPath={interpolateString(extraData, config?.sendPath)}
                                    onChange={(val: any) => {
                                        onChange(val);
                                    }}
                                    readonly={evaluateReadonly(config)}
                                />
                            </span>
                        );
                    case "status":
                        return (
                            <span>
                                <StatusCell
                                    readonly={evaluateReadonly(config)}
                                    options={config?.options}
                                    data={data}
                                    sendPath={interpolateString(extraData, config?.sendPath)}
                                    //recordId={extraData.id}
                                    //tableName={config?.tableName}
                                    onChange={(val: any) => {
                                        onChange(val);
                                    }}
                                /></span>
                        );
                    default:
                        return <div><span>{extraData[config?.name] || "-"}</span></div>;
                }
            })()}
        </div>
    );
}
export default DynamicCell;
