import React, { useState, useEffect } from "react";
import { Button, IconButton } from "@mui/material";
import { InputField } from "components/fields/InputField/InputField";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import colors from "config/colors";
import "./EditableChecklistField.scss";
import Icon from "components/Icon/Icon";
const EditableChecklistField = ({
  data,
  itemType = "text",
  itemPlaceholder = "",
  onChange,
}: any) => {
  // Inicializamos items como un arreglo de objetos en lugar de strings
  const [items, setItems] = useState(data || []);

  const handleAddItem = () => {
    const itemsCopy = [...items];
    // Agregamos un objeto vacío para un nuevo ítem
    itemsCopy.push({ text: "", checked: false });
    setItems(itemsCopy);
  };

  const handleChangeItemInput = (index: number, value: string) => {
    const itemsCopy = [...items];
    itemsCopy[index].text = value;
    setItems(itemsCopy);
  };

  const handleToggleItemChecked = (index: number) => {
    const itemsCopy = [...items];
    itemsCopy[index].checked = !itemsCopy[index].checked;
    setItems(itemsCopy);
  };

  const handleDeleteItem = (index: number) => {
    const itemsCopy = [...items];
    itemsCopy.splice(index, 1);
    setItems(itemsCopy);
  };
  useEffect(() => {
    onChange(items);
  }, [items]);
  return (
    <div className="editable-checklist-field">
      <div className="add-item-btn-wrapper">
        <Button onClick={handleAddItem} startIcon={<Icon name="add" />}>
          Agregar
        </Button>
      </div>
      <div className="list-field-items">
        {items?.map((item: any, index: number) => {
          return (
            <div key={index} className={item.checked ? "done" : ""}>
              <IconButton
                //size="large"
                onClick={() => handleToggleItemChecked(index)}
              >
                {item.checked ? (
                  <CheckBoxIcon style={{ color: colors.primary }} />
                ) : (
                  <CheckBoxOutlineBlankIcon
                    style={{ color: colors.textTint }}
                  />
                )}
              </IconButton>
              <InputField
                placeholder={itemPlaceholder}
                defaultValue={item.text}
                onChange={(value: string) =>
                  handleChangeItemInput(index, value)
                }
              />

              <IconButton
                aria-label="delete"
                onClick={() => handleDeleteItem(index)}
                color="error"
              >
                <Icon name="delete" />
              </IconButton>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export { EditableChecklistField };
