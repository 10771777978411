import { useState, useEffect } from "react";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import "./CheckboxItemField.scss";

const CheckboxItemField = (props: any) => {
  let {
    defaultValue,

    label,

    onChange,


  }:
    any = props;
  const [value, setValue] = useState(defaultValue as any);



  useEffect(() => {
    if (onChange) onChange(value);
  }, [value]);

  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);
  const handleClick = () => {
    setValue((prev: any) => !prev);
  };


  return (
    <>
      <a className="checkbox-item-field-container" onClick={handleClick}>

        <div className="item-field-header">
          {label && (

            <span>{label}</span>

          )}
        </div>


        <div className="item-field-body">

          <div className="checkbox">

            {value ? <CheckBoxIcon /> : <CheckBoxOutlineBlankIcon />}

          </div>

        </div>
      </a>
    </>
  );
};

export { CheckboxItemField };
