// components/configurations/GridConfiguration.tsx
import React from 'react';
import { ColorField } from 'components/fields/ColorField/ColorField';
import { RadioField } from 'components/fields/RadioField/RadioField';
import ExpandableSection from 'components/ExpandableSection/ExpandableSection';
import SizeField from '../../SizeField/SizeField';

interface GridConfigurationProps {
    localConfig: any;
    selectedTab: string;
    handleChange: (field: string, value: any) => void;
    getFieldValues: (field: string) => any;
}

const GridConfiguration: React.FC<GridConfigurationProps> = ({
    localConfig,
    selectedTab,
    handleChange,
    getFieldValues
}) => {
    return (
        <>
            {/* Aquí irá la configuración específica del componente Grid */}
        </>
    );
};

export default GridConfiguration;
