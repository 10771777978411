import { useState, useEffect } from "react";
import { FieldEditControls } from "components/FieldEditControls/FieldEditControls";
import "./InputField.scss";
import { useSessionStorage } from "hooks/useSessionStorage";
import Icon from "components/Icon/Icon";

const InputField = (props: any) => {
  let {
    type = "text",
    defaultValue,
    editable,
    label,
    editPath,
    id,
    startIcon,
    endIcon,
    onChange,
    placeholder,
    name,
    prepend,
    append,
    size = "md", //md/sm
    description,
    onEdit,
    mode
  }: any = props;
  const [value, setValue] = useState(defaultValue as any);
  const [isEditEnabled, setIsEditEnabled] = useState(false);

  useEffect(() => {
    if (onChange) onChange(value);
  }, [value]);

  // Esto da error al editar dynamicListField
  useEffect(() => {
    if (JSON.stringify(defaultValue) !== JSON.stringify(value)) setValue(defaultValue);
    //setValue(defaultValue);
  }, [defaultValue]);

  return (
    <>
      <div className={"input-field-container " + size}>
        {label && <label>{label}</label>}
        <div>
          <div>
            {mode == 'readonly' ||
              (mode == 'edit' && editable && !isEditEnabled) ||
              (mode == 'edit' && !editable) ? (
              <div className="readonly-content">
                {startIcon && <Icon name={startIcon} />}
                {prepend && <span>{prepend}</span>}
                <div>{value || "-"}</div>
                {append && <span>{append}</span>}
                {endIcon && <Icon name={endIcon} />}
              </div>
            ) : (
              <div className="editable-content">
                {startIcon && <Icon name={startIcon} />}
                {prepend && <span>{prepend}</span>}
                <input
                  id={id}
                  type={
                    type == "integer" || type == "decimal" ? "number" : "text"
                  }
                  placeholder={placeholder}
                  value={value || ""}
                  onChange={(e) => setValue(e.target.value)}
                  style={{
                    textAlign:
                      type == "integer" || type == "decimal" ? "right" : "left",
                  }}
                />
                {append && <span>{append}</span>}
                {endIcon && <Icon name={endIcon} />}
              </div>
            )}
          </div>

          {(mode == 'edit' && editable) && (
            <>

              <FieldEditControls
                value={value}
                fieldName={name}
                editPath={editPath}
                onEditStart={() => {
                  setIsEditEnabled(true);
                }}
                onEditCancel={(originalValue) => {
                  console.log(originalValue)
                  setIsEditEnabled(false);
                  setValue(originalValue);
                }}
                onEditEnd={(success: boolean, originalValue) => {
                  setIsEditEnabled(false);
                  onEdit();
                  if (!success) {
                    setValue(originalValue);

                  }
                }}
              />
            </>

          )}

        </div>

      </div>
      {description && <span className="field-description">{description}</span>}
    </>
  );
};

export { InputField };
