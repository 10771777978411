// components/configurations/T1Configuration.tsx
import React from 'react';
import IconButtonGroup from 'components/IconButtonGroup/IconButtonGroup';
import { RadioField } from 'components/fields/RadioField/RadioField';
import SizeField from '../../SizeField/SizeField';
import { ColorField } from 'components/fields/ColorField/ColorField';
import colors from 'config/colors';
import ExpandableSection from 'components/ExpandableSection/ExpandableSection';

interface T1ConfigurationProps {
    localConfig: any;
    selectedTab: string;
    handleChange: (field: string, value: any) => void;
    getFieldValues: (field: string) => any;
}

const T1Configuration: React.FC<T1ConfigurationProps> = ({
    localConfig,
    selectedTab,
    handleChange,
    getFieldValues
}) => {
    return (
        <>
            <div className="action-wrapper">
                <SizeField
                    label="Ancho"
                    field="width"
                    isEnabled={getFieldValues('width').value !== null}
                    value={getFieldValues('width').value}
                    unit={getFieldValues('width').unit}
                    handleChange={handleChange}
                    minValue={0}
                />
            </div>

            <div className="action-wrapper">
                <ColorField
                    defaultValue={selectedTab === 'default' ? localConfig?.color || colors?.text : localConfig?.responsive?.[selectedTab]?.color || localConfig?.color || colors?.text}
                    label={"Color"}
                    onChange={(color: any) => handleChange('color', color)}
                />
            </div>
            <div className="action-wrapper">
                <ColorField
                    defaultValue={selectedTab === 'default' ? localConfig?.backgroundColor || null : localConfig?.responsive?.[selectedTab]?.backgroundColor || localConfig?.backgroundColor || null}
                    label={"Color de fondo"}
                    onChange={(color: any) => handleChange('backgroundColor', color)}
                />
            </div>
            <div className="action-wrapper">
                <label>Alineación</label>
                <IconButtonGroup
                    options={[
                        { name: 'left', icon: 'alignLeft' },
                        { name: 'center', icon: 'alignCenter' },
                        { name: 'right', icon: 'alignRight' },
                        { name: 'justify', icon: 'alignJustify' }
                    ]}
                    multiple={false}
                    defaultValues={[selectedTab === 'default' ? localConfig?.textAlign : localConfig?.responsive?.[selectedTab]?.textAlign || localConfig?.textAlign]}
                    onSelect={(selectedOptions: any) => handleChange('textAlign', selectedOptions[0]?.name)}
                />
            </div>
            <div className="action-wrapper">
                <label>Estilo de la fuente</label>
                <IconButtonGroup
                    options={[
                        { name: 'regular', icon: 'regular' },
                        { name: 'italic', icon: 'regularItalic' },
                    ]}
                    multiple={false}
                    defaultValues={[selectedTab === 'default' ? localConfig?.fontStyle : localConfig?.responsive?.[selectedTab]?.fontStyle || localConfig?.fontStyle]}
                    onSelect={(selectedOptions: any) => handleChange('fontStyle', selectedOptions[0]?.name)}
                />
            </div>
            <div className="action-wrapper">
                <RadioField
                    defaultValue={selectedTab === 'default' ? localConfig?.fontWeight || '400' : localConfig?.responsive?.[selectedTab]?.fontWeight || localConfig?.fontWeight || '400'}
                    label={"Grosor de la fuente"}
                    options={[
                        { name: "200", visibleName: "Extra light" },
                        { name: "300", visibleName: "Light" },
                        { name: "400", visibleName: "Regular" },
                        { name: "500", visibleName: "Medium" },
                        { name: "600", visibleName: "Semi bold" },
                        { name: "700", visibleName: "Bold" },
                        { name: "800", visibleName: "Extra bold" },
                        { name: "900", visibleName: "Black" },
                    ]}
                    onChange={(type: string) => handleChange('fontWeight', type)}
                />
            </div>
            <SizeField
                label="Tamaño fuente"
                field="fontSize"
                isEnabled={getFieldValues('fontSize').value !== null}
                value={getFieldValues('fontSize').value}
                unit={getFieldValues('fontSize').unit}
                handleChange={handleChange}
                minValue={1}
            />
            <div className="action-wrapper">
                <label>Decoración del texto</label>

                <IconButtonGroup
                    options={[
                        { name: 'none', icon: 'textDecorationNone' },
                        { name: 'line-through', icon: 'textDecorationLineThrough' },
                        { name: 'overline', icon: 'textDecorationOverline' },
                        { name: 'underline', icon: 'textDecorationUnderline' },
                        { name: 'underline overline', icon: 'textDecorationOverlineUnderline' },

                    ]}
                    multiple={false}
                    defaultValues={[selectedTab === 'default' ? localConfig?.textDecoration : localConfig?.responsive?.[selectedTab]?.textDecoration || localConfig?.textDecoration]}
                    onSelect={(selectedOptions: any) => handleChange('textDecoration', selectedOptions[0]?.name)}
                />

            </div>


            <ExpandableSection title="Márgenes">

                <SizeField
                    label="Margen arriba"
                    field="marginTop"
                    isEnabled={getFieldValues('marginTop').value !== null}
                    value={getFieldValues('marginTop').value}
                    unit={getFieldValues('marginTop').unit}
                    handleChange={handleChange}
                    minValue={0}
                />
                <SizeField
                    label="Margen abajo"
                    field="marginBottom"
                    isEnabled={getFieldValues('marginBottom').value !== null}
                    value={getFieldValues('marginBottom').value}
                    unit={getFieldValues('marginBottom').unit}
                    handleChange={handleChange}
                    minValue={0}
                />
                <SizeField
                    label="Margen izquierda"
                    field="marginLeft"
                    isEnabled={getFieldValues('marginLeft').value !== null}
                    value={getFieldValues('marginLeft').value}
                    unit={getFieldValues('marginLeft').unit}
                    handleChange={handleChange}
                    minValue={0}
                />
                <SizeField
                    label="Margen derecha"
                    field="marginRight"
                    isEnabled={getFieldValues('marginRight').value !== null}
                    value={getFieldValues('marginRight').value}
                    unit={getFieldValues('marginRight').unit}
                    handleChange={handleChange}
                    minValue={0}
                />
            </ExpandableSection>
            <ExpandableSection title="Márgenes internos">
                <SizeField
                    label="Margen interno arriba"
                    field="paddingTop"
                    isEnabled={getFieldValues('paddingTop').value !== null}
                    value={getFieldValues('paddingTop').value}
                    unit={getFieldValues('paddingTop').unit}
                    handleChange={handleChange}
                    minValue={0}
                />
                <SizeField
                    label="Margen interno abajo"
                    field="paddingBottom"
                    isEnabled={getFieldValues('paddingBottom').value !== null}
                    value={getFieldValues('paddingBottom').value}
                    unit={getFieldValues('paddingBottom').unit}
                    handleChange={handleChange}
                    minValue={0}
                />
                <SizeField
                    label="Margen interno derecha"
                    field="paddingRight"
                    isEnabled={getFieldValues('paddingRight').value !== null}
                    value={getFieldValues('paddingRight').value}
                    unit={getFieldValues('paddingRight').unit}
                    handleChange={handleChange}
                    minValue={0}
                />
                <SizeField
                    label="Margen interno izquierda"
                    field="paddingLeft"
                    isEnabled={getFieldValues('paddingLeft').value !== null}
                    value={getFieldValues('paddingLeft').value}
                    unit={getFieldValues('paddingLeft').unit}
                    handleChange={handleChange}
                    minValue={0}
                />
            </ExpandableSection>
        </>
    );
};

export default T1Configuration;
