import React, { useEffect, useState } from "react";
import { AnimatedNumber } from "../../AnimatedNumber/AnimatedNumber";
import "./FilledShapeChart.scss";
import Icon from "components/Icon/Icon";
import { IconButton } from "@mui/material";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
const FilledShapeChart = (props: any) => {
  const { data, decimalPrecision } = props;
  const [currentIndex, setCurrentIndex] = useState(data?.length - 1);
  const [currentData, setCurrentData] = useState(data[data?.length - 1]?.data);
  //const squareSize = data[currentIndex]?.data?.length > 2 ? 140 / data[currentIndex]?.data.length : 50;
  const squareSize = 200;
  const goToPrevious = () => {
    setCurrentIndex((prevIndex: number) =>
      prevIndex > 0 ? prevIndex - 1 : prevIndex
    );
  };

  // Función para ir al próximo período
  const goToNext = () => {
    setCurrentIndex((prevIndex: number) =>
      prevIndex < data.length - 1 ? prevIndex + 1 : prevIndex
    );
  };
  useEffect(() => {
    setCurrentData(null);
    setTimeout(() => {
      if (data[currentIndex]?.data?.length) {
        // Sumar todos los valores de los items
        const totalValue = data[currentIndex]?.data.reduce((acc: any, item: any) => acc + item.value, 0);

        // Calcular el porcentaje (height) y actualizar los datos
        const dataWithHeight = data[currentIndex]?.data.map((item: any) => ({
          ...item,
          height: (item.value / totalValue) * 100,
        }));

        setCurrentData(dataWithHeight)
      }

    }, 100)
  }, [currentIndex])
  return (
    <div className="filled-shape-chart-container">
      <div className="current-period">
        <IconButton
          aria-label="Previous Period"
          onClick={goToPrevious}
          disabled={currentIndex === 0}
        >
          <KeyboardArrowLeftIcon />
        </IconButton>
        <strong>{data[currentIndex]?.visible_period}</strong>
        <IconButton
          aria-label="Next Period"
          onClick={goToNext}
          disabled={currentIndex === data.length - 1}
        >
          <KeyboardArrowRightIcon />
        </IconButton>
      </div>
      <div className="graph">
        {currentData?.map((item: any, index: number) => {
          return (
            <div key={index}>
              <div
                style={{
                  width: squareSize + "px",
                  height: squareSize + "px",
                }}
              >
                <div
                  style={{
                    width: squareSize + "px",
                    height: squareSize + "px",
                  }}
                >
                  <Icon name={item?.icon} color="#fff" size={squareSize} />
                </div>
                <div
                  style={{
                    height: squareSize - 2 + "px",
                    width: squareSize - 2 + "px",
                  }}
                ></div>
                <div
                  style={{
                    backgroundColor: item.color,
                    height: item.height - 2 + "%",
                    width: squareSize - 2 + "px",
                  }}
                ></div>
              </div>
              <div>
                <div>
                  <AnimatedNumber
                    value={item.value}
                    duration="500"
                    color={item?.color}
                    fontSize={"1.2em"}
                    decimalPrecision={decimalPrecision}
                    type="decimal"
                  />
                </div>
                <span className="label" style={{ fontSize: "1em" }}>{item.label}</span>
              </div>
            </div>
          );
        })}
      </div>

    </div>
  );
};

export { FilledShapeChart };
