import React from "react";
import Grid from "@mui/material/Grid";
import "./ReadonlyBillingField.scss";
import { formatNumber } from "../../../../utils/formatNumber";
import { ReadonlyBillingItem } from "../ReadonlyBillingItem/ReadonlyBillingItem";
import Icon from "components/Icon/Icon";
const ReadonlyBillingField = ({
  data,
  documentType,
  transactionType,
}: any) => {
  const partyType = transactionType === "purchase" ? "supplier" : "customer";

  return (
    <div className="readonly-billing-field">
      <div className="grid-wrapper">
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} className="date-wrapper">
            {documentType == "Cotización" ? (
              <>{data?.valid_until}</>
            ) : (
              <>{data?.due_date}</>
            )}
          </Grid>
          {transactionType === "sale" && (
            <Grid item xs={12} sm={6} className="price-list-wrapper">
              {data?.price_list?.name}
            </Grid>
          )}

          <Grid item xs={12}>
            {data && data[`${partyType}_id`] && (
              <div>
                <strong>{data[partyType]?.name}</strong>
                <span>CUIT: {data[partyType]?.tin || "-"} </span>
                <span>Email: {data[partyType]?.email || "-"} </span>
              </div>
            )}
          </Grid>
          <Grid item xs={12}>
            <div className="items-wrapper">
              {(data?.products?.length == 0 || !data?.products) &&
                (data?.services?.length == 0 || !data?.services) ? (
                <div className="no-content">
                  <Icon name="cart" />
                  <span>No hay productos/servicios en el carrito</span>
                </div>
              ) : (
                <>
                  <table>
                    <thead>
                      <tr>
                        <th className="ta-c">Cant.</th>
                        <th className="ta-l">Detalle</th>
                        <th className="ta-r">Precio unitario</th>
                        <th className="ta-r" style={{ minWidth: "60px" }}>
                          Desc. (%)
                        </th>
                        <th className="ta-r" style={{ minWidth: "100px" }}>
                          Desc. ($)
                        </th>
                        <th className="ta-r">Subtotal</th>
                        <th className="ta-c">Impuesto</th>
                        <th className="ta-r">Subtotal con imp.</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data?.products?.map((item: any, index: number) => {
                        return (
                          <React.Fragment key={item.id}>
                            <ReadonlyBillingItem
                              data={item}
                              transactionType={transactionType}
                              profitMargin={data?.price_list_profit_margin}
                            />
                          </React.Fragment>
                        );
                      })}

                      {data?.services?.map((item: any, index: number) => {
                        return (
                          <React.Fragment key={item.id}>
                            <ReadonlyBillingItem
                              data={item}
                              transactionType={transactionType}
                              profitMargin={data?.price_list_profit_margin}
                            />
                          </React.Fragment>
                        );
                      })}
                    </tbody>
                  </table>
                </>
              )}
            </div>

            <div className="totals-wrapper">
              <div>
                <table>
                  <tbody>
                    <tr>
                      <td>Importe neto gravado</td>
                      <td style={{ textAlign: "right" }}>
                        <div className="amount-wrapper">
                          <span>$</span>
                          <span>{formatNumber(data?.taxable_total)}</span>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>Importe neto no gravado / exento</td>
                      <td style={{ textAlign: "right" }}>
                        <div className="amount-wrapper">
                          <span>$</span>
                          {formatNumber(data?.nontaxable_total)}
                        </div>
                      </td>
                    </tr>
                    {data?.valueAddedTaxes?.map((item: any, index: number) => {
                      return (
                        <tr key={index}>
                          <td>{item.name}</td>
                          <td style={{ textAlign: "right" }}>
                            <div className="amount-wrapper">
                              <span>$</span> {formatNumber(item.amount)}
                            </div>
                          </td>
                        </tr>
                      );
                    })}

                    {data?.perceptions?.map((item: any, index: number) => {
                      return (
                        <tr key={index}>
                          <td>
                            <div> {item.name}</div>
                            <span className="taxable-base">Base imponible</span>
                            <div className="input-group">
                              <span>$</span>
                              {item.taxable_base || 0}
                            </div>
                          </td>

                          <td style={{ textAlign: "right" }}>
                            <span>$</span> {formatNumber(item.amount)}
                          </td>
                        </tr>
                      );
                    })}
                    {data?.otherTaxes?.map((item: any, index: number) => {
                      return (
                        <tr key={index}>
                          <td>
                            <div>{item.name}</div>
                            <span>Base imponible</span>
                            <div className="input-group">
                              <span>$</span>
                              {item.taxable_base || 0}
                            </div>
                          </td>

                          <td style={{ textAlign: "right" }}>
                            <span>$</span>
                            {formatNumber(item.amount)}
                          </td>
                        </tr>
                      );
                    })}

                    <tr className="main-total-wrapper">
                      <td>Total</td>
                      <td style={{ textAlign: "right" }}>
                        <div className="amount-wrapper">
                          <span>$</span>
                          <span>{formatNumber(data?.total)}</span>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            {(documentType == "invoice" || documentType == "debitNote") && (
              <div className="pay-wrapper">
                <div>
                  <h3 className="label">Pago</h3>
                  <div className="payment-methods">
                    {data?.paymentMethods?.map((item: any, index: number) => {
                      return (
                        <div key={index}>
                          <span>{item.visibleName}</span>
                          <div>
                            <div className="input-group">
                              <span>$</span>
                              {item.amount}
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                  <div className="total-paid">
                    <span>Total cobrado</span>{" "}
                    <strong>$ {formatNumber(data?.total - data?.balance)}</strong>
                  </div>
                </div>
              </div>
            )}
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export { ReadonlyBillingField };
