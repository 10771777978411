import React from 'react';
import { useIcons } from 'contexts/IconContext';
import { DynamicIcon } from 'components/DynamicIcon/DynamicIcon';

const Icon = (props: any) => {
  const { name, color, size, style = {} } = props;
  const { icons, loading } = useIcons();

  if (loading) {
    return null; // or a loading spinner
  }

  const iconData = icons.find((icon) => icon.name === name);

  if (!iconData) {
    console.warn(`Icon with name "${name}" not found`);
    return null;
  }

  return (
    <DynamicIcon
      style={style}
      size={size}
      paths={iconData.paths.map((path) => ({ ...path, color: color || path.color }))}
    />
  );
};

export default Icon;
