import React, { useEffect, useState } from "react";
import "./RingBinder.scss";
import { SvgIcon } from "@mui/material";
import horizontalConfig from "./configFiles/horizontal.json";
import viewedFromFrontConfig from "./configFiles/viewedFromFront.json";
import viewedFromTopConfig from "./configFiles/viewedFromTop.json";
import viewedFromSideConfig from "./configFiles/viewedFromSide.json";
import { adjustColor } from "utils/adjustColor";
import { RingBinderDetailModal } from "components/modals/RingBinderDetailModal/RingBinderDetailModal";
import TextLines from "components/TextLines/TextLines";

const RingBinder = (props: any) => {
  const {
    style,
    color = "#9cb0be",
    type = "horizontal",
    label = "",
    id,
    name,
    mode = 'all',
    //canDeleteFiles,
    canUploadFiles,
    onClick
  } = props;
  const [config, setConfig] = useState(null as any);
  const [ringBinderDetailModalIsOpen, setRingBinderDetailModalIsOpen] = useState(false);
  useEffect(() => {
    switch (type) {
      case "horizontal":
        setConfig(horizontalConfig);
        break;
      case "viewedFromFront":
        setConfig(viewedFromFrontConfig);
        break;
      case "viewedFromTop":
        setConfig(viewedFromTopConfig);
        break;
      case "viewedFromSide":
        setConfig(viewedFromSideConfig);
        break;
      default:
        setConfig(horizontalConfig);
        break;
    }
  }, [type]);

  const getColor = (colorKey: string) => {
    switch (colorKey) {
      case "mainColor":
        return color;
      case "darker":
        return adjustColor(color, -20);
      case "lighter":
        return adjustColor(color, 20);
      case "darkest":
        return adjustColor(color, -40);
      case "lightest":
        return adjustColor(color, 40);
      default:
        return colorKey; // for static colors like #fff, #ccc, etc.
    }
  };

  const getLabelLines = (text: string, maxCharsPerLine: number, maxLines: number) => {
    let words = text.split(' '); // Array con todas las palabras
    const lines = []; // Array con las líneas

    for (let i = 0; i < maxLines; i++) {
      let currentLine = '';

      // Recorrer las palabras disponibles y armar la línea
      while (words.length > 0 && (currentLine.length + words[0].length + 1) <= maxCharsPerLine) {
        currentLine += words.shift() + ' ';
      }

      // Truncar la palabra si es demasiado larga para la línea
      if (words.length > 0 && (currentLine.length + words[0].length + 1) > maxCharsPerLine) {
        if (currentLine.length === 0) {
          // Si la palabra es demasiado larga para una línea vacía, cortarla
          currentLine = words[0].slice(0, maxCharsPerLine - 3) + '...';
          //words[0] = words[0].slice(maxCharsPerLine - 3);
          //words[0] = '';
          words.shift()
        }
      }

      lines.push(currentLine.trim());

      // Si hemos alcanzado el número máximo de líneas y aún hay palabras, truncar la última línea
      if (i === maxLines - 1 && words.length > 0) {
        let lastLine: any = lines.pop();
        lastLine = lastLine.slice(0, maxCharsPerLine - 3) + '...';
        lines.push(lastLine);
        break;
      }
    }

    return lines;
  };



  if (!config) return null;

  const labelLines = getLabelLines(label, config.maxCharsPerLine, 3);
  let labelTop = '30px';
  let labelLeft = '10px';
  switch (type) {
    case "horizontal":
      labelTop = '20px';
      labelLeft = '90px';
      break;
    case "viewedFromFront":
      labelTop = '30px';
      labelLeft = '17px';
      break;
    case "viewedFromTop":
      labelTop = '30px';
      labelLeft = '17px';
      break;
    case "viewedFromSide":
      labelTop = '30px';
      labelLeft = '10px';
      break;
    default:
      labelTop = '30px';
      labelLeft = '10px';
      break;
  }
  return (
    <>
      <a className="ring-binder-container"
        onClick={() => {
          setRingBinderDetailModalIsOpen(true)
          //onClick()
        }}
      // onClick={onClick}
      >
        <SvgIcon style={{ ...style, width: config?.width, height: config?.height }} viewBox={`0 0 ${config?.width} ${config?.height}`}>
          {config.paths.map((path: any, index: number) => (
            <path key={index} fill={getColor(path.color)} d={path.d} opacity={path.opacity} />
          ))}
        </SvgIcon>
        {type !== 'horizontal' ? (
          // <div className="ring-binder-label vertical" style={{ top: labelTop, left: labelLeft }}>
          //   {labelLines.map((line: string, index: number) => (
          //     <div key={index}>{line}</div>
          //   ))}
          // </div>
          <TextLines
            className="ring-binder-label vertical"
            style={{ top: labelTop, left: labelLeft }}
            text={label}
            maxCharsPerLine={config.maxCharsPerLine}
            maxLines={3}
          />
        ) : (
          // <div className="ring-binder-label horizontal" style={{ top: labelTop, left: labelLeft }}>
          //   {labelLines.map((line: string, index: number) => (
          //     <div key={index}>{line}</div>
          //   ))}
          // </div>
          <TextLines
            className="ring-binder-label horizontal"
            style={{ top: labelTop, left: labelLeft }}
            text={label}
            maxCharsPerLine={config.maxCharsPerLine}
            maxLines={3}
          />
        )}
      </a>
      <RingBinderDetailModal
        isOpen={ringBinderDetailModalIsOpen}
        title={label}
        binderId={id}
        mode={mode}
        canLinkFilesToUsers={mode === 'all' ? true : false}
        //canDeleteFiles={canDeleteFiles}
        canUploadFiles={canUploadFiles}
        onClose={(res: any) => {
          setRingBinderDetailModalIsOpen(false)
        }}
      />
    </>

  );
};

export { RingBinder };
