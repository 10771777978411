import React, { useState } from "react";
import "./ThumbnailCell.scss";
import urls from "config/urls";
import { DefaultThumbnail } from "./DefaultThumbnail";

const ThumbnailCell = ({ data }: any) => {
  // data es el valor de imagePath
  const [imageError, setImageError] = useState(false);
  const imageUrl =
    data && typeof data === "string" ? `${urls.server}${data}` : null;

  const handleImageError = () => {
    setImageError(true);
  };

  return (
    <div className="thumbnail-cell-container">
      {!imageError && imageUrl ? (
        <img src={imageUrl} alt="Thumbnail" onError={handleImageError} />
      ) : (
        <DefaultThumbnail />
      )}
    </div>
  );
};

export { ThumbnailCell };
