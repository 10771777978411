import React, { useEffect, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import LoadingButton from '@mui/lab/LoadingButton';
import "./FixUnfixBoardCardsModal.scss";
import { Spinner } from 'components/Spinner/Spinner';
import AxiosInterceptor from "../../../AxiosInterceptor";
import urls from 'config/urls';
import SearchBar from 'components/SearchBar/SearchBar';
import colors from 'config/colors';
import Icon from 'components/Icon/Icon';
import { useDebounce } from 'hooks/useDebounce';

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  onChange?: () => void;
}

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children: React.ReactElement },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const FixUnfixBoardCardsModal: React.FC<ModalProps> = ({ isOpen, onClose, onChange }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [loading, setLoading] = useState(false);
  const [loadingMore, setLoadingMore] = useState(false);
  const [cards, setCards] = useState([] as any);
  const [refreshOnClose, setRefreshOnClose] = useState(false);
  const [offset, setOffset] = useState(0);
  const [total, setTotal] = useState(0);
  const debouncedValue = useDebounce<string>(searchTerm, 500);
  const limit = 20;
  const loadData = async (newOffset = 0, append = false) => {
    if (append) {
      setLoadingMore(true);
    } else {
      setLoading(true);
    }

    try {
      let url = `${urls.server}/api/my/cards?offset=${newOffset}&limit=${limit}&search_term=${searchTerm}`
      const res: any = await AxiosInterceptor.get(
        url
      );
      const resData = res.data;
      if (append) {
        setCards((prev: any) => [...prev, ...resData.cards]);
      } else {
        setCards(resData.cards);
      }
      console.log(resData)
      setTotal(resData.total);
    } catch (error) {
      console.error(error);
    } finally {
      if (append) {
        setLoadingMore(false);
      } else {
        setLoading(false);
      }
    }
  };

  const fixUnfixCard = async (cardId: number) => {
    try {
      const res = await AxiosInterceptor.put(`${urls.server}/api/my/cards/${cardId}`);
      const data = await res?.data;
      if (data.success) {
        setCards((prev: any) => {
          return prev.map((card: any) => {
            if (card.id === cardId) {
              return { ...card, is_fixed: !card.is_fixed };
            }
            return card;
          });
        });
        setRefreshOnClose(true);
      }
    } catch (error) {

    }
  };

  const handleLoadMore = () => {
    const newOffset = offset + 10;
    setOffset(newOffset);
    loadData(newOffset, true);
  };

  useEffect(() => {
    if (isOpen) {
      loadData();
    }
  }, [isOpen, debouncedValue]);

  useEffect(() => {
    if (!isOpen) setRefreshOnClose(false);
  }, [isOpen]);

  return (
    <Dialog
      fullScreen
      open={isOpen}
      onClose={() => {
        onClose();
        if (refreshOnClose && onChange) onChange();
      }}
      TransitionComponent={Transition}
      className={`fix-unfix-cards-modal-container`}
      PaperProps={{
        style: {
          backgroundColor: "transparent",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          paddingTop: "50px",
          paddingBottom: "50px",
          paddingLeft: "20px",
          paddingRight: "35px",
        },
      }}
    >
      <div className='modal-window'>

        <a onClick={() => {
          onClose();
          if (refreshOnClose && onChange) onChange();
        }}>
          <Icon name="cancel" />
        </a>

        <div>
          <div className="header-searchbar-wrapper">
            <SearchBar
              style={{
                backgroundColor: colors.light,
              }}
              placeholder={'Buscar...'}
              onChange={(searchTerm) => {
                setSearchTerm(searchTerm);
                setOffset(0);
              }}
            />
          </div>
          <div className='cards-wrapper'>
            {loading && (
              <div className="spinner-wrapper">
                <Spinner visible={loading} />
              </div>
            )}

            {!loading && (
              <>

                {cards?.length === 0 && (
                  <div className="no-content">
                    <Icon name="board" />
                    <p>No hay tarjetas</p>
                  </div>
                )}

                {cards?.map((item: any) => (
                  <a
                    key={item.id}
                    className={item.is_fixed ? "fixed" : ""}
                    onClick={() => fixUnfixCard(item.id)}
                  >
                    <span>
                      <Icon name="pinWrapper" />
                      <Icon name="pin" />
                    </span>

                    {item.visible_name}
                  </a>
                ))}


              </>
            )}
          </div>
          {!loading && cards?.length < total && (
            <div className="loading-button-wrapper">
              <LoadingButton
                onClick={handleLoadMore}
                loading={loadingMore}
                startIcon={<Icon name="plus" />}
              >
                Más tarjetas
              </LoadingButton>
            </div>
          )}
        </div>
      </div>
    </Dialog>
  );
};

export default FixUnfixBoardCardsModal;
