import React from 'react'

interface ImageProps {
    style: React.CSSProperties;
    commonProps?: any;
    src?: string;
}
const Image: React.FC<ImageProps> = ({ style, commonProps, src }) => {
    return (
        <>
            {
                src ? <img {...commonProps} style={style} src={src} /> : <img src={"assets/placeholder-image.svg"} alt="loading" width="100%" />
            }
        </>

    )
}

export default Image