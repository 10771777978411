import axios from "axios";
import urls from "config/urls";
const instance = axios.create({
  baseURL: urls.server + "/api",
});

const requestHandler = (request: any) => {
  // Token will be dynamic so we can use any app-specific way to always
  // fetch the new token before making the call
  request.headers.Authorization = `Bearer ${localStorage.getItem("token")}`;

  return request;
};
const responseHandler = (response: any) => {
  return response;
};
const flattenErrorsObject = (errors: any, memo: any = []) => {
  if (typeof errors === "string") {
    if (memo.indexOf(errors) > -1) {
      return null;
    }
    return memo.push(errors);
  }
  if (Array.isArray(errors)) {
    errors.forEach((err) => {
      flattenErrorsObject(err, memo);
    });
  } else if (Object.values(errors).length > 0) {
    Object.values(errors).forEach((err) => {
      flattenErrorsObject(err, memo);
    });
  }
  return memo.join(" ");
};
const responseErrorHandler = (error: any) => {
  if (error.response.status === 401) {
    localStorage.removeItem("token");
    window.location.href = "/sign-in";
    return null;
    //
  }
  if (error.response.status === 500) {
    //return null;
  }
  //429 Too Many Requests
  if (error.response.status === 429) {
    //return null;
  }
  const errors = error.response.data.errors
    ? flattenErrorsObject(error.response.data.errors)
    : error.response.data.message;
  throw new Error(errors);
};
const requestErrorHandler = (error: any) => {
  return Promise.reject(error);
};
instance.interceptors.request.use(
  (request) => requestHandler(request),
  (error) => {
    requestErrorHandler(error);
  }
);

instance.interceptors.response.use(
  (response) => responseHandler(response),
  (error) => {
    responseErrorHandler(error);
  }
);

export default instance;
