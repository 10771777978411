import React, { useEffect, useState } from "react";
import "./RingBindersExpandedCard.scss";
import { Header } from "components/Header/Header";
import urls from "config/urls";
import AxiosInterceptor from "../../../../AxiosInterceptor";
import Shelf from "components/Shelf/Shelf";
import { Button } from "@mui/material";
import Icon from "components/Icon/Icon";
import { RingBinder } from "components/RingBinder/RingBinder";
import { Spinner } from "components/Spinner/Spinner";

const RingBindersExpandedCard = (props: any) => {
  const { title } = props;
  const [searchTerm, setSearchTerm] = useState("");
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [ringBinderType, setRingBinderType] = useState('viewedFromSide');

  const handleSearchChange = (res: string) => {
    setSearchTerm(res);
  };

  const loadData = async () => {
    setLoading(true);
    const res = await AxiosInterceptor.get(
      `${urls.server}/api/ring-binders`
    );
    setData(res?.data);
    setLoading(false);
  };

  useEffect(() => {
    loadData();
  }, []);

  // Function to calculate the number of binders per shelf based on screen width
  const calculateBindersPerShelf = () => {
    const screenWidth = window.innerWidth;
    if (screenWidth < 600) {
      return 3;
    } else if (screenWidth < 900) {
      return 4;
    } else if (screenWidth < 1200) {
      return 5;
    } else {
      return 6;
    }
  };

  // Function to toggle ring binder type through the four types
  const toggleRingBinderType = () => {
    const types = ['horizontal', 'viewedFromFront', 'viewedFromTop', 'viewedFromSide'];
    const currentIndex = types.indexOf(ringBinderType);
    const nextIndex = (currentIndex + 1) % types.length;
    setRingBinderType(types[nextIndex]);
  };

  // Text for button based on ringBinderType
  const buttonText = {
    horizontal: 'Horizontal',
    viewedFromFront: 'Visto desde frente',
    viewedFromTop: 'Visto desde arriba',
    viewedFromSide: 'Visto desde costado'
  }[ringBinderType];

  // Divide binders into shelves if the type is not horizontal
  const bindersPerShelf = calculateBindersPerShelf();
  const shelves = [];
  if (ringBinderType !== 'horizontal') {
    for (let i = 0; i < data.length; i += bindersPerShelf) {
      shelves.push(data.slice(i, i + bindersPerShelf));
    }
  }

  return (
    <div className="ring-binders-expanded-card-container">
      <Header
        title={title}
        hasSearchBar
        onSearchChange={handleSearchChange}
      />
      <div className="expanded-card-body">
        {loading ? (
          <div className="spinner-wrapper">
            <Spinner visible={loading} />
          </div>
        ) : <>
          <div style={{
            display: 'flex',
            justifyContent: 'flex-end',
            marginBottom: 20,
            paddingRight: 10
          }}>
            <Button
              size="small"
              startIcon={<Icon name="eye" />}
              onClick={toggleRingBinderType}
              style={{
                borderRadius: 99,
                padding: 5,
                margin: 0,
              }}
            >
              {buttonText}
            </Button>
          </div>
          <div>
            {ringBinderType === 'horizontal' ? (
              <div className="horizontal-binders">
                {data.map((binder: any, index) => (
                  <div className="ring-binder-wrapper" key={index}>
                    <RingBinder
                      mode={'all'}
                      id={binder.id}
                      color={binder.color}
                      type={ringBinderType}
                      label={binder?.visible_name}
                      //canDeleteFiles={true}
                      canUploadFiles={true}
                    />
                  </div>
                ))}
              </div>
            ) : (
              shelves.map((shelf, index) => (
                <Shelf mode={'all'} key={index} binders={shelf} ringBinderType={ringBinderType} />
              ))
            )}
          </div></>}

      </div>
    </div>
  );
};

export { RingBindersExpandedCard };
