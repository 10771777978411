import { useState, useEffect } from "react";
import { Parser } from 'expr-eval';
import "./CalculatedField.scss";
import { formatNumber } from "utils";

const CalculatedField = (props: any) => {
  const {
    defaultValue,
    label,
    formula,
    formData
  } = props;
  const [value, setValue] = useState(defaultValue);

  useEffect(() => {
    try {
      const parser = new Parser();
      const expr = parser.parse(formula);
      const result = expr.evaluate(formData);  // Evaluando la fórmula usando formData como variables
      setValue(result);
    } catch (error) {
      console.error("Error evaluating formula: ", error);
    }
  }, [formData, formula, defaultValue]);

  return (
    <div className="calculated-field-container">
      {label && <label>{label}</label>}
      <div>
        {value ? formatNumber(value) : defaultValue ? defaultValue : '-'}
      </div>
    </div>
  );
};

export { CalculatedField };

