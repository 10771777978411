import React from "react";
import { Snackbar, Alert, Portal } from "@mui/material";

type ToastProps = {
  isOpen: boolean;
  message: string;
  severity?: any;
  onClose: () => void;
};

const Toast = ({
  isOpen,
  onClose,
  severity,
  message,
}: ToastProps) => {
  const handleCloseAlert = (
    event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    onClose();
  };
  if (!isOpen) return null;
  return (
    <Portal>
      <Snackbar open={isOpen} autoHideDuration={4000} onClose={handleCloseAlert}>
        <Alert

          onClose={handleCloseAlert}
          severity={severity}
          sx={{ width: "100%", color: "#ffffff" }}
          variant="filled"
        >
          {message}
        </Alert>
      </Snackbar>
    </Portal>
  );
};

export { Toast };
