import React, { useEffect, useState, useRef } from "react";
import "./IndicatorExpandedCard.scss";

import PillTabs from "../../../../components/tabs/PillTabs/PillTabs";

import { ChartWrapper } from "../ChartWrapper/ChartWrapper";
import { SingleBreakdownIndicatorTable } from "../SingleBreakdownIndicatorTable/SingleBreakdownIndicatorTable";
import { DynamicForm } from "components/DynamicForm/DynamicForm";
import { getFields } from "../../utils/get-fileds";
import { SimpleIndicatorTable } from "../SimpleIndicatorTable/SimpleIndicatorTable";

import AxiosInterceptor from "../../../../AxiosInterceptor";
import urls from "config/urls";
import Icon from "components/Icon/Icon";
import { FilesField } from "components/fields/FilesField/FilesField";
const IndicatorExpandedCard = (props: any) => {
  const { title, data, config, id } = props;
  const [loading, setLoading] = useState(false);
  const [sheetData, setSheetData] = useState(null as any);
  const tabOptions = [
    "Grafico",
    "Tabla",
    "Ficha",
    "Archivos"
    //"Exportar"
  ];
  const [currentTabIndex, setCurrentTabIndex] = useState(0);
  const fields: any = getFields().indicator;

  const loadIndicator = async () => {
    try {
      setLoading(true);
      const res = await AxiosInterceptor.get(
        `${urls.server}/api/cards/${id}/indicator`
      );
      const resData = res?.data;
      console.log(resData);
      setSheetData(resData);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }

  };
  useEffect(() => {
    if (currentTabIndex == 2 || currentTabIndex == 3) {
      loadIndicator();
    }
  }, [currentTabIndex]);

  return (
    <div className="indicator-expanded-card-container">
      <div className="expanded-card-header">
        <h2>{title}</h2>
      </div>
      <div className="subtitle-wrapper">
        <strong>Unidad de medida:</strong> {config.measurementUnit}
      </div>
      <div className="expanded-card-body">
        <div className="tab-wrapper">
          <PillTabs
            options={tabOptions}
            onTabChange={(index: number) => {
              setCurrentTabIndex(index);
            }}
          />
        </div>
        <div>
          {currentTabIndex == 0 && (
            <>
              {data.length > 0 ? (
                <ChartWrapper data={data} config={config} />
              ) : (
                <div className="no-content">Indicador sin datos</div>
              )}
            </>
          )}
          {currentTabIndex == 1 && (
            <div className="table-wrapper">
              <>
                {data.length > 0 ? (
                  <>
                    {(() => {
                      switch (config.indicatorType) {
                        case "simple":
                          return (
                            <SimpleIndicatorTable
                              data={data}
                              impactType={config?.impactType}
                              decimalPrecision={config?.decimalPrecision}
                            />
                          );
                        case "singleBreakdown":
                          return <SingleBreakdownIndicatorTable data={data} decimalPrecision={config?.decimalPrecision} />;
                      }
                    })()}
                  </>
                ) : (
                  <div className="no-content">Indicador sin datos</div>
                )}
              </>
            </div>
          )}
          {currentTabIndex == 2 && (
            <div className="sheet-wrapper">
              {sheetData && (
                <div className="paper">
                  <span></span>
                  <span></span>
                  <div>
                    <DynamicForm
                      fields={fields}
                      title={"Ficha del indicador"}
                      onChange={() => { }}
                      defaultData={sheetData}
                      readonly={true}
                    />
                  </div>
                </div>
              )}
            </div>
          )}
          {currentTabIndex == 3 && (
            <div className="files-wrapper">
              <FilesField
                defaultValue={sheetData?.files}
                mode={"readonly"}
              />
            </div>
          )}
          {currentTabIndex == 4 && (
            <div className="export-wrapper">
              <a className="card-btn lg" onClick={() => { }}>
                <strong>PDF</strong>
                <div>
                  <Icon name="downloadWrapper" />
                  <Icon name="download" />
                </div>
              </a>
              <a className="card-btn lg" onClick={() => { }}>
                <strong>JSON</strong>
                <div>
                  <Icon name="downloadWrapper" />
                  <Icon name="download" />
                </div>
              </a>
              <a className="card-btn lg" onClick={() => { }}>
                <strong>CSV</strong>
                <div>
                  <Icon name="downloadWrapper" />
                  <Icon name="download" />
                </div>
              </a>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
export { IndicatorExpandedCard };
