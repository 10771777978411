import React, { useState, useEffect } from "react";
import './App.css';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import { BoardPage } from "./pages/BoardPage/BoardPage";
import { ProfilePage } from "./pages/ProfilePage/ProfilePage";
import { SignInPage } from "./pages/SignInPage/SignInPage";
import { ThemeProvider } from "@mui/material";
import { createTheme } from "@mui/material/styles";
import shadows from "@mui/material/styles/shadows";
import { MySnapsPage } from "./pages/MySnapsPage/MySnapsPage";
import { ForgotPasswordPage } from "./pages/ForgotPasswordPage/ForgotPasswordPage";
import { OnboardingPage } from "./pages/OnboardingPage/OnboardingPage";
import { useAuth } from "./contexts/AuthContext";
import { NotFoundPage } from "./pages/NotFoundPage/NotFoundPage";
import { NotificationsPage } from "./pages/NotificationsPage/NotificationsPage";
import axios from "axios";
import AxiosInterceptor from "./AxiosInterceptor";
import colors from "config/colors";
import urls from "config/urls";
import { TabNavigator } from "components/TabNavigator/TabNavigator";
import { MyActivityPage } from "pages/MyActivityPage/MyActivityPage";
import { MyTasksPage } from "pages/MyTasksPage/MyTasksPage";
import { Spinner } from "components/Spinner/Spinner";
import { IconProvider } from "contexts/IconContext";
import { SalesPointPage } from "pages/SalesPointPage/SalesPointPage";
import { MyRingBindersPage } from "pages/MyRingBindersPage/MyRingBindersPage";
import { MyDebtPage } from "pages/MyDebtPage/MyDebtPage";
import DynamicPage from "pages/DynamicPage/DynamicPage";

import HomePage from "pages/HomePage/HomePage";

const AuthenticatedRoutes = ({ navigationTabs }: { navigationTabs: any[] }) => {
  const hasAccess = (path: string) => navigationTabs.some(tab => tab.path === path);

  return (
    <Routes>
      {hasAccess("/board") && <Route path="/board" element={<BoardPage />} />}
      {hasAccess("/profile") && <Route path="/profile" element={<ProfilePage />} />}
      {hasAccess("/my-snaps") && <Route path="/my-snaps" element={<MySnapsPage />} />}
      {hasAccess("/my-activity") && <Route path="/my-activity" element={<MyActivityPage />} />}
      {hasAccess("/my-tasks") && <Route path="/my-tasks" element={<MyTasksPage />} />}
      {hasAccess("/my-ring-binders") && <Route path="/my-ring-binders" element={<MyRingBindersPage />} />}
      {hasAccess("/my-debt") && <Route path="/my-debt" element={<MyDebtPage />} />}
      {hasAccess("/notifications") && <Route path="/notifications" element={<NotificationsPage />} />}

      <Route path="/public/:slug" element={<DynamicPage />} />

      {/* Rutas que no dependen de navigationTabs */}
      <Route path="/onboarding" element={<OnboardingPage />} />
      <Route path="/sales-point" element={<SalesPointPage />} />
      <Route
        path="/forgot-password"
        element={<ForgotPasswordPage />}
      />
      <Route path="/sign-in" element={<SignInPage />} />
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
};

function App() {
  const [primaryColor, setPrimaryColor] = useState(colors.primary);
  const [loading, setLoading] = useState(true);
  const [navigationTabs, setNavigationTabs] = useState([] as any);
  const [startupMode, setStartupMode] = useState("dashboard");
  const [homePage, setHomePage] = useState("/profile");
  const { getToken } = useAuth();
  const isAuthenticated = !!getToken();

  const getPublicSettings = async () => {
    try {
      const res = await axios.get(`${urls.server}/api/public/settings`);
      return res?.data;
    } catch (error: any) {
      console.error("Error fetching public settings", error);
      return {};
    }
  };

  const getPrivateSettings = async () => {
    try {
      const res = await AxiosInterceptor.get(`${urls.server}/api/private/settings`);
      return res?.data;
    } catch (error: any) {
      console.error("Error fetching private settings", error);
      return {};
    }
  };

  // const applySettings = (data: any) => {
  //   const root = document.documentElement;
  //   const primaryColor = data.primaryColor ? data.primaryColor : "#e16945";
  //   root?.style.setProperty("--color-primary", primaryColor);
  //   if (data.primaryColor) setPrimaryColor(data.primaryColor);
  //   localStorage.setItem("primaryColor", primaryColor);

  //   let link: any = document.querySelector("link[rel~='icon']");
  //   if (data.favicon) link.href = `${urls.server}${data.favicon}`;
  //   if (data.navigationTabs) {
  //     setNavigationTabs(data.navigationTabs);
  //   }

  //   setStartupMode(data.defaultStartupMode || "dashboard");
  //   setHomePage(data.homePage || "/profile");
  //   //alert(data.defaultStartupMode);
  // };
  const applySettings = (data: any) => {
    const root = document.documentElement;
    const primaryColor = data.primaryColor ? data.primaryColor : "#e16945";
    root?.style.setProperty("--color-primary", primaryColor);
    if (data.primaryColor) setPrimaryColor(data.primaryColor);
    localStorage.setItem("primaryColor", primaryColor);

    let link: any = document.querySelector("link[rel~='icon']");
    if (data.favicon) link.href = `${urls.server}${data.favicon}`;
    if (data.navigationTabs) {
      setNavigationTabs(data.navigationTabs);
    }

    if (data.websiteConfig) {
      localStorage.setItem("websiteConfig", JSON.stringify(data.websiteConfig));
    }

    setStartupMode(data.defaultStartupMode || "dashboard");
    setHomePage(data.homePage || "/profile");
  };

  useEffect(() => {
    const fetchSettings = async () => {
      setLoading(true);
      let publicSettings = {};
      let privateSettings = {};
      if (isAuthenticated) {
        [publicSettings, privateSettings] = await Promise.all([getPublicSettings(), getPrivateSettings()]);
      } else {
        publicSettings = await getPublicSettings();
      }
      const combinedSettings: any = { ...publicSettings, ...privateSettings };
      applySettings(combinedSettings);
      setLoading(false);
    };

    fetchSettings();

    const root = document.documentElement;
    root?.style.setProperty("--color-primary", colors.primary);
    root?.style.setProperty("--color-primary-tint", colors.primaryTint);
    root?.style.setProperty("--color-primary-shade", colors.primaryShade);
    root?.style.setProperty("--color-dark", colors.dark);
    root?.style.setProperty("--color-medium", colors.medium);
    root?.style.setProperty("--color-light", colors.light);
    root?.style.setProperty("--color-text", colors.text);
    root?.style.setProperty("--color-text-shade", colors.textShade);
    root?.style.setProperty("--color-text-tint", colors.textTint);
  }, [isAuthenticated]);

  const theme = React.useMemo(
    () =>
      createTheme({
        shadows: shadows.map(() => "none"),
        palette: {
          text: {
            primary: colors.text,
          },
          background: {
            default: colors.light,
          },
          primary: { main: primaryColor },
          warning: {
            main: colors.danger,
          },
          success: {
            main: colors.success,
          },
        },
      } as any),
    [primaryColor]
  );

  return (
    <ThemeProvider theme={theme}>
      {loading && (
        <div className="spinner-wrapper">
          <Spinner visible={loading} />
        </div>
      )}
      {!loading && (
        <IconProvider>
          <div className="App">
            <Router>
              <Routes>
                <Route
                  path="/"
                  element={
                    <HomePage startupMode={startupMode} homePage={homePage} />
                  }
                />
                <Route
                  path="*"
                  element={
                    isAuthenticated ? (
                      <AuthenticatedRoutes navigationTabs={navigationTabs} />
                    ) : (
                      <Routes>
                        <Route path="/public/:slug" element={<DynamicPage />} />
                        <Route
                          path="/forgot-password"
                          element={<ForgotPasswordPage />}
                        />
                        <Route path="/sign-in" element={<SignInPage />} />
                        <Route path="*" element={<Navigate to="/sign-in" />} />
                      </Routes>
                    )
                  }
                />
              </Routes>

              {isAuthenticated && <TabNavigator options={navigationTabs} />}
            </Router>
          </div>
        </IconProvider>
      )}
    </ThemeProvider>
  );
}

export default App;

