import { useState, useEffect, Fragment } from "react";
import { Button } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";
import "./GridField.scss";
import { FieldEditControls } from "components/FieldEditControls/FieldEditControls";

import { GridInput } from "./GridInput";
import * as ev from "expr-eval";
import Icon from "components/Icon/Icon";

const GridField = (props: any) => {
  let {
    defaultValue,
    editable,
    label,
    editPath,
    onChange,
    name,
    cols,
    //readonly,
    extraData,
    canAddRows = true,
    canRemoveRows = true,
    formData,
    forceMobileView = false,
    mode
  }: any = props;
  const [data, setData] = useState(defaultValue as any);
  const [isEditEnabled, setIsEditEnabled] = useState(false);

  const addRow = () => {
    const output: any = {};
    cols.forEach((col: any) => {
      if (col.defaultValue !== undefined) {
        output[col.name] = col.defaultValue;
      }
    });
    setData((prevData: any) => [...(prevData || []), output]);
  };
  const handleInputChange = (event: any, rowIndex: number, colName: string) => {
    const value = event.target.value;
    setData((prevData: any) => {
      const newData = [...prevData];
      newData[rowIndex][colName] = value;
      return newData;
    });
  };
  const handleDeleteRow = (rowIndex: number) => {
    setData((prevData: any) => {
      const newData = [...prevData];
      newData.splice(rowIndex, 1);
      return newData;
    });
  };

  const moveItemUp = (index: number) => {
    if (index === 0) return;
    setData((prevData: any) => {
      const newData = [...prevData];
      [newData[index - 1], newData[index]] = [newData[index], newData[index - 1]];
      return newData;
    });
  };

  const moveItemDown = (index: number) => {
    if (index === data.length - 1) return;
    setData((prevData: any) => {
      const newData = [...prevData];
      [newData[index + 1], newData[index]] = [newData[index], newData[index + 1]];
      return newData;
    });
  };

  useEffect(() => {
    if (onChange) {
      onChange(data);
    }
  }, [data]);
  const showIf = (exp: any) => {
    return exp ? ev.Parser.evaluate(exp, formData) : true;
  };
  const containerClasses = `grid-field-container ${forceMobileView ? "mobile-view" : ""}`;
  return (
    <div className={containerClasses}>
      <div className="field-header">
        {label && (
          <label>
            <span>{label}</span>
          </label>
        )}
        {editable && (
          <div>
            {(mode == 'edit' && editable) && (
              <FieldEditControls
                value={data}
                fieldName={name}
                editPath={editPath}
                onEditStart={() => {
                  setIsEditEnabled(true);
                }}
                onEditCancel={(originalValue) => {
                  setIsEditEnabled(false);
                  setData(originalValue);
                }}
                onEditEnd={(success: boolean, originalValue) => {
                  setIsEditEnabled(false);
                  if (!success) {
                    setData(originalValue);
                  }
                }}
              />
            )}</div>
        )}
      </div>
      <div className="field-body">
        <div>
          {mode == 'readonly' ||
            (mode == 'edit' && editable && !isEditEnabled) ||
            (mode == 'edit' && !editable) ? (
            <div className="readonly-content">
              {data?.length == 0 ? (
                <span>-</span>
              ) : (
                <div>{JSON.stringify(data)}</div>
              )}
            </div>
          ) : (
            <div className="editable-content">
              {canAddRows && (
                <div className="add-btn-wrapper">
                  <Button onClick={addRow} startIcon={<Icon name="add" />}>
                    Agregar
                  </Button>
                </div>
              )}

              <div className="table-wrapper">
                {data?.length > 0 && (
                  <table>
                    <thead>
                      <tr>
                        {cols.map((col: any, colIndex: number) => (
                          <th
                            style={{ width: (100 / 12) * col.size + "%" }}
                            key={colIndex}
                          >
                            {col.visibleName}
                          </th>
                        ))}
                        <th></th>
                        {/* {canRemoveRows && <th></th>} */}
                      </tr>
                    </thead>
                    <tbody>
                      {data?.map((row: any, rowIndex: number) => (
                        <tr key={rowIndex}>
                          {cols.map((col: any, colIndex: number) => (
                            <Fragment key={colIndex}>
                              {((col.showIf && showIf(col.showIf)) ||
                                !col.showIf) && (
                                  <td
                                    key={colIndex}
                                    data-column-header={col["visibleName"]}
                                  >
                                    <GridInput
                                      setData={setData}
                                      rowIndex={rowIndex}
                                      col={col}
                                      row={row}
                                      handleInputChange={handleInputChange}
                                      extraData={extraData}
                                    />
                                  </td>
                                )}
                            </Fragment>
                          ))}
                          <td>

                            {rowIndex > 0 && (

                              <IconButton
                                aria-label="subir"
                                onClick={() => moveItemUp(rowIndex)}
                                color="primary"
                              >
                                <Icon name="upArrow" />
                              </IconButton>
                            )}
                            {rowIndex < data.length - 1 && (

                              <IconButton
                                aria-label="bajar"
                                onClick={() => moveItemDown(rowIndex)}
                                color="primary"
                              >
                                <Icon name="downArrow" />
                              </IconButton>
                            )}
                            {canRemoveRows && (

                              <IconButton
                                aria-label="delete"
                                onClick={() => handleDeleteRow(rowIndex)}
                                color="error"
                              >
                                <Icon name="delete" />
                              </IconButton>

                            )}
                          </td>

                        </tr>
                      ))}
                    </tbody>
                  </table>
                )}
              </div>
            </div>
          )}
        </div>
      </div>

    </div>
  );
};

export { GridField };
