import React, { useState, useEffect } from "react";
import "./CompletedTasksModal.scss";
import Modal from "components/Modal/Modal";

interface ReportModalProps {
  mode?: 'all' | 'user';
  isOpen: boolean;
  onClose: (refreshOnClose?: boolean) => void;
}
const CompletedTasksModal: React.FC<ReportModalProps> = ({ isOpen, mode, onClose }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [filters, setFilters] = useState([]);
  return (
    <>
      <Modal
        title={"Tareas finalizadas"}
        className={'completed-tasks-modal-container'}
        isOpen={isOpen}
        onClose={onClose}
        hasSearchBar={true}
        filters={filters}
        onSearchChange={(searchTerm: string) => {
          setSearchTerm(searchTerm);
        }}
        onActiveFiltersChange={(filters: any) => {
          setFilters(filters);
        }}

      >

        <div>
        </div>

      </Modal>

    </>
  );
};

export { CompletedTasksModal };
