import React, { useEffect, useState } from "react";

import "./SelectableListModal.scss";
import Modal from "components/Modal/Modal";
import DynamicList from "components/DynamicList/DynamicList";
import ActionButtons from "components/ActionButtons/ActionButtons";
import { FormDialog } from "components/dialogs/FormDialog/FormDialog";

interface ModalProps {
  title: string;
  subtitle: string;
  listPath: string;
  isOpen: boolean;
  hiddenActiveFilters?: any[];
  leftCells?: any[];
  rightCells?: any[];
  noContentText?: string;
  noContentIcon?: string;
  selectedItemActions: any[];
  onClose: (refreshOnClose?: boolean) => void;
}

const SelectableListModal: React.FC<ModalProps> = ({
  isOpen,
  title,
  subtitle,
  listPath,
  hiddenActiveFilters = [],
  leftCells,
  rightCells,
  noContentText,
  noContentIcon,
  selectedItemActions,
  onClose

}) => {
  const [refreshOnClose, setRefreshOnClose] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [shouldRefresh, setShouldRefresh] = useState(true);
  const [activeFilters, setActiveFilters] = useState([] as any);
  const [selectedItems, setSelectedItems] = useState([] as any);
  const [formIsOpen, setFormIsOpen] = useState(false);
  const [currentAction, setCurrentAction] = useState(null as any);
  const handleRefreshComplete = () => {
    setShouldRefresh(false);
  };
  useEffect(() => {
    if (isOpen) {
      setShouldRefresh(true);

    } else {
      setShouldRefresh(false);
      setSelectedItems([]);
    }
  }, [isOpen])
  return (
    <>
      <Modal
        title={title}
        subtitle={subtitle}
        className={'selectable-list-modal-container'}
        isOpen={isOpen}
        onClose={onClose}
        hasSearchBar={true}
        onSearchChange={(searchTerm: string) => {
          setSearchTerm(searchTerm);
        }}
      >
        {
          isOpen &&
          <>
            <div className='list-actions-wrapper'>
              <ActionButtons actions={selectedItemActions} disabled={selectedItems.length == 0} onSelectAction={(action: any) => {
                setCurrentAction(action);
                if (action?.fields && action.fields.length > 0) {
                  setFormIsOpen(true);
                }
              }} />
            </div>

            <DynamicList
              className="data-list-container"
              leftCells={leftCells}
              rightCells={rightCells}
              limit={10}
              listPath={listPath}
              activeFilters={activeFilters}
              hiddenActiveFilters={hiddenActiveFilters}
              noContentText={noContentText}
              noContentIcon={noContentIcon}
              forceRefresh={shouldRefresh}
              onRefreshComplete={handleRefreshComplete}
              searchTerm={searchTerm}
              itemStyle={{

              }}
              selectableItems={true}
              onSelectedItemsChange={(res) => {
                console.log(res)
                setSelectedItems(res);
              }}
            />


          </>

        }

      </Modal>
      <FormDialog
        isOpen={formIsOpen}
        onClose={(data: any) => {
          setFormIsOpen(false);

          if (data) {
            setShouldRefresh(true);
          }
        }}
        title={currentAction?.name}
        size="sm"
        fields={currentAction?.fields}
        createPath={currentAction?.sendPath}
        extraData={{

        }}
        mode={'create'}
      />


    </>
  );
};

export { SelectableListModal };
