import React from "react";
import "./ReadonlyListField.scss";
const ReadonlyListField = ({ data, itemType }: any) => {
  return (
    <div className="readonly-list-field-container">
      <div className="list-field-items">
        {data?.map((item: any, index: number) => {
          switch (itemType) {
            case "text":
              return <div key={index}>{item}</div>;
            default:
              return <div key={index}>{item}</div>;
          }
        })}
      </div>
    </div>
  );
};

export { ReadonlyListField };
