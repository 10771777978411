import React, { useState, useEffect } from "react";
import "./IndicatorGroupExpandedCard.scss";
import { FilesField } from "components/fields/FilesField/FilesField";
import AxiosInterceptor from "../../../../AxiosInterceptor";
import urls from "config/urls";
import { Spinner } from "components/Spinner/Spinner";

const IndicatorGroupExpandedCard = (props: any) => {
  const { title, data, config, id } = props;
  const [loading, setLoading] = useState(false);
  const [sheetData, setSheetData] = useState(null as any);
  const loadIndicatorGroup = async () => {
    try {
      setLoading(true);
      const res = await AxiosInterceptor.get(
        `${urls.server}/api/cards/${id}/indicator-group`
      );
      const resData = res?.data;
      console.log(resData);
      setSheetData(resData);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }

  };
  useEffect(() => {
    loadIndicatorGroup();
  }, []);
  return (
    <div className="indicator-group-expanded-card-container">
      <div className="expanded-card-header">
        <h2>{title}</h2>
      </div>
      <div className="expanded-card-body">
        {loading ? (
          <div className="spinner-wrapper">
            <Spinner visible={loading} />
          </div>
        ) :
          <FilesField
            defaultValue={sheetData?.files}
            mode={"readonly"}
          />
        }
      </div>
    </div>
  );
};

export { IndicatorGroupExpandedCard };
