// components/configurations/VideoConfiguration.tsx
import React from 'react';
import { VideoField } from 'components/fields/VideoField/VideoField';
import SizeField from '../../SizeField/SizeField';

interface VideoConfigurationProps {
    localConfig: any;
    selectedTab: string;
    handleChange: (field: string, value: any) => void;
    getFieldValues: (field: string) => any;
}

const VideoConfiguration: React.FC<VideoConfigurationProps> = ({
    localConfig,
    selectedTab,
    handleChange,
    getFieldValues
}) => {
    return (
        <>
            <div className="action-wrapper">
                <VideoField
                    id={localConfig?.id}
                    label="Video"
                    onChange={(path: any) => handleChange('src', path)}
                    defaultValue={selectedTab === 'default' ? localConfig?.src : localConfig?.responsive?.[selectedTab]?.src || localConfig?.src}
                />
            </div>
            <SizeField
                label="Ancho"
                field="width"
                isEnabled={getFieldValues('width').value !== null}
                value={getFieldValues('width').value}
                unit={getFieldValues('width').unit}
                handleChange={handleChange}
                minValue={0}
            />
            <SizeField
                label="Alto"
                field="height"
                isEnabled={getFieldValues('height').value !== null}
                value={getFieldValues('height').value}
                unit={getFieldValues('height').unit}
                handleChange={handleChange}
                minValue={0}
            />
        </>
    );
};

export default VideoConfiguration;
