import React, { useEffect, useState } from 'react'
import Modal from 'components/Modal/Modal';
import './DataTableModal.scss';
import { Dividers } from 'components/Dividers/Dividers';
import { DynamicTable } from 'components/DynamicTable/DynamicTable';
import { FormModal } from '../FormModal/FormModal';
import interpolateString from 'utils/interpolateString';
import { Fab } from "@mui/material";
import Icon from 'components/Icon/Icon';
interface DataTableModalProps {
  title: string;
  listPath: string;
  createPath: string;
  formTitle: string;
  fields: any[];
  isOpen: boolean;
  cols: any[];
  dividerGroups?: any[];
  filters?: any[];
  rowActions?: any[];
  moreRowActions?: any[];
  hiddenActiveFilters?: any[];
  onSelectRowAction?: (actionName: string, recordId: number) => void;
  //onSelectMoreRowAction?: (actionName: string, recordId: number) => void;
  onClose: (refreshOnClose?: boolean) => void;
}

const DataTableModal: React.FC<DataTableModalProps> = ({
  isOpen,
  title,
  formTitle,
  listPath,
  createPath,
  dividerGroups = [],
  cols = [],
  filters = [],
  hiddenActiveFilters = [],
  fields = [],
  rowActions = [],
  moreRowActions = [],
  onSelectRowAction,
  //onSelectMoreRowAction,
  onClose
}) => {
  const [editFormModalIsOpen, setEditFormModalIsOpen] = useState(false);
  const [createFormModalIsOpen, setCreateFormModalIsOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [activeFilters, setActiveFilters] = useState([] as any);
  const [activeTabFilters, setActiveTabFilters] = useState([]);
  const [shouldRefresh, setShouldRefresh] = useState(true);
  const [refreshOnClose, setRefreshOnClose] = useState(false);
  const [viewPath, setViewPath] = useState(null as any);
  const handleRefreshComplete = () => {
    setShouldRefresh(false);
  };
  const handleSelectItemAction = ({ actionName, currentRow, config }: any) => {
    if (onSelectRowAction) {
      onSelectRowAction(actionName, currentRow);
    }
    if (actionName == 'view') {
      setEditFormModalIsOpen(true);

      setViewPath(interpolateString(currentRow, config?.viewPath));
    }
  }
  useEffect(() => {
    if (isOpen) {
      setShouldRefresh(true);
    } else {
      setShouldRefresh(false);
    }
  }, [isOpen]);
  return (
    <>
      <Modal
        title={title}
        className={'data-table-modal-container'}
        isOpen={isOpen}
        onClose={() => { onClose(refreshOnClose) }}
        hasSearchBar={true}
        filters={filters}
        onSearchChange={(searchTerm: string) => {
          setSearchTerm(searchTerm);
        }}
        onActiveFiltersChange={(res) => {
          setActiveFilters(res);
        }}
      >
        <div>
          {isOpen && dividerGroups && dividerGroups.length > 0 && (
            <Dividers
              groups={dividerGroups}
              onChange={(res: any) => {
                setActiveTabFilters(res)
              }}
            />
          )}
          {isOpen && (
            <>
              <DynamicTable
                listPath={listPath}
                cols={cols}
                activeTabFilters={activeTabFilters}
                activeFilters={activeFilters}
                hiddenActiveFilters={hiddenActiveFilters}
                searchTerm={searchTerm}
                rowActions={rowActions}
                moreRowActions={moreRowActions}
                onDeleteRowSuccess={() => {
                  setRefreshOnClose(true)
                }}
                onSelectRowAction={handleSelectItemAction}
                onSelectMoreRowAction={handleSelectItemAction}
                forceRefresh={shouldRefresh}
                onRefreshComplete={handleRefreshComplete}

              />
              <Fab
                color="primary"
                aria-label="add"
                sx={{
                  position: "fixed",
                  bottom: 16,
                  right: 16,
                }}
                onClick={() => {
                  setCreateFormModalIsOpen(true);
                }}

              >
                <Icon name="add" style={{ marginRight: 0 }} />
              </Fab>
            </>

          )}
        </div>
        <FormModal
          isOpen={editFormModalIsOpen}
          onClose={(res: any) => {
            setEditFormModalIsOpen(false)
            if (res) {
              setShouldRefresh(true)
            } else {
              setShouldRefresh(false)
            }
          }}
          title={formTitle}
          fields={fields}
          viewPath={viewPath}
          mode="edit"
        />
        <FormModal
          isOpen={createFormModalIsOpen}
          onClose={(res: any) => {

            setCreateFormModalIsOpen(false)
            if (res) {
              setShouldRefresh(true)
              setRefreshOnClose(true);
              console.log(res);
            } else {
              setShouldRefresh(false)
            }
          }}
          title={formTitle}
          fields={fields}
          createPath={createPath}
          mode="create"
        />
      </Modal>

    </>

  )
}

export { DataTableModal }