const OPERATORS_BY_TYPE: any = {
    date: [
        { name: "equal", visibleName: "Igual a" },
        { name: "greaterThan", visibleName: "Mayor que" },
        { name: "greaterThanOrEqual", visibleName: "Mayor o igual que" },
        { name: "lessThan", visibleName: "Menor que" },
        { name: "lessThanOrEqual", visibleName: "Menor o igual que" },
        { name: "between", visibleName: "Entre" },
        { name: "isNull", visibleName: "Es nulo" },
        { name: "isNotNull", visibleName: "No es nulo" }
    ],
    text: [
        { name: "equal", visibleName: "Igual a" },
        { name: "notEqual", visibleName: "Diferente de" },
        { name: "like", visibleName: "Coincide parcialmente" },
        { name: "notLike", visibleName: "No coincide parcialmente" },
        { name: "isNull", visibleName: "Es nulo" },
        { name: "isNotNull", visibleName: "No es nulo" }
    ],
    number: [
        { name: "equal", visibleName: "Igual a" },
        { name: "notEqual", visibleName: "Diferente de" },
        { name: "greaterThan", visibleName: "Mayor que" },
        { name: "greaterThanOrEqual", visibleName: "Mayor o igual que" },
        { name: "lessThan", visibleName: "Menor que" },
        { name: "lessThanOrEqual", visibleName: "Menor o igual que" },
        { name: "between", visibleName: "Entre" },
        { name: "isNull", visibleName: "Es nulo" },
        { name: "isNotNull", visibleName: "No es nulo" }
    ],
    boolean: [
        { name: "equal", visibleName: "Igual a" },
        { name: "notEqual", visibleName: "Diferente de" },
        { name: "isNull", visibleName: "Es nulo" },
        { name: "isNotNull", visibleName: "No es nulo" }
    ]
};

export const prepareFilterConfigurations = (inputFilters: any) => {
    return inputFilters?.map((filter: any) => {
        const operators = OPERATORS_BY_TYPE[filter.type] || [];
        return {
            ...filter,
            operator: operators.length > 0 ? operators[0].name : "equal",
            value: null,
            active: false,
            availableOperators: operators
        };
    });
}
