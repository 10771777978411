import { useState } from "react";
import AxiosInterceptor from "../AxiosInterceptor";
import urls from "config/urls";

type SortProps = {
  sortedData: any;
  sortPath: String;
};
export const useSortItems = ({ sortPath }: SortProps) => {
  const baseUrl = `${urls.server}/api/${sortPath}`;
  const [processing, setProcessing] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [alertMsg, setAlertMsg] = useState("");
  const [severity, setSeverity] = useState("success");

  const sortItems = async (sortedData: any) => {
    setProcessing(true);
    try {
      const data = {
        data: sortedData,
      };
      const res = await AxiosInterceptor.post(`${baseUrl}`, data);
      if (res === undefined) {
        setOpenAlert(true);
        setSeverity("error");
        setAlertMsg("Ha ocurrido un error en el servidor.");
      }
      setProcessing(false);
    } catch (error: any) {
      setProcessing(false);
      setOpenAlert(true);
      setSeverity("error");
      setAlertMsg(error.toString());
    }
  };
  return {
    sortItems,
    openAlert,
    setOpenAlert,
    processing,
    alertMsg,
    severity,
  };
};
