import React from 'react'
import './Paper.scss';
interface PaperProps {
    title?: string;
    children: React.ReactNode;
    size?: 'md' | 'sm';
}
const Paper: React.FC<PaperProps> = ({ children, title, size = 'md' }) => {
    return (
        <div className={`paper-container ${size}`}>
            <span></span>
            <span></span>
            <div className='paper-header'>
                {
                    title && <div className='paper-header-title'>{title}</div>
                }
            </div>
            <div className='paper-body'>
                {children}
            </div>
        </div>
    )
}


export default Paper