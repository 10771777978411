import React, { useEffect } from 'react';

interface T1Props {
    textContent: string;
    selected: boolean;
    onChange: (text: string) => void;
    style: React.CSSProperties;
    //commonProps: any;
}

const T1: React.FC<T1Props> = ({ textContent, selected, onChange, style,

}) => {
    useEffect(() => {
        //console.log('style', style)
    }, [style])
    const handleTextareaClick = (e: React.MouseEvent) => {
        e.stopPropagation();
    };

    return selected ? (
        <textarea
            autoFocus
            value={textContent}
            onChange={(e) => onChange(e.target.value)}
            onClick={handleTextareaClick}

            style={{ ...style, resize: 'none', border: 'none', outline: 'none' }}
        />
    ) : (
        <h1 style={{ ...style, lineHeight: 1 }}>{textContent}</h1>

    );
};

export default T1;
