import { useEffect, useState } from "react";
import AxiosInterceptor from "../../../../AxiosInterceptor";
import { InputField } from "components/fields/InputField/InputField";

import { Grid, Box } from "@mui/material";
import "./SettingsExpandedCard.scss";
import { ImageField } from "components/fields/ImageField/ImageField";
import { ColorField } from "components/fields/ColorField/ColorField";
import { Spinner } from "components/Spinner/Spinner";
import urls from "config/urls";
import ExpandableSection from "components/ExpandableSection/ExpandableSection";
import { ImageRadioField } from "components/fields/ImageRadioField/ImageRadioField";
import { CheckboxField } from "components/fields/CheckboxField/CheckboxField";
import { RadioField } from "components/fields/RadioField/RadioField";

const SettingsExpandedCard = (props: any) => {
  const { title } = props;
  const [data, setData] = useState({} as any);
  const [processing, setProcessing] = useState(false);
  const getData = async () => {
    setProcessing(true);
    try {
      const res = await AxiosInterceptor.get(`${urls.server}/api/settings/get`);
      const data = res?.data;
      console.log(data);
      setData(data);
      setProcessing(false);
    } catch (error: any) {
      console.error(error);
    }
  };
  useEffect(() => {
    getData();
  }, []);
  const footerAnimations = [
    {
      name: 'salta',
      visibleName: 'Salta',
      imageUrl: 'assets/footer-images/salta.png',
      showInModes: ['edit']
    },
    {
      name: 'cafayate',
      visibleName: 'Cafayate',
      imageUrl: 'assets/footer-images/cafayate.png',
      showInModes: ['edit']
    },

    {
      name: 'cachi',
      visibleName: 'Cachi',
      imageUrl: 'assets/footer-images/cachi.png',
      showInModes: ['edit']
    },
    {
      name: 'iruya',
      visibleName: 'Iruya',
      imageUrl: 'assets/footer-images/iruya.png',
      showInModes: ['edit']
    },
    {
      name: 'office',
      visibleName: 'Oficina',
      imageUrl: 'assets/footer-images/office.png',
      showInModes: ['edit']
    },
    {
      name: 'hospital',
      visibleName: 'Hospital',
      imageUrl: 'assets/footer-images/hospital.png',
      showInModes: ['edit']
    },
    {
      name: 'school',
      visibleName: 'Escuela',
      imageUrl: 'assets/footer-images/school.png',
      showInModes: ['edit']
    },
    {
      name: 'chart',
      visibleName: 'Gráfico',
      imageUrl: 'assets/footer-images/chart.png',
      showInModes: ['edit']
    },
    {
      name: 'potholedStreet',
      visibleName: 'Calle con baches',
      imageUrl: 'assets/footer-images/potholed-street.png',
      showInModes: ['edit']
    },

  ]
  return (
    <div className="settings-expanded-card-container">
      <div className="expanded-card-header">
        <h2>{title}</h2>
      </div>
      {processing ? (
        <div className="spinner-wrapper">
          <Spinner visible={processing} />
        </div>
      ) : (
        <div className="expanded-card-body">
          <ExpandableSection title="General" collapsedIconDirection="right">
            <Grid container spacing={2} style={{ marginTop: 20 }}>
              <Grid item xs={12} md={4}>
                <InputField
                  name="project-name"

                  defaultValue={data?.name}
                  label={"Nombre del projecto"}
                  editable={true}
                  editPath={"settings/update-name"}
                  mode={"edit"}
                  onEdit={(value: string) => {

                  }}
                //editable
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <InputField
                  name="project-visible-name"
                  defaultValue={data?.visibleName}
                  label={"Nombre a mostrar"}
                  editable={true}
                  editPath={"settings/update-visible-name"}
                  mode={"edit"}
                  onEdit={(value: string) => {

                  }}
                //editable
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <InputField
                  name="logo-width"
                  defaultValue={data?.logoWidth}
                  label={"Ancho del logo"}
                  editable={true}
                  editPath={"settings/update-logo-width"}
                  mode={"edit"}
                  onEdit={(value: string) => {

                  }}

                />
              </Grid>
              <Grid item xs={12} md={6}>
                <ImageField
                  name="project-logo"
                  defaultValue={data?.logo}
                  label={"Logo"}
                  editable={true}
                  editPath={"settings/update-logo"}
                  onChange={(value: string) => {
                    console.log("imagen", value);
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <ImageField
                  name="project-favicon"
                  defaultValue={data?.favicon}
                  label={"Favicon"}
                  editable={true}
                  editPath={"settings/update-favicon"}
                  accept=".png"
                  onChange={(value: string) => {
                    console.log("imagen", value);
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <ColorField
                  name={"primary-color"}
                  defaultValue={data?.primaryColor}
                  label={"Color principal"}
                  editable={true}
                  editPath={"settings/update-primary-color"}
                  onEdit={(value: string) => {
                    console.log("color", value);
                  }}
                  onChange={(value: string) => {
                    console.log("color", value);
                  }}
                  disablePrimary={true}
                />
              </Grid>
            </Grid>
          </ExpandableSection>
          <ExpandableSection title="Animaciones" collapsedIconDirection="right">
            <div style={{ marginTop: 10 }}>
              <ImageRadioField
                name="sign-in-animation"
                defaultValue={data?.signInAnimation}
                options={footerAnimations}
                label={"Animación del login"}
                editable={true}
                editPath={"settings/update-sign-in-animation"}
                mode={"edit"}
              />
            </div>
            <div style={{ marginTop: 10 }}>
              <ImageRadioField
                name="sign-up-animation"
                defaultValue={data?.signUpAnimation}
                options={footerAnimations}
                label={"Animación del registro"}
                editable={true}
                editPath={"settings/update-sign-up-animation"}
                mode={"edit"}
              />
            </div>
            <div style={{ marginTop: 10 }}>
              <ImageRadioField
                name="forgot-password-animation"
                defaultValue={data?.forgotPasswordAnimation}
                options={footerAnimations}
                label={"Animación del recupero de contra."}
                editable={true}
                editPath={"settings/update-forgot-password-animation"}
                mode={"edit"}
              />
            </div>
          </ExpandableSection>
          <ExpandableSection title="Inicio por defecto" collapsedIconDirection="right">
            <Grid container spacing={2} style={{ marginTop: 10 }}>
              <Grid item xs={12} md={6}>
                <RadioField
                  name="default-startup-mode"
                  defaultValue={data?.defaultStartupMode}
                  label={"Mode de inicio"}
                  editable={true}
                  editPath={"settings/update-default-startup-mode"}
                  mode={"edit"}
                  options={[
                    {
                      name: "publicPages",
                      visibleName: "Páginas públicas"
                    },
                    {
                      name: "dashboard",
                      visibleName: "Dashboard"
                    },
                  ]}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <InputField
                  name="home-page"
                  defaultValue={data?.homePage}
                  label={"Página de inicio"}
                  editable={true}
                  editPath={"settings/update-home-page"}
                  mode={"edit"}
                  onEdit={(value: string) => {

                  }}
                //editable
                />
              </Grid>
            </Grid>
          </ExpandableSection>
          <ExpandableSection title="Ojo!" collapsedIconDirection="right">
            <Grid container spacing={2} style={{ marginTop: 10 }}>
              <Grid item xs={12} md={6}>
                <CheckboxField
                  defaultValue={data?.enableSignUpPage}
                  label={"Habilitar registro de usuarios"}
                  editable={true}
                  editPath={"settings/update-enable-sign-up-page"}
                  mode={"edit"}
                />
              </Grid>
            </Grid>
          </ExpandableSection>
        </div>
      )}
    </div>
  );
};

export { SettingsExpandedCard };
