import React from "react";

import colors from "../../../config/colors";
import Icon from "../../Icon/Icon";
import './CardButton.scss';
interface CardButtonProps {
  title: string;
  icon?: string;
  footerIcon?: string;
  textColor?: string;
  iconColor?: string;
  footerIconColor?: string;
  disabled?: boolean;
  backgroundColor?: string;
  size?: 'lg' | 'md' | 'sm';
  onPress: () => void;
}

const CardButton: React.FC<CardButtonProps> = ({
  title,
  icon,
  footerIcon,
  //iconColor = colors.primary,
  //footerIconColor = colors.primary,
  iconColor = localStorage.getItem("primaryColor"),
  footerIconColor = localStorage.getItem("primaryColor"),
  textColor = colors.text,
  disabled = false,
  backgroundColor = '#fff',
  size = 'lg',
  onPress,
}) => {

  return (
    <a className={`card-button-container ${size}`} style={{ backgroundColor: backgroundColor }} onClick={onPress}>
      {
        icon && <Icon name={icon} color={iconColor} />
      }
      <strong>{title}</strong>
      {
        footerIcon &&
        <div>
          <Icon name={`${footerIcon}Wrapper`} color={backgroundColor} />
          <Icon name={footerIcon} color={footerIconColor} />
        </div>
      }

    </a>
  );
};

export default CardButton;
