import React, { useState, useEffect } from "react";
import { Button } from "@mui/material";
import { SelectModal } from "../../../modals/SelectModal/SelectModal";
import Icon from "components/Icon/Icon";

const ValueAddedTaxPicker = ({ value, onChange }: any) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [data, setData] = useState(value);
  const fields = [
    {
      type: "text",
      visibleName: "Nombre",
      name: "name",
      description: null,
      size: 6,
      editable: null,
      editableIf: null,
      validations: {},
    },
    {
      type: "decimal",
      visibleName: "Alícuota",
      name: "rate",
      description: null,
      append: "%",
      size: 6,
      editable: null,
      editableIf: null,
      validations: {},
    },
  ];
  useEffect(() => {
    onChange(data);
  }, [data]);
  return (
    <>
      {data ? (
        // <a
        //   onClick={() => {
        //     setData(null);
        //   }}
        // >
        //   {data.name}
        //   <Icon name="close" />
        // </a>
        <Button
          onClick={() => {
            setData(null);
          }}
          endIcon={<Icon name="close" />}
        >
          {data.name}
        </Button>
      ) : (
        // <a
        //   onClick={() => {
        //     setModalIsOpen(true);
        //   }}
        // >
        //   Agregar
        //   <Icon name="add" />
        // </a>
        <Button
          onClick={() => {
            setModalIsOpen(true);
          }}
          startIcon={<Icon name="add" />}
        >
          Agregar
        </Button>
      )}
      <SelectModal
        isOpen={modalIsOpen}
        title="Seleccionar impuesto"
        listPath="value-added-taxes"
        formTitle="Ficha de impuesto"
        formFields={fields}
        primaryKey="name"
        filters={[]}
        //secondaryKey="tin"
        onClose={(data: any) => {
          setModalIsOpen(false)
          console.log("data", data);
          if (data) {
            setData(data);
          }
        }}
      />
    </>
  );
};

export { ValueAddedTaxPicker };
