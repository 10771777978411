import React, { useState } from "react";
import {
  IconButton,
  Popover,
} from "@mui/material";
import urls from "config/urls";
import AxiosInterceptor from "../../../../AxiosInterceptor";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { WorkflowEntryDetailModal } from "../WorkflowEntryDetailModal/WorkflowEntryDetailModal";
import DeleteConfirmationDialog from "components/dialogs/DeleteConfirmationDialog/DeleteConfirmationDialog";
import { StepperCell } from "components/cells/StepperCell/StepperCell";
import "./WorkflowEntryListItem.scss";
import Icon from "components/Icon/Icon";

const WorkflowEntryListItem = ({
  data,
  onDelete,
  onChange,
  onSelectPopoverItem,
  resetState,
}: any) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [openAlert, setOpenAlert] = useState(false);
  const openPopover = Boolean(anchorEl);
  const [confirmDialogIsOpen, setConfirmDialogIsOpen] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [workflowEntryDetailModalIsOpen, setWorkflowEntryDetailModalIsOpen] =
    useState(false);
  const presentPopover = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const closeConfirmDialog = () => {
    setConfirmDialogIsOpen(false);
  };
  const closePopover = () => {
    setAnchorEl(null);
  };
  const closeAlert = () => {
    setOpenAlert(false);
  };
  const removeRecord = async () => {
    setProcessing(true);
    const res = await AxiosInterceptor.delete(
      `${urls.server}/api/workflow-entries/${data?.id}`
    );
    console.log(res);
    onDelete(true);
    setProcessing(false);
    closeAlert();
    closePopover();
    //setRefreshOnClose(true);
  };
  const lockRecord = async () => {
    setProcessing(true);
    const res = await AxiosInterceptor.put(
      `${urls.server}/api/workflow-entries/${data?.id}/lock`
    );
    setProcessing(false);
    closePopover();
    onChange(true);
    //setRefreshOnClose(true);
  };
  const unlockRecord = async () => {
    setProcessing(true);
    const res = await AxiosInterceptor.put(
      `${urls.server}/api/workflow-entries/${data?.id}/unlock`
    );
    setProcessing(false);
    closePopover();
    onChange(true);
    //setRefreshOnClose(true);
  };
  return (
    <div className="workflow-entry-list-item-container">
      <div>
        <div>
          <span>{data?.visible_id}</span>
          <div>
            <span>Workflow:</span>
            <strong>{data?.workflow_visible_name}</strong>
          </div>
          <div>
            <div dangerouslySetInnerHTML={{ __html: data?.description }} />
          </div>
        </div>
      </div>
      <div>
        <StepperCell steps={data?.steps} stepsPerGroup={2} />
        <div className="item-btns">
          <IconButton aria-label="delete" onClick={presentPopover}>
            <MoreVertIcon />
          </IconButton>
          <Popover
            id={"workflow-popover-" + data?.id}
            open={openPopover}
            anchorEl={anchorEl}
            onClose={closePopover}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          >
            <div className="popover-items">
              <a
                onClick={() => {
                  setWorkflowEntryDetailModalIsOpen(true);
                  setAnchorEl(null);
                }}
              >
                Ver
              </a>
              {data?.can_lock && (
                <a onClick={data.is_locked ? unlockRecord : lockRecord}>
                  {data.is_locked ? "Desbloquear" : "Bloquear"}
                </a>
              )}
              {!data?.is_locked && (
                <a
                  onClick={() => {
                    setConfirmDialogIsOpen(true);
                    setAnchorEl(null);
                  }}
                >
                  {/** deletePath -> workflow-step-entries/{data.id} */}
                  Eliminar
                </a>
              )}
            </div>
          </Popover>
          <DeleteConfirmationDialog
            open={confirmDialogIsOpen}
            onClose={() => setConfirmDialogIsOpen(false)}
            onConfirm={closeConfirmDialog}
            data={data}
            resetState={resetState}
            deletePath={`workflow-step-entries/${data.id}`}
            confirmationText={
              <>
                Desea eliminar la entrada <b>{data?.visible_id}</b>
              </>
            }
          />

          <WorkflowEntryDetailModal
            isOpen={workflowEntryDetailModalIsOpen}
            onClose={(res: any) => {
              setWorkflowEntryDetailModalIsOpen(false)
              if (res) onChange(true);
            }}
            workflowEntryId={data?.id}
          />
        </div>
      </div>
      {data?.is_locked ? (
        <span>
          <Icon name="locked" /> Entrada bloqueada
        </span>
      ) : (
        <></>
      )}
    </div>
  );
};

export { WorkflowEntryListItem };
