import React, { useState, useEffect } from "react";
import './NotFoundPage.scss';

const NotFoundPage = () => {
  const [showContent, setShowContent] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowContent(true);
    }, 2000); // 2 segundos

    return () => clearTimeout(timer);
  }, []);

  return (
    <div className="not-found-page-container">
      {showContent ? (
        <>
          <h1>404 Not Found</h1>
          <p>The page you are looking for does not exist.</p>
        </>
      ) : null}
    </div>
  );
};

export { NotFoundPage };

