import { useState, useEffect, useRef } from "react";
import AxiosInterceptor from "../../../AxiosInterceptor";
import urls from "config/urls";
import { IconButton } from "@mui/material";
import "./FilesField.scss";
import { Spinner } from "components/Spinner/Spinner";
import { FieldEditControls } from "components/FieldEditControls/FieldEditControls";
import Icon from "components/Icon/Icon";
import { ImageViewerModal } from "components/modals/ImageViewerModal/ImageViewerModal";
import { PDFViewerModal } from "components/modals/PDFViewerModal/PDFViewerModal";

const FilesField = (props: any) => {
  let {
    editable,
    label,
    editPath,
    onChange,
    name,
    accept = "*",
    id,
    maxItems,
    defaultValue,
    folder = "files",
    //readonly,
    mode,
  }: any = props;
  const [value, setValue] = useState(defaultValue as any);
  const [imageViewerModalIsOpen, setImageViewerModalIsOpen] = useState(false);
  const [pdfViewerModalIsOpen, setPdfViewerModalIsOpen] = useState(false);
  const [isEditEnabled, setIsEditEnabled] = useState(false);
  const [queuedFiles, setQueuedFiles] = useState([] as any);
  const [currentItem, setCurrentItem] = useState(null as any);
  const fileInputRef = useRef(null);
  useEffect(() => {
    if (onChange) {
      console.log("value", value);
      onChange(value);
    }
  }, [value]);
  useEffect(() => {
    console.log(defaultValue);
    setValue(defaultValue);
  }, [defaultValue]);

  const handleChange = async (e: any, value: any) => {
    var reader = new FileReader();
    const file: File = e.target.files[0];
    const extension = file?.name.split(".").pop();
    const fileSize = file?.size;  // Tamaño del archivo en bytes
    const fileName = file?.name.substring(0, file.name.lastIndexOf('.'));
    reader.readAsDataURL(file);
    reader.onload = await function () {
      const data = {
        content: reader.result,
        extension,
        size: fileSize,
        name: fileName,
        folder: folder,
      };
      saveFile(value, data);
    };
    reader.onerror = function (error) {
      console.log("Error: ", error);
    };
    // Resetear el valor del input de archivo
    e.target.value = null;
  };
  const removeFile = (index: number) => {
    const valueCopy = [...value];
    valueCopy.splice(index, 1);
    setValue(valueCopy);
  };

  const saveFile = async (value: any, { content, extension, folder, size, name }: any) => {
    const queuedFilesCopy = [...queuedFiles];
    queuedFilesCopy.push("");
    setQueuedFiles(queuedFilesCopy);
    try {
      const res = await AxiosInterceptor.post(`${urls.server}/api/files`, {
        file_content: content,
        extension,
        folder,
      });
      const resData = await res?.data;

      const valueCopy = value !== null ? [...value] : [];
      //valueCopy.push(resData?.path);
      valueCopy.push({
        size: size,
        name: name,
        extension: extension,
        path: resData?.path
      });
      setValue(valueCopy);
      const queuedFilesCopy = [...queuedFiles];
      queuedFilesCopy.pop();
      setQueuedFiles(queuedFilesCopy);
    } catch (error: any) {
      const queuedFilesCopy = [...queuedFiles];
      queuedFilesCopy.pop();
      setQueuedFiles(queuedFilesCopy);
    }
  };
  useEffect(() => {
    if (currentItem) {
      const extension = currentItem?.path.split('.').pop();
      const imageExtensions = ['jpg', 'jpeg', 'png', 'gif', 'bmp'];
      const isImage = imageExtensions.includes(extension?.toLowerCase());
      if (extension == 'pdf') {
        setPdfViewerModalIsOpen(true);
      } else if (isImage) {
        setImageViewerModalIsOpen(true);
      }
    }

  }, [currentItem]);
  const downloadFile = async (item: any) => {
    try {
      const response = await AxiosInterceptor.get(`${urls.server}/api/files/download`, {
        params: { path: item?.path },
        responseType: 'blob',
      });

      const blob = new Blob([response.data], { type: response.data.type });
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = item?.name || 'download';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(link.href);
    } catch (error) {
      console.error('Error downloading the file:', error);
    }
  };

  return (
    <div className="files-field-container field-container">
      <div className="field-header">
        {label && (
          <label>
            <span>{label}</span>
          </label>
        )}
        {editable && (
          <div>
            {(mode == 'edit' && editable) && (
              <FieldEditControls
                value={value}
                fieldName={name}
                editPath={editPath}
                onEditStart={() => {
                  setIsEditEnabled(true);
                }}
                onEditCancel={(originalValue) => {
                  setIsEditEnabled(false);
                  setValue(originalValue);
                }}
                onEditEnd={(success: boolean, originalValue) => {
                  setIsEditEnabled(false);
                  if (!success) {
                    setValue(originalValue);
                  }
                }}
              />
            )}</div>
        )}
      </div>
      <div className="field-body">
        {mode == 'readonly' ||
          (mode == 'edit' && editable && !isEditEnabled) ||
          (mode == 'edit' && !editable) ? (
          <div className="readonly-content">
            {value && value.length > 0 ? (
              value.map((item: any, index: number) => {
                return (
                  <div key={index} className="file-preview-wrapper">
                    <a onClick={() => {
                      setCurrentItem(item)
                    }}>
                      <div><b>Nombre: </b>{item.name}</div>
                      <div><b>Tamaño: </b>{item.size}</div>
                      <div><b>Extensión: </b>{item.extension}</div>
                    </a>
                    <div className="btns-wrapper">

                      <IconButton
                        aria-label="download"
                        onClick={() => downloadFile(item)}
                      >
                        <Icon name="download" />
                      </IconButton>
                    </div>
                  </div>
                );
              })
            ) : (
              <div className="no-value">
                <Icon name="files" />
                <span>Sin archivos</span>
              </div>
            )}
          </div>
        ) : (
          <div className="editable-content">

            {((maxItems && value?.length < maxItems) || maxItems == null) && (
              <div className="add-file-btn">
                <input
                  id={id}
                  accept={accept}
                  type="file"
                  onChange={(e) => handleChange(e, value)}
                  ref={fileInputRef}
                />
                <div>
                  <div>
                    <Icon name="file" />
                    <span>Seleccionar archivo</span>
                  </div>
                  <label htmlFor={id}></label>
                </div>
              </div>
            )}

            {queuedFiles.length > 0 && (
              <div className="spinner-wrapper">
                <Spinner size="sm" />
              </div>
            )}

            {value?.length > 0 && (
              <>
                {value.map((item: any, index: number) => {
                  return (
                    <div key={index} className="file-preview-wrapper" >
                      <a onClick={() => {
                        setCurrentItem(item)
                      }}>
                        <div><b>Nombre: </b>{item.name}</div>
                        <div><b>Tamaño: </b>{item.size}</div>
                        <div><b>Extensión: </b>{item.extension}</div>
                      </a>
                      <div className="btns-wrapper">
                        <IconButton
                          aria-label="delete"
                          onClick={() => removeFile(index)}
                        >
                          <Icon name="delete" />
                        </IconButton>
                        <IconButton
                          aria-label="download"
                          onClick={() => downloadFile(item)}
                        >
                          <Icon name="download" />
                        </IconButton>
                      </div>

                    </div>
                  );
                })}
              </>
            )}
          </div>
        )}
      </div>
      <ImageViewerModal
        isOpen={imageViewerModalIsOpen}
        onClose={() => {
          setImageViewerModalIsOpen(false);
          setCurrentItem(null)
        }}
        title={currentItem?.name}
        fileUrl={`${urls.server}/storage/${currentItem?.path}`}
      />
      <PDFViewerModal
        isOpen={pdfViewerModalIsOpen}
        onClose={() => {
          setPdfViewerModalIsOpen(false);
          setCurrentItem(null)
        }}
        title={currentItem?.name}
        fileUrl={`${urls.server}/storage/${currentItem?.path}`}
      />
    </div>
  );
};

export { FilesField };
