import React, { useState, useEffect } from "react";
import IconButton from "@mui/material/IconButton";
import ArrowCircleRightIcon from "@mui/icons-material/ArrowCircleRight";
import "./EditableSimplePaymentField.scss";
import { formatNumber } from "../../../../utils/formatNumber";
const EditableSimplePaymentField = ({
  defaultData,
  totalDueKey = "total_due",
  formData,
  onChange,
}: any) => {
  const [data, setData] = useState((defaultData as any) || ([] as any));
  const calculateTotalPaid = () => {
    if (Array.isArray(data.paymentMethods) && data.paymentMethods.length > 0) {
      // Calcular totalPaid sumando los amount de data.paymentMethods
      const totalPaid = data.paymentMethods.reduce(
        (total: number, current: any) => {
          // Asegurarse de que current.amount es un número
          const amount = parseFloat(current.amount) || 0;
          return total + amount;
        },
        0
      );

      // Actualizar data con el nuevo valor de totalPaid
      setData((prevData: any) => ({
        ...prevData,
        totalPaid: totalPaid,
      }));
    }
  };
  useEffect(() => {
    if (onChange) {
      onChange(data);
    }
  }, [data]);

  return (
    <div className="editable-simple-payment-field-container">
      {
        formData[totalDueKey] &&
        <div>
          <div className="total-due-wrapper">
            <strong>Total adeudado: </strong>$ {formatNumber(formData[totalDueKey])}
          </div>
          <div className="payment-methods-wrapper">
            {data?.paymentMethods?.map((item: any, index: number) => {
              return (
                <div key={index}>
                  <span>{item.visibleName}</span>
                  <div>
                    <IconButton
                      aria-label="pay"
                      onClick={() => {
                        const dataCopy: any = { ...data };
                        dataCopy?.paymentMethods.forEach((item: any) => {
                          item.amount = 0;
                        });
                        const amount = Math.round(formData[totalDueKey] * 100) / 100;
                        dataCopy.paymentMethods[index].amount = amount;
                        setData(dataCopy);
                        calculateTotalPaid();
                      }}
                    >
                      <ArrowCircleRightIcon />
                    </IconButton>
                    <div className="input-group">
                      <span>$</span>
                      <input
                        className="ta-r"
                        value={item.amount}
                        onChange={(e) => {
                          const dataCopy: any = { ...data };
                          const value: any = e.target.value;

                          dataCopy.paymentMethods[index].amount = value;
                          setData(dataCopy);
                          calculateTotalPaid();
                        }}
                      />
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
          <div className="total-paid-wrapper">
            <span>Total cobrado</span>{" "}
            <strong>$ {formatNumber(data.totalPaid)}</strong>
          </div>
        </div>
      }

    </div>
  );
};

export { EditableSimplePaymentField };
