import { useEffect, useState } from "react";
import { FormModal } from "../../../../components/modals/FormModal/FormModal";

import { DeletedRecordsModal } from "../../../../components/modals/DeletedRecordsModal/DeletedRecordsModal";
import { Spinner } from "components/Spinner/Spinner";
import "./DataTableExpandedCard.scss";

import { TagsModal } from "components/modals/TagsModal/TagsModal";
import { DataTableModal } from "components/modals/DataTableModal/DataTableModal";
import Icon from "components/Icon/Icon";



const DataTableExpandedCard = (props: any) => {
  const { title, config, setRefreshOnClose } = props;
  const [formModalIsOpen, setFormModalIsOpen] = useState(false);
  const [recordsModalIsOpen, setRecordsModalIsOpen] = useState(false);
  const [deletedRecordsModalIsOpen, setDeletedRecordsModalIsOpen] =
    useState(false);
  const [tagsModalIsOpen, setTagsModalIsOpen] = useState(false);
  // const [formTitle, setFormTitle] = useState("");
  // const [tableTitle, setTableTitle] = useState("");
  // const [fields, setFields] = useState([]);
  // const [cols, setCols] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {

  }, [])

  return (
    <>
      <div className="data-table-expanded-card-container">
        <div className="expanded-card-header">
          <h2>{title}</h2>
        </div>
        {loading ? (
          <div className="spinner-wrapper">
            <Spinner visible={loading} />
          </div>
        ) :
          <div className="expanded-card-body">
            <a
              className="card-btn lg"
              onClick={() => {
                setFormModalIsOpen(true);
              }}
            >
              <strong>Nuevo registro</strong>
              <div>
                <Icon name="addWrapper" />
                <Icon name="add" />
              </div>
            </a>
            <a
              className="card-btn lg"
              onClick={() => {
                setRecordsModalIsOpen(true);
              }}
            >
              <strong>Listar registros</strong>
              <div>
                <Icon name="gridWrapper" />
                <Icon name="grid" />
              </div>
            </a>
            {
              config?.hasTags &&
              <a
                className="card-btn lg"
                onClick={() => {
                  setTagsModalIsOpen(true);
                }}
              >
                <strong>Etiquetas</strong>
                <div>
                  <Icon name="tagsWrapper" />
                  <Icon name="tags" />
                </div>
              </a>
            }
            {/* <a
              className="card-btn lg"
              onClick={() => {
                setDeletedRecordsModalIsOpen(true);
              }}
            >
              <strong>Registros Eliminados</strong>
              <div>
                <Icon name="deleteWrapper" />
                <Icon name="delete" />
              </div>
            </a> */}
          </div>
        }

      </div>
      <DataTableModal
        isOpen={recordsModalIsOpen}
        onClose={(res) => {
          console.log(res);
          if (res) {
            setRefreshOnClose(true);
          }
          setRecordsModalIsOpen(false);
        }}
        title={title}
        //tableName={config?.tableName}
        cols={config?.cols}
        dividerGroups={config?.dividerGroups}
        formTitle={config?.formTitle}
        fields={config?.fields}
        rowActions={config?.rowActions}
        moreRowActions={config?.moreRowActions}
        listPath={config?.listPath}
        //deletePath={config?.deletePath}
        createPath={config?.createPath}
        filters={config?.filters}
        //deleteConfirmationMessage={config?.deleteConfirmationMessage}
        //hiddenFilters={[]}
        hiddenActiveFilters={config?.hiddenActiveFilters}
      />
      <FormModal
        isOpen={formModalIsOpen}
        onClose={(res) => {
          console.log(res);
          if (res) {
            setRefreshOnClose(true);
          }
          setFormModalIsOpen(false)
        }}
        title={config?.formTitle}
        fields={config?.fields}
        mode='create'
        extraData={{
          //data_table_id: config?.data_table_id
        }}
        createPath={config?.createPath}
      />
      {
        config?.hasTags &&
        <TagsModal
          isOpen={tagsModalIsOpen}
          visibleGroupName={config?.visibleTagGroupName}
          groupName={config?.tagGroupName}
          onClose={() => {
            setTagsModalIsOpen(false);
          }}
        />
      }

      {/* <DeletedRecordsModal
        isOpen={deletedRecordsModalIsOpen}
        subtitle={''}
        onClose={() => {
          setDeletedRecordsModalIsOpen(false)
        }}
      /> */}
    </>
  );
};

export { DataTableExpandedCard };
