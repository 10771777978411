import { useState, useEffect } from "react";
import "./PasswordField.scss";
import Icon from "components/Icon/Icon";
const PasswordField = (props: any) => {
  const { defaultValue = "", label, id, placeholder = '', borderRadius = 10, onChange } = props;
  const [value, setValue] = useState(defaultValue);
  const [isVisible, setIsVisible] = useState(false);

  const showPassword = () => {
    setIsVisible(true);
  };
  const hidePassword = () => {
    setIsVisible(false);
  };
  useEffect(() => {
    if (onChange) onChange(value);
  }, [value]);
  return (
    <div className="password-field-container" style={{
      borderRadius: borderRadius
    }}>
      {label && <label>{label}</label>}
      <div>
        <input
          placeholder={placeholder}
          id={id}
          type={isVisible ? "text" : "password"}
          value={value}
          onChange={(e) => setValue(e.target.value)}
        />

        {!isVisible ? (
          <button type="button" onClick={showPassword}>
            <Icon name="show" />
          </button>
        ) : (
          <button type="button" onClick={hidePassword}>
            <Icon name="hide" />
          </button>
        )}
      </div>
    </div>
  );
};

export { PasswordField };
