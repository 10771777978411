import { useState, useEffect, useRef } from "react";
import { IconButton } from "@mui/material";
import "./LottieField.scss";
import { FieldEditControls } from "components/FieldEditControls/FieldEditControls";
import { Spinner } from "components/Spinner/Spinner";
import Icon from "components/Icon/Icon";
import Lottie from "lottie-react";

const LottieField = (props: any) => {
  let {
    editable,
    label,
    editPath,
    onChange,
    name,
    recordId,
    id,
    defaultValue,
    loop = false,
  }: any = props;
  const [value, setValue] = useState(defaultValue as any);
  const [isEditEnabled, setIsEditEnabled] = useState(false);
  const [processing, setProcessing] = useState(false);
  const fileInputRef = useRef(null);


  useEffect(() => {
    if (onChange) {
      console.log("value", value);
      onChange(value);
    }
  }, [value]);
  useEffect(() => {
    console.log(defaultValue);
    setValue(defaultValue);
  }, [defaultValue]);


  // const handleChange = async (e: any) => {
  //   console.log(URL.createObjectURL(e.target.files[0]));
  //   var reader = new FileReader();
  //   const file: File = e.target.files[0];
  //   const extension = file.name.split(".").pop();
  //   console.log(extension);
  //   reader.readAsDataURL(file);
  //   reader.onload = await function () {
  //     console.log(reader.result);
  //     const data = {
  //       content: reader.result,
  //       extension,
  //     };
  //     console.log(data);
  //   };
  //   reader.onerror = function (error) {
  //     console.log("Error: ", error);
  //   };
  //   // Resetear el valor del input de archivo
  //   e.target.value = null;
  // };
  const handleChange = async (e: any) => {
    const file: File = e.target.files[0];
    const extension = file.name.split(".").pop();

    if (extension !== "json") {
      console.log("Por favor selecciona un archivo JSON");
      return;
    }

    var reader = new FileReader();

    reader.onload = function (event) {
      try {
        const jsonContent = JSON.parse(event.target?.result as string); // Parsear el JSON
        setValue(jsonContent); // Establecer el contenido JSON en el estado 'value'
      } catch (error) {
        console.error("Error al parsear el archivo JSON", error);
      }
    };

    reader.onerror = function (error) {
      console.log("Error al leer el archivo: ", error);
    };

    reader.readAsText(file); // Leer el archivo como texto para parsear JSON

    // Resetear el valor del input de archivo
    e.target.value = null;
  };

  const removeFile = () => {
    setValue(null);
  };


  return (
    <div className="lottie-field-container">
      {label && (
        <label>
          <span>{label}</span>
        </label>
      )}

      <div className="content">
        {(recordId || editPath) && editable && !isEditEnabled ? (
          <div className="readonly-content">
            {value ? (
              <div className="file-preview-wrapper">
                <Lottie
                  loop={loop}
                  animationData={value}
                  style={{
                    // position: "absolute", 
                    // width: "100%", 
                    // height: "100%" 
                  }}
                />
              </div>
            ) : (
              <div className="no-value">
                <Icon name="lottie" />
                <span>Sin archivo</span>
              </div>
            )}
          </div>
        ) : (
          <div className="editable-content">
            {processing ? (
              <div className="spinner-wrapper">
                <Spinner size="sm" />
              </div>
            ) : (
              <>
                {!value && (
                  <div className="add-image-btn">
                    <input
                      id={id}
                      accept={'.json'}
                      type="file"
                      onChange={handleChange}
                      ref={fileInputRef}
                    />
                    <div>
                      <div>
                        <Icon name="lottie" />
                        <span>Seleccionar archivo</span>
                      </div>
                      <label htmlFor={id}></label>
                    </div>
                  </div>
                )}
                {value && (
                  <div className="file-preview-wrapper">

                    <Lottie
                      loop={loop}
                      animationData={value}
                      style={{
                        // position: "absolute", 
                        // width: "100%", 
                        // height: "100%" 
                      }}
                    />
                    <IconButton aria-label="delete" onClick={removeFile}>
                      <Icon name="delete" />
                    </IconButton>
                  </div>
                )}
              </>
            )}
          </div>
        )}
      </div>

      {(recordId || editPath) && editable && (
        <div className="btns">
          <FieldEditControls
            value={value}
            fieldName={name}
            editPath={editPath}
            onEditStart={() => {
              setIsEditEnabled(true);
            }}
            onEditCancel={(originalValue) => {
              setIsEditEnabled(false);
              setValue(originalValue);
            }}
            onEditEnd={(success: boolean, originalValue) => {
              setIsEditEnabled(false);
              if (!success) {
                setValue(originalValue);
              }
            }}
          />
        </div>
      )}


    </div>
  );
};

export { LottieField };
