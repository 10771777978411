import React, { useState, useEffect } from "react";
import "./DateFilter.scss";

const DateFilter = ({ label, availableOperators, initialOperator, initialValue, onChange }: any) => {
  const [selectedOperator, setSelectedOperator] = useState(initialOperator || "equal");
  const [value, setValue] = useState(initialValue || [null, null]);
  //const [active, setActive] = useState(false);

  useEffect(() => {
    const isActive = () => {
      if (selectedOperator === "isNull" || selectedOperator === "isNotNull") {
        return true;
      }
      if (selectedOperator === "between") {
        return (value[0] !== null && value[0] !== "") && (value[1] !== null && value[1] !== "");
      }
      return value[0] !== null && value[0] !== "";
    };

    //setActive(isActive());

    if (onChange) {
      onChange({
        operator: selectedOperator,
        value: selectedOperator === "between" ? value : value[0],
        active: isActive()
      });
    }
  }, [selectedOperator, value]);

  const handleOperatorChange = (operator: string) => {
    setSelectedOperator(operator);
    if (operator !== "between") {
      setValue([value[0], null]);
    }
  };

  const handleValueChange = (index: number, newValue: string) => {
    const newValues = [...value];
    newValues[index] = newValue;
    setValue(newValues);
  };

  return (
    <div className="date-filter-container">
      <label className="filter-label">{label}</label>
      <div className="radio-wrapper">
        {availableOperators.map((item: any, index: number) => (
          <a
            key={index}
            className={selectedOperator === item.name ? "selected" : ""}
            onClick={() => handleOperatorChange(item.name)}
          >
            {item.visibleName}
          </a>
        ))}
      </div>
      {selectedOperator !== "isNull" && selectedOperator !== "isNotNull" && (
        <div className="value-wrapper">
          {selectedOperator === "between" ? (
            <>
              <input
                type="date"
                onChange={(e) => handleValueChange(0, e.target.value)}
                value={value[0] || ""}
              />
              <input
                type="date"
                onChange={(e) => handleValueChange(1, e.target.value)}
                value={value[1] || ""}
              />
            </>
          ) : (
            <input
              type="date"
              onChange={(e) => handleValueChange(0, e.target.value)}
              value={value[0] || ""}
            />
          )}
        </div>
      )}
    </div>
  );
};

export { DateFilter };
