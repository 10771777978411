import { useState, useEffect, Fragment } from "react";
import { Autocomplete, TextField, CircularProgress, Box } from "@mui/material";
import Chip from "@mui/material/Chip";
import "./TagsFilter.scss";
import urls from "config/urls";
import AxiosInterceptor from "../../../AxiosInterceptor";

const TagsFilter = ({ initialValue, label, onChange, groupName }: any) => {
  const [selectedOperator] = useState("in"); // Operador por defecto
  const [value, setValue] = useState(initialValue || []);
  const [noOptionsText, setNoOptionsText] = useState("Buscar...");
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const isActive = value.length > 0;

    if (onChange) {
      onChange({
        operator: selectedOperator,
        value: value.map((tag: any) => tag.id),
        active: isActive
      });
    }
  }, [value, selectedOperator]);

  const handleChange = async (event: any) => {
    try {
      if (event.target.value !== "") {
        setNoOptionsText("Sin resultado");
      } else {
        setNoOptionsText("Buscar...");
      }
      setLoading(true);

      let url = `${urls.server}/api/tags?group_name=${groupName}`;

      const res = await AxiosInterceptor.get(`${url}`);
      const data = await res?.data;
      setOptions(data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!open) {
      setOptions([]);
      setLoading(false);
    } else {
      setNoOptionsText("Buscar...");
    }
  }, [open]);

  return (
    <div className="multi-select-filter-container">
      <label className="filter-label">{label}</label>
      <div className="autocomplete-wrapper">
        <Autocomplete
          multiple
          noOptionsText={noOptionsText}
          loadingText="Buscando..."
          open={open}
          onOpen={() => setOpen(true)}
          onClose={() => setOpen(false)}
          isOptionEqualToValue={(option: any, value: any) => option?.id === value?.id}
          getOptionLabel={(option: any) => option?.name}
          options={options}
          renderOption={(props, option) => (
            <Box component="li" {...props}>
              {option?.name}
            </Box>
          )}
          loading={loading}
          onChange={(event: any, newValue: any | null) => {
            setValue(newValue);
          }}
          renderTags={(value: any[], getTagProps) =>
            value.map((option: any, index: number) => (
              <Chip
                style={{ backgroundColor: option?.color }}
                label={option?.name}
                {...getTagProps({ index })}
                key={index}
              />
            ))
          }
          renderInput={(params) => (
            <TextField
              hiddenLabel
              onChange={handleChange}
              {...params}
              size="small"
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <Fragment>
                    {loading ? <CircularProgress color="inherit" size={20} /> : null}
                    {params.InputProps.endAdornment}
                  </Fragment>
                ),
              }}
            />
          )}
          value={value}
        />
      </div>
    </div>
  );
};

export { TagsFilter };
