import React from "react";
import "./ColorCell.scss";
const ColorCell = ({ data, extraData }: any) => {
  return <div className="color-cell-container">
    {
      data && <span style={{ background: data }}></span>
    }
  </div>;
};

export { ColorCell };
