import React, { useState, useEffect } from 'react'
import './ConfigurationItemSelector.scss';
import CardButton from 'components/buttons/CardButton/CardButton';


interface ConfigurationItemSelectorProps {
    items: Array<[]>;
    onSelectItem: (item: any) => void;
}
const ConfigurationItemSelector: React.FC<ConfigurationItemSelectorProps> = ({ items, onSelectItem }) => {


    const handleClickItem = (item: any) => {
        onSelectItem(item);
    };
    return (
        <div className='configuration-item-selector-container'>

            <div className='items-wrapper'>
                {
                    items.map((item: any, index: number) => {
                        return <CardButton
                            size={'md'}
                            key={index}
                            title={item.visible_type}
                            icon={item.icon}
                            footerIcon={'add'}
                            onPress={() => {
                                handleClickItem(item)
                            }} />
                    })
                }
            </div>

        </div>
    )
}

export default ConfigurationItemSelector