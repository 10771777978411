import { useState, useEffect, useRef } from "react";
import AxiosInterceptor from "../../../AxiosInterceptor";
import urls from "config/urls";
import { IconButton } from "@mui/material";
import "./FileField.scss";
import { FieldEditControls } from "components/FieldEditControls/FieldEditControls";
import { Spinner } from "components/Spinner/Spinner";
import Icon from "components/Icon/Icon";
import { ImageViewerModal } from "components/modals/ImageViewerModal/ImageViewerModal";
import { PDFViewerModal } from "components/modals/PDFViewerModal/PDFViewerModal";
const FileField = (props: any) => {
  let {
    editable,
    label,
    editPath,
    onChange,
    recordId,
    accept = "*",
    id,
    defaultValue,
    folder = "files",
  }: any = props;
  const [value, setValue] = useState(defaultValue as any);
  const [isEditEnabled, setIsEditEnabled] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [imageViewerModalIsOpen, setImageViewerModalIsOpen] = useState(false);
  const [pdfViewerModalIsOpen, setPdfViewerModalIsOpen] = useState(false);
  const fileInputRef = useRef(null);
  useEffect(() => {
    if (onChange) {
      onChange(value);
    }
  }, [value]);
  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);


  const handleChange = async (e: any) => {
    console.log(URL.createObjectURL(e.target.files[0]));
    var reader = new FileReader();
    const file: File = e.target.files[0];
    const extension = file.name.split(".").pop();
    const fileSize = file?.size;  // Tamaño del archivo en bytes
    const fileName = file?.name.substring(0, file.name.lastIndexOf('.'));
    console.log(extension);
    reader.readAsDataURL(file);
    reader.onload = await function () {
      console.log(reader.result);
      const data = {
        content: reader.result,
        extension,
        size: fileSize,
        name: fileName,
        folder: folder,
      };
      saveFile(data);
    };
    reader.onerror = function (error) {
      console.log("Error: ", error);
    };
    // Resetear el valor del input de archivo
    e.target.value = null;
  };
  const saveFile = async ({ content, extension, folder, size, name }: any) => {
    setProcessing(true);
    try {
      const res = await AxiosInterceptor.post(`${urls.server}/api/files`, {
        file_content: content,
        extension,
        folder,
      });
      const resData = await res?.data;
      setValue({
        size: size,
        name: name,
        extension: extension,
        path: resData?.path
      });
      setProcessing(false);
    } catch (error: any) {
      setProcessing(false);
    }
  };
  const removeFile = () => {
    setValue(null);
  };

  const downloadFile = async () => {
    try {
      const response = await AxiosInterceptor.get(`${urls.server}/api/files/download`, {
        params: { path: value?.path },
        responseType: 'blob',
      });

      const blob = new Blob([response.data], { type: response.data.type });
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = value?.name || 'download';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(link.href);
    } catch (error) {
      console.error('Error downloading the file:', error);
    }
  };
  return (
    <div className="file-field-container">
      {label && (
        <label>
          <span>{label}</span>
        </label>
      )}

      <div className="content">
        {(recordId || editPath) && editable && !isEditEnabled ? (
          <div className="readonly-content">
            {value ? (
              <div className="img-preview-wrapper">
                {/* <img src={`${urls.server}${value}`} /> */}
              </div>
            ) : (
              <div className="no-value">
                <Icon name="file" />
                <span>Sin archivo</span>
              </div>
            )}
          </div>
        ) : (
          <div className="editable-content">
            {processing ? (
              <div className="spinner-wrapper">
                <Spinner size="sm" />
              </div>
            ) : (
              <>
                {!value?.path && (
                  <div className="add-file-btn">
                    <input
                      id={id}
                      accept={accept}
                      type="file"
                      onChange={handleChange}
                      ref={fileInputRef}
                    />
                    <div>
                      <div>
                        <Icon name="file" />
                        <span>Seleccionar archivo</span>
                      </div>
                      <label htmlFor={id}></label>
                    </div>
                  </div>
                )}
                {value?.path && (
                  <div className="file-preview-wrapper">
                    {/* <img src={`${urls.server}${value}`} /> */}
                    <a onClick={() => {
                      const extension = value?.path.split('.').pop();
                      const imageExtensions = ['jpg', 'jpeg', 'png', 'gif', 'bmp'];
                      const isImage = imageExtensions.includes(extension?.toLowerCase());
                      if (extension == 'pdf') {
                        setPdfViewerModalIsOpen(true);
                      } else if (isImage) {
                        setImageViewerModalIsOpen(true);
                      }
                    }}>
                      <div><b>Nombre: </b>{value?.name}</div>
                      <div><b>Tamaño: </b>{value?.size}</div>
                      <div><b>Extensión: </b>{value?.extension}</div>
                    </a>

                    <div className="btns-wrapper">
                      <IconButton
                        aria-label="delete"
                        onClick={() => removeFile()}
                      >
                        <Icon name="delete" />
                      </IconButton>
                      <IconButton
                        aria-label="download"
                        onClick={() => downloadFile()}
                      >
                        <Icon name="download" />
                      </IconButton>
                    </div>
                  </div>
                )}
              </>
            )}
          </div>
        )}
      </div>

      {(recordId || editPath) && editable && (
        <div className="btns">
          <FieldEditControls
            value={value}
            fieldName={label}
            editPath={editPath}
            onEditStart={() => {
              setIsEditEnabled(true);
            }}
            onEditCancel={(originalValue) => {
              setIsEditEnabled(false);
              setValue(originalValue);
            }}
            onEditEnd={(success: boolean, originalValue) => {
              setIsEditEnabled(false);
              if (!success) {
                setValue(originalValue);
              }
            }}
          />
        </div>
      )}

      <ImageViewerModal
        isOpen={imageViewerModalIsOpen}
        onClose={() => {
          setImageViewerModalIsOpen(false);

        }}
        title={value?.name}
        fileUrl={`${urls.server}/storage/${value?.path}`}
      />
      <PDFViewerModal
        isOpen={pdfViewerModalIsOpen}
        onClose={() => {
          setPdfViewerModalIsOpen(false);

        }}
        title={value?.name}
        fileUrl={`${urls.server}/storage/${value?.path}`}
      />
    </div>
  );
};

export { FileField };
