import React, { useEffect, useState } from 'react';
import AxiosInterceptor from "../../AxiosInterceptor";
import urls from "config/urls";
import FileItem from './FileItem/FileItem';
import Icon from 'components/Icon/Icon';
import './FileList.scss';
import { LoadingButton } from '@mui/lab';
import { Spinner } from 'components/Spinner/Spinner';
import { ImageViewerModal } from 'components/modals/ImageViewerModal/ImageViewerModal';

interface FileListProps {


    listPath: string;
    searchTerm?: string;
    activeFilters?: any[];
    hiddenActiveFilters?: any[];
    limit?: number;
    //canDeleteFiles?: boolean;
    canLinkFilesToUsers?: boolean;
    mode?: 'all' | 'my';
}

const FileList: React.FC<FileListProps> = ({
    //canDeleteFiles = false,
    searchTerm,
    listPath,
    activeFilters = [],
    hiddenActiveFilters = [],
    limit = 3,
    canLinkFilesToUsers = false,
    mode
}) => {
    const [files, setFiles] = useState([] as any[]);
    const [loading, setLoading] = useState(true);
    const [loadingMore, setLoadingMore] = useState(false);
    const [currentItem, setCurrentItem] = useState(null as any);

    const [offset, setOffset] = useState(0);
    const [orderBy, setOrderBy] = useState('updated_at');
    const [orderDirection, setOrderDirection] = useState('desc');

    const fetchFiles = async (reset: boolean = false) => {
        if (reset) {
            setLoading(true);
            setOffset(0);
        } else {
            setLoadingMore(true);
        }

        const combinedFilters = [...activeFilters, ...hiddenActiveFilters];

        try {
            const response = await AxiosInterceptor.get(`${urls.server}/api/${listPath}?offset=${reset ? 0 : offset}&limit=${limit}&order_by=${orderBy}&order_direction=${orderDirection}&search_term=${searchTerm}&filters=${encodeURIComponent(JSON.stringify(combinedFilters))}`);
            const data = response.data;

            if (reset) {
                setFiles(data);
                setOffset(limit);
            } else {
                setFiles(prevFiles => [...prevFiles, ...data]);
                setOffset(prevOffset => prevOffset + limit);
            }
        } catch (error) {
            console.error("Error fetching files", error);
        } finally {
            setLoading(false);
            setLoadingMore(false);
        }
    };

    const handleDelete = async (fileId: number) => {
        // Implement the delete functionality
        // After successful delete, you might want to refetch the files or remove the deleted file from the state
    };

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            fetchFiles(true);
        }, 500); // Ajusta el tiempo de debounce según sea necesario

        return () => clearTimeout(delayDebounceFn);
    }, [searchTerm, activeFilters, hiddenActiveFilters, orderBy, orderDirection]);

    if (loading) {
        return <div className="spinner-wrapper">
            <Spinner visible={loading} />
        </div>;
    }

    return (
        <div className='file-list-container'>
            {
                files?.length == 0 ? (
                    <div className="no-content">
                        <Icon name={'files'} />
                        <p>{searchTerm ? "Sin resultado" : "No hay archivos"}</p>
                    </div>
                ) : <div>
                    <div className="file-list-wrapper">
                        {files.map(file => (
                            <FileItem
                                key={file.id}
                                file={file}
                                canLinkToUser={canLinkFilesToUsers}
                                //canDelete={canDeleteFiles}
                                canDelete={mode == 'all' ? true : false}
                                onDelete={handleDelete}
                                //isPrivate={file.path.includes('private-files')}
                                isPrivate={file.is_private}
                                mode={mode}
                                onClick={() => {
                                    console.log(file)
                                    setCurrentItem(file);
                                }}
                            />
                        ))}
                    </div>
                    {files.length > 0 && (
                        <div className="loading-button-wrapper">
                            <LoadingButton
                                onClick={() => fetchFiles(false)}
                                loading={loadingMore}
                                loadingPosition="start"
                                startIcon={<Icon name="plus" />}
                            >
                                Más registros
                            </LoadingButton>
                        </div>
                    )}
                </div>

            }

        </div>

    );
}

export default FileList;
