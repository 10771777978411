import { useState, useEffect } from "react";
import urls from "config/urls";
import { Button } from "@mui/material";
import "./UsersField.scss";
import { FieldEditControls } from "components/FieldEditControls/FieldEditControls";
import { RecordLinkingModal } from "components/modals/RecordLinkingModal/RecordLinkingModal";
import Icon from "components/Icon/Icon";

const UsersField = (props: any) => {
  let {
    defaultValue = [],
    editable,
    label,
    editPath,
    onChange,
    name,
    subtitle,
    recordId,
    readonly = false,
    mode
  }: any = props;
  const [value, setValue] = useState(defaultValue as any);
  const [isEditEnabled, setIsEditEnabled] = useState(false);

  const [recordLinkingModalIsOpen, setRecordLinkingModalIsOpen] =
    useState(false);

  useEffect(() => {
    if (onChange) {
      onChange(value);
    }
  }, [value]);

  return (
    <div className="users-field-container">
      {/* <div className="field-header">
        {label && (
          <label>
            <span>{label}</span>
          </label>
        )}
        {(recordId || editPath) && editable && (
          <FieldEditControls
            value={value}
            fieldName={name}
            editPath={editPath}
            onEditStart={() => {
              setIsEditEnabled(true);
            }}
            onEditCancel={(originalValue) => {
              setIsEditEnabled(false);
              setValue(originalValue);
            }}
            onEditEnd={(success: boolean, originalValue) => {
              setIsEditEnabled(false);
              if (!success) {
                setValue(originalValue);
              }
            }}
          />
        )}
      </div> */}
      <div className="field-header">
        {label && (
          <label>
            <span>{label}</span>
          </label>
        )}
        {editable && (
          <div>
            {(mode == 'edit' && editable) && (
              <FieldEditControls
                value={value}
                fieldName={name}
                editPath={editPath}
                onEditStart={() => {
                  setIsEditEnabled(true);
                }}
                onEditCancel={(originalValue) => {
                  setIsEditEnabled(false);
                  setValue(originalValue);
                }}
                onEditEnd={(success: boolean, originalValue) => {
                  setIsEditEnabled(false);
                  if (!success) {
                    setValue(originalValue);
                  }
                }}
              />
            )}
          </div>
        )}
      </div>
      <div className="field-body">
        <div>
          {mode == 'readonly' ||
            (mode == 'edit' && editable && !isEditEnabled) ||
            (mode == 'edit' && !editable) ? (
            <div className="readonly-content">
              {value.length == 0 ? (
                <div className="no-content">
                  <Icon name="users" />
                  <span>Sin usuarios</span>
                </div>
              ) : (
                <a
                  className="avatars"
                  onClick={() => {
                    setRecordLinkingModalIsOpen(true);
                  }}
                >
                  {value?.map((item: any, index: number) => {
                    return (
                      index < 2 && (
                        <img
                          key={index}
                          className="avatar"
                          src={urls.server + item.profile_picture}
                          alt=""
                        />
                      )
                    );
                  })}
                  {value?.length > 2 && (
                    <div className="additional-count">+ {value.length - 2}</div>
                  )}
                </a>
              )}
            </div>
          ) : (
            <div className="editable-content">
              <a
                className="avatars"
                onClick={() => {
                  setRecordLinkingModalIsOpen(true);
                }}
              >
                {value?.map((item: any, index: number) => {
                  return (
                    index < 2 && (
                      <img
                        key={index}
                        className="avatar"
                        src={urls.server + item.profile_picture}
                        alt=""
                      />
                    )
                  );
                })}
                {value?.length > 2 && (
                  <div className="additional-count">+ {value.length - 2}</div>
                )}
              </a>
              {value.length === 0 && (
                <Button
                  onClick={() => {
                    setRecordLinkingModalIsOpen(true);
                  }}
                >
                  Seleccionar usuario
                </Button>
              )}
            </div>
          )}
        </div>
      </div>
      <RecordLinkingModal
        isOpen={recordLinkingModalIsOpen}
        onClose={(items, refresh) => {
          setRecordLinkingModalIsOpen(false);
          if (items) {
            setValue(items);
          }
          if (refresh) {
            onChange();
          }
        }}
        title="Vincular usuarios"
        subtitle={subtitle}
        readonly={(mode == 'edit' && editable && !isEditEnabled) ||
          (mode == 'edit' && !editable)}
        searchPath="users"
        linkPath={editPath}
        primaryKey="name"
        secondaryKey="email"
        thumbnailKey="profile_picture"
        noContentIcon="users"
        noContentText="No hay usuarios vinculados"
        data={value}
      />
    </div>
  );
};

export { UsersField };
