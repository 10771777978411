import React, { useState, useEffect } from "react";
import "./MyActivityPage.scss";
import { Spinner } from "components/Spinner/Spinner";
import { Fab } from "@mui/material";

import { Header } from "components/Header/Header";
import DynamicList from "components/DynamicList/DynamicList";
import configurationService from "services/configurationService";
import { NewWorkflowEntryModal } from "cards/workflowEntries/components/NewWorkflowEntryModal/NewWorkflowEntryModal";
import { WorkflowEntryDetailModal } from "cards/workflowEntries/components/WorkflowEntryDetailModal/WorkflowEntryDetailModal";
import Icon from "components/Icon/Icon";

const MyActivityPage = () => {
  const [loading, setLoading] = useState(false);
  const [loadingConfig, setLoadingConfig] = useState(false);
  const [data, setData] = useState([]);
  const [searchTerm, setSearchTerm] = React.useState('' as any);
  const [leftCells, setLeftCells] = React.useState([] as any);
  const [rightCells, setRightCells] = React.useState([] as any);
  const [moreItemActions, setMoreItemActions] = React.useState([] as any);
  const [selectedItemId, setSelectedItemId] = React.useState(null as any);
  const [shouldRefresh, setShouldRefresh] = useState(false);
  const [filters, setFilters] = useState([] as any);
  const [activeFilters, setActiveFilters] = useState([] as any);
  const [moreHeaderActions, setMoreHeaderActions] = useState([] as any);
  const [newWorkflowEntryModalIsOpen, setNewWorkflowEntryModalIsOpen] =
    useState(false);
  const [workflowEntryDetailModalIsOpen, setWorkflowEntryDetailModalIsOpen] =
    useState(false);
  const [currentWorkflowEntryId, setCurrentWorkflowEntryId] = useState(null);
  const handleSearchChange = (res: string) => {
    setSearchTerm(res)
  }

  const handleChangeItem = (val: any, item: any, index: number) => {
    setShouldRefresh(true);
  }

  const handleRefreshComplete = () => {
    setShouldRefresh(false);
  };

  useEffect(() => {
    setLoadingConfig(true);
    const configurationNames = [
      "myWorkflowEntryLeftCells",
      "myWorkflowEntryRightCells",
      "myWorkflowEntryFilters",
      "myWorkflowEntryMoreItemActions",
      "myWorkflowEntryMoreHeaderActions",
    ];
    configurationService
      .findByNames(configurationNames)
      .then((configurations) => {

        setLoadingConfig(false);
        configurations.forEach((configuration: any) => {
          switch (configuration.name) {
            case "myWorkflowEntryLeftCells":
              setLeftCells(configuration.json_schema);
              break;
            case "myWorkflowEntryRightCells":
              setRightCells(configuration.json_schema);
              break;
            case "myWorkflowEntryMoreItemActions":
              setMoreItemActions(configuration.json_schema);
              break;
            case "myWorkflowEntryFilters":
              setFilters(configuration.json_schema);
              break;
            case "myWorkflowEntryMoreHeaderActions":
              setMoreHeaderActions(configuration.json_schema);
              break;
            default:
            // Manejar cualquier otro caso
          }
        });
      })
      .catch((error) => {
        setLoadingConfig(false);
        console.log(error);
      });
  }, []);

  return (
    <div className="my-activity-page-container page-container">
      <Header title="Mi actividad" hasSearchBar onSearchChange={handleSearchChange} filters={filters} moreActions={moreHeaderActions} onActiveFiltersChange={(filters) => {
        setActiveFilters(filters)
        console.log(filters);
      }} />
      {!loadingConfig ?
        <>
          <div className="page-body">

            <DynamicList
              className="my-activity-list-container"
              leftCells={leftCells}
              rightCells={rightCells}
              limit={5}
              listPath="my/workflow-entries"
              noContentText="No hay actividad cargada"
              noContentIcon="workflow"
              moreItemActions={moreItemActions}
              onSelectItemAction={(res: any) => {
                console.log(res)
                if (res.actionName == 'view') {
                  setWorkflowEntryDetailModalIsOpen(true);
                  setCurrentWorkflowEntryId(res.currentItem?.id);

                }
              }}
              // deletePath="my/tasks/{{id}}"
              // deleteConfirmationMessage={"Esta seguro que desea eliminar la tarea <b>{{visible_id}}</b>?"}
              forceRefresh={shouldRefresh}
              onRefreshComplete={handleRefreshComplete}
              activeFilters={activeFilters}
              searchTerm={searchTerm}
              onChangeItem={handleChangeItem}
            />
          </div>
          <Fab

            color="primary"
            aria-label="add"
            sx={{
              position: "fixed",
              bottom: 76,
              right: 16,
            }}
            onClick={() => {
              setNewWorkflowEntryModalIsOpen(true);
            }}

          >
            <Icon name="add" sx={{ mr: 0 }} />
          </Fab>

        </>

        : <div className="spinner-wrapper">
          <Spinner visible={loadingConfig} />
        </div>
      }
      <NewWorkflowEntryModal
        isOpen={newWorkflowEntryModalIsOpen}
        onClose={(res: any) => {
          if (res) {
            setShouldRefresh(true)
          }
          setNewWorkflowEntryModalIsOpen(false);
        }}
      />
      <WorkflowEntryDetailModal
        isOpen={workflowEntryDetailModalIsOpen}
        onClose={(res) => {
          console.log(res)
          if (res) {
            setShouldRefresh(true)
          }
          setWorkflowEntryDetailModalIsOpen(false)
        }}
        workflowEntryId={currentWorkflowEntryId}
      //goToStep={1}
      />

    </div>
  );
};

export { MyActivityPage };
