import React, { useEffect, useState } from 'react'
import Modal from 'components/Modal/Modal';
import './DataListModal.scss';
import { Dividers } from 'components/Dividers/Dividers';
import { FormModal } from '../FormModal/FormModal';
import { Fab } from "@mui/material";
import Icon from 'components/Icon/Icon';
import DynamicList from 'components/DynamicList/DynamicList';
import interpolateString from 'utils/interpolateString';
interface DataListModalProps {
  title: string;
  listPath: string;
  createPath: string;
  formTitle: string;
  fields: any[];
  isOpen: boolean;
  dividerGroups?: any[];
  filters?: any[];
  hiddenActiveFilters?: any[];
  itemActions?: any[];
  moreItemActions?: any[];
  leftCells?: any[];
  rightCells?: any[];
  noContentText?: string;
  noContentIcon?: string;
  onSelectItemAction?: (actionName: string, recordId: number) => void;
  onClose: (refreshOnClose?: boolean) => void;
}

const DataListModal: React.FC<DataListModalProps> = ({
  isOpen,
  title,
  formTitle,
  listPath,
  createPath,
  dividerGroups = [],
  filters = [],
  hiddenActiveFilters = [],
  fields = [],
  itemActions = [],
  moreItemActions = [],
  leftCells,
  rightCells,
  noContentText,
  noContentIcon,
  onSelectItemAction,
  onClose
}) => {
  const [editFormModalIsOpen, setEditFormModalIsOpen] = useState(false);
  const [createFormModalIsOpen, setCreateFormModalIsOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [activeFilters, setActiveFilters] = useState([] as any);
  const [activeTabFilters, setActiveTabFilters] = useState([]);
  const [shouldRefresh, setShouldRefresh] = useState(false);
  const [selectedRecordId, setSelectedRecordId] = useState(null);
  const [refreshOnClose, setRefreshOnClose] = useState(false);
  const [viewPath, setViewPath] = useState(null as any);
  const handleRefreshComplete = () => {
    setShouldRefresh(false);
  };
  const handleSelectItemAction = ({ actionName, currentItem, config }: any) => {
    if (actionName == 'view') {
      setEditFormModalIsOpen(true);
      setSelectedRecordId(currentItem?.id);
      setViewPath(interpolateString(currentItem, config?.viewPath));
    }
    if (onSelectItemAction) {
      onSelectItemAction(actionName, currentItem);
    }
  }
  const handleChangeItem = () => { }
  useEffect(() => {
    if (isOpen) {
      setShouldRefresh(true);
    } else {
      setShouldRefresh(false);
    }
  }, [isOpen]);
  return (
    <>
      <Modal
        title={title}
        className={'data-list-modal-container'}
        isOpen={isOpen}
        onClose={() => { onClose(refreshOnClose) }}
        hasSearchBar={true}
        filters={filters}
        onSearchChange={(searchTerm: string) => {
          setSearchTerm(searchTerm);
        }}
        onActiveFiltersChange={(res) => {
          setActiveFilters(res);
        }}
      >
        <div>
          {isOpen && dividerGroups && dividerGroups.length > 0 && (
            <Dividers
              groups={dividerGroups}
              onChange={(res: any) => {
                setActiveTabFilters(res)
              }}
            />
          )}
          {isOpen && (
            <>
              <DynamicList
                className="data-list-container"
                leftCells={leftCells}
                rightCells={rightCells}
                limit={10}
                listPath={listPath}
                activeFilters={activeFilters}
                hiddenActiveFilters={hiddenActiveFilters}
                noContentText={noContentText}
                noContentIcon={noContentIcon}
                moreItemActions={moreItemActions}
                onSelectItemAction={handleSelectItemAction}
                //onSelectMoreItemAction={handleSelectItemAction}
                itemActions={itemActions}
                forceRefresh={shouldRefresh}
                onRefreshComplete={handleRefreshComplete}
                searchTerm={searchTerm}
                onChangeItem={handleChangeItem}
                itemStyle={{
                  // filter: "none",
                  // backgroundColor: colors.light
                }}
              />
              <Fab
                color="primary"
                aria-label="add"
                sx={{
                  position: "fixed",
                  bottom: 16,
                  right: 16,
                }}
                onClick={() => {
                  setCreateFormModalIsOpen(true);
                }}

              >
                <Icon name="add" style={{ marginRight: 0 }} />
              </Fab>
            </>

          )}
        </div>
        <FormModal
          isOpen={editFormModalIsOpen}
          onClose={(res: any) => {
            setEditFormModalIsOpen(false)
            if (res) {
              setShouldRefresh(true)
            } else {
              setShouldRefresh(false)
            }
          }}
          title={formTitle}
          fields={fields}
          viewPath={viewPath}
          mode="edit"
        />
        <FormModal
          isOpen={createFormModalIsOpen}
          onClose={(res: any) => {
            setCreateFormModalIsOpen(false)
            if (res) {
              setShouldRefresh(true)
              setRefreshOnClose(true);
            } else {
              setShouldRefresh(false)
            }
          }}
          title={formTitle}
          fields={fields}
          createPath={createPath}
          mode="create"
        />
      </Modal>

    </>

  )
}

export { DataListModal }