import React, { useEffect, useState } from "react";
import "./MyRingBindersPage.scss";
import { Header } from "components/Header/Header";
import { Spinner } from "components/Spinner/Spinner";
import urls from "config/urls";
import AxiosInterceptor from "../../AxiosInterceptor";
import { Button } from "@mui/material";
import Icon from "components/Icon/Icon";
import { RingBinder } from "components/RingBinder/RingBinder";
import Shelf from "components/Shelf/Shelf";
const MyRingBindersPage = () => {
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = React.useState('' as any);
  const [data, setData] = useState([]);
  const [ringBinderType, setRingBinderType] = useState('viewedFromSide');
  const [moreHeaderActions, setMoreHeaderActions] = useState([] as any);

  const handleSearchChange = (res: string) => {
    setSearchTerm(res)
  }
  const loadData = async () => {
    setLoading(true);
    const res = await AxiosInterceptor.get(
      `${urls.server}/api/my/ring-binders`
    );
    setData(res?.data);
    setLoading(false);
  };
  useEffect(() => {
    loadData();
  }, []);
  const calculateBindersPerShelf = () => {
    const screenWidth = window.innerWidth;
    if (screenWidth < 600) {
      return 3;
    } else if (screenWidth < 900) {
      return 4;
    } else if (screenWidth < 1200) {
      return 5;
    } else {
      return 6;
    }
  };

  // Function to toggle ring binder type through the four types
  const toggleRingBinderType = () => {
    const types = ['horizontal', 'viewedFromFront', 'viewedFromTop', 'viewedFromSide'];
    const currentIndex = types.indexOf(ringBinderType);
    const nextIndex = (currentIndex + 1) % types.length;
    setRingBinderType(types[nextIndex]);
  };

  // Text for button based on ringBinderType
  const buttonText = {
    horizontal: 'Horizontal',
    viewedFromFront: 'Visto desde frente',
    viewedFromTop: 'Visto desde arriba',
    viewedFromSide: 'Visto desde costado'
  }[ringBinderType];

  // Divide binders into shelves if the type is not horizontal
  const bindersPerShelf = calculateBindersPerShelf();
  const shelves = [];
  if (ringBinderType !== 'horizontal') {
    for (let i = 0; i < data.length; i += bindersPerShelf) {
      shelves.push(data.slice(i, i + bindersPerShelf));
    }
  }
  return (
    <div className="my-ring-binders-page-container page-container">
      <Header
        title="Mis biblioteca"
        hasSearchBar
        onSearchChange={handleSearchChange}
        moreActions={moreHeaderActions}
        onSelectMoreAction={(res: any) => {
        }}
      />
      {!loading ?
        <>
          <div className="page-body">
            {
              data.length > 0 ? <div
                style={{
                  width: '100%',
                  maxWidth: "700px",
                  margin: "0 auto",
                }}
              >
                <div style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  marginBottom: 20,
                  paddingRight: 10
                }}>
                  <Button
                    size="small"
                    startIcon={<Icon name="eye" />}
                    onClick={toggleRingBinderType}
                    style={{
                      borderRadius: 99,
                      padding: 5,
                      margin: 0,
                    }}
                  >
                    {buttonText}
                  </Button>
                </div>
                <div>
                  {ringBinderType === 'horizontal' ? (
                    <div className="horizontal-binders">
                      {data.map((binder: any, index) => (
                        <div className="ring-binder-wrapper" key={index}>
                          <RingBinder
                            mode={'my'}
                            id={binder.id}
                            color={binder.color}
                            type={ringBinderType}
                            label={binder?.visible_name}
                            //canDeleteFiles={binder?.can_delete_files}
                            canUploadFiles={binder?.can_upload_files}
                          />
                        </div>
                      ))}
                    </div>
                  ) : (
                    shelves.map((shelf, index) => (
                      <Shelf mode={'my'} key={index} binders={shelf} ringBinderType={ringBinderType} />
                    ))
                  )}
                </div>
              </div> : <>
                <div className="no-content">
                  <Icon name="ringBinders" />
                  <p>No hay biblioratos asignados</p>
                </div>
              </>
            }

          </div>
        </>

        : <div className="spinner-wrapper">
          <Spinner visible={loading} />
        </div>}

    </div>
  );
};

export { MyRingBindersPage };
