import { useState, useEffect, Fragment } from "react";
import Icon from "../../Icon/Icon";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import { FieldEditControls } from "components/FieldEditControls/FieldEditControls";
import "./IconRadioField.scss";

const IconRadioField = (props: any) => {
  let {
    editable,
    label,
    editPath,
    onChange,
    options = [],
    defaultValue,
    //readonly,
    name,
    mode
  }: any = props;

  const [value, setValue] = useState(defaultValue as any);
  const [isEditEnabled, setIsEditEnabled] = useState(false);


  const selectItem = (index: number) => {

    setValue(options[index].name);

  };
  useEffect(() => {
    if (onChange) {
      //console.log(value)
      onChange(value);
    }
  }, [value]);
  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);
  useEffect(() => {
    console.log(options);
  }, [options])
  return (
    <div className="icon-radio-field-container">
      <div className="field-header">
        {label && (
          <label>
            <span>{label}</span>
          </label>
        )}
        {(mode == 'edit' && editable) && (
          <FieldEditControls
            value={value}
            fieldName={name}
            editPath={editPath}
            onEditStart={() => {
              setIsEditEnabled(true);
            }}
            onEditCancel={(originalValue) => {
              setIsEditEnabled(false);
              setValue(originalValue);
            }}
            onEditEnd={(success: boolean, originalValue) => {
              setIsEditEnabled(false);
              if (!success) {
                setValue(originalValue);
              }
            }}
          />
        )}
      </div>
      <div className="field-body">
        <div>
          {mode == 'readonly' ||
            (mode == 'edit' && editable && !isEditEnabled) ||
            (mode == 'edit' && !editable) ? (
            <div className="readonly-item">
              <div>
                {options.find((item: any) => item.name === value) ? (
                  <div>

                    {options.find((item: any) => item.name === value).icon && (
                      <div>
                        <Icon name={`${options.find((item: any) => item.name === value).icon}Wrapper`} />
                        <Icon name={options.find((item: any) => item.name === value).icon} />
                      </div>
                    )}

                    <span>{options.find((item: any) => item.name === value).visibleName}</span>
                  </div>
                ) : (
                  <span>-</span>
                )}
              </div>
            </div>

          ) : (
            <div className="editable-items">

              {options.map((item: any, index: number) => {
                //const shouldShowOption = (!item.showOnEdit || (item.showOnEdit && mode === 'edit'));
                const showInModes = item.showInModes || [];
                const shouldShowOption = showInModes.includes(mode);
                return (
                  <Fragment key={index}>
                    {shouldShowOption && (
                      <a
                        style={{ marginTop: item.icon ? "20px" : "10px" }}
                        onClick={() => selectItem(index)}
                      >
                        {item.icon && (
                          <div>
                            <Icon name={`${item.icon}Wrapper`} />
                            <Icon name={item.icon} />
                          </div>
                        )}
                        <span style={{ marginTop: item.icon ? "20px" : "0px" }}>
                          {item.visibleName}
                        </span>

                        <span style={{ borderRadius: "99px" }}>
                          {item.name == value ? (
                            <RadioButtonCheckedIcon color="primary" />
                          ) : (
                            <RadioButtonUncheckedIcon />
                          )}
                        </span>
                      </a>
                    )}
                  </Fragment>
                );
              })}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export { IconRadioField };
