import React, { useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import { DynamicForm } from "components/DynamicForm/DynamicForm";
import "./FormDialog.scss";
import { DialogContentText, DialogTitle } from "@mui/material";
import urls from "config/urls";
import AxiosInterceptor from "../../../AxiosInterceptor";
import { Toast } from "components/Toast/Toast";
interface DialogProps {
  isOpen: boolean;
  fields: any[];
  title: string;
  description?: string;
  createPath?: string;
  editPath?: string;
  tableName?: string;
  recordId?: number;
  readonly?: boolean;
  extraData?: any;
  //globalEdit?: boolean;
  //mode?: 'create' | 'edit' | 'save';
  mode?: 'create' | 'edit' | 'globalEdit' | 'save' | 'readonly';
  defaultData?: any;
  size?: 'lg' | 'md' | 'sm';
  onClose: (refreshOnClose?: boolean) => void;
  onSubmit?: (data: any) => void;
}
const FormDialog: React.FC<DialogProps> = ({
  isOpen,
  title,
  description,
  fields,
  onClose,
  onSubmit,
  extraData,
  defaultData,
  size = "md",
  createPath,
  editPath,
  mode
}) => {
  const [data, setData] = useState({} as any);
  const [processing, setProcessing] = useState(false);
  const [toastIsOpen, setToastIsOpen] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [toastSeverity, setToastSeverity] = useState("error");
  const handleSave = async () => {
    setProcessing(true);
    const dataToSend = data;
    try {
      if (editPath) {
        const url = `${urls.server}/api/${editPath}`;
        const res = await AxiosInterceptor.put(url, dataToSend);
        const data = await res?.data;
        const { message } = data;
        setToastMessage(message.toString());
        onClose(data);
        setToastIsOpen(true);
        setToastSeverity("success");
      } else if (createPath) {
        const url = `${urls.server}/api/${createPath}`;
        const res = await AxiosInterceptor.post(url, dataToSend);
        const data = await res?.data;
        const { message } = data;
        setToastMessage(message.toString());
        onClose(data);
        setToastIsOpen(true);
        setToastSeverity("success");
      }
      setProcessing(false);

    } catch (error: any) {
      setProcessing(false);
      setToastMessage(error.toString());
      setToastIsOpen(true);
      setToastSeverity("error");
    }
  };

  const handleClose = () => {
    onClose();
  };

  return (
    <>
      <Dialog
        className="form-dialog-container"
        fullWidth
        open={isOpen}
        maxWidth={size}
        onClose={handleClose}
      >
        <DialogTitle className="form-dialog-title">{title}</DialogTitle>
        <DialogContent>
          <DialogContentText className="form-dialog-description">
            {description}
          </DialogContentText>
          <DynamicForm
            fields={fields}
            onChange={(res: any) => {

              setData({ ...extraData, ...res });
            }}
            extraData={extraData}
            defaultData={defaultData}
            mode={mode}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancelar</Button>
          <Button
            disabled={processing}
            onClick={() => {
              if (editPath || createPath) {
                handleSave();
              } else {
                onClose(true);
                if (onSubmit) onSubmit(data);
              }
            }}
          >
            Enviar
          </Button>
        </DialogActions>
      </Dialog>
      <Toast
        isOpen={toastIsOpen}
        onClose={() => setToastIsOpen(false)}
        message={toastMessage}
        severity={toastSeverity}
      />

    </>
  );
};

export { FormDialog };
