import React, { useState, useEffect } from "react";
import { Fab, Button } from "@mui/material";
import AxiosInterceptor from "AxiosInterceptor";
import "./WorkflowEntryDetailModal.scss";
import { Spinner } from "components/Spinner/Spinner";
import { WorkflowEntryStepper } from "../WorkflowEntryStepper/WorkflowEntryStepper";
import { DynamicForm } from "components/DynamicForm/DynamicForm";
import { CommentsDrawer } from "../../../../components/drawers/CommentsDrawer/CommentsDrawer";
import { InteractiveActions } from "../InteractiveActions/InteractiveActions";
import urls from "config/urls";
import colors from "config/colors";
import Modal from "components/Modal/Modal";
import { Toast } from "components/Toast/Toast";
import Icon from "components/Icon/Icon";


const FormWrapper = ({
  formConfig,
  defaultFormData,
  canApprove,
  canComplete,
  minimumApprovals,
  userApprovalStatus,
  stepEntryId,
  status,
  onChange,
  approvals,
  setToastSeverity,
  setToastIsOpen,
  setToastMessage,
  isLocked,
  isEditable,
  readonly,
  stepVisibleName,
  commentCount,
}: any) => {
  const [processing, setProcessing] = useState(false);
  const [openComments, setOpenComments] = useState(false);
  const [data, setData] = useState({} as any);
  const saveData = async () => {
    setProcessing(true);

    try {
      const res = await AxiosInterceptor.post(
        `${urls.server}/api/workflow-step-entries/${stepEntryId}/save-data`,
        { data: data }
      );
      const resData = await res?.data;
      const { message } = resData;
      setToastMessage(message.toString());
      setProcessing(false);
      setToastIsOpen(true);
      setToastSeverity("success");
      onChange(true);
    } catch (error: any) {
      setProcessing(false);
      setToastMessage(error.toString());
      setToastIsOpen(true);
      setToastSeverity("error");
    }
  };

  const saveDraftData = async () => {
    setProcessing(true);

    try {
      const res = await AxiosInterceptor.post(
        `${urls.server}/api/workflow-step-entries/${stepEntryId}/save-draft-data`,
        { data: data }
      );
      const resData = await res?.data;
      const { message } = resData;
      setToastMessage(message.toString());
      setProcessing(false);
      setToastIsOpen(true);
      setToastSeverity("success");
      onChange(true);
    } catch (error: any) {
      setProcessing(false);
      setToastMessage(error.toString());
      setToastIsOpen(true);
      setToastSeverity("error");
    }
  };
  const approve = async () => {
    setProcessing(true);
    try {
      const res = await AxiosInterceptor.post(
        `${urls.server}/api/workflow-step-entries/${stepEntryId}/approve`
      );
      setProcessing(false);
      onChange(true);
    } catch (error) {
      setProcessing(false);
    }
  };
  const reject = async () => {
    setProcessing(true);
    try {
      const res = await AxiosInterceptor.post(
        `${urls.server}/api/workflow-step-entries/${stepEntryId}/reject`
      );
      setProcessing(false);
      onChange(true);
    } catch (error) {
      setProcessing(false);
    }
  };
  return (
    <div className="form-container">
      {(status == "completed" ||
        status == "approved" ||
        status == "partialApproval" ||
        status == "rejected") &&
        canApprove &&
        !isLocked && (
          <div className="approval-container">
            {approvals.length > 0 && (
              <div className="approval-users">
                {approvals.map((item: any, index: number) => {
                  return (
                    <div key={index}>
                      {item.is_approved ? (
                        <span style={{ background: colors.success }}>
                          <Icon name="check" />
                        </span>
                      ) : (
                        <span style={{ background: colors.danger }}>
                          <Icon name="alert" />
                        </span>
                      )}

                      <img
                        key={index}
                        src={urls.server + item.user_profile_picture}
                        alt=""
                        style={{}}
                      />
                      <div>{item.user_name}</div>
                    </div>
                  );
                })}
              </div>
            )}

            <div className="approval-btns">
              <Button
                disabled={userApprovalStatus == "approved"}
                onClick={approve}
                color="success"
              >
                <Icon name="check" />
                Aprobar
              </Button>
              <Button
                disabled={userApprovalStatus == "rejected"}
                onClick={reject}
                color="warning"
              >
                <Icon name="alert" />
                Rechazar
              </Button>
            </div>
          </div>
        )}

      <div>
        {formConfig ? (
          <>
            <div className="paper">
              <span></span>
              <span></span>
              <div>
                {/* {JSON.stringify(form?.fields)} */}
                <DynamicForm
                  fields={formConfig?.fields}
                  onChange={(res: any) => {
                    setData(res);
                    //console.log("form res", res);
                    //setFormData(res);
                  }}
                  title={formConfig?.title}
                  //formData={formData}
                  defaultData={defaultFormData}
                  readonly={
                    (!isEditable && status == "completed") ||
                      !canComplete ||
                      userApprovalStatus == "approved" ||
                      isLocked
                      ? true
                      : false
                  }
                />
              </div>
            </div>

            <Fab
              aria-label="save"
              size="small"
              disabled={processing}
              sx={{
                position: "fixed",
                bottom: !readonly ? 121 : 30,
                right: 16,
                overflow: "inherit",
              }}
              className="comment-fab"
              onClick={() => {
                setOpenComments(true);
              }}
            >
              <span className="badge">
                {commentCount > 99 ? "+99" : commentCount}
              </span>
              <Icon name="comment" />
            </Fab>
            <CommentsDrawer
              open={openComments}
              setOpen={setOpenComments}
              stepVisibleName={stepVisibleName}
              stepEntryId={stepEntryId}
              onClose={(res: boolean) => {
                if (res) onChange(true);
              }}
            />
            {!readonly && (
              <Fab
                //color="primary"
                variant="extended"
                aria-label="save"
                size="small"
                disabled={processing}
                sx={{
                  position: "fixed",
                  bottom: 76,
                  right: 16,
                }}
                onClick={() => {
                  saveDraftData();
                }}
              >
                <Icon name="saveAsDraft" sx={{ mr: 1 }} />
                Borrador
              </Fab>
            )}
            {!readonly && (
              <Fab
                color="primary"
                variant="extended"
                aria-label="save"
                disabled={processing}
                sx={{
                  position: "fixed",
                  bottom: 16,
                  right: 16,
                }}
                onClick={() => {
                  saveData();
                }}
              >
                <Icon name="check" sx={{ mr: 1 }} />
                Guardar
              </Fab>
            )}
          </>
        ) : (
          <div className="no-content">
            <Icon name="lockedDocument" />
            <p>Formulario bloqueado</p>
          </div>
        )}
      </div>
    </div>
  );
};

const StepBody = ({
  type,
  interactiveActions,
  formConfig,
  defaultFormData,
  canApprove,
  canComplete,
  minimumApprovals,
  userApprovalStatus,
  stepEntryId,
  status,
  onChange,
  approvals,
  setToastSeverity,
  setToastIsOpen,
  setToastMessage,
  isLocked,
  isEditable,
  readonly,
  visibleName,
  commentCount,
  canExecute,
}: any) => {
  return (
    <div className="step-body-container">
      {type == "formStep" ? (
        <FormWrapper
          stepEntryId={stepEntryId}
          type={type}
          formConfig={formConfig}
          defaultFormData={defaultFormData}
          canApprove={canApprove}
          canComplete={canComplete}
          minimumApprovals={minimumApprovals}
          status={status}
          userApprovalStatus={userApprovalStatus}
          approvals={approvals}
          setToastSeverity={setToastSeverity}
          setToastIsOpen={setToastIsOpen}
          setToastMessage={setToastMessage}
          isLocked={isLocked}
          isEditable={isEditable}
          readonly={readonly}
          stepVisibleName={visibleName}
          commentCount={commentCount}
          onChange={onChange}
        />
      ) : (
        <>
          <InteractiveActions
            actions={interactiveActions || []}
            commentCount={commentCount}
            stepEntryId={stepEntryId}
            stepVisibleName={visibleName}
            onChange={onChange}
            canExecute={canExecute}
          />
        </>
      )}
    </div>
  );
};
interface ModalProps {
  workflowEntryId: number | null;
  goToStep?: any;
  isOpen: boolean;
  onClose: (refreshOnClose?: boolean) => void;
}
const WorkflowEntryDetailModal: React.FC<ModalProps> = ({
  isOpen,
  onClose,
  workflowEntryId,
  goToStep,
}) => {
  const [refreshOnClose, setRefreshOnClose] = useState(false);
  const [loading, setLoading] = useState(false);
  const [title, setTitle] = useState("");
  const [subtitle, setSubtitle] = useState("");
  const [steps, setSteps] = useState([]);

  const [currentStep, setCurrentStep] = useState(null as any);
  const [isLocked, setIsLocked] = useState(null);
  const [toastIsOpen, setToastIsOpen] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [toastSeverity, setToastSeverity] = useState("error");
  const loadData = async () => {
    setLoading(true);
    try {

      const res = await AxiosInterceptor.get(
        `${urls.server}/api/workflow-entries/${workflowEntryId}/steps`
      );

      const resData = res?.data;
      setTitle(resData?.workflow_visible_name);
      setSubtitle(resData?.visible_id);
      setSteps(resData?.steps);
      setIsLocked(resData?.is_locked);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }

  };
  useEffect(() => {
    if (isOpen) {
      loadData();
      setRefreshOnClose(false)
    } else {
      setSteps([]);
    }
  }, [isOpen]);
  useEffect(() => {
    console.log(currentStep);
  }, [currentStep]);

  return (
    <>
      <Modal
        title={title}
        subtitle={subtitle}
        className={'workflow-entry-detail-modal-container'}
        isOpen={isOpen}
        onClose={() => {
          onClose(refreshOnClose);
        }}


      >
        {isLocked ? (
          <span>
            <Icon name="locked" /> Entrada bloqueada
          </span>
        ) : (
          <></>
        )}
        <div>

          {loading && (
            <div className="spinner-wrapper">
              <Spinner visible={loading} />
            </div>
          )}
          {!loading && (
            <>
              <div className="stepper-wrapper">
                <WorkflowEntryStepper
                  steps={steps}
                  goToStep={goToStep}
                  onStepChange={(res: any) => {
                    setCurrentStep(steps[res]);
                  }}
                />
              </div>
              <div className="step-body-wrapper">
                <StepBody
                  stepEntryId={currentStep?.id}
                  type={currentStep?.type}
                  interactiveActions={currentStep?.interactive_actions}
                  formConfig={currentStep?.form_config}
                  defaultFormData={currentStep?.form_data}
                  canApprove={currentStep?.can_approve}
                  canComplete={currentStep?.can_complete}
                  minimumApprovals={currentStep?.minimum_approvals}
                  status={currentStep?.status}
                  comments={currentStep?.comments}
                  userApprovalStatus={currentStep?.user_approval_status}
                  approvals={currentStep?.approvals}
                  setToastSeverity={setToastSeverity}
                  setToastIsOpen={setToastIsOpen}
                  setToastMessage={setToastMessage}
                  isLocked={isLocked}
                  isEditable={currentStep?.is_editable}
                  readonly={currentStep?.readonly}
                  visibleName={currentStep?.visible_name}
                  commentCount={currentStep?.comment_count}
                  canExecute={currentStep?.can_execute}
                  onChange={(res: boolean) => {
                    if (res) {
                      setCurrentStep(null);
                      loadData();
                      setRefreshOnClose(true);
                      setToastIsOpen(false);
                    }
                  }}
                />
              </div>

              <Toast
                isOpen={toastIsOpen}
                onClose={() => setToastIsOpen(false)}
                message={toastMessage}
                severity={toastSeverity}
              />
            </>
          )}
        </div>
      </Modal>
    </>
  );
};

export { WorkflowEntryDetailModal };
