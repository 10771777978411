import React from 'react'
import './ConfigurationItemEditor.scss';
import { RadioField } from 'components/fields/RadioField/RadioField';
import { SelectField } from 'components/fields/SelectField/SelectField';
import { InputField } from 'components/fields/InputField/InputField';
import { LongTextField } from 'components/fields/LongTextField/LongTextField';
import { CheckboxField } from 'components/fields/CheckboxField/CheckboxField';
import { SliderField } from 'components/fields/SliderField/SliderField';
import { GridField } from 'components/fields/GridField/GridField';
import ConfigurationField from 'components/fields/ConfigurationField/ConfigurationField';
import ListField from 'components/fields/ListField/ListField';
import { DynamicListField } from 'components/fields/DynamicListField/DynamicListField';
import { IconPickerField } from 'components/fields/IconPickerField/IconPickerField';
import { ColorField } from 'components/fields/ColorField/ColorField';
import Icon from 'components/Icon/Icon';
import { MultiCheckboxField } from 'components/fields/MultiCheckboxField/MultiCheckboxField';

interface ConfigurationItemEditorProps {
    configurationType: string;
    data: any;
    isVisible: boolean;
    onHide: (data?: any) => void
}
const ConfigurationItemEditor: React.FC<ConfigurationItemEditorProps> = ({ data = {}, isVisible = false, configurationType, onHide }) => {
    const [modifiedData, setModifiedData] = React.useState(data);
    const [isSaveButtonEnabled, setIsSaveButtonEnabled] = React.useState(false);
    const handleChange = (attributeName: string, value: any) => {
        setModifiedData((prevData: any) => ({
            ...prevData,
            configurable_attributes: {
                ...prevData?.configurable_attributes,
                [attributeName]: value,
            },
        }));
        setIsSaveButtonEnabled(true);
    };
    React.useEffect(() => {
        //if (isVisible) {
        setModifiedData(data);
        //}

        setIsSaveButtonEnabled(false);
        console.log(data);
    }, [
        data,
        //isVisible
    ]);
    return (
        <div className={`configuration-item-editor-container isVisible ${isVisible ? "visible" : "hidden"} `}>
            {
                isVisible && <>
                    <a onClick={() => { onHide() }}>
                        <Icon name="close" />
                    </a>
                    <a onClick={() => { if (isSaveButtonEnabled) onHide(modifiedData) }} className={isSaveButtonEnabled ? "" : "disabled"}>
                        <Icon name="check" />
                    </a>
                </>
            }
            <div>
                {
                    data?.configurable_attributes &&
                    <>
                        {
                            Object.keys(data?.configurable_attributes).map((keyName, i) => (
                                <div key={i}>
                                    {keyName == "name" && (
                                        <InputField
                                            defaultValue={data?.configurable_attributes[keyName]}
                                            label={"Nombre"}
                                            onChange={(value: any) => handleChange(keyName, value)}
                                        />
                                    )}
                                    {keyName == "visibleName" && (
                                        <InputField
                                            defaultValue={data?.configurable_attributes[keyName]}
                                            label={"Nombre a mostar"}
                                            onChange={(value: any) => handleChange(keyName, value)}
                                        />
                                    )}
                                    {keyName == "defaultValue" && (
                                        <InputField
                                            defaultValue={data?.configurable_attributes[keyName]}
                                            label={"Valor por defecto"}
                                            onChange={(value: any) => handleChange(keyName, value)}
                                        />
                                    )}
                                    {keyName == "groupName" && (
                                        <InputField
                                            defaultValue={data?.configurable_attributes[keyName]}
                                            label={"Nombre del grupo"}
                                            onChange={(value: any) => handleChange(keyName, value)}
                                        />
                                    )}
                                    {keyName == "title" && (
                                        <InputField
                                            defaultValue={data?.configurable_attributes[keyName]}
                                            label={"Título"}
                                            onChange={(value: any) => handleChange(keyName, value)}
                                        />
                                    )}
                                    {keyName == "subtitle" && (
                                        <InputField
                                            defaultValue={data?.configurable_attributes[keyName]}
                                            label={"Subtítulo"}
                                            onChange={(value: any) => handleChange(keyName, value)}
                                        />
                                    )}
                                    {keyName == "maxItems" && (
                                        <InputField
                                            defaultValue={data?.configurable_attributes[keyName]}
                                            label={"Numero maximo de items"}
                                            type="integer"
                                            onChange={(value: any) => handleChange(keyName, value)}
                                        />
                                    )}
                                    {keyName == "minItems" && (
                                        <InputField
                                            defaultValue={data?.configurable_attributes[keyName]}
                                            label={"Numero minimo de items"}
                                            type="integer"
                                            onChange={(value: any) => handleChange(keyName, value)}
                                        />
                                    )}
                                    {keyName == "maxLength" && (
                                        <InputField
                                            defaultValue={data?.configurable_attributes[keyName]}
                                            label={"Numero maximo de caracteres"}
                                            type="integer"
                                            onChange={(value: any) => handleChange(keyName, value)}
                                        />
                                    )}
                                    {keyName == "minLength" && (
                                        <InputField
                                            defaultValue={data?.configurable_attributes[keyName]}
                                            label={"Numero minimo de caracteres"}
                                            type="integer"
                                            onChange={(value: any) => handleChange(keyName, value)}
                                        />
                                    )}
                                    {keyName == "aspect" && (
                                        <InputField
                                            defaultValue={data?.configurable_attributes[keyName]}
                                            label={"Relacion de aspecto"}
                                            type="integer"
                                            onChange={(value: any) => handleChange(keyName, value)}
                                        />
                                    )}
                                    {keyName == "abbr" && (
                                        <InputField
                                            defaultValue={data?.configurable_attributes[keyName]}
                                            label={"Abreviatura"}
                                            onChange={(value: any) => handleChange(keyName, value)}
                                        />
                                    )}
                                    {keyName == "path" && (
                                        <InputField
                                            defaultValue={data?.configurable_attributes[keyName]}
                                            label={"Ruta"}
                                            onChange={(value: any) => handleChange(keyName, value)}
                                        />
                                    )}
                                    {keyName == "sendPath" && (
                                        <InputField
                                            defaultValue={data?.configurable_attributes[keyName]}
                                            label={"Ruta de envío"}
                                            onChange={(value: any) => handleChange(keyName, value)}
                                        />
                                    )}

                                    {keyName == "sortPath" && (
                                        <InputField
                                            defaultValue={data?.configurable_attributes[keyName]}
                                            label={"Ruta para ordenar"}
                                            onChange={(value: any) => handleChange(keyName, value)}
                                        />
                                    )}

                                    {/* {keyName == "searchPath" && (
                                        <InputField
                                            defaultValue={data?.configurable_attributes[keyName]}
                                            label={"Ruta de búsqueda"}
                                            onChange={(value: any) => handleChange(keyName, value)}
                                        />
                                    )} */}
                                    {keyName == "linkPath" && (
                                        <InputField
                                            defaultValue={data?.configurable_attributes[keyName]}
                                            label={"Ruta para vincular"}
                                            onChange={(value: any) => handleChange(keyName, value)}
                                        />
                                    )}
                                    {keyName == "importerSubtitle" && (
                                        <InputField
                                            defaultValue={data?.configurable_attributes[keyName]}
                                            label={"Subtítulo del importador"}
                                            onChange={(value: any) => handleChange(keyName, value)}
                                        />
                                    )}
                                    {keyName == "importType" && (
                                        <RadioField
                                            defaultValue={data?.configurable_attributes[keyName]}
                                            label={"Tipo de importación"}
                                            options={
                                                [
                                                    {
                                                        name: "singleRecord",
                                                        visibleName: "Simple",
                                                    },
                                                    {
                                                        name: "multipleRecords",
                                                        visibleName: "Múltiple",
                                                    }
                                                ]
                                            }
                                            onChange={(value: any) => handleChange(keyName, value)}
                                        />
                                    )}

                                    {keyName == "noContentText" && (
                                        <InputField
                                            defaultValue={data?.configurable_attributes[keyName]}
                                            label={"Texto sin contenido"}
                                            onChange={(value: any) => handleChange(keyName, value)}
                                        />
                                    )}

                                    {keyName == "noContentIcon" && (
                                        <>
                                            <IconPickerField
                                                defaultValue={data?.configurable_attributes[keyName]}
                                                label={"Ícono sin contenido"}
                                                description=""
                                                onChange={(value: any) => handleChange(keyName, value)}
                                            />
                                        </>
                                    )}
                                    {keyName == "icon" && (
                                        <>
                                            <IconPickerField
                                                defaultValue={data?.configurable_attributes[keyName]}
                                                label={"Icono"}
                                                description=""
                                                onChange={(value: any) => handleChange(keyName, value)}
                                            />
                                        </>
                                    )}
                                    {keyName == "createFormTitle" && (
                                        <InputField
                                            defaultValue={data?.configurable_attributes[keyName]}
                                            label={"Título de formulario de creación"}
                                            onChange={(value: any) => handleChange(keyName, value)}
                                        />
                                    )}
                                    {keyName == "editFormTitle" && (
                                        <InputField
                                            defaultValue={data?.configurable_attributes[keyName]}
                                            label={"Título de formulario de edición"}
                                            onChange={(value: any) => handleChange(keyName, value)}
                                        />
                                    )}
                                    {keyName == "fileName" && (
                                        <InputField
                                            defaultValue={data?.configurable_attributes[keyName]}
                                            label={"Nombre del archivo"}
                                            onChange={(value: any) => handleChange(keyName, value)}
                                        />
                                    )}
                                    {configurationType == 'fields' && keyName == "size" && (
                                        <>
                                            <SliderField
                                                defaultValue={data?.configurable_attributes[keyName]}
                                                label={"Tamaño"}
                                                onChange={(value: any) => handleChange(keyName, value)}
                                            />
                                        </>
                                    )}
                                    {configurationType == 'dividerGroups' && keyName == "options" && (
                                        <>
                                            <GridField
                                                defaultValue={data?.configurable_attributes[keyName]}
                                                label={"Opciones"}
                                                description=""
                                                forceMobileView={true}
                                                cols={[
                                                    {
                                                        type: "text",
                                                        name: "label",
                                                        visibleName: "Label",
                                                    },
                                                    {
                                                        type: "longText",
                                                        name: "activeFilters",
                                                        visibleName: "Filtros activos",
                                                    }
                                                ]}
                                                onChange={(value: any) => handleChange(keyName, value)}
                                            />
                                        </>
                                    )}
                                    {(configurationType == 'cells' || configurationType == 'cols') && data.type == 'icon' && keyName == "size" && (
                                        <>
                                            <InputField
                                                defaultValue={data?.configurable_attributes[keyName]}
                                                label={"Tamaño"}
                                                type="integer"
                                                onChange={(value: any) => handleChange(keyName, value)}
                                            />
                                        </>
                                    )}
                                    {keyName == "editable" && (
                                        <>
                                            <CheckboxField
                                                defaultValue={data?.configurable_attributes[keyName]}
                                                label={"Se edita?"}
                                                description=""
                                                onChange={(value: any) => handleChange(keyName, value)}
                                            />
                                        </>
                                    )}
                                    {keyName == "readonly" && (
                                        <>
                                            <CheckboxField
                                                defaultValue={data?.configurable_attributes[keyName]}
                                                label={"Solo lectura?"}
                                                description=""
                                                onChange={(value: any) => handleChange(keyName, value)}
                                            />
                                        </>
                                    )}
                                    {keyName == "ordeable" && (
                                        <>
                                            <CheckboxField
                                                defaultValue={data?.configurable_attributes[keyName]}
                                                label={"Se ordena?"}
                                                description=""
                                                onChange={(value: any) => handleChange(keyName, value)}
                                            />
                                        </>
                                    )}
                                    {keyName == "areChildrenSortable" && (
                                        <>
                                            <CheckboxField
                                                defaultValue={data?.configurable_attributes[keyName]}
                                                label={"Los hijos se ordenan?"}
                                                description=""
                                                onChange={(value: any) => handleChange(keyName, value)}
                                            />
                                        </>
                                    )}
                                    {keyName == "searchable" && (
                                        <>
                                            <CheckboxField
                                                defaultValue={data?.configurable_attributes[keyName]}
                                                label={"Es buscable?"}
                                                description=""
                                                onChange={(value: any) => handleChange(keyName, value)}
                                            />
                                        </>
                                    )}
                                    {keyName == "required" && (
                                        <>
                                            <CheckboxField
                                                defaultValue={data?.configurable_attributes[keyName]}
                                                label={"Es requerido?"}
                                                description=""
                                                onChange={(value: any) => handleChange(keyName, value)}
                                            />
                                        </>
                                    )}
                                    {keyName == "showInModes" && (
                                        <>
                                            <MultiCheckboxField
                                                defaultValue={data?.configurable_attributes[keyName]}
                                                label={"Visible en los modo"}
                                                description=""
                                                options={[
                                                    { name: "create", visibleName: "Crear" },
                                                    { name: "edit", visibleName: "Editar" },
                                                    // { name: "globalEdit", visibleName: "Edición global" },
                                                    // { name: "save", visibleName: "Guardar" },
                                                    // { name: "readonly", visibleName: "Solo lectura" },
                                                ]}
                                                onChange={(value: any) => handleChange(keyName, value)}
                                            />
                                        </>
                                    )}
                                    {configurationType == 'fields' && ((data.type == 'grid' && keyName == "cols") || (data.type == 'dynamicList' && keyName == "cells")) && (
                                        <>
                                            <DynamicListField
                                                defaultValue={data?.configurable_attributes[keyName]}
                                                label={keyName == "cols" ? "Columnas" : "Celdas"}
                                                description=""
                                                cells={[
                                                    {
                                                        type: "radio",
                                                        name: "type",
                                                        visibleName: "Tipo de col.",
                                                        options: [
                                                            { name: "text", visibleName: "Texto" },
                                                            {
                                                                name: "longText",
                                                                visibleName: "Texto largo",
                                                            },
                                                            { name: "select", visibleName: "Seleccionar item" },
                                                            {
                                                                name: "money",
                                                                visibleName: "Dinero",
                                                            },
                                                            {
                                                                name: "decimal",
                                                                visibleName: "Núm. decimal",
                                                            },
                                                            {
                                                                name: "integer",
                                                                visibleName: "Núm. entero",
                                                            },
                                                            {
                                                                name: "checkbox",
                                                                visibleName: "Checkbox",
                                                            },
                                                            {
                                                                name: "iconPicker",
                                                                visibleName: "Icono",
                                                            },
                                                            {
                                                                name: "color",
                                                                visibleName: "Color",
                                                            },
                                                            {
                                                                name: "pastelColor",
                                                                visibleName: "Color pastel",
                                                            },
                                                            {
                                                                name: "radio",
                                                                visibleName: "Radio",
                                                            },
                                                            {
                                                                name: "imageCropper",
                                                                visibleName: "Cortador de imágenes",
                                                            },
                                                        ],
                                                    },
                                                    {
                                                        type: "text",
                                                        name: "name",
                                                        visibleName: "Nombre",
                                                    },
                                                    {
                                                        type: "text",
                                                        name: "visibleName",
                                                        visibleName: "Nombre a mostrar",
                                                    },
                                                    {
                                                        type: "text",
                                                        name: "searchPath",
                                                        visibleName: "Path de búsqueda",
                                                        showIf: "type == 'select'"
                                                    },
                                                    {
                                                        type: "text",
                                                        name: "primaryKey",
                                                        visibleName: "Campo principal",
                                                        showIf: "type == 'select'"
                                                    },
                                                    {
                                                        type: "slider",
                                                        name: "size",
                                                        visibleName: "Tamaño",
                                                    },
                                                    {
                                                        type: "checkbox",
                                                        name: "readonly",
                                                        visibleName: "Solo lectura?",
                                                        showIf: "type != 'select'"
                                                    },
                                                    {
                                                        type: "grid",
                                                        name: "options",
                                                        visibleName: "Opciones",
                                                        showIf: "type == 'radio'",
                                                        cols: [
                                                            {
                                                                type: "text",
                                                                name: "name",
                                                                visibleName: "Nombre",
                                                            },
                                                            {
                                                                type: "text",
                                                                name: "visibleName",
                                                                visibleName: "Nombre a mostrar",
                                                            }
                                                        ]
                                                    },
                                                    {
                                                        type: "multiCheckbox",
                                                        name: "onlyTypes",
                                                        visibleName: "Solo tipos",
                                                        showIf: "type == 'iconPicker'",
                                                        options: [
                                                            {
                                                                name: 'singleColor',
                                                                visibleName: 'Monocolor'
                                                            },
                                                            {
                                                                name: 'multiColor',
                                                                visibleName: 'Multicolor'
                                                            },
                                                            {
                                                                name: 'inverted',
                                                                visibleName: 'Invertido'
                                                            },
                                                            {
                                                                name: 'wrapper',
                                                                visibleName: 'Envoltura'
                                                            },
                                                        ]
                                                    },
                                                    {
                                                        type: "multiCheckbox",
                                                        name: "onlyWith",
                                                        visibleName: "Solo con",
                                                        showIf: "type == 'iconPicker'",
                                                        options: [
                                                            {
                                                                name: 'wrapper',
                                                                visibleName: 'Envoltura'
                                                            },
                                                            {
                                                                name: 'inverted',
                                                                visibleName: 'Version invertida'
                                                            }
                                                        ]
                                                    },
                                                ]}
                                                onChange={(value: any) => handleChange(keyName, value)}
                                            />
                                        </>
                                    )}
                                    {configurationType == 'fields' && (data.type == 'iconRadio' || data.type == 'iconCheckboxes') && keyName == "options" && (
                                        <>
                                            <GridField
                                                defaultValue={data?.configurable_attributes[keyName]}
                                                label={"Opciones"}
                                                description=""
                                                forceMobileView={true}
                                                cols={[
                                                    {
                                                        type: "iconPicker",
                                                        name: "icon",
                                                        visibleName: "Icono",
                                                        onlyWith: ['wrapper']
                                                    },
                                                    {
                                                        type: "text",
                                                        name: "name",
                                                        visibleName: "Nombre",
                                                    },
                                                    {
                                                        type: "text",
                                                        name: "visibleName",
                                                        visibleName: "Nombre a mostrar",
                                                    },
                                                    {
                                                        type: "multiCheckbox",
                                                        name: "showInModes",
                                                        visibleName: "Visible en modo",
                                                        options: [
                                                            { name: "create", visibleName: "Crear" },
                                                            { name: "edit", visibleName: "Editar" },
                                                        ]
                                                    },

                                                ]}
                                                onChange={(value: any) => handleChange(keyName, value)}
                                            />
                                        </>
                                    )}
                                    {
                                        ((configurationType == 'fields' && (data.type == 'radio' || data.type == 'multiCheckbox')) || (configurationType == 'filters' && data.type == 'multiCheckbox'))
                                        && keyName == "options" && (
                                            <>
                                                <GridField
                                                    defaultValue={data?.configurable_attributes[keyName]}
                                                    label={"Opciones"}
                                                    description=""
                                                    forceMobileView={true}
                                                    cols={[
                                                        {
                                                            type: "text",
                                                            name: "name",
                                                            visibleName: "Nombre",
                                                        },
                                                        {
                                                            type: "text",
                                                            name: "visibleName",
                                                            visibleName: "Nombre a mostrar",
                                                        }
                                                    ]}
                                                    onChange={(value: any) => handleChange(keyName, value)}
                                                />
                                            </>
                                        )}
                                    {keyName == "canAddItems" && (
                                        <>
                                            <CheckboxField
                                                defaultValue={data?.configurable_attributes[keyName]}
                                                label={"Puede agregar items?"}
                                                description=""
                                                onChange={(value: any) => handleChange(keyName, value)}
                                            />
                                        </>
                                    )}
                                    {keyName == "canRemoveItems" && (
                                        <>
                                            <CheckboxField
                                                defaultValue={data?.configurable_attributes[keyName]}
                                                label={"Puede eliminar items?"}
                                                description=""
                                                onChange={(value: any) => handleChange(keyName, value)}
                                            />
                                        </>
                                    )}
                                    {keyName == "canAddRows" && (
                                        <>
                                            <CheckboxField
                                                defaultValue={data?.configurable_attributes[keyName]}
                                                label={"Puede agregar filas?"}
                                                description=""
                                                onChange={(value: any) => handleChange(keyName, value)}
                                            />
                                        </>
                                    )}
                                    {keyName == "canRemoveRows" && (
                                        <>
                                            <CheckboxField
                                                defaultValue={data?.configurable_attributes[keyName]}
                                                label={"Puede quitar filas?"}
                                                description=""
                                                onChange={(value: any) => handleChange(keyName, value)}
                                            />
                                        </>
                                    )}
                                    {keyName == "forceMobileView" && (
                                        <>
                                            <CheckboxField
                                                defaultValue={data?.configurable_attributes[keyName]}
                                                label={"Forzar vista móvil?"}
                                                description=""
                                                onChange={(value: any) => handleChange(keyName, value)}
                                            />
                                        </>
                                    )}
                                    {keyName == "startIcon" && (
                                        <>
                                            <IconPickerField
                                                defaultValue={data?.configurable_attributes[keyName]}
                                                label={"Icono al inicio"}
                                                description=""
                                                onChange={(value: any) => handleChange(keyName, value)}
                                            />
                                        </>
                                    )}
                                    {keyName == "endIcon" && (
                                        <>
                                            <IconPickerField
                                                defaultValue={data?.configurable_attributes[keyName]}
                                                label={"Icono al final"}
                                                description=""
                                                onChange={(value: any) => handleChange(keyName, value)}
                                            />
                                        </>
                                    )}
                                    {configurationType == 'cols' && data.type == 'status' && keyName == "options" && (
                                        <>
                                            <GridField
                                                defaultValue={data?.configurable_attributes[keyName]}
                                                label={"Estados"}
                                                description=""
                                                forceMobileView={true}
                                                cols={[
                                                    {
                                                        type: "text",
                                                        name: "name",
                                                        visibleName: "Nombre",
                                                    },
                                                    {
                                                        type: "text",
                                                        name: "visibleName",
                                                        visibleName: "Nombre a mostrar",
                                                    },
                                                    {
                                                        type: "iconPicker",
                                                        name: "icon",
                                                        size: 2,
                                                        visibleName: "Icono",
                                                    },
                                                    {
                                                        type: "pastelColor",
                                                        name: "color",
                                                        visibleName: "Color",
                                                        size: 4,
                                                    },


                                                ]}
                                                onChange={(value: any) => {
                                                    console.log("secambio", value);
                                                    handleChange(keyName, value)
                                                }}
                                            />

                                        </>
                                    )}
                                    {configurationType == 'fields' && data.type == 'date' && keyName == "format" && (
                                        <RadioField
                                            defaultValue={data?.configurable_attributes[keyName]}
                                            label={"Formato de fecha"}
                                            description=""
                                            options={
                                                [
                                                    {
                                                        name: "fullDate",
                                                        visibleName: "Fecha",
                                                    },
                                                    // {
                                                    //     name: "fullDateTime",
                                                    //     visibleName: "Fecha y hora",
                                                    // },
                                                    {
                                                        name: "yearOnly",
                                                        visibleName: "Año",
                                                    },
                                                    {
                                                        name: "monthOnly",
                                                        visibleName: "Mes",
                                                    },
                                                    {
                                                        name: "monthYear",
                                                        visibleName: "Mes y año",
                                                    },

                                                ]
                                            }
                                            onChange={(value: any) => handleChange(keyName, value)}

                                        />
                                    )}
                                    {keyName == "configurationType" && (
                                        <RadioField
                                            defaultValue={data?.configurable_attributes[keyName]}
                                            label={"Tipo de configuración"}
                                            description=""
                                            options={
                                                [
                                                    {
                                                        name: "fields",
                                                        visibleName: "Campos",
                                                    },
                                                    {
                                                        name: "cols",
                                                        visibleName: "Columnas",
                                                    },
                                                    {
                                                        name: "cells",
                                                        visibleName: "Celdas",
                                                    },
                                                    {
                                                        name: "filters",
                                                        visibleName: "Filtros",
                                                    },
                                                    {
                                                        name: "rowActions",
                                                        visibleName: "Acciones de la fila",
                                                    },
                                                    {
                                                        name: "itemActions",
                                                        visibleName: "Acciones del item",
                                                    },
                                                    {
                                                        name: "moreRowActions",
                                                        visibleName: "Ottras acciones de la fila",
                                                    },
                                                    {
                                                        name: "moreItemActions",
                                                        visibleName: "Otras acciones del item",
                                                    },
                                                    {
                                                        name: "headerActions",
                                                        visibleName: "Acciones de la cabecera",
                                                    },
                                                    {
                                                        name: "moreHeaderActions",
                                                        visibleName: "Otras acciones de la cabecera",
                                                    },
                                                    {
                                                        name: "dividerGroups",
                                                        visibleName: "Grupos de divisores",
                                                    },
                                                ]
                                            }
                                            onChange={(value: any) => handleChange(keyName, value)}

                                        />
                                    )}
                                    {keyName == "description" && (
                                        <>
                                            <LongTextField
                                                defaultValue={data?.configurable_attributes[keyName]}
                                                label={"Descripción"}
                                                description=""
                                                onChange={(value: any) => handleChange(keyName, value)}
                                            />
                                        </>
                                    )}
                                    {keyName == "deleteConfirmationMessage" && (
                                        <>
                                            <LongTextField
                                                defaultValue={data?.configurable_attributes[keyName]}
                                                label={"Texto de confirmación al eliminar"}
                                                description=""
                                                onChange={(value: any) => handleChange(keyName, value)}
                                            />
                                        </>
                                    )}

                                    {keyName == "editableIf" && (
                                        <>
                                            <LongTextField
                                                defaultValue={data?.configurable_attributes[keyName]}
                                                label={"Se edita si"}
                                                description=""
                                                onChange={(value: any) => handleChange(keyName, value)}
                                            />
                                        </>
                                    )}
                                    {keyName == "readonlyIf" && (
                                        <>
                                            <LongTextField
                                                defaultValue={data?.configurable_attributes[keyName]}
                                                label={"Solo lectura si"}
                                                description=""
                                                onChange={(value: any) => handleChange(keyName, value)}
                                            />
                                        </>
                                    )}
                                    {keyName == "showIf" && (
                                        <>
                                            <LongTextField
                                                defaultValue={data?.configurable_attributes[keyName]}
                                                label={"Mostrar si"}
                                                description=""
                                                onChange={(value: any) => handleChange(keyName, value)}
                                            />
                                        </>
                                    )}
                                    {keyName == "showDiff" && (
                                        <>
                                            <CheckboxField
                                                defaultValue={data?.configurable_attributes[keyName]}
                                                label={"Mostar Hace.../Falta..."}
                                                description=""
                                                onChange={(value: any) => handleChange(keyName, value)}
                                            />
                                        </>
                                    )}
                                    {keyName == "formula" && (
                                        <>
                                            <LongTextField
                                                defaultValue={data?.configurable_attributes[keyName]}
                                                label={"Fórmula"}
                                                description="Ej: Es una operación matematica que involucra a campos previamente definidos"
                                                onChange={(value: any) => handleChange(keyName, value)}
                                            />
                                        </>
                                    )}
                                    {keyName == "htmlContent" && (
                                        <>
                                            <LongTextField
                                                defaultValue={data?.configurable_attributes[keyName]}
                                                label={"Contenido HTML"}

                                                onChange={(value: any) => handleChange(keyName, value)}
                                            />
                                        </>
                                    )}

                                    {keyName == "prepend" && (
                                        <InputField
                                            defaultValue={data?.configurable_attributes[keyName]}
                                            label={"Anteponer"}
                                            placeholder="EJ: $"
                                            onChange={(value: any) => handleChange(keyName, value)}
                                        />
                                    )}
                                    {keyName == "append" && (
                                        <InputField
                                            defaultValue={data?.configurable_attributes[keyName]}
                                            label={"Postponer"}
                                            placeholder="EJ: %"
                                            onChange={(value: any) => handleChange(keyName, value)}
                                        />
                                    )}
                                    {keyName == "fieldName" && (
                                        <InputField
                                            defaultValue={data?.configurable_attributes[keyName]}
                                            label={"Nombre del campo"}
                                            placeholder=""
                                            onChange={(value: any) => handleChange(keyName, value)}
                                        />
                                    )}
                                    {keyName == "label" && (
                                        <InputField
                                            defaultValue={data?.configurable_attributes[keyName]}
                                            label={"Label"}
                                            placeholder=""
                                            onChange={(value: any) => handleChange(keyName, value)}
                                        />
                                    )}
                                    {keyName == "color" && (
                                        <ColorField
                                            defaultValue={data?.configurable_attributes[keyName]}
                                            label={"Color"}
                                            onChange={(value: any) => handleChange(keyName, value)}
                                        />
                                    )}
                                    {keyName == "colorKey" && (
                                        <InputField
                                            defaultValue={data?.configurable_attributes[keyName]}
                                            label={"Color key"}
                                            description="Es el nombre de la llave cuyo valor es un color. Ej: {color: '#ccc'}"
                                            onChange={(value: any) => handleChange(keyName, value)}
                                        />
                                    )}
                                    {keyName == "pathsKey" && (
                                        <InputField
                                            defaultValue={data?.configurable_attributes[keyName]}
                                            label={"Paths key"}
                                            description="Es el nombre de la llave cuyo valor es un array de paths (Iconos en svg)."
                                            onChange={(value: any) => handleChange(keyName, value)}
                                        />
                                    )}
                                    {keyName == "primaryKey" && (
                                        <InputField
                                            defaultValue={data?.configurable_attributes[keyName]}
                                            label={"Llave principal"}
                                            onChange={(value: any) => handleChange(keyName, value)}
                                        />
                                    )}
                                    {keyName == "secondaryKey" && (
                                        <InputField
                                            defaultValue={data?.configurable_attributes[keyName]}
                                            label={"Llave secundaria"}
                                            onChange={(value: any) => handleChange(keyName, value)}
                                        />
                                    )}
                                    {keyName == "thumbnailKey" && (
                                        <InputField
                                            defaultValue={data?.configurable_attributes[keyName]}
                                            label={"Llave de la miniatura"}
                                            onChange={(value: any) => handleChange(keyName, value)}
                                            description="Es el nombre del campo de la miniatura"
                                        />
                                    )}
                                    {keyName == "tagGroup" && (
                                        <SelectField
                                            defaultValue={data?.configurable_attributes[keyName]}
                                            label={"Grupo de etiqueta"}
                                            searchPath={"tag-groups"}
                                            primaryKey={"visible_name"}
                                            onChange={(value: any) => handleChange(keyName, value)}
                                        />
                                    )}
                                    {keyName == "transactionType" && (
                                        <RadioField
                                            defaultValue={data?.configurable_attributes[keyName]}
                                            label={"Tipo de transacción"}
                                            options={
                                                [
                                                    {
                                                        name: "sale",
                                                        visibleName: "Venta",
                                                    },
                                                    {
                                                        name: "purchase",
                                                        visibleName: "Compra",
                                                    }
                                                ]
                                            }
                                            onChange={(value: any) => handleChange(keyName, value)}
                                        />
                                    )}

                                    {keyName == "documentType" && (
                                        <RadioField
                                            defaultValue={data?.configurable_attributes[keyName]}
                                            label={"Tipo de documento"}
                                            options={
                                                [
                                                    {
                                                        name: "invoice",
                                                        visibleName: "Factura",
                                                    },
                                                    {
                                                        name: "debitNote",
                                                        visibleName: "Nota de débito",
                                                    },
                                                    {
                                                        name: "creditNote",
                                                        visibleName: "Nota de crédito",
                                                    }
                                                ]
                                            }
                                            onChange={(value: any) => handleChange(keyName, value)}
                                        />
                                    )}
                                    {keyName == "listActions" && (
                                        <ConfigurationField
                                            defaultValue={data?.configurable_attributes[keyName]}
                                            label={"Acciones de la lista"}
                                            configurationType={'listActions'}
                                            onChange={(value: any) => handleChange(keyName, value)}
                                        />
                                    )}
                                    {keyName == "headerActions" && (
                                        <ConfigurationField
                                            defaultValue={data?.configurable_attributes[keyName]}
                                            label={"Acciones de la cabecera"}
                                            configurationType={'headerActions'}
                                            onChange={(value: any) => handleChange(keyName, value)}
                                        />
                                    )}
                                    {keyName == "itemActions" && (
                                        <ConfigurationField
                                            defaultValue={data?.configurable_attributes[keyName]}
                                            label={"Acciones del item"}
                                            configurationType={'itemActions'}
                                            onChange={(value: any) => handleChange(keyName, value)}
                                        />
                                    )}
                                    {keyName == "itemFields" && (
                                        <ConfigurationField
                                            defaultValue={data?.configurable_attributes[keyName]}
                                            label={"Campos del formulario"}
                                            configurationType={'fields'}
                                            onChange={(value: any) => handleChange(keyName, value)}
                                        />
                                    )}
                                    {keyName == "formFields" && (
                                        <ConfigurationField
                                            defaultValue={data?.configurable_attributes[keyName]}
                                            label={"Campos del formulario"}
                                            configurationType={'fields'}
                                            onChange={(value: any) => handleChange(keyName, value)}
                                        />
                                    )}

                                    {keyName == "cells" && (
                                        <ConfigurationField
                                            defaultValue={data?.configurable_attributes[keyName]}
                                            label={"Celdas"}
                                            configurationType={'cells'}
                                            onChange={(value: any) => handleChange(keyName, value)}
                                        />
                                    )}

                                    {keyName == "leftCells" && (
                                        <ConfigurationField
                                            defaultValue={data?.configurable_attributes[keyName]}
                                            label={"Celdas (izquierda)"}
                                            configurationType={'cells'}
                                            onChange={(value: any) => handleChange(keyName, value)}
                                        />
                                    )}

                                    {keyName == "rightCells" && (
                                        <ConfigurationField
                                            defaultValue={data?.configurable_attributes[keyName]}
                                            label={"Celdas (derecha)"}
                                            configurationType={'cells'}
                                            onChange={(value: any) => handleChange(keyName, value)}
                                        />
                                    )}


                                    {keyName == "fields" && (
                                        <ConfigurationField
                                            defaultValue={data?.configurable_attributes[keyName]}
                                            label={"Campos del formulario"}
                                            configurationType={'fields'}
                                            onChange={(value: any) => handleChange(keyName, value)}
                                        />
                                    )}
                                    {keyName == "formTitle" && (
                                        <>
                                            <InputField
                                                defaultValue={data?.configurable_attributes[keyName]}
                                                label={"Título del formulario"}
                                                description=""
                                                onChange={(value: any) => handleChange(keyName, value)}
                                            />
                                        </>
                                    )}
                                    {keyName == "exportPath" && (
                                        <>
                                            <InputField
                                                defaultValue={data?.configurable_attributes[keyName]}
                                                label={"Ruta para exportar"}
                                                description=""
                                                onChange={(value: any) => handleChange(keyName, value)}
                                            />
                                        </>
                                    )}
                                    {keyName == "importPath" && (
                                        <>
                                            <InputField
                                                defaultValue={data?.configurable_attributes[keyName]}
                                                label={"Ruta para importar"}
                                                description=""
                                                onChange={(value: any) => handleChange(keyName, value)}
                                            />
                                        </>
                                    )}
                                    {keyName == "deletePath" && (
                                        <>
                                            <InputField
                                                defaultValue={data?.configurable_attributes[keyName]}
                                                label={"Ruta para eliminar"}
                                                description=""
                                                onChange={(value: any) => handleChange(keyName, value)}
                                            />
                                        </>
                                    )}
                                    {keyName == "searchPath" && (
                                        <>
                                            <InputField
                                                defaultValue={data?.configurable_attributes[keyName]}
                                                label={"Ruta de busqueda"}
                                                description=""
                                                onChange={(value: any) => handleChange(keyName, value)}
                                            />
                                        </>
                                    )}
                                    {keyName == "createPath" && (
                                        <>
                                            <InputField
                                                defaultValue={data?.configurable_attributes[keyName]}
                                                label={"Ruta de creación"}
                                                description=""
                                                onChange={(value: any) => handleChange(keyName, value)}
                                            />
                                        </>
                                    )}
                                    {keyName == "listPath" && (
                                        <>
                                            <InputField
                                                defaultValue={data?.configurable_attributes[keyName]}
                                                label={"Ruta de la lista"}
                                                description=""
                                                onChange={(value: any) => handleChange(keyName, value)}
                                            />
                                        </>
                                    )}
                                    {keyName == "viewPath" && (
                                        <>
                                            <InputField
                                                defaultValue={data?.configurable_attributes[keyName]}
                                                label={"Ruta para ver"}
                                                description=""
                                                onChange={(value: any) => handleChange(keyName, value)}
                                            />
                                        </>
                                    )}
                                    {keyName == "editPath" && (
                                        <>
                                            <InputField
                                                defaultValue={data?.configurable_attributes[keyName]}
                                                label={"Ruta para editar"}
                                                description=""
                                                onChange={(value: any) => handleChange(keyName, value)}
                                            />
                                        </>
                                    )}
                                    {keyName == "searchFields" && (
                                        <>
                                            <ListField
                                                defaultValue={data?.configurable_attributes[keyName]}
                                                label={"Campos de búsqueda"}
                                                description=""
                                                onChange={(value: any) => handleChange(keyName, value)}
                                            />
                                        </>
                                    )}

                                </div>
                            ))
                        }
                    </>
                }
            </div>
        </div>
    )
}

export default ConfigurationItemEditor