import React, { useState, useEffect } from "react";
import urls from "config/urls";
import AxiosInterceptor from "../../../../AxiosInterceptor";
import "./NewWorkflowEntryModal.scss";
import { Spinner } from "../../../../components/Spinner/Spinner";
import { WorkflowEntryDetailModal } from "../WorkflowEntryDetailModal/WorkflowEntryDetailModal";
import Modal from "components/Modal/Modal";
import Icon from "components/Icon/Icon";

const WorkflowItem = ({ data, onSelect }: any) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  //const [workflowEntryId, setWorkflowEntryId] = useState(null);
  const [processing, setProcessing] = useState(false);
  const createEntry = async () => {
    setProcessing(true);
    const res = await AxiosInterceptor.post(
      `${urls.server}/api/workflow-entries`,
      {
        workflow_id: data.id,
        description_template: data.entry_description_template,
      }
    );
    console.log(res);

    //setWorkflowEntryId(res?.data?.record_id);
    onSelect({
      workflowEntryId: res?.data?.record_id
    })
    setProcessing(false);
  };

  return (
    <>
      <button
        onClick={() => {
          createEntry();
        }}
        disabled={processing}
      >
        {data.visible_name}
        <div>
          <span>
            <Icon name="add" />
          </span>
        </div>
      </button>

    </>
  );
};
const NewWorkflowEntryModal = ({ isOpen, onClose }: any) => {
  const [refreshOnClose, setRefreshOnClose] = useState(false);
  const [loading, setLoading] = useState(false);
  const [records, setRecords] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [currentWorkflowEntryId, setCurrentWorkflowEntryId] = useState(null);
  const [detailModalIsOpen,
    setDetailModalIsOpen] = useState(false);
  const loadData = async () => {
    setLoading(true);
    const res = await AxiosInterceptor.get(`${urls.server}/api/my/workflows`);
    setRecords(res?.data);
    setLoading(false);
  };
  useEffect(() => {
    if (isOpen) {
      loadData();
      setRefreshOnClose(false);
    };
  }, [isOpen]);
  useEffect(() => {
    if (currentWorkflowEntryId) {
      setDetailModalIsOpen(true);
    }
  }, [currentWorkflowEntryId]);
  return (
    <>

      <Modal
        title={"Nueva entrada de workflow"}
        className={'new-workflow-entry-modal-container'}
        isOpen={isOpen}
        onClose={() => {
          onClose(refreshOnClose);
        }}
        hasSearchBar={true}
        onSearchChange={(searchTerm: string) => {
          setSearchTerm(searchTerm);
        }}

      >

        <div>
          {loading && (
            <div className="spinner-wrapper">
              <Spinner visible={loading} />
            </div>
          )}
          {!loading && records.length == 0 && (
            <div className="no-content">
              <Icon name="workflow" />
              <p>No hay workflows registrados</p>
            </div>
          )}
          {!loading && records.length > 0 && (
            <>
              <div className="workflow-list">
                {records.map((item: any, index: number) => {
                  return <WorkflowItem key={index} data={item} onSelect={(res: any) => {
                    setCurrentWorkflowEntryId(res?.workflowEntryId);
                    setRefreshOnClose(true);
                  }} />;
                })}
              </div>
            </>
          )}
        </div>
      </Modal>
      {
        isOpen && <WorkflowEntryDetailModal
          isOpen={detailModalIsOpen}
          onClose={() => {
            setDetailModalIsOpen(false)
          }}
          workflowEntryId={currentWorkflowEntryId}
        />
      }

    </>
  );
};

export { NewWorkflowEntryModal };
