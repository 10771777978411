import CardPreview from "components/CardPreview/CardPreview";
import { BasicIndicator } from "../../../../components/BasicIndicator/BasicIndicator";
import "./DataListCard.scss";
const DataListCard = (props: any) => {
  const { title, data, config } = props;
  return (
    <div className="data-list-card-container">
      <h2 className="card-title">{title}</h2>
      <div>
        {/* <BasicIndicator
          color={config.color}
          value={data?.recordsCount || 0}
          label={"Registros"}
        /> */}
        <CardPreview
          type={config?.previewType}
          icon={config?.previewIcon}
          valueDefinitions={config?.previewValueDefinitions}
          data={data}
        />
      </div>
    </div>
  );
};

export { DataListCard };
