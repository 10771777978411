import { useEffect, useState } from "react";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import {
  SortableContainer,
  SortableElement,
  SortableHandle,
} from "react-sortable-hoc";
import IconButton, { IconButtonProps } from "@mui/material/IconButton";
import "./StepList.scss";
import { FormModal } from "../../../../components/modals/FormModal/FormModal";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Popover } from "@mui/material";
import DeleteConfirmationDialog from "components/dialogs/DeleteConfirmationDialog/DeleteConfirmationDialog";

const DragHandle = SortableHandle(() => <DragIndicatorIcon />);

const SortableItem = SortableElement<{
  value: any;
  itemIndex: number;
  onItemChange: any;
  resetState: any;
  workflowStepFields: any;
  workflowStepUserFields: any;
}>(
  ({
    value,
    itemIndex,
    onItemChange,
    resetState,
    workflowStepFields,
    workflowStepUserFields,
  }: {
    value: any;
    itemIndex: number;
    onItemChange: any;
    resetState: any;
    workflowStepFields: any;
    workflowStepUserFields: any;
  }) => {

    const [formModalIsOpen, setFormModalIsOpen] = useState(false);
    const [userFormModalIsOpen, setUserFormModalIsOpen] = useState(false);

    useEffect(() => {
      console.log("value", value);
    }, []);
    // const fields = getFields().step;
    // const userFields = getFields().stepUsers;
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
    const openPopover = Boolean(anchorEl);
    const presentPopover = (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget);
    };
    const [confirmDialogIsOpen, setConfirmDialogIsOpen] = useState(false);
    const closeConfirmDialog = () => {
      setConfirmDialogIsOpen(false);
    };
    const closePopover = () => {
      setAnchorEl(null);
    };

    return (
      <>
        <div className="step-item" style={{ zIndex: 99999999 }}>
          <span>
            <strong>{itemIndex + 1}</strong>
          </span>
          <div>
            <strong>{value.visible_name}</strong>
          </div>
          <div className="btns-wrapper">

            <div className="drag-handle-wrapper">
              <DragHandle />
            </div>

            <IconButton aria-label="delete" onClick={presentPopover}>
              <MoreVertIcon />
            </IconButton>
            <Popover
              id={"workflow-popover-" + value.id}
              open={openPopover}
              anchorEl={anchorEl}
              onClose={closePopover}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
            >
              <div className="popover-items">
                <a
                  onClick={() => {
                    setAnchorEl(null);
                    setFormModalIsOpen(true);
                  }}
                >
                  Editar
                </a>
                <a
                  onClick={() => {
                    setAnchorEl(null);
                    setUserFormModalIsOpen(true);
                  }}
                >
                  Usuarios
                </a>
                <a
                  onClick={() => {
                    {
                      setConfirmDialogIsOpen(true);
                      setAnchorEl(null);
                      /** deletePath -> workflow-steps/{data.id} */
                    }
                  }}
                >
                  Eliminar
                </a>
              </div>
            </Popover>
          </div>
        </div>
        <FormModal
          isOpen={formModalIsOpen}
          onClose={(res: any) => {
            setFormModalIsOpen(false)
            console.log(res);
            if (res) {
              onItemChange(true);
            }
          }}
          //recordId={value?.id}
          title={"Paso del workflow"}
          fields={workflowStepFields}
          //tableName={"workflow-steps"}
          extraData={{ workflow_id: value?.workflow_id }}
          mode={'edit'}
        />
        <FormModal
          isOpen={userFormModalIsOpen}
          onClose={(res: any) => {
            setUserFormModalIsOpen(false)
            console.log(res);
            if (res) {
              onItemChange(true);
            }
          }}
          title={"Usuarios vinculados"}
          fields={workflowStepUserFields}
          //recordId={value?.id}
          //tableName={"workflow-steps"}
          editPath={`workflow-steps/${value?.id}/users`}
          mode={'edit'}
          extraData={{
            workflow_id: value?.workflow_id,
            step_type: value?.type,
            step_requires_approval: value?.requires_approval,
          }}
        />
        <DeleteConfirmationDialog
          open={confirmDialogIsOpen}
          onClose={() => setConfirmDialogIsOpen(false)}
          onConfirm={closeConfirmDialog}
          data={value}
          resetState={resetState}
          deletePath={`workflow-steps/${value.id}`}
          confirmationText={
            <>
              Desea eliminar la entrada <b>{value?.visible_id}</b>
            </>
          }
        />
      </>
    );
  }
);

const SortableList = SortableContainer<{
  items: string[];
  onItemChange: any;
  resetState: any;
  workflowStepFields: any;
  workflowStepUserFields: any
}>(
  ({
    items,
    onItemChange,
    resetState,
    workflowStepFields,
    workflowStepUserFields,
  }: {
    items: string[];
    onItemChange: any;
    resetState: any;
    workflowStepFields: any;
    workflowStepUserFields: any
  }) => {
    return (
      <div className="step-list-container">
        {items.map((value, index) => (
          <SortableItem
            key={`item-${index}`}
            index={index}
            value={value}
            itemIndex={index}
            onItemChange={onItemChange}
            resetState={resetState}
            workflowStepFields={workflowStepFields}
            workflowStepUserFields={workflowStepUserFields}
          />
        ))}
      </div>
    );
  }
);

const StepList = ({ items, onSortEnd, onItemChange, resetState, workflowStepFields,
  workflowStepUserFields }: any) => {
  return (
    <SortableList
      items={items}
      resetState={resetState}
      onSortEnd={onSortEnd}
      onItemChange={onItemChange}
      useDragHandle
      workflowStepFields={workflowStepFields}
      workflowStepUserFields={workflowStepUserFields}
    />
  );
};

export { StepList };
