import React, { useState, useEffect } from "react";
import "./MiniBreakdownChart.scss";

const MiniBreakdownChart = ({ data = [], height = 55, onItemSelect }: any) => {
  const [sortedData, setSortedData] = useState([] as any);
  const [totalValue, setTotalValue] = useState(0 as any);
  const [selectedItemIndex, setSelectedItemIndex] = useState<number | null>(null);

  useEffect(() => {
    if (data) {
      const totalValue = data.reduce(
        (total: number, item: any) => total + item.value,
        0
      );
      setTotalValue(totalValue);

      // Ordena los datos por valor
      const sortedData = [...data].sort((a: any, b: any) => b.value - a.value);
      setSortedData(sortedData);

      // Selecciona el primer ítem por defecto
      if (sortedData.length > 0) {
        setSelectedItemIndex(data.length - 1);
        if (onItemSelect) {
          const firstItem = sortedData[0];
          onItemSelect({
            color: firstItem.color,
            label: firstItem.label,
            value: firstItem.value,
          });
        }
      }
    }
  }, [data]);

  const handleClick = (index: number, item: any) => {
    setSelectedItemIndex(index);
    if (onItemSelect) {
      const { color, label, value } = item;
      onItemSelect({ color, label, value });
    }
  };

  return (
    <div className="mini-breakdown-chart-container">
      <div className="graph" style={{ height: height + "px" }}>
        {sortedData.map((item: any, index: number) => {
          // Calcula el ancho en porcentaje para cada ítem
          const widthPercentage = (item.value / totalValue) * 100;

          return (
            <a
              key={index}
              style={{
                width: `${widthPercentage}%`,
                backgroundColor: item.color,
                border: selectedItemIndex === index ? `2px solid ${item.color}` : "none",
              }}
              onClick={() => handleClick(index, item)}
            ></a>
          );
        })}
      </div>
    </div>
  );
};

export { MiniBreakdownChart };
